export const mockPublicData = {
	results: [
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DDEL",
			status: "Delivered",
			uiStatus: "Delivered",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "October 01, 2024 03:08 PM",
			timezone: null,
			city: "Brentwood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DONR",
			status: "Out for Delivery",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "October 01, 2024 06:10 AM",
			timezone: null,
			city: "Brentwood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DARR",
			status: "Arrived at Post Office",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "October 01, 2024 04:19 AM",
			timezone: null,
			city: "Brentwood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DARR",
			status: "Accepted at USPS Destination Sort Facility",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "October 01, 2024 03:04 AM",
			timezone: null,
			city: "Brentwood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DARR",
			status: "Shipment Received, Package Acceptance Pending",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 30, 2024 01:40 PM",
			timezone: null,
			city: "Brentwood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DDEP",
			status: "Depature Scan: Enroute to USPS",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 28, 2024 12:53 PM",
			timezone: null,
			city: "Edgewood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DDEP",
			status: "Arrived at UPS Facility",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 28, 2024 10:15 AM",
			timezone: null,
			city: "Edgewood",
			state: "NY",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DARR",
			status: "Departed UPS Facility",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 27, 2024 05:01 AM",
			timezone: null,
			city: "Fontana",
			state: "CA",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DDEP",
			status: "Package processed",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 26, 2024 08:17 PM",
			timezone: null,
			city: "Fontana",
			state: "CA",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DDEP",
			status: "In Transit to International Processing Facility",
			uiStatus: "In Transit",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 25, 2024 02:33 PM",
			timezone: null,
			city: "Butner",
			state: "NC",
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
		{
			trackingNumber: "PTHB3B1M",
			carrierTrackingNumber: "803486826904668038",
			uspsTrackingNumber: "92612903033386543400002919",
			carrierName: "UPSMI",
			networkTrackCode: "DLCT",
			status: "Order information received",
			uiStatus: "Label Created",
			sliderPoint: "green tick",
			comments: null,
			alternateStatusCode: null,
			timestamp: "September 25, 2024 05:20 AM",
			timezone: null,
			city: null,
			state: null,
			zip: null,
			latitude: 0.0,
			longitude: 0.0,
		},
	],
	shipFrom: {
		city: "Avenel",
		state: "NJ",
		zip: "07001",
		country: "USA",
	},
	shipTo: {
		city: "Fort Worth",
		state: "TX",
		zip: "76177",
		country: "US",
	},
	weight: {
		unit: "OZ",
		value: 1.0,
	},
	dimension: {
		unit: "inch",
		length: 1.0,
		width: 1.0,
		height: 1.0,
	},
	shipDate: "07/05/2024",
	expectedDate: "07/13/2024",
	carrierTrackingNumber: "XXXXXXXXXXXXXXXXXX",
	uspsTrackingNumber: "92612903033386543400002919",
	carrierName: "UPS Mail Innovations",
};
