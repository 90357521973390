import React, { useContext, useState } from "react";

import { UserContext } from "../../../interfaces";
import ParcelHeader from "./ParcelHeader";
import ParcelDetails from "./ParcelDetails";
import EstimatedCharges from "./EstimatedCharges";

function ParcelDetailScreen({
	trackingId,
	parcelDetails,
	trackingDetails,
	isPublicTracking,
}) {
	const { user } = useContext(UserContext);
	const [trackingData] = useState(trackingDetails);
	const [parcelData] = useState(parcelDetails);

	return (
		<div
			className="flex-grow h-full w-full p-8 ml-2 mt-2 bg-[#181921]"
			id="tracking-logged-in"
		>
			<ParcelHeader
				trackingData={trackingData}
				parcelDatum={parcelData}
				trackingNumber={trackingId}
				isPublicTracking={isPublicTracking}
			/>
			<div className="flex flex-col md:flex-row gap-5 mt-5">
				<div className="flex-1 md:max-w-[50%]">
					<ParcelDetails
						data={parcelData}
						isPublicTracking={isPublicTracking}
					/>
				</div>
				{!isPublicTracking && (user.isShipper || user.isPlatformAdmin) && (
					<div className="flex-1">
						<EstimatedCharges data={trackingData} parcelData={parcelData} />
					</div>
				)}
			</div>
		</div>
	);
}

export default ParcelDetailScreen;
