import React, { useState, useEffect, useCallback, useContext } from "react";
import Trackbar from "../interfaces/TrackTrace/Trackbar";
import Tracker from "../interfaces/TrackTrace/Tracker";
import Tabs from "../interfaces/TrackTrace/Tabs";
import { UserContext } from "../interfaces";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

const TrackTrace = () => {
  const [truckDetails, setTruckDetails] = useState([]);
  const [swimLane, setSwimLane] = useState([]);
  const [mapRoute, setMapRoute] = useState([]);
  const { user, token } = useContext(UserContext);

  const fetchDetails = useCallback(() => {
    if (user && token) {
      fetch(apiUrl + "/truck-detail?truckNumber=GP-88495-003", {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
            setTruckDetails(response);
          } else {
            console.error("Unexpected response format:", response);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [user, token]);

  const fetchSwim = useCallback(() => {
    if (user && token) {
      fetch(apiUrl + "/truck-lane?truckNumber=GP-88495-003", {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
            setSwimLane(response);
          } else {
            console.error("Unexpected response format:", response);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [user, token]);

  const fetchRoute = useCallback(() => {
    if (user && token) {
      fetch(apiUrl + "/truck-map-detail?truckNumber=GP-88495-003", {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((response) => {
          if (response) {
            setMapRoute(response);
          } else {
            console.error("Unexpected response format:", response);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [user, token]);

  useEffect(() => {
    fetchSwim();
    fetchDetails();
    fetchRoute();
  }, [fetchDetails, fetchSwim, fetchRoute]);

  return (
    <div className="flex-grow p-5 ml-2 mt-2">
      <Trackbar details={truckDetails} />
      <Tracker tracking={swimLane} />
      <Tabs route={mapRoute} />
    </div>
  );
};

export default TrackTrace;
