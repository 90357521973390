import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Select,
  Table,
  TableContainer,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { DownloadIcon, WarningTwoIcon } from "@chakra-ui/icons";
import {
  getPartners,
  downloadRateForCarriers,
  downloadNetworkRates,
} from "../../../services";
import { UserContext } from "../../Providers";

const coreServiceUrl = `${process.env.REACT_APP_CORE_SERVICES_URL}`;
const coreServicePath = `${process.env.REACT_APP_CORE_SERVICES_PATH}`;
const apiUrl = coreServiceUrl + coreServicePath;

export function RateCard({ setShowRateCard, handleBack }) {
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [isLoadingFedEx, setIsLoadingFedEx] = useState(false);
  const [carrierRateCards, setCarrierRateCards] = useState([]);
  const [showCarrierRateCards, setShowCarrierRateCards] = useState(false);
  const [formData, setFormData] = useState({
    code: "",
    discountPercent: 40,
    dimFactor: 50,
  });

  const { token } = useContext(UserContext);

  const postGetRatesForCarrier = async (postData) => {
    setIsLoading(true);
    setShowCarrierRateCards(false);
    try {
      const response = await fetch(apiUrl + "/get-rate-for-carriers", {
        method: "POST",
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      });
      const resp = await response.json();
      if (resp.error) {
        setShowCarrierRateCards(false);
        setError("Something went wrong, please try again later");
      } else {
        setShowCarrierRateCards(true);
        setCarrierRateCards(resp.carrierRateCards);
      }
    } catch (error) {
      setShowCarrierRateCards(false);
      setError("Something went wrong, please try again later");
      console.error(`Error fetching Rate:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async function () {
      const resp = await getPartners(token);
      if (resp) {
        setCarrierOptions(resp.carriers);
        setFormData((data) => ({
          ...data,
          code: resp.carriers[0].code,
        }));
      } else {
        setError("Something went wrong, please try again later");
      }
    })();
  }, [token]);

  function handleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }

  function handleBackClick() {
    handleBack();
    setCarrierRateCards([]);
  }

  function handleSubmit(e) {
    e.preventDefault();
    postGetRatesForCarrier(formData);
  }

  function handleDownload() {
    downloadRateForCarriers(formData, setIsLoadingCsv, "rate-card.csv", token);
  }

  function handleFedExDownload() {
    const postData = {
      ...formData,
      code: "FEDEX",
    };
    downloadNetworkRates(postData, setIsLoadingFedEx, "fedex-rate-card.csv", token);
  }

  function Actions() {
    return (
      <Flex mt={5} gap={5} justifyContent={"flex-end"}>
        <Button variant="outline" onClick={handleBackClick}>
          Back
        </Button>
        <Button
          variant="ghost"
          leftIcon={<DownloadIcon />}
          onClick={handleFedExDownload}
          isLoading={isLoadingFedEx}
        >
          Download FedEx Rates
        </Button>
        <Button
          className="chakra-primary-button"
          leftIcon={<DownloadIcon />}
          onClick={handleDownload}
          isLoading={isLoadingCsv}
          isDisabled={carrierRateCards.length === 0}
        >
          Download Rates
        </Button>
      </Flex>
    );
  }

  return (
    <>
      <Heading as={"h3"} size={"md"} fontWeight={"normal"} my={7}>
        Shipment Cost Table
      </Heading>
      {error && (
        <Alert status="error">
          <WarningTwoIcon />
          <Text>{error}</Text>
        </Alert>
      )}
      <form onSubmit={(e) => handleSubmit(e)}>
        <Flex gap={5}>
          <FormControl>
            <FormLabel>Carrier</FormLabel>
            <Select
              name="code"
              value={formData.code}
              onChange={(e) => handleChange(e)}
            >
              {carrierOptions.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>FedEx Discount %</FormLabel>
            <Input
              name="discountPercent"
              required
              pattern="\d{1,2}"
              min="0"
              value={formData.discountPercent}
              max="90"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>DIM Factor</FormLabel>
            <Input
              name="dimFactor"
              required
              pattern="\d{1,3}"
              min="0"
              value={formData.dimFactor}
              max="450"
              onChange={(e) => handleChange(e)}
            />
          </FormControl>
        </Flex>
        <Flex mt={7} mb={7}>
          <Button
            type="submit"
            className="chakra-primary-button"
            isLoading={isLoading}
            loadingText="Calculating..."
          >
            Calculate Card
          </Button>
        </Flex>
      </form>
      {showCarrierRateCards && (
        <>
          <hr />
          <Actions />
          <TableContainer my={7}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Weight</Th>
                  {carrierRateCards[0].carrierRates.map((rate) => (
                    <Th key={rate.zone}>{rate.zone}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {carrierRateCards.length > 0 ? (
                  carrierRateCards.map((weightGroup, i) => (
                    <Tr key={i}>
                      <Td>{weightGroup.weight}</Td>
                      {weightGroup.carrierRates.map((zone) => (
                        <Td key={`${i}-${zone.zone}`}>{zone.rate}</Td>
                      ))}
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan="100">
                      <Text textAlign={"center"}>No data</Text>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          {carrierRateCards.length > 0 && <Actions />}
        </>
      )}
    </>
  );
}
