import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Spinner } from "@chakra-ui/react";

export function EmbeddedDocument({ src, title, height, width = "100%" }) {
	const [loading, setLoading] = useState(true);

	return (
		<Box width={width} height={height} position={"relative"}>
			{loading && (
				<div className=" absolute w-full h-full rounded bg-white flex justify-center items-center">
					<Spinner color="black" size={"lg"} />
				</div>
			)}
			<iframe
				src={src}
				title={title}
				height={"100%"}
				width={"100%"}
				className={"rounded"}
				onLoad={() => setLoading(false)}
			/>
		</Box>
	);
}

EmbeddedDocument.propTypes = {
	src: PropTypes.string,
	title: PropTypes.string,
	height: PropTypes.string,
	width: PropTypes.string,
};
