import React from "react";
import Button from "../../../componentLibrary/components/Button";

const DataSlide = ({
	direction,
	title,
	type,
	data,
	event,
	isWebGL,
	isPublicTracking,
}) => {
	const animationClass =
		direction === "left" ? "slide-in-left" : "slide-in-right";

	let destinationCity = "";
	let destinationState = "";
	let destinationAddress = "";
	let destinationZip = "";

	const formatDate = (dateString, type) => {
		const date = new Date(dateString);
		if (type === "day") {
			return date.toLocaleString("default", { weekday: "long" });
		} else {
			const day = date.getDate();
			const month = date.toLocaleString("default", { month: "long" });
			const year = date.getFullYear();
			return `${day} ${month}, ${year}`;
		}
	};

	// if (type === "status") {
	// 	if (data && data[data.length - 1]?.status === "Delivered") {
	// 		if (
	// 			data &&
	// 			data.length > 0 &&
	// 			compareData &&
	// 			compareData[0]?.shipTo?.city &&
	// 			data[data.length - 1]?.city
	// 		) {
	// 			const trimmedDataCity = data[data.length - 1].city
	// 				.replace(/,$/, "")
	// 				.trim();
	// 			const trimmedCompareCity = compareData[0]?.shipTo?.city
	// 				.replace(/,$/, "")
	// 				.trim();

	// 			if (trimmedDataCity === trimmedCompareCity) {
	// 				actualStatus = data[data.length - 1].status;
	// 			} else {
	// 				actualStatus = "In Transit";
	// 			}
	// 		}
	// 	} else {
	// 		actualStatus = data[data.length - 1]?.status;
	// 	}
	// }

	if (isPublicTracking) {
		if (type === "destination" && data && data?.shipTo) {
			destinationCity = data?.shipTo?.city?.trim() + " " || "";
			destinationState = data?.shipTo?.state || "";
			destinationZip = data?.shipTo?.zip || "";
			destinationAddress = data?.shipTo?.address1 || "";
		}
	} else {
		if (type === "destination" && data && data.length > 0 && data[0]?.shipTo) {
			destinationCity = data[0]?.shipTo?.city?.trim() + " " || "";
			destinationState = data[0]?.shipTo?.state || "";
			destinationZip = data[0]?.shipTo?.zip || "";
			destinationAddress = data[0]?.shipTo?.address1 || "";
		}
	}

	return (
		<div className={`data-slide ${animationClass} ml-4`}>
			<div
				className={` ${
					isPublicTracking ? "shadow-peal" : "shadow-teal"
				} w-[80vw] max-w-[400px] lg:max-w-[400px] min-h-[70px] md:min-h-[90px] h-[80%] lg:h-auto pb-2 rounded-sm md:rounded-md bg-[#000000] bg-opacity-40 mb-2 md:mb-5 border-2 border-[#06D8D8] border-opacity-30 ${
					!isPublicTracking && direction === "left"
						? "shadow-lg-rtl"
						: "shadow-lg"
				}`}
			>
				<div className={`flex flex-col ml-6 items-start`}>
					<div className="text-[#A2A2A2] font-semibold text-xs pt-2">
						{title}
					</div>
				</div>
				<div className={`flex flex-col ml-6 items-start`}>
					<div className="mt-1 text-white font-semibold text-lg">
						{type === "id" && (
							<div className="flex flex-col justify-start text-left pb-2 text-base lg:text-md">
								{data && data[0]?.trackingNumber}
								<Button
									className="mt-2 text-sm !px-0 !h-2 text-[#4FC1FF] font-semibold underline underline-offset-2"
									context="outline"
									onClick={event}
								>
									Track another ID
								</Button>
							</div>
						)}
						{type === "destination" && (
							<div className="flex flex-col items-end my-1 text-base lg:text-lg">
								<div
									className="text-base lg:text-md text-white font-semibold text-left leading-5"
									dir="ltr"
								>
									{destinationAddress &&
										destinationAddress.length > 0 &&
										!isPublicTracking && (
											<>
												{destinationAddress}
												<br />
											</>
										)}
									{destinationCity} {destinationState} {destinationZip}
								</div>
							</div>
						)}
						{type === "status" && (
							<div className="flex flex-col justify-start text-base lg:text-md text-left pb-2">
								{data[0]?.uiStatus}
								<Button
									className="mt-2 text-sm !px-0 !h-2 text-[#4FC1FF] font-semibold underline underline-offset-2"
									onClick={event}
									context="outline"
								>
									Tracking History
								</Button>
							</div>
						)}
						{type === "details" && (
							<div className="flex flex-col justify-start text-base lg:text-lg text-left pb-2">
								<Button
									className="mt-2 text-sm !px-0 !h-2 text-[#4FC1FF] underline underline-offset-2"
									onClick={event}
									context="outline"
								>
									Click to View
								</Button>
							</div>
						)}
						{type === "edt" && (
							<div className="flex flex-col mt-1 justify-start">
								<div className="text-base lg:text-md">
									{data &&
										data.length > 0 &&
										(data[0]?.actualDeliveryDate
											? formatDate(data[0]?.actualDeliveryDate)
											: formatDate(data[0]?.expectedDate))}
								</div>
								<div className="text-white justify-start text-left">
									{data &&
										data.length > 0 &&
										(data[0]?.actualDeliveryDate
											? formatDate(data[0]?.actualDeliveryDate, "day")
											: formatDate(data[0]?.expectedDate, "day"))}
								</div>
							</div>
						)}
						{type === "carriers" && (
							<div className="text-base lg:text-md">
								{data && data.length > 0 && data[0]?.originCode}
							</div>
						)}
						{type === "exceptions" && (
							<div
								className={`text-base lg:text-md text-left my-1 ${
									data && data.length > 0 && data[0]?.exception
										? "text-[#FF0000]"
										: "text-[#FFFFFF]"
								}`}
							>
								{data && data.length > 0
									? data[0]?.exception || "No Exception"
									: null}
								{data && data.length === 0 && data[0]?.exception && (
									<Button
										className="mt-2 text-sm !px-0 !h-2 text-[#4FC1FF] underline underline-offset-2"
										onClick={event}
										context="outline"
									>
										Click to View
									</Button>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DataSlide;
