import React, { useContext, useState } from "react";
import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { ArrowUpTrayIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Button, Modal, Popover, UploadFile } from "../../componentLibrary";
import { UserContext } from "../Providers";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;


export const ImportFile = ({
  isDataExists = false,
  isImportFileDisable,
  selectedOption,
  carrierCode,
  endpoint,
  showDownloadTemplateBtn = true,
  onFileSelected = () => {}
}) => {
  const [isFile, setIsFile] = useState();
  const importFileURL = `${apiUrl}/${endpoint}`;
  const { token } = useContext(UserContext);
  const {
    isOpen: isOpenPopOver,
    onClose: onClosePopOver,
    onOpen: onOpenPopOver,
  } = useDisclosure();

  const {
    isOpen: isOpenImportFileModal,
    onClose: onCloseImportFileModal,
    onOpen: onOpenImportFileModal,
  } = useDisclosure();

  const {
    isOpen: isOpenErrorModal,
    onClose: onCloseErrorModal,
    onOpen: onOpenErrorModal,
  } = useDisclosure();


  const downloadFile = async () => {
    if (!endpoint) return;
    try {
      const response = await fetch(importFileURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          file: isFile[0],
          carrierCode: carrierCode,
          participantType: selectedOption ? selectedOption : "CARRIER", // TODO: for testing purpose
        }),
      });
      if (response.ok) {
        const carrierRatesUrl = await response.text();
      } else {
        console.error("Error fetching rate card:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching rate card:", error);
    } finally {
      onCloseImportFileModal();
    }
  };

  return (
    <>
      {isDataExists ? (
        <Popover
          content={
            <PopoverContent
              onClose={onClosePopOver}
              onOpenImportFileModal={onOpenImportFileModal}
            />
          }
          open={isOpenPopOver}
          hasArrow={false}
          side="bottom"
          align="start"
        >
          <Button
            prefixIcon={<ArrowUpTrayIcon />}
            onClick={onOpenPopOver}
            context="outlined"
            disabled={isImportFileDisable}
            className={`${isImportFileDisable ? "!bg-[#292A35]" : ""}`}
          >
            Upload
          </Button>
        </Popover>
      ) : (
        <Button
          prefixIcon={<ArrowUpTrayIcon />}
          onClick={onOpenImportFileModal}
          context="outlined"
        >
          Upload
        </Button>
      )}

      <Modal
        title="Import information using your Excel file"
        open={isOpenImportFileModal}
        onClose={onCloseImportFileModal}
        className={{
          content: "!min-w-[964px] !p-11",
          title: "!text-2xl !text-[#fff]",
          controls: "!hidden",
        }}
      >
        <Flex
          mt={8}
          mr={3}
          flexDirection={"column"}
          gap={6}
          alignItems={"center"}
        >
          <Box className="self-start">
            Upload an Excel file with account data to automatically populate the
            information for the selected account.
          </Box>
          {showDownloadTemplateBtn && (
            <Button
              context="outlined"
              className="text-sm font-medium"
              onClick={downloadFile}
            >
              Download template
            </Button>
          )}
          <UploadFile
            accept={{
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
            className={{
              container: "w-full",
              uploadFile: "h-[372px] !max-w-full",
            }}
            setIsFile={(file) => {
              setIsFile(file);
              if(onFileSelected) {
                onFileSelected(file[0]);
                onCloseImportFileModal();
              }
            }}
          />
        </Flex>
      </Modal>

      <Modal
        open={isOpenErrorModal}
        onClose={onCloseErrorModal}
        className={{
          content: "!min-w-[580px]",
          title: "!hidden",
          closeButton: "!hidden",
          controls: "!hidden",
        }}
      >
        <Flex gap={6} alignItems={"flex-start"}>
          <Box padding={1}>
            <XCircleIcon width={48} stroke="#FF263F" strokeWidth={2} />
          </Box>
          <Flex gap={6} flexDirection={"column"} width={"100%"}>
            <Flex
              paddingY={2}
              gap={6}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <div>
                <Text className="mb-4 text-lg font-medium">
                  Excel file error
                </Text>
                <Text className="">Issue detected in the uploaded file.</Text>
              </div>
              <div>
                <XMarkIcon
                  width={24}
                  className="cursor-pointer"
                  onClick={onCloseErrorModal}
                />
              </div>
            </Flex>
            <Flex paddingY={2} justifyContent={"space-between"}>
              <Button
                context="outlinedSecondary"
                className="w-[142px] font-medium text-sm"
                onClick={onCloseErrorModal}
              >
                Cancel
              </Button>
              <Button
                className="w-[142px] font-medium text-sm"
                onClick={onOpenImportFileModal}
              >
                Re-upload
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

const PopoverContent = ({ onClose, onOpenImportFileModal }) => {
  return (
    <div className="p-2 z-[9999] w-[528px]">
      <Flex
        paddingY={2}
        marginBottom={6}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
      >
        <Box className="text-[#EBEBEB]">
          <Text className="text-[#EBEBEB] text-lg font-medium pb-4">
            Replace Existing Information?
          </Text>
          <Text className="text-base">
            Uploading a file will replace the current information with new data.
            Are you sure you want to proceed?
          </Text>
        </Box>
        <Box>
          <XMarkIcon width={24} onClick={onClose} className="cursor-pointer" />
        </Box>
      </Flex>
      <Flex paddingY={2} justifyContent={"space-between"}>
        <Button
          context="outlinedSecondary"
          className="w-[142px] font-medium"
          onClick={onClose}
        >
          No
        </Button>
        <Button
          className="w-[142px] font-medium"
          onClick={() => {
            onOpenImportFileModal();
            onClose();
          }}
        >
          Yes
        </Button>
      </Flex>
    </div>
  );
};
