import { useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Text } from "@chakra-ui/react";

import { UserContext } from "../Providers";

import classes from "./AccountTabs.module.css";

export function AccountTabs() {
	const { user, viewingAs } = useContext(UserContext);
	const accountPageRoot = "/app/account/configuration";
	const linkClass = ({ isActive }) =>
		isActive ? `${classes.links} ${classes.active}` : classes.links;

	const isCarrierOnly = useMemo(
		() => viewingAs.isCarrier && !viewingAs.isShipper,
		[viewingAs],
	);

	return (
		<nav role="navigation" className={classes.nav}>
			<ul className={classes.navList}>
				{(user.isPlatformAdmin ||
					user.isCarrier ||
					user.isShipper ||
					user.isFinance) && (
					<li>
						<NavLink
							className={linkClass}
							to={accountPageRoot + "/information"}
						>
							<Text>Information</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin || user.isCarrier || user.isShipper) && (
					<li>
						<NavLink
							className={linkClass}
							to={accountPageRoot + "/preferences"}
						>
							<Text>Preferences</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin || user.isCarrier || user.isShipper) && (
					<li>
						<NavLink
							className={linkClass}
							to={accountPageRoot + "/service-types"}
						>
							<Text>Service Type</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin || user.isCarrier || user.isShipper) && (
					<li>
						<NavLink className={linkClass} to={accountPageRoot + "/facilities"}>
							<Text>Facilities</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin || user.isCarrier || user.isShipper) && (
					<li>
						<NavLink
							className={linkClass}
							to={accountPageRoot + "/zip-coverage"}
						>
							<Text>Zip Coverage</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin || user.isCarrier || user.isShipper) && (
					<li>
						<NavLink
							className={linkClass}
							to={accountPageRoot + "/service-attributes"}
						>
							<Text>Service Attribute & Surcharges</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin || user.isCarrier || user.isShipper) && (
					<li>
						<NavLink
							className={linkClass}
							to={accountPageRoot + "/tracking-codes"}
						>
							<Text>
								{isCarrierOnly ? "Tracking" : "Orchestro Tracking Codes"}
							</Text>
						</NavLink>
					</li>
				)}
				{(user.isPlatformAdmin ||
					user.isCarrier ||
					user.isShipper ||
					user.isFinance) && (
					<li>
						<NavLink className={linkClass} to={accountPageRoot + "/rates"}>
							<Text>Rates</Text>
						</NavLink>
					</li>
				)}
			</ul>
		</nav>
	);
}
