import React, { useContext, useEffect, useState } from "react";
import { BaseDrawer, UserContext } from "../";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Spinner,
} from "@chakra-ui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  CloseIcon,
  TimeIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  getPartnerOnAccountRequestById,
  updatePartnerOnAccountRequestById,
} from "../../services";

const AccountValidationSidePanel = ({
  isOpen,
  onClose,
  partnerOnboardingId,
  setIsAccountSidePanelClose,
  currentTab,
}) => {
  const { token } = useContext(UserContext);
  const [accountData, setAccountData] = useState();
  const [selectedStatus, setIsSelectedStatus] = useState("Pending");
  const [currentStatus, setCurrentStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = (id, newStatus) => {
    setIsSelectedStatus(newStatus);
  };

  const handleSave = async () => {
    if (currentTab.toLowerCase() === "approved") {
      onClose();
      return;
    }
    await updatePartnerOnAccountRequestById(accountData?.id, selectedStatus, token);
    onClose();
    setIsAccountSidePanelClose({});
  };

  useEffect(() => {
    const fetchAccountData = () => {
      setIsLoading(true);
      getPartnerOnAccountRequestById(partnerOnboardingId, token)
        .then((response) => {
          setCurrentStatus(response.status);
          setIsLoading(false);
          if (response) setAccountData(response);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    if (isOpen) {
      fetchAccountData();
    }
  }, [isOpen, partnerOnboardingId, token]);

  const statusIcons = {
    pending: <TimeIcon color="#FFC252" />,
    approved: <CheckIcon color="#4BEA9E" />,
    rejected: <CloseIcon color="#FF7B80" />,
  };

  const handleMenuItemClick = (status) => {
    setCurrentStatus(status);
    handleStatusChange(accountData?.id, status);
  };

  return (
    <BaseDrawer
      open={isOpen}
      onClose={onClose}
      drawerHeader={accountData?.companyName}
      closeIcon={<CloseIcon color="#858698" />}
      variant={"deepNavyBlue"}
      size={"sm"}
    >
      <br />
      {isLoading ? (
        <div className="h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="p-3">
          <div className="flex flex-col gap-9">
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                {accountData?.duplicateCompanyName ? (
                  <WarningIcon color="#DA2C17" />
                ) : (
                  <CheckCircleIcon color="#05944F" />
                )}
                <span>Company Name</span>
              </div>
              <div>{accountData?.companyName}</div>
            </div>
            <div className="flex justify-between w-full -mt-8">
              {accountData?.duplicateCompanyName && (
                <div className="flex gap-2 items-center">
                  <span className="ml-5 text-[#FF4F63]">Duplicate entry</span>
                </div>
              )}
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                {accountData?.duplicateCompanyURL ? (
                  <WarningIcon color="#DA2C17" />
                ) : (
                  <CheckCircleIcon color="#05944F" />
                )}
                <span>Company URL</span>
              </div>
              <div>{accountData?.companyURL}</div>
            </div>
            <div className="flex justify-between w-full -mt-8">
              {accountData?.duplicateCompanyURL && (
                <div className="flex gap-2 items-center">
                  <span className="ml-5 text-[#FF4F63]">Duplicate entry</span>
                </div>
              )}
              <div></div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                <span className="ml-5">Person</span>
              </div>
              <div>{`${accountData?.firstName} ${accountData?.lastName}`}</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                <span className="ml-5">Member Type</span>
              </div>
              <div>Carrier</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                {accountData?.verifiedEmail ? (
                  <CheckCircleIcon color="#05944F" />
                ) : (
                  <WarningIcon color="#DA2C17" />
                )}
                <span>Email</span>
              </div>
              <div>{accountData?.email}</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                {accountData?.verifiedContactNumber ? (
                  <CheckCircleIcon color="#05944F" />
                ) : (
                  <WarningIcon color="#DA2C17" />
                )}
                <span>Number</span>
              </div>
              <div>{accountData?.contactNumber}</div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                <span className="ml-5">Status</span>
              </div>
              <div className="flex">
                <Menu>
                  <MenuButton
                    as={currentTab?.toLowerCase() !== "approved" && Button}
                    rightIcon={
                      currentTab?.toLowerCase() !== "approved" && (
                        <ChevronDownIcon />
                      )
                    }
                    className={`flex items-center rounded-md group !h-12 !px-2 ${currentTab?.toLowerCase() === "approved" && "cursor-auto !p-0 !px-0 !h-auto"}`}
                  >
                    <div
                      className={`flex gap-2 items-center p-2 rounded-md !opacity-100 ${
                        currentStatus.toLowerCase() === "approved"
                          ? "custom-green-opacity"
                          : currentStatus.toLowerCase() === "rejected"
                            ? "custom-red-opacity"
                            : "custom-yellow-opacity"
                      }`}
                    >
                      {statusIcons[currentStatus.toLowerCase()]}
                      <span
                        className={`!font-bold ${
                          currentStatus.toLowerCase() === "approved"
                            ? "!text-green-600 !opacity-100"
                            : currentStatus.toLowerCase() === "rejected"
                              ? "!text-red-600 !opacity-100"
                              : "!text-yellow-600 !opacity-100"
                        }`}
                      >
                        {currentStatus}
                      </span>
                    </div>
                  </MenuButton>
                  {currentTab?.toLowerCase() !== "approved" && (
                    <MenuList className="bg-gray-800 text-white border-0 shadow-lg !min-w-44 !p-4">
                      {["Approved", "Rejected", "Pending"].map((status) => {
                        if (
                          status.toLocaleLowerCase() ===
                          currentStatus.toLocaleLowerCase()
                        )
                          return; // TODO: refactor this
                        return (
                          <MenuItem
                            key={status}
                            className={`flex items-center space-x-2 px-4 py-2 mb-2 last:mb-0 rounded-md ${
                              status === "Approved"
                                ? "custom-green-opacity"
                                : status === "Rejected"
                                  ? "custom-red-opacity"
                                  : "custom-yellow-opacity"
                            }`}
                            onClick={() => handleMenuItemClick(status)}
                          >
                            <div className="flex gap-2 items-center">
                              {statusIcons[status.toLowerCase()]}
                            </div>
                            <span
                              className={`font-bold opacity-100 ${
                                status === "Approved"
                                  ? "text-green-600"
                                  : status === "Rejected"
                                    ? "text-red-600"
                                    : "text-yellow-600"
                              }`}
                            >
                              {status}
                            </span>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  )}
                </Menu>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex gap-2 items-center">
                <span className="ml-5"></span>
              </div>
              {currentTab?.toLowerCase() !== "approved" &&<div className="flex gap-3">
                <Button
                  variant="outline"
                  className="border-gray-500 text-white !px-7 py-2 rounded-full border"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  className="!bg-[#3BA0E6] text-white !px-8 py-2 rounded-full"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>}
            </div>
          </div>
        </div>
      )}
    </BaseDrawer>
  );
};

export default AccountValidationSidePanel;
