import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import classes from "../css-files/dispatchDetails.module.css";
import {
  Flex,
  Box,
  Button,
  Card,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
} from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { Page, UserContext } from "../interfaces";

const DispatchDetails = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedName, setSelectedName] = useState("");

  // Use useParams to get the selectedOpenOrder from the URL
  const { selectedOpenOrder } = useParams();

  const { token } = useContext(UserContext);

  const [orderDetails, setOrderDetails] = useState({
    orderNumber: "",
    lane: "",
    pickup: "",
    middleMileCarrier: "",
    dispatchTime: "",
    vehicleLicense: "",
    driverName: "",
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSelectedName(file.name);
    // Additional validation logic
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const coreServiceUrl = `${process.env.REACT_APP_CORE_SERVICES_URL}`;
        const coreServicePath = `${process.env.REACT_APP_CORE_SERVICES_PATH}`;
        const apiUrl = `${coreServiceUrl}${coreServicePath}/get-middle-mile-order-detail`;

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            orderNumber: selectedOpenOrder,
          }),
        });

        const data = await response.json();

        const isoDispatchTime = new Date(data.dispatchedOn).toISOString();

        // Update the state with the fetched order details
        setOrderDetails({
          orderNumber: data.orderNumber,
          lane: data.lane,
          pickup: data.pickupDate,
          middleMileCarrier: data.middleMileCarrierCode,
          dispatchTime: isoDispatchTime,
          vehicleLicense: data.vehicleLicense,
          driverName: data.driverName,
          containersOrdered: data.numberOfContainersOrdered,
          orderPlacedDate: data.orderDate,
          contactName: data.middleMileContactName,
          contactPhoneNo: data.middleMileContactPhoneNumber,
        });
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [selectedOpenOrder]);

  //////////////// SAVE API //////////////////

  const [dispatchTime, setDispatchTime] = useState("");
  const [enteredVehicleLicense, setEnteredVehicleLicense] = useState("");
  const [enteredDriverName, setEnteredDriverName] = useState("");

  const handleSaveClick = async () => {
    try {
      const coreServiceUrl = `${process.env.REACT_APP_CORE_SERVICES_URL}`;
      const coreServicePath = `${process.env.REACT_APP_CORE_SERVICES_PATH}`;
      const apiUrl = `${coreServiceUrl}${coreServicePath}/save-middle-mile-order`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          lane: orderDetails.lane,
          orderNumber: orderDetails.orderNumber,
          numberOfContainersOrdered: orderDetails.containersOrdered,
          orderDate: orderDetails.orderPlacedDate,
          pickupDate: orderDetails.pickup,
          middleMileCarrierCode: orderDetails.middleMileCarrier,
          middleMileContactName: orderDetails.contactName,
          middleMileContactPhoneNumber: orderDetails.contactPhoneNo,
          status: "ON_ROUTE",
          vehicleLicense: enteredVehicleLicense,
          driverName: enteredDriverName,
          billOfLading: "null", // You need to get this value from the user input
          dispatchedOn: dispatchTime,
        }),
      });
      if (response.ok) {
        // Show success alert
        alert("Your Dispatch is saved");
      } else {
        // Show error alert
        const errorResponse = await response.json();
        alert(`Error saving Order: ${errorResponse.message}`);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle other errors, e.g., show an error message
      alert("An unexpected error occurred. Please try again later.");
    }
  };

  const formatDateTimeLocal = (isoDateTime) => {
    const date = new Date(isoDateTime);
    const year = date.getFullYear();
    const month = `${(date.getMonth() + 1).toString().padStart(2, "0")}`;
    const day = `${date.getDate().toString().padStart(2, "0")}`;
    const hours = `${date.getHours().toString().padStart(2, "0")}`;
    const minutes = `${date.getMinutes().toString().padStart(2, "0")}`;
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formattedDispatchTime =
    orderDetails.dispatchTime && formatDateTimeLocal(orderDetails.dispatchTime);

  const formattedPickUpTime =
    orderDetails.pickup && formatDateTimeLocal(orderDetails.pickup);

  return (
    <Page>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
        <Heading as={"h1"} size={"md"}>
          Dispatch Order: {selectedOpenOrder}
        </Heading>
        <Button onClick={handleSaveClick} className="chakra-primary-button">
          Dispatch
        </Button>
      </Flex>
      <Card
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        p={8}
        flex="1"
      >
        <Heading as={"h3"} size={"sm"} mb={7}>
          Dispatch Details
        </Heading>
        <Box w="33%" mb="20px" pr={3}>
          <FormControl>
            <FormLabel fontSize={"sm"}>Order Number</FormLabel>
            <Input isDisabled readOnly value={orderDetails.orderNumber} />
          </FormControl>
        </Box>
        <Flex gap={7} mb={7}>
          <FormControl>
            <FormLabel fontSize={"sm"}>Lane</FormLabel>
            <Input isDisabled readOnly value={orderDetails.lane} />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"sm"}>Pickup</FormLabel>
            <Input
              type="datetime-local"
              isDisabled
              readOnly
              value={formattedPickUpTime}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"sm"}>Middle Mile Carrier</FormLabel>
            <Input isDisabled readOnly value={orderDetails.middleMileCarrier} />
          </FormControl>
        </Flex>

        <Flex gap={7} mb={7}>
          <FormControl>
            <FormLabel fontSize={"sm"}>Dispatch Time</FormLabel>
            <Input
              type="datetime-local"
              value={formattedDispatchTime}
              onChange={(e) => setDispatchTime(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"sm"}>Vehicle License</FormLabel>
            <Input
              id="VehicleLicenseInput"
              value={orderDetails.vehicleLicense || ""}
              onChange={(e) => setEnteredVehicleLicense(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontSize={"sm"}>Driver Name</FormLabel>
            <Input
              id="DriverNameInput"
              value={orderDetails.driverName || ""}
              onChange={(e) => setEnteredDriverName(e.target.value)}
            />
          </FormControl>
        </Flex>

        <Flex justifyContent="space-between">
          <Heading as={"h4"} size={"md"} mb={2}>
            Bill of Lading
          </Heading>
          <Heading as={"h4"} size={"md"} mb={2}>
            Bill of Lading Template
          </Heading>
        </Flex>
        <Flex w="100%">
          <div className={classes.fileUpload}>
            <ArrowUpIcon boxSize="100px" />
            <Text size={"md"}>{selectedName || "Upload Bill of Lading"}</Text>
            <Text size={"sm"} color={"#bbcada"}>
              PDF files are allowed
            </Text>
            <Input type="file" onChange={handleFileChange} />
          </div>
        </Flex>
      </Card>
    </Page>
  );
};

export default DispatchDetails;
