import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Button, Box, Flex, Spinner, Text, Heading } from "@chakra-ui/react";
import { CalendarIcon, DownloadIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Page, TrackingHistory, TrackingImage, UserContext } from "../interfaces";
import Map from "../interfaces/Map";
import { getEventsBasedOnTrackerId } from "../services";
import {
  renderTime,
  renderDate,
  getCurrentStatusFromEvent,
} from "../utilities";
import classes from "../css-files/DetailedTracking.module.css";

function DetailedTracking() {
  const { trackingNumber } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [currentStatus, setCurrentStatus] = useState({
    status: "",
    backgroundColor: "",
    textColor: "",
    padding: "",
  });
  const [latest, setLatest] = useState();

  const { token } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    getEventsBasedOnTrackerId(trackingNumber, token)
      .then((responseData) => {
        if (responseData && responseData.results) {
          setData(responseData.results);

          if (responseData.results.length > 0) {
            setLatest(responseData.results[0]);
            const status = getCurrentStatusFromEvent(
              responseData.results[0].status
            );
            setCurrentStatus(status);
          }
        } else {
          console.error("Unexpected response format:", responseData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [trackingNumber, token]);

  if (isLoading)
    return (
      <Page>
        <Box display="flex" justifyContent="center" my={12}>
          <Spinner />
        </Box>
      </Page>
    );

  return (
    <Page>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
        <Heading as={"h1"} size={"md"}>
          Shipment: {trackingNumber}
        </Heading>
        <Flex gap={2}>
          <Button leftIcon={<CalendarIcon />} variant="ghost">
            Print Label
          </Button>
          <Button leftIcon={<DownloadIcon />} className="chakra-primary-button">
            Export
          </Button>
        </Flex>
      </Flex>
      <div className={classes.flexbox}>
        <div className={classes.leftBox}>
          <div className={classes.textContainer}>
            <Flex mb={3}>
              {currentStatus.status && (
                <>
                  <Text flexBasis={"50%"}>Current Status</Text>
                  <div
                    className={classes.status}
                    style={{
                      backgroundColor: currentStatus.backgroundColor,
                      borderRadius: "10px",
                      padding: currentStatus.padding,
                    }}
                  >
                    <Text
                      className={classes.statusText}
                      fontWeight={"600"}
                      textDecoration={"underline"}
                      color={currentStatus.textColor}
                    >
                      {currentStatus.status}
                    </Text>
                    <InfoOutlineIcon
                      color={currentStatus.textColor}
                      boxSize={5}
                    />

                    {/**************** POPUP ****************/}

                    <div className={classes.popup}>
                      {latest ? (
                        <>
                          <Text fontWeight={"600"} my={3}>
                            <time dateTime={latest.timestamp}>
                              {renderDate(latest.timestamp)},{" "}
                              {renderTime(latest.timestamp)}
                            </time>
                          </Text>
                          <div className={classes.textFlex}>
                            <Text className={classes.left}>Carrier:</Text>
                            <Text className={classes.right}>
                              {latest?.carrierName || "-"}
                            </Text>
                          </div>
                          <div className={classes.textFlex}>
                            <Text className={classes.left}>Status:</Text>
                            <Text className={classes.right}>
                              {latest?.status || "-"}
                            </Text>
                          </div>
                          <div className={classes.textFlex}>
                            <Text className={classes.left}>
                              Delivery Signature:
                            </Text>
                            <Text className={classes.right}>
                              {latest?.deliverySignature || "-"}
                            </Text>
                          </div>
                          <div className={classes.textFlex}>
                            <Text className={classes.left}>Comments:</Text>
                            <Text className={classes.right}>
                              {latest?.comments || "-"}
                            </Text>
                          </div>
                        </>
                      ) : (
                        <Text>No data available</Text>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Flex>

            {/**************** Top details ****************/}

            <Flex mb={3}>
              <Text flexBasis={"50%"}>Created on</Text>
              <Text flexBasis={"50%"}>{latest?.createdOn || "-"}</Text>
            </Flex>
            <Flex mb={3}>
              <Text flexBasis={"50%"}>TNT Status</Text>
              <Text flexBasis={"50%"}>{latest?.tntStatus || "-"}</Text>
            </Flex>
            <Flex mb={3}>
              <Text flexBasis={"50%"}>ETA Destination Hub</Text>
              <Text flexBasis={"50%"}>{latest?.etaDestinationHub || "-"}</Text>
            </Flex>
            <Flex mb={3}>
              <Text flexBasis={"50%"}>Expected Delivery Date</Text>
              <Text flexBasis={"50%"}>
                {latest?.expectedDeliveryDate ? (
                  <time
                    dateTime={format(
                      latest?.expectedDeliveryDate,
                      "yyyy-MM-dd"
                    )}
                  >
                    {latest?.expectedDeliveryDate}
                  </time>
                ) : (
                  "-"
                )}
              </Text>
            </Flex>
            <Flex mb={3}>
              <Text flexBasis={"50%"}>Destination</Text>
              <Text flexBasis={"50%"}>
                {!latest?.city && !latest?.state && "-"}
                {[latest?.city, latest?.state].filter((i) => i).join(", ")}
              </Text>
            </Flex>
          </div>

          {/**************** Timeline ****************/}

          <Heading as={"h3"} size={"sm"} mt={10} mb={5}>
            Tracking History
          </Heading>

          <TrackingHistory data={data} />
        </div>
        <div className={classes.rightBox}>
          <TrackingImage data={data} />
          <Map trackingData={data} />
        </div>
      </div>
    </Page>
  );
}

export default DetailedTracking;
