import {
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import { TableLoading } from "..";
import { renderDate } from "../../utilities";

function ParcelDifferenceInvoice({ data, isOpen = true, isLoading }) {
	return (
		<TableContainer className="w-full mb-5">
			<Table>
				<Thead>
					<Tr>
						<Th></Th>
						<Th>Invoice No.</Th>
						<Th>Date</Th>
						<Th>Amount</Th>
						<Th>Base Rate</Th>
						<Th>Fuel</Th>
						<Th>Correction</Th>
						<Th>Add Sign</Th>
						<Th>Handling</Th>
					</Tr>
				</Thead>
				{isOpen && (
					<Tbody>
						{isLoading && <TableLoading />}
						{((!isLoading && data && data.length === 0) || !data) && (
							<Tr>
								<Td colSpan="100">No data available</Td>
							</Tr>
						)}
						{data &&
							data.map((invoice, index) => {
								return (
									<Tr key={index}>
										<Td>
											<strong>{invoice?.rowTitle ?? "-"}</strong>
										</Td>
										<Td>{invoice?.invoiceNumber ?? "-"}</Td>
										<Td>
											{invoice?.invoiceDate ? (
												<time dateTime={invoice.invoiceDate}>
													{renderDate(invoice.invoiceDate, "MM/dd/yyyy")}
												</time>
											) : (
												"-"
											)}
										</Td>
										<Td>
											{invoice?.totalAmount
												? `$${Number(invoice.totalAmount).toFixed(2)}`
												: "-"}
										</Td>
										<Td>
											{invoice?.baseRate
												? `$${Number(invoice.baseRate).toFixed(2)}`
												: "-"}
										</Td>
										<Td>
											{invoice?.fuel
												? `$${Number(invoice.fuel).toFixed(2)}`
												: "$0.0"}
										</Td>
										<Td>
											{invoice?.totalSurcharge
												? `$${invoice.totalSurcharge}`
												: "-"}
										</Td>
										<Td>
											{invoice?.totalSurcharge
												? `$${invoice.totalSurcharge}`
												: "-"}
										</Td>
										<Td>
											{invoice?.totalSurcharge
												? `$${invoice.totalSurcharge}`
												: "-"}
										</Td>
									</Tr>
								);
							})}
					</Tbody>
				)}
			</Table>
		</TableContainer>
	);
}

export default ParcelDifferenceInvoice;
