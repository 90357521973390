import { useContext } from "react";
import { Link } from "react-router-dom";
import { Text } from "@chakra-ui/react";

import { UserContext } from "../Providers";

import classes from "./InvoiceTabs.module.css";

export function InvoiceTabs({ onTabChange, selectedTab }) {
	const { user } = useContext(UserContext);

	const handleTabChange = (memberType) => {
		if (onTabChange) onTabChange(memberType); // Notify parent component
	};

	return (
		<nav role="navigation" className={classes.nav}>
			<ul className={classes.navList}>
				<>
					{(user.isShipper || user.isPlatformAdmin) && (
						<li>
							<Link
								className={
									selectedTab === "SHIPPER"
										? `${classes.links} ${classes.active}`
										: classes.links
								}
								onClick={() => handleTabChange("SHIPPER")}
							>
								<Text>Shipper</Text>
							</Link>
						</li>
					)}
					{(user.isCarrier || user.isPlatformAdmin) && (
						<li>
							<Link
								className={
									selectedTab === "CARRIER"
										? `${classes.links} ${classes.active}`
										: classes.links
								}
								onClick={() => handleTabChange("CARRIER")}
							>
								<Text>Carrier</Text>
							</Link>
						</li>
					)}
				</>
			</ul>
		</nav>
	);
}
