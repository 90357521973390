import React, { useContext, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { Grid, GridItem, Text, useMediaQuery } from "@chakra-ui/react";
import {
  getChartDataByPartner,
  getParcelTileDetailByPartner,
  getParcelRecommendationByPartner,
} from "../services";
import {
  getCarrierColor,
  setTileAmountPrefix,
  setTileAmountPostfix,
} from "../utilities";
import {
  ParcelIntelligenceFilters as Filters,
  Recommendation,
  Summary,
  UserContext,
} from "../interfaces";
import CurrentDate from "../interfaces/Dashboard/CurrentDate";
import BarChart from "../interfaces/Dashboard/BarChart";
import Pod from "../interfaces/Dashboard/pod";

const ParcelIntelligence = () => {
  const [isLargerThan1200] = useMediaQuery("(min-width: 1200px)");

  const [filters, setFilters] = useState({
    partner: "",
    shippers: "",
    carrier: "",
  });
  const [chartData, setChartData] = useState([]);
  const [tilesData, setTilesData] = useState({});
  const [recommendation, setRecommendation] = useState({});
  const [isLoadingRecommendation, setIsLoadingRecommendation] = useState(false);
  const [recommendationError, setRecommendationError] = useState("");

  const { token } = useContext(UserContext);

  useEffect(() => {
    getChartDataByPartner(filters.partner, token).then((data) => {
      setChartData(data);
    });
  }, [filters.partner, token]);

  useEffect(() => {
    getParcelTileDetailByPartner(filters.partner || "All", token).then((data) => {
      setTilesData(data);
    });
  }, [filters.partner, token]);

  useEffect(() => {
    setIsLoadingRecommendation(true);
    getParcelRecommendationByPartner(
      (filters.partner === "All" ? "EASYPOST" : filters.partner, token) || "EASYPOST"
    )
      .then((data) => {
        setRecommendationError("");
        setRecommendation(data);
      })
      .catch((error) => {
        setRecommendationError(error.message);
      })
      .finally(() => {
        setIsLoadingRecommendation(false);
      });
  }, [filters.partner, token]);

  const chartDatasets = useMemo(
    () =>
      chartData.reduce((prev, chart) => {
        prev.labels = chart.chartData.map((data) => data.billableWeight);

        const dataset = {
          value: chart.chartData.map((data) => data.averageCost),
          label: chart.name,
          color: getCarrierColor(chart.name.toLowerCase()),
        };

        if (chart.name === "Orchestro" || chart.name === filters.partner) {
          if (prev.datasets) {
            prev.datasets.push(dataset);
          } else {
            prev.datasets = [dataset];
          }
        }

        return prev;
      }, {}),
    [chartData, filters.partner]
  );

  const pods = useMemo(
    () =>
      Object.entries(tilesData.tileDetails ?? {}).map(([key, tile]) => {
        return {
          label: key.split(/(?=[A-Z])/).join(" "),
          amount: tile,
          variant: typeof tile === "object" ? "list" : "",
          amountPrefix: setTileAmountPrefix(key),
          amountPostfix: setTileAmountPostfix(tile, key),
        };
      }),
    [tilesData]
  );

  return (
    <div className="flex-grow p-5 ml-2 mt-2">
      <CurrentDate className={"font-medium"} dateFormat={"d MMM yyyy HH:mm"} />

      <Text className="mt-11 text-lg font-semibold text-[#F9F9FF]">
        Filter view
      </Text>

      <Filters
        filters={filters}
        setFilters={setFilters}
        shippers={recommendation?.recommendationHighlight?.dropdown}
      />

      <div className="mt-[18px]">
        <Text className="text-base md:text-lg text-white font-normal">
          Since your last visit{" "}
          {tilesData?.lastVisitDate ? (
            <time dateTime={format(tilesData?.lastVisitDate, "yyyy-MM-dd")}>
              {tilesData?.lastVisitDate}
            </time>
          ) : (
            <></>
          )}
          :
        </Text>
        <Text className="text-base md:text-lg text-white font-medium">
          Data from an additional {tilesData?.numberOfRecordsFromLastLogin}{" "}
          million parcels has been received.
        </Text>
      </div>

      {(filters.partner || filters.shippers) && (
        <Recommendation
          data={recommendation?.recommendationHighlight}
          shipper={filters.shippers}
          setFilters={setFilters}
          isLoading={isLoadingRecommendation}
          error={recommendationError}
        />
      )}
      {filters.shippers && (
        <Summary
          data={
            recommendation?.recommendationHighlight?.summary[filters.shippers]
          }
        />
      )}

      <Grid
        gap={4}
        marginTop={7}
        className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        {pods.map((pod, index) => (
          <GridItem key={index}>
            <Pod pod={pod} />
          </GridItem>
        ))}
      </Grid>

      <div className="active rounded-md w-full mt-5">
        {Object.keys(chartDatasets).length > 0 ? (
          <BarChart
            title="Average Price by Billable Weight"
            xAxis="Billable Weight"
            yAxis="Average Cost ($)"
            data={chartDatasets}
            isMultiBar={true}
            isDisplayLegend={true}
            isDisplayTimeRange={false}
            options={{
              legend: {
                labels: {
                  font: {
                    size: 12,
                    family: "Montserrat",
                    weight: "normal",
                  },
                  color: "#fff",
                  boxWidth: 20,
                  boxHeight: 20,
                  padding: isLargerThan1200 ? 42 : 10,
                },
              },
              padding: isLargerThan1200
                ? { left: 60, right: 60, bottom: 20 }
                : 1,
              titleClasses: `${isLargerThan1200 ? "mt-7 text-lg" : "text-base"} font-medium`,
              axisTitle: {
                font: {
                  size: 14,
                  family: "Montserrat",
                  weight: 500,
                },
              },
            }}
          />
        ) : (
          <div className="h-[120px] flex items-center justify-center">
            <p className="text-base font-medium">No data available for chart</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ParcelIntelligence;
