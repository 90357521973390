import React, { useState, useEffect, useRef } from "react";

const History = ({ itemNumber, title, status, location, last }) => {
	const [packageStatus, setPackageStatus] = useState("");
	const [packageTitle, setPackageTitle] = useState(title);
	const [isTruncated, setIsTruncated] = useState(false);
	const textRef = useRef(null);

	useEffect(() => {
		setPackageStatus(status);
		setPackageTitle(title);
	}, [title, status]);

	useEffect(() => {
		const checkIfTruncated = () => {
			if (textRef.current) {
				const lineHeight = parseFloat(
					window.getComputedStyle(textRef.current).lineHeight,
				);
				const maxLines = 2;
				const maxHeight = lineHeight * maxLines;

				setIsTruncated(textRef.current.scrollHeight > maxHeight);
			}
		};

		checkIfTruncated();
	}, [packageTitle]);

	return (
		<div className="bg-[#242630] w-[320px] rounded-md">
			<div className="p-3 flex w-full">
				<div className="flex flex-col w-full ml-2">
					<div className="flex flex-row w-full">
						<div className="flex items-center justify-between w-full text-xs">
							<div className="relative group max-w-[140px]">
								<div
									ref={textRef}
									className="text-white font-semibold line-clamp-2"
								>
									{packageTitle}
								</div>
								{isTruncated && (
									<span
										className={`absolute ${
											itemNumber === 0 ? "top-full" : "bottom-full"
										} left-1/2 transform -translate-x-1/2 hidden z-10 mb-2 w-max max-w-xs p-2 text-xs text-white bg-[#1E2028] rounded-md shadow-lg group-hover:block`}
									>
										{packageTitle}
									</span>
								)}
							</div>
							<div
								className={` ${
									last === true ? "text-[#261900] bg-[#FFB323]" : "bg-[#858698]"
								} font-semibold rounded-full text-center p-1 px-3 text-nowrap`}
							>
								{packageStatus}
							</div>
						</div>
					</div>
					<div className="mt-2">
						<div className="flex items-center justify-start w-full text-xs">
							<div className="text-[#858698]">Location</div>
							<div className="ml-auto text-right mr-2 w-1/2">{location}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default History;
