import React, { useState } from 'react';
import { Flex, FormControl, FormErrorMessage, InputGroup, Select } from '@chakra-ui/react';
import { Label } from '../../../componentLibrary';
import { InputField } from '../../InputFields';

const options = [
  { value: 'DDAS', label: 'DDAS' },
  { value: 'EDAS', label: 'EDAS' },
  { value: 'ADDL', label: 'ADDL' },
  { value: 'DDIM', label: 'DDIM' },
  { value: 'ADCR', label: 'ADCR' },
  { value: 'VOLM', label: 'VOLM' },
];

const servicesOption=[
  { value: 'POD_RQD', label: 'POD_RQD' },
  { value: 'AD_SIGN', label: 'AD_SIGN' },
]

const ServiceTable = ({ tableData, serviceTableData,setBodyData, setServiceBodyData, setSaveStatus }) => {
  const [data, setData] = useState(tableData);
  const [serviceData, setServiceData] = useState(serviceTableData);

  const handleInputChange = (index, field, value) => {
    setSaveStatus(false)
    const newData = [...data];
    newData[index][field] = value;
    setBodyData(newData)
    setData(newData);
  };

  const handleServiceInputChange = (index, field, value) => {
    setSaveStatus(false)
    const newData = [...serviceData];
    newData[index][field] = value;
    setServiceBodyData(newData)
    setServiceData(newData);
  };

  const colorCodes = (code) => {
    if (code === undefined) {
      return "code";
    } else {
      return "";
    }
  };

  return (
    <>
      {data?.map((e, i) => (
        <div key={i} className="my-2">
          <Flex className="gap-5 w-fit-">
            <FormControl>
              <Label htmlFor={`Surchages-${i}`}>
                Other Fees/Surcharges
              </Label>
              <InputGroup>
                <InputField
                  name={`Surchages-${i}`}
                  id={`Surchages-${i}`}
                  value={e["Other Fees / Surcharges"]}
                  className='my-2'
                  disabled
                  width={"300px"}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <Label htmlFor={`Fee-${i}`}>
                Fee
              </Label>
              <InputGroup>
                <InputField
                  value={e["Fee"]}
                  name={`Fee-${i}`}
                  id={`Fee-${i}`}
                  disabled
                  className='my-2'
                  width={"112px"}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <Label htmlFor={`Carrier Code-${i}`}>
              Carrier Code
              </Label>
              <InputGroup>
                <InputField
                  value={e["Carrier Code"]}
                  name={`Carrier Code-${i}`}
                  id={`Carrier Code-${i}`}
                  disabled
                  className='my-2'
                  width={"112px"}
                />
              </InputGroup>
            </FormControl>
            <FormControl isInvalid={colorCodes(e["OCN Code"]) === "code"}>
              <Label
                htmlFor={`OCN Code-${i}`}
                className={colorCodes(e["OCN Code"]) === "code"? "text-[#FF7B80]" : ""}
              >
                OCN Code
              </Label>
              <Select
                name={`OCN Code-${i}`}
                id={`OCN Code-${i}`}
                value={e["OCN Code"] || ""}
                onChange={(e) => handleInputChange(i, "OCN Code", e.target.value)}
                className='my-2'
                width={"300px"}
              >
                <option value="" disabled>Select OCN Code</option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage className="!mt-2">
                *Required
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </div>
      ))}
      {serviceData?.map((e, i) => (
        <div key={i} className="my-2">
          <Flex className="gap-5 w-fit-">
            <FormControl>
              <Label htmlFor={`Surchages-${i}`}>
                Other Fees/Surcharges
              </Label>
              <InputGroup>
                <InputField
                  name={`Surchages-${i}`}
                  id={`Surchages-${i}`}
                  value={e["Other Fees / Surcharges"]}
                  className='my-2'
                  disabled
                  width={"300px"}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <Label htmlFor={`Fee-${i}`}>
                Fee
              </Label>
              <InputGroup>
                <InputField
                  value={e["Fee"]}
                  name={`Fee-${i}`}
                  id={`Fee-${i}`}
                  disabled
                  className='my-2'
                  width={"112px"}
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <Label htmlFor={`Carrier Code-${i}`}>
              Carrier Code
              </Label>
              <InputGroup>
                <InputField
                  value={e["Carrier Code"]}
                  name={`Carrier Code-${i}`}
                  id={`Carrier Code-${i}`}
                  disabled
                  className='my-2'
                  width={"112px"}
                />
              </InputGroup>
            </FormControl>
            <FormControl isInvalid={colorCodes(e["OCN Code"]) === "code"}>
              <Label
                htmlFor={`OCN Code-${i}`}
                className={colorCodes(e["OCN Code"]) === "code"? "text-[#FF7B80]" : ""}
              >
                OCN Code
              </Label>
              <Select
                name={`OCN Code-${i}`}
                id={`OCN Code-${i}`}
                value={e["OCN Code"] || ""}
                onChange={(e) => handleServiceInputChange(i, "OCN Code", e.target.value)}
                className='my-2'
                width={"300px"}
              >
                <option value="" disabled>Select OCN Code</option>
                {servicesOption.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
              <FormErrorMessage className="!mt-3">
                *Required
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </div>
      ))}
    </>
  );
};

export default ServiceTable;
