// Add all fetch APIs here to use anywhere in code base
import { createFeature } from "../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const bidEngineServiceUrl = process.env.REACT_APP_BID_ENGINE_SERVICE_URL;
const bidEngineServicePath = process.env.REACT_APP_BID_ENGINE_SERVICE_PATH;
const authorizationToken = process.env.REACT_APP_CORE_SERVICES_TOKEN;
const coreService = coreServiceUrl + coreServicePath;
const bidEngineService = bidEngineServiceUrl + bidEngineServicePath;

// submit-service-type

const submitServiceType = async (carrierCode, token, itemObj) => {
	try {
		const response = await fetch(
			`${coreService}/submit-service-type?carrierCode=${carrierCode}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify(itemObj),
			},
		);

		if (!response.ok) {
			const errorText = await response.text();
			console.error(`Error ${response.status}: ${response.statusText}`);
			console.error(`Response body: ${errorText}`);
			throw new Error("Network response was not ok");
		}

		return response;
	} catch (error) {
		console.error("Error while submitting service type:", error);
		return null;
	}
};

//services-type-download

const serviceTypeDownload = async (token, permission) => {
	try {
		const response = await fetch(`${coreService}/download-service-type`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode: permission,
			}),
		});
		if (!response.ok) {
			throw new Error("Error fetching Account Request data");
		}

		return await response;
	} catch (error) {
		console.error("Error fetching Account Request data:", error);
	}
};

// generate-rate-card

const generateRateCardRequest = async (token, body) => {
	try {
		const response = await fetch(`${coreService}/generate-rate-card`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(body),
		});
		if (!response.ok) {
			throw new Error("Error fetching Account Request data");
		}
		return await response;
	} catch (error) {
		console.error("Error fetching Account Request data:", error);
	}
};

// get-all-partner onboarding

const getAllPartnerOnAccountRequest = async (status, token) => {
	try {
		const response = await fetch(
			`${coreService}/get-all-partner-onboarding?status=${status.toUpperCase()}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Error fetching Account Request data");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching Account Request data:", error);
	}
};

// update-partner onboarding

const updatePartnerOnAccountRequestById = async (
	accountDataId,
	selectedStatus,
	token,
) => {
	try {
		const response = await fetch(
			`${coreService}/update-partner-onboarding?partnerOnboardingId=${accountDataId}&status=${selectedStatus.toUpperCase()}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return response;
	} catch (error) {
		console.error("Error updating status:", error);
	}
};

// getPartner onboarding

const getPartnerOnAccountRequestById = async (partnerOnboardingId, token) => {
	try {
		const response = await fetch(
			`${coreService}/get-partner-onboarding?partnerOnboardingId=${partnerOnboardingId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}
		return await response.json();
	} catch (error) {
		console.error("Error fetching account data:", error);
	}
};

// Events

// Public Call Directly

const getEventsBasedOnTrackerIdPublic = async (trackingNumber) => {
	try {
		const response = await fetch(
			`${coreService}/get-status?trackingNumber=${trackingNumber}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getEventsBasedOnTrackerId = async (trackingNumber, token) => {
	try {
		const response = await fetch(
			`${coreService}/ui-events?trackingNumber=${trackingNumber}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Parcels

const getParcels = async (body, token) => {
	try {
		const response = await fetch(coreService + "/get-parcels", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getParcelsBasedOnId = async (trackingNumber, token) => {
	try {
		const response = await fetch(coreService + "/get-parcels", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				trackingNumber: trackingNumber,
				limit: 1, // Adjust the limit as needed
				start: 1,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Parcel Intelligence

const getParcelTileDetailByPartner = async (partner = "All", token) => {
	try {
		const response = await fetch(
			`${bidEngineService}/parcel-tile-detail?partner=${partner}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getChartDataByPartner = async (partner) => {
	try {
		const response = await fetch(
			`${bidEngineService}/chart-data?partner=${partner}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getParcelRecommendationByPartner = async (partner = "") => {
	try {
		const response = await fetch(
			`${bidEngineService}/parcel-recommendation?partner=${partner}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		throw new Error("Error while fetching recommendation data");
	}
};

const getAllPartners = async () => {
	try {
		const response = await fetch(bidEngineService + "/partners", {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getShippersByPartner = async (partner) => {
	try {
		const response = await fetch(
			`${bidEngineService}/shippers?partner=${partner}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getCarriersByShipper = async (shipper) => {
	try {
		const response = await fetch(
			`${bidEngineService}/carriers?shipper=${shipper}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Invoice

const getInvoice = async (
	token,
	memberCode = "",
	memberType,
	pageNumber = 1,
	pageSize = 25,
) => {
	try {
		const response = await fetch(
			`${coreService}/get-invoice?pageNumber=${pageNumber}&pageSize=${pageSize}${
				memberType ? `&memberType=${memberType}` : ""
			}${memberCode ? `&memberCode=${memberCode}` : ""}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getParcelInvoice = async (token, trackingId = "") => {
	try {
		const response = await fetch(
			`${coreService}/get-parcel-invoice?carrierTrackingId=${trackingId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Carrier

const getCarrierInfo = async (carrierCode, token) => {
	try {
		const response = await fetch(coreService + "/get-carrier-info", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getCarriers = async (token) => {
	try {
		const response = await fetch(coreService + "/get-carriers", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error(`Error fetching data:`, error);
	}
};

// Service Attributes

const getServiceAttributes = async (carrierCode, token) => {
	try {
		const response = await fetch(coreService + "/get-service-attributes", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Service Types

const getServiceType = async (carrierCode, token) => {
	try {
		const response = await fetch(coreService + "/get-service-type", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Bid Analytics

const getLiveBidCountDetail = async () => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-analytics/live-bid-count`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getWonLostBidDetail = async (start, end, duration) => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-analytics/won-lost-bid-chart?start=${start}&end=${end}&duration=${duration}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getRseStatsForCarriers = async () => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-analytics/rse-stats-for-carriers`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getAvgRseStatsForDay = async (date) => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-analytics/avg-rse-stats-for-day?date=${date}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getBidPerformanceDetails = async (limit, skip) => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-performance?limit=${limit}&skip=${skip}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Bid Config

const getBidConfigDetails = async (limit, skip) => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-config?limit=${limit}&skip=${skip}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const createBidConfig = async (body) => {
	try {
		const response = await fetch(`${bidEngineService}/bid-config/create`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getBidConfigById = async (id) => {
	try {
		const response = await fetch(`${bidEngineService}/bid-config/${id}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const updateBidConfig = async (id, body) => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-config/update/${id}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
				body: JSON.stringify(body),
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getBidTierConfiguration = async (risk, biddingTier) => {
	try {
		const response = await fetch(
			`${bidEngineService}/bid-tier-configuration?risk=${risk}&biddingTier=${biddingTier}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Surcharges

const getSurcharges = async (carrierCode, token) => {
	try {
		const response = await fetch(coreService + "/get-surcharges", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Tracking Codes

const getTrackingCodes = async (carrierCode, token) => {
	try {
		const response = await fetch(coreService + "/get-tracking-codes", {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Preset Dashboard

const getPresetDashboard = async () => {
	try {
		const response = await fetch(`${bidEngineService}/pdashboard`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Carrier Addition

const getCarrierAddition = async () => {
	try {
		const response = await fetch(`${bidEngineService}/carriers-add`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getCarrierAddCoverageMap = async (body) => {
	try {
		const response = await fetch(
			`${bidEngineService}/carriers-add/coverage-map`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
				body: JSON.stringify(body),
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getCarrierAddImpact = async (body) => {
	try {
		const response = await fetch(`${bidEngineService}/carriers-add/impact`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getCarrierAddInsights = async (body) => {
	try {
		const response = await fetch(`${bidEngineService}/carriers-add/insights`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getCarrierAddRateCompetitiveness = async (body) => {
	try {
		const response = await fetch(
			`${bidEngineService}/carriers-add/rate-competitiveness`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json; charset=UTF-8",
					Authorization: authorizationToken,
				},
				body: JSON.stringify(body),
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Shipper Addition

const getShipperAddition = async () => {
	try {
		const response = await fetch(`${bidEngineService}/shippers-add`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getShipperAddLaneMap = async (body) => {
	try {
		const response = await fetch(`${bidEngineService}/shippers-add/lane-map`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getShipperAddImpact = async (body) => {
	try {
		const response = await fetch(`${bidEngineService}/shippers-add/impact`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getShipperAddInsights = async (body) => {
	try {
		const response = await fetch(`${bidEngineService}/shippers-add/insights`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8",
				Authorization: authorizationToken,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

// Partners

const getPartners = async (token) => {
	try {
		const response = await fetch(coreService + "/get-partners", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error(`Error fetching data:`, error);
	}
};

// Location Summary

const getLocationsSummary = async (token) => {
	try {
		const response = await fetch(coreService + "/node-summary", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const data = await response.json();

		return {
			type: "FeatureCollection",
			features: data.map(createFeature),
		};
	} catch (error) {
		console.error("Error fetching locations:", error);
	}
};

// Rate Discovery

const downloadPldData = async (pldTransactionIdVal, setIsLoadingCsv, token) => {
	try {
		setIsLoadingCsv(true);
		const response = await fetch(coreService + "/download-pld-chart-data", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				pldTransactionId: pldTransactionIdVal, // Update this as needed
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const data = await response;
		// file object
		data.blob().then((blob) => {
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.download = "pld.csv";
			a.click();
		});
	} catch (error) {
		console.error(`Error fetching data:`, error);
	} finally {
		setIsLoadingCsv(false);
	}
};

const downloadNetworkRates = async (payload, setIsLoading, fileName, token) => {
	try {
		setIsLoading(true);
		const response = await fetch(coreService + "/download-network-rates", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(payload),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const data = await response;
		// file object
		data.blob().then((blob) => {
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.download = fileName;
			a.click();
		});
	} catch (error) {
		console.error(`Error fetching data:`, error);
	} finally {
		setIsLoading(false);
	}
};

const downloadRateForCarriers = async (
	payload,
	setIsLoading,
	fileName,
	token,
) => {
	try {
		setIsLoading(true);
		const response = await fetch(coreService + "/download-rate-for-carriers", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(payload),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		const data = await response;
		// file object
		data.blob().then((blob) => {
			let url = window.URL.createObjectURL(blob);
			let a = document.createElement("a");
			a.href = url;
			a.download = fileName;
			a.click();
		});
	} catch (error) {
		console.error(`Error fetching data:`, error);
	} finally {
		setIsLoading(false);
	}
};

const getPldChartData = async (fileData, data, setIsLoadingPld, token) => {
	if (fileData && data) {
		if (setIsLoadingPld && typeof setIsLoadingPld === "function") {
			setIsLoadingPld(true);
		}

		const formData = new FormData();
		formData.append("files", fileData);

		const json = JSON.stringify(data);
		const blob = new Blob([json], {
			type: "application/json",
		});
		formData.append("pldRequest", blob);

		try {
			const response = await fetch(coreService + "/get-pld-chart-data", {
				method: "POST",
				headers: {
					Authorization: token,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (!data.errors) return data;
		} catch (error) {
			console.error(`Error fetching data:`, error);
		} finally {
			if (setIsLoadingPld && typeof setIsLoadingPld === "function") {
				setIsLoadingPld(false);
			}
		}
	}
};

const getRateCalculator = async (postData, token) => {
	try {
		const response = await fetch(coreService + "/get-rate-calculator", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(postData),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error(`Error fetching data:`, error);
	}
};

// Participant
// Note, there is a /participants and /get-participant endpoint.
const getParticipants = async (token, permission) => {
	try {
		const response = await fetch(
			`${coreService}/participants${
				permission ? `?participantCode=${permission}` : ""
			}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching participant:", error);
	}
};

// Shipment Filter

const getFilters = async (userName, token) => {
	try {
		const response = await fetch(
			coreService + `/get-filter?username=${userName}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching filters:", error);
	}
};

const saveFilter = async (body, token) => {
	try {
		const response = await fetch(coreService + "/save-filter", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return response;
	} catch (error) {
		console.error(`Error fetching data:`, error);
	}
};

const deleteFilter = async (body, token) => {
	try {
		const response = await fetch(coreService + `/delete-filter`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(body),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching filters:", error);
	}
};

// self-serve onboarding

const updateParticipant = async (body, token) => {
	try {
		const response = await fetch(coreService + `/update-participant`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body,
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return response.ok;
	} catch (error) {
		console.error("Error while updating participant:", error);
	}
};

const getPreferences = async (carrierCode, token) => {
	try {
		const response = await fetch(coreService + `/get-preference`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify({
				carrierCode,
			}),
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching preferences:", error);
	}
};

const fetchPreferenceValues = async (token) => {
	try {
		const response = await fetch(coreService + `/fetch-preference-values`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error fetching preference values:", error);
	}
};

const updateParticipantPreference = async (body, carrierCode, token) => {
	try {
		const response = await fetch(
			coreService + `/participant-preference?carrierCode=${carrierCode}`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body,
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return response.ok;
	} catch (error) {
		console.error("Error while updating participant preference:", error);
	}
};

const createParticipant = async (body, token) => {
	try {
		const response = await fetch(coreService + `/create-participant`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body,
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return response.ok;
	} catch (error) {
		console.error("Error while creating participant:", error);
	}
};

const getRateCardDetails = async (carrierCode, participantType, token) => {
	try {
		const response = await fetch(
			coreService +
				`/get-rate-card?carrierCode=${carrierCode}&participantType=${participantType}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error while fetching rate card:", error);
	}
};

// Note, there is a /participants and /get-participant endpoint.
const getParticipant = async (token) => {
	try {
		const response = await fetch(coreService + `/get-participant`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
		});

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return await response.json();
	} catch (error) {
		console.error("Error while fetching participant:", error);
	}
};

const updateRateCardDetails = async (
	carrierCode,
	participantType,
	body,
	token,
) => {
	try {
		const response = await fetch(
			coreService +
				`/submit-rate-card?carrierCode=${carrierCode}&participantType=${participantType}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify(body),
			},
		);

		if (!response.ok) {
			throw new Error("Network response was not ok");
		}

		return response.ok;
	} catch (error) {
		console.error("Error while fetching rate card:", error);
	}
};

const uploadSortCodeFacilityDetails = async (data, carrierCode, token) => {
	try {
		const response = await fetch(
			`${coreService}/submit-facilities?carrierCode=${carrierCode}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify(data),
			},
		);
		return response;
	} catch (error) {
		console.error("Error while uploading Sort Code Details:", error);
	}
};

const uploadSurchargesDetails = async (data, carrierCode, token) => {
	try {
		const response = await fetch(
			`${coreService}/submit-surcharge?carrierCode=${carrierCode}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify(data),
			},
		);
		return response;
	} catch (error) {
		console.error("Error while uploading Surcharges Details:", error);
	}
};

const uploadServiceAttributesDetails = async (data, carrierCode, token) => {
	try {
		const response = await fetch(
			`${coreService}/submit-service-attributes?carrierCode=${carrierCode}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify(data),
			},
		);
		return response;
	} catch (error) {
		console.error("Error while uploading Surcharges Details:", error);
	}
};

const validateZipCode = async (data, token) => {
	try {
		const response = await fetch(`${coreService}/validate-zip-codes`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: token,
			},
			body: JSON.stringify(data),
		});
		const responseFulfilled = await response.json();
		return responseFulfilled;
	} catch (error) {
		console.error("Error while getting Validate ZipCode Details:", error);
	}
};

export {
	createBidConfig,
	createParticipant,
	deleteFilter,
	downloadNetworkRates,
	downloadPldData,
	downloadRateForCarriers,
	fetchPreferenceValues,
	generateRateCardRequest,
	getAllPartnerOnAccountRequest,
	getAllPartners,
	getAvgRseStatsForDay,
	getBidConfigById,
	getBidConfigDetails,
	getBidPerformanceDetails,
	getBidTierConfiguration,
	getCarrierAddCoverageMap,
	getCarrierAddImpact,
	getCarrierAddInsights,
	getCarrierAddRateCompetitiveness,
	getCarrierAddition,
	getCarrierInfo,
	getCarriers,
	getCarriersByShipper,
	getChartDataByPartner,
	getEventsBasedOnTrackerId,
	getEventsBasedOnTrackerIdPublic,
	getFilters,
	getInvoice,
	getLiveBidCountDetail,
	getLocationsSummary,
	getParcelInvoice,
	getParcelRecommendationByPartner,
	getParcelTileDetailByPartner,
	getParcels,
	getParcelsBasedOnId,
	getParticipant,
	getParticipants,
	getPartnerOnAccountRequestById,
	getPartners,
	getPldChartData,
	getPreferences,
	getPresetDashboard,
	getRateCalculator,
	getRateCardDetails,
	getRseStatsForCarriers,
	getServiceAttributes,
	getServiceType,
	getShipperAddImpact,
	getShipperAddInsights,
	getShipperAddLaneMap,
	getShipperAddition,
	getShippersByPartner,
	getSurcharges,
	getTrackingCodes,
	getWonLostBidDetail,
	saveFilter,
	serviceTypeDownload,
	submitServiceType,
	updateBidConfig,
	updateParticipant,
	updateParticipantPreference,
	updatePartnerOnAccountRequestById,
	updateRateCardDetails,
	uploadServiceAttributesDetails,
	uploadSortCodeFacilityDetails,
	uploadSurchargesDetails,
	validateZipCode,
};
