import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { AccountTabs, Page, TableLoading, UserContext } from "../interfaces";
import { getSurcharges } from "../services";
import { Button } from "../componentLibrary";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

const Surcharges = () => {
  const [surcharges, setSurcharges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isNonProdEnvironment, token, user, viewingAs } = useContext(UserContext);

  const isCarrierOnly = useMemo(
    () => (viewingAs.isCarrier && !viewingAs.isShipper),
    [viewingAs]
  );

  useEffect(() => {
    if (!viewingAs.participantCode) return;
    if (viewingAs.isCarrier || viewingAs.isShipper) {
      const fetchData = async () => {
        setIsLoading(true);

        const data = await getSurcharges(viewingAs.participantCode, token);
        setIsLoading(false);
        setSurcharges(data.surcharges);
      };

      fetchData().catch(console.error);
    }
  }, [token, viewingAs]);

  return (
    <>
      <AccountTabs />
      <Page className={"!min-h-0"}>
        <div className="flex justify-between items-center">
          <h2 className="text-xl my-5">
            {isCarrierOnly ? "Surcharges" : "Orchestro Surcharges"}
          </h2>
          {user.isPlatformAdmin && isNonProdEnvironment && (
            <Button
              context="primary"
              prefixIcon={<ArrowDownTrayIcon />}
            >
              Download
            </Button>
            )
          }
        </div>

        <TableContainer className="max-h-[calc(100vh_-_220px)] !overflow-y-auto w-full mb-4">
          <Table>
            <Thead>
              <Tr>
                <Th>Code</Th>
                <Th>Description</Th>
                <Th>Rate</Th>
                {isCarrierOnly && (
                  <>
                    <Th>Network Surcharge Code</Th>
                    <Th>Network Surcharge Description</Th>
                    <Th>Network Rate</Th>
                  </>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && <TableLoading data={surcharges} />}
              {!isLoading && surcharges.length === 0 && (
                <Tr>
                  <Td colSpan="100">No data found</Td>
                </Tr>
              )}
              {surcharges &&
                surcharges.map((surcharge, i) => (
                  <Tr key={i + "-" + surcharge.code}>
                    <Td fontWeight="bold">{surcharge?.code}</Td>
                    <Td>{surcharge?.description}</Td>
                    <Td fontWeight="bold">
                      {surcharge?.rate === 0 ? "N/A" : `$${surcharge?.rate}`}
                    </Td>
                    {isCarrierOnly && (
                      <>
                        <Td>{surcharge?.networkSurchargeCode}</Td>
                        <Td>{surcharge?.networkSurchargeDescription}</Td>
                        <Td>
                          {surcharge?.networkRate === 0 ? (
                            "N/A"
                          ) : (
                            <Text fontWeight="bold">
                              ${surcharge?.networkRate}
                            </Text>
                          )}
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Page>
    </>
  );
};

export default Surcharges;
