import React, { useEffect, useState } from "react";
import { format } from "date-fns";

const ParcelHeader = ({ trackingNumber, trackingData, parcelDatum, isPublicTracking }) => {
	const [eventsData, setEventDate] = useState({});
	const [parcelData, setParcelData] = useState({});

	useEffect(() => {
		if (trackingData && trackingData.length > 0) setEventDate(trackingData[0]);
		if (isPublicTracking) {
			if (parcelDatum) setParcelData(parcelDatum);
		} else {
			if (parcelDatum && parcelDatum.length > 0) setParcelData(parcelDatum[0]);
		}
		
	}, [trackingData, parcelDatum]);

	return (
		<div className="flex bg-[#1A202E] shadow-lg rounded-md mb-2">
			<div className="mx-4">
				<img
					alt="parcel"
					width="240px"
					className="mt-[-15px]"
					src="/images/shipments/parcel.png"
				/>
			</div>
			<div className="ml-2 mt-12">
				<div className="flex flex-col">
					<div className="font-semibold">{trackingNumber || ""}</div>
					<div className="flex flex-row mt-8">
						<div className="flex flex-col">
							<div className="text-xs font-medium flex">
								{eventsData?.status?.toLowerCase() === "delayed" && (
									<img
										alt="high risk"
										className="mr-1 mt-[-2px]"
										src="/images/popover/high-risk.svg"
									/>
								)}
								{eventsData?.uiStatus || "-"}
							</div>
							<div className="text-xs text-[#7D8488]">Current Status</div>
						</div>
						<div className="flex flex-col ml-14">
							<div className="text-xs font-medium capitalize">
								{eventsData.flag && eventsData?.city ? (
									eventsData?.city.toLowerCase() + ", " + eventsData?.state
								) : (
									<span>-</span>
								)}
							</div>
							<div className="text-xs text-[#7D8488]">Current Location</div>
						</div>
						{/* <div className="flex flex-col ml-12">
							<div className="text-xs font-medium">
								{parcelData?.expectedDate ? (
									<time
										dateTime={format(parcelData?.expectedDate, "yyyy-MM-dd")}
									>
										{parcelData?.expectedDate}
									</time>
								) : (
									"n/a"
								)}
							</div>
							<div className="text-xs text-[#7D8488]">ETA</div>
						</div> */}
						<div className="flex flex-col ml-12">
							<div className="text-xs font-medium">
								{parcelData?.shipDate ? (
									<time dateTime={format(parcelData?.shipDate, "yyyy-MM-dd")}>
										{parcelData?.shipDate}
									</time>
								) : (
									"n/a"
								)}
							</div>
							<div className="text-xs text-[#7D8488]">Shipment Date</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ParcelHeader;
