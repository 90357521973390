import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { format } from "date-fns";
import {
  Box,
  Flex,
  HStack,
  Card,
  Heading,
  Spinner,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
// import {, EditIcon, AddIcon } from "@chakra-ui/icons";

import classes from "../css-files/OrderDetails.module.css";
import { Page, UserContext } from "../interfaces";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

const OrderEvents = () => {
  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [orderData, setOrderData] = useState({
    lane: "",
    orderNumber: "",
    numberOfContainersOrdered: 0,
    orderDate: "",
    pickupDate: "",
    middleMileCarrierCode: "",
    middleMileContactName: "",
    middleMileContactPhoneNumber: "",
    status: null,
    vehicleLicense: null,
    driverName: null,
    billOfLading: null,
    dispatchedOn: null,
  });

  const [lanes, setLanes] = useState([]);
  const [selectedLane, setSelectedLane] = useState(null);
  const [selectedCarrier, setSelectedCarrier] = useState(null);

  const { token } = useContext(UserContext);

  const fetchData = useCallback(async () => {
    try {
      const [lanesResponse, orderDetailResponse] = await Promise.all([
        fetch(apiUrl + "/get-lanes", {
          headers: {
            Authorization: token,
          },
        }),
        fetch(apiUrl + "/get-middle-mile-order-detail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            orderNumber: orderId,
          }),
        }),
      ]);

      if (!lanesResponse.ok) {
        throw new Error("Failed to fetch lanes data");
      }
      const lanesData = await lanesResponse.json();
      setLanes(lanesData || []);

      if (!orderDetailResponse.ok) {
        throw new Error("Failed to fetch order detail data");
      }
      const orderDetailData = await orderDetailResponse.json();

      // Assuming the orderDetailData has properties like lane, orderNumber, etc.
      setOrderData((orderData) => ({
        ...orderData,
        ...orderDetailData,
      }));

      setSelectedLane(orderDetailData.lane);
      setSelectedCarrier(orderDetailData.middleMileCarrierCode);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [orderId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <Page>
        <Box display="flex" justifyContent="center" my={12}>
          <Spinner />
        </Box>
      </Page>
    );
  }

  // Find the selected lane's description
  const selectedLaneDescription =
    lanes.find((lane) => lane.lane === selectedLane)?.description || "";

  return (
    <Page>
      <Flex justifyContent={"space-between"} mb={5}>
        <Heading as={"h1"} size={"md"}>
          Middle Mile Order: {orderId}
        </Heading>
        {/* <Flex gap={2}>
          <Button leftIcon={<AddIcon />}>
            Save
          </Button>
          <Button leftIcon={<EditIcon />}>
            Edit
          </Button>
        </Flex> */}
      </Flex>
      <Flex gap={8} mb={8}>
        <Card
          borderRadius="20px"
          borderWidth="1px"
          borderColor="rgba(133, 134, 152, 0.2)"
          p={8}
          flex="1"
        >
          <Flex>
            <Heading as={"h1"} size={"sm"} mb={5}>
              Order Details
            </Heading>
          </Flex>
          <hr className={classes.hr} />
          <Flex mt={5} alignItems={"center"} gap="2">
            <Box w={"50%"}>
              <Text mb={1}>Lane</Text>
            </Box>
            <Box w={"50%"}>
              <Text fontSize="sm" fontWeight="semibold">
                {selectedLane || ""}
              </Text>
            </Box>
          </Flex>
          <Flex mt={5} alignItems={"center"} gap="2">
            <Box w={"50%"}>
              <Text mb={1}>Description</Text>
            </Box>
            <Box w={"50%"}>
              <Text fontSize="sm" fontWeight="semibold">
                {selectedLaneDescription}
              </Text>
            </Box>
          </Flex>
          <Flex mt={5} alignItems={"center"} gap="2">
            <Box w={"50%"}>
              <Text mb={1}>Bill of Lading</Text>
            </Box>
            <Box w={"50%"}>
              {orderData.billOfLading ? (
                <Text as="b">
                  <Link
                    href={orderData.billOfLading}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bill of Lading.pdf
                  </Link>
                </Text>
              ) : (
                "Not available"
              )}
            </Box>
          </Flex>
        </Card>
        <Card
          borderRadius="20px"
          borderWidth="1px"
          borderColor="rgba(133, 134, 152, 0.2)"
          p={8}
          flex="1"
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Heading as={"h3"} size={"sm"}>
              Status
            </Heading>
            <IconButton
              aria-label="View tracking map"
              as={Link}
              to={`/app/middle-mile-orders/${orderId}/tracking`}
              icon={<ChevronRightIcon boxSize={6} />}
            />
          </Flex>
          <hr className={classes.hr} />
          <Flex mt={5}>
            <Box w={"100%"} display={"inline-grid"}>
              <Text mb={1}>Current Status</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                Not Dispatched
              </Text>
            </Box>
            <Box w={"100%"} display={"inline-grid"}>
              <Text mb={1}>ETA Destination Hub</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                <time dateTime="12:54">12:54 pm PST</time>
              </Text>
            </Box>
          </Flex>
        </Card>
      </Flex>

      <Card
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        p={8}
      >
        <Heading size="sm" mb={3}>
          Order Details
        </Heading>
        <hr className={classes.hr} />
        <Flex>
          <HStack w="100%" spacing="24px" mb="20px">
            <Box w="100%" display={"inline-grid"}>
              <Text mb={1}>Order Number</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {orderData.orderNumber || ""}
              </Text>
            </Box>
            <Box w="100%" display={"inline-grid"}>
              <Text mb={1}>Number Of Containers</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {orderData.numberOfContainersOrdered || 0}
              </Text>
            </Box>
          </HStack>
        </Flex>
        <Flex gap={7} mb="20px">
          <HStack w={"50%"}>
            <Box w={"50%"} display={"inline-grid"}>
              <Text mb={1}>Order Date</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {orderData.orderDate ? (
                  <time dateTime={format(orderData.orderDate, "yyyy-MM-dd")}>
                    {orderData.orderDate}
                  </time>
                ) : (
                  ""
                )}
              </Text>
            </Box>
            <Box w={"50%"} display={"inline-grid"}>
              <Text mb={1}>Pickup Date</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {orderData.orderDate ? (
                  <time dateTime={format(orderData.orderDate, "yyyy-MM-dd")}>
                    {orderData.orderDate}
                  </time>
                ) : (
                  ""
                )}
              </Text>
            </Box>
          </HStack>
          <HStack w={"50%"}>
            <Box display={"inline-grid"}>
              <Text mb={1}>Select Middle Mile Carrier</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {selectedCarrier || ""}
              </Text>
            </Box>
          </HStack>
        </Flex>

        <Flex>
          <HStack w="100%" spacing="24px" mb="20px">
            <Box w="100%" display={"inline-grid"}>
              <Text mb={1}>Middle Mile Contact Name</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {orderData.middleMileContactName || ""}
              </Text>
            </Box>
            <Box w="100%" display={"inline-grid"}>
              <Text mb={1}>Middle Mile Contact Phone</Text>
              <Text fontSize={"18px"} fontWeight="semibold">
                {orderData.middleMileContactPhoneNumber || ""}
              </Text>
            </Box>
          </HStack>
        </Flex>
      </Card>
    </Page>
  );
};

export default OrderEvents;
