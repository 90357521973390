import { useCallback, useContext, useState } from "react";
import {
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Flex,
	IconButton,
} from "@chakra-ui/react";
import { Button } from "../../componentLibrary";
import { TableLoading, UserContext } from "..";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { downloadFileFromBlob, renderDate } from "../../utilities";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

function ParcelShipperInvoice({ data, isOpen = true, isLoading }) {
	const { token } = useContext(UserContext);

	const [isInvoiceDownloading, setIsInvoiceDownloading] = useState({});
	const [invoiceDownloaded, setInvoiceDownloaded] = useState([]);

	const downloadInvoice = useCallback(
		async (invoiceNumber) => {
			setIsInvoiceDownloading((isInvoiceDownloading) => ({
				...isInvoiceDownloading,
				[invoiceNumber]: true,
			}));

			await downloadFileFromBlob(
				`${apiUrl}/invoice-download?invoiceNumber=${invoiceNumber}&participantType=SHIPPER`,
				token,
				`Orchestro_shipper_invoice_${invoiceNumber}`,
			);

			setIsInvoiceDownloading((isInvoiceDownloading) => ({
				...isInvoiceDownloading,
				[invoiceNumber]: false,
			}));
		},
		[apiUrl, token],
	);

	return (
		<TableContainer className="w-full mb-10">
			<Table>
				<Thead>
					<Tr>
						<Th></Th>
						<Th>Invoice No.</Th>
						<Th>Date</Th>
						<Th>Amount</Th>
						<Th>Base Rate</Th>
						<Th>Fuel</Th>
						<Th>Correction</Th>
						<Th>Add Sign</Th>
						<Th>Handling</Th>
						<Th textAlign="center">Shipper Invoice</Th>
					</Tr>
				</Thead>
				{isOpen && (
					<Tbody>
						{isLoading && <TableLoading />}
						{((!isLoading && data && data.length === 0) || !data) && (
							<Tr>
								<Td colSpan="100">No data available</Td>
							</Tr>
						)}
						{data &&
							data.map((invoice, index) => (
								<Tr key={index}>
									<Td>
										<strong>{invoice?.rowTitle ?? "-"}</strong>
									</Td>
									<Td>{invoice?.invoiceNumber ?? "-"}</Td>
									<Td>
										{invoice?.invoiceDate ? (
											<time dateTime={invoice.invoiceDate}>
												{renderDate(invoice.invoiceDate, "MM/dd/yyyy")}
											</time>
										) : (
											"-"
										)}
									</Td>
									<Td>
										{invoice?.totalAmount
											? `$${Number(invoice.totalAmount).toFixed(2)}`
											: "-"}
									</Td>
									<Td>
										{invoice?.baseRate
											? `$${Number(invoice.baseRate).toFixed(2)}`
											: "-"}
									</Td>
									<Td>
										{invoice?.fuel
											? `$${Number(invoice.fuel).toFixed(2)}`
											: "$0.0"}
									</Td>
									<Td>
										{invoice?.totalSurcharge
											? `$${invoice.totalSurcharge}`
											: "-"}
									</Td>
									<Td>
										{invoice?.totalSurcharge
											? `$${invoice.totalSurcharge}`
											: "-"}
									</Td>
									<Td>
										{invoice?.totalSurcharge
											? `$${invoice.totalSurcharge}`
											: "-"}
									</Td>
									<Td textAlign="center" className="h-[60px]">
										{invoice?.processedFileUploadIdExternal &&
										invoice?.invoiceNumber ? (
											isInvoiceDownloading[invoice.invoiceNumber] ? (
												<Spinner />
											) : invoiceDownloaded.includes(invoice.invoiceNumber) ? (
												<Flex justifyContent="center" alignItems="center">
													<Button
														context="text"
														className="text-[14px] items-center py-2 w-[130px]"
														onClick={() => {
															downloadInvoice(invoice.invoiceNumber);
														}}
													>
														Re-download
													</Button>
												</Flex>
											) : (
												<IconButton
													aria-label="Download"
													bg="transparent"
													_hover={{
														bg: "rgb(255 179 35)",
													}}
													_active={{ bg: "transparent" }}
													onClick={() => {
														downloadInvoice(invoice.invoiceNumber);
														setInvoiceDownloaded([
															...invoiceDownloaded,
															invoice.invoiceNumber,
														]);
													}}
													icon={
														<ArrowDownTrayIcon className="size-6 text-[#2294FF]" />
													}
												/>
											)
										) : (
											<p className="text-sm py-[10px] text-[#2489ff] cursor-default">
												{invoice?.rowTitle.includes("Total")
													? "-"
													: "No Invoice File"}
											</p>
										)}
									</Td>
								</Tr>
							))}
					</Tbody>
				)}
			</Table>
		</TableContainer>
	);
}

export default ParcelShipperInvoice;
