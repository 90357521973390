import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	Flex,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
} from "@chakra-ui/react";
import {
	ArrowDownTrayIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@heroicons/react/24/solid";
import * as XLSX from "xlsx";
import ReactFlow, { ReactFlowProvider, addEdge } from "reactflow";
import { useToast } from "@chakra-ui/react";
import { Button } from "../componentLibrary";
import {
	AccountTabs,
	ImportFile,
	Page,
	TableLoading,
	UserContext,
} from "../interfaces";
import { getServiceType, submitServiceType } from "../services";
import ExcelJS from "exceljs";
import "reactflow/dist/style.css";

function debounce(func, wait) {
	let timeout;
	return function (...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}

const ServiceTypes = () => {
	const [carrierServiceTypes, setCarrierServiceTypes] = useState([]);
	const [shipperServiceTypes, setShipperServiceTypes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showServiceTypeModal, setShowServiceTypeModal] = useState(false);
	const [serviceType, setServiceType] = useState([]);
	const [isOpenTable, setIsOpenTable] = useState({
		carrier: true,
		shipper: true,
	});
	const [nodes, setNodes] = useState([]);
	const [edges, setEdges] = useState([]);
	const [activeNode, setActiveNode] = useState(null);
	const [tooltip, setTooltip] = useState({
		visible: false,
		position: { x: 0, y: 0 },
	});
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [isServiceTypeModalLoading, setIsServiceTypeModalLoading] =
		useState(false);
	const leftPosition =
		windowWidth < 800
			? "400px"
			: windowWidth < 1000
			? "500px"
			: windowWidth < 1100
			? "550px"
			: windowWidth > 1200
			? "800px"
			: "700px";

	const { isNonProdEnvironment, token, user, viewingAs } =
		useContext(UserContext);

	const toast = useToast();
	const carrierCode = viewingAs.participantCode;

	const handleToggleTable = useCallback(
		(key) => {
			if (viewingAs.isCarrier || viewingAs.isShipper) {
				setIsOpenTable((tables) => ({ ...tables, [key]: !tables[key] }));
			}
		},
		[viewingAs],
	);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		const debouncedHandleResize = debounce(handleResize, 100);

		window.addEventListener("resize", debouncedHandleResize);

		return () => {
			window.removeEventListener("resize", debouncedHandleResize);
		};
	}, []);

	useEffect(() => {
		if (serviceType.length > 0 && !activeNode) {
			const firstNode = serviceType[0];
			setActiveNode({
				id: `carrier-${firstNode.code}-0`,
				data: {
					label: `${firstNode.code} - ${firstNode.description} (SLA ${firstNode.sla})`,
					description: firstNode.description,
					sla: firstNode.sla,
				},
			});
		}
	}, [serviceType, activeNode]);

	useEffect(() => {
		const positionX =
			windowWidth < 800
				? 400
				: windowWidth < 1000
				? 500
				: windowWidth < 1100
				? 550
				: windowWidth > 1200
				? 800
				: 700;

		const minWidth = windowWidth < 1100 ? "230px" : "318px";

		setNodes([
			...serviceType.map((item, index) => ({
				id: `carrier-${item.code}-${index}`,
				data: {
					label: `${item.code} - ${item.description} (SLA ${item.sla})`,
					description: item.description,
					sla: item.sla,
				},
				position: { x: 0, y: index * 60 + 30 },
				style: {
					display: "flex",
					borderRadius: "0.375rem",
					cursor: "pointer",
					alignItems: "center",
					justifyContent: "space-between",
					textAlign: "left",
					fontSize: "0.875rem",
					lineHeight: "1.25rem",
					minWidth: minWidth,
					height: "2.75rem",
					maxWidth: "100%",
					paddingLeft: "1rem",
					paddingRight: "1rem",
					paddingTop: "0.5rem",
					paddingBottom: "0.5rem",
					backgroundColor:
						activeNode && activeNode.id === `carrier-${item.code}-${index}`
							? "rgba(34, 229, 136,0.2)"
							: "rgba(133, 134, 152, 0.2)",
					border:
						activeNode && activeNode.id === `carrier-${item.code}-${index}`
							? "1px solid rgba(34, 229, 136)"
							: "1px solid rgba(133, 134, 152, 0.2)",
					color:
						activeNode && activeNode.id === `carrier-${item.code}-${index}`
							? "rgba(34, 229, 136)"
							: "white",
				},
				sourcePosition: "right",
				targetPosition: "right",
				className: "right-handle-node",
			})),
			...shipperServiceTypes.map((item, index) => ({
				id: `shipper-${item.code}-${index}`,
				data: { label: item?.code },
				position: { x: positionX, y: index * 60 },
				style: {
					display: "flex",
					borderRadius: "0.375rem",
					cursor: "pointer",
					alignItems: "center",
					justifyContent: "space-between",
					textAlign: "left",
					fontSize: "0.875rem",
					lineHeight: "1.25rem",
					minWidth: minWidth,
					height: "2.75rem",
					maxWidth: "100%",
					paddingLeft: "1rem",
					paddingRight: "1rem",
					paddingTop: "0.5rem",
					paddingBottom: "0.5rem",
					backgroundColor: "rgba(133, 134, 152, 0.2)",
					border: "1px solid rgba(133, 134, 152, 0.2)",
					color: "#fff",
				},
				sourcePosition: "left",
				targetPosition: "left",
				className: "left-handle-node",
			})),
		]);
	}, [serviceType, shipperServiceTypes, windowWidth, activeNode]);

	const onFileSelected = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target?.result);
			const workbook = XLSX.read(data, { type: "array" });
			const sheetName = workbook.SheetNames[4];
			const worksheet = workbook.Sheets[sheetName];
			const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 10 });
			const carrierCodeRowIndex = sheetData.findIndex((row) =>
				Object.values(row).some(
					(cell) =>
						typeof cell === "string" &&
						cell.includes("Network Service Type Code"),
				),
			);

			if (carrierCodeRowIndex !== -1) {
				const dataSet = sheetData.slice(carrierCodeRowIndex + 1);
				generateZoneWiseDetails(dataSet);
			} else {
				console.log('"Carrier Code" not found in the sheet');
			}
		};
		reader.readAsArrayBuffer(file);
	};

	const generateZoneWiseDetails = (fileData) => {
		const fileReadData = fileData.map((item) => ({
			code: item["#VALUE!"],
			description: item["__EMPTY"],
			sla: item["Orchestro Carrier Onboarding Kit"],
			networkServiceType: item["__EMPTY_1"],
		}));
		if (!fileReadData) return;

		setServiceType(fileReadData);
		setShowServiceTypeModal(
			fileReadData.length > 0 && shipperServiceTypes.length > 0,
		);

		const prefilledEdges = fileReadData
			.filter((item) => item.networkServiceType)
			.map((item, index) => {
				const matchedIndices = shipperServiceTypes
					.map((item2, index) =>
						item.networkServiceType === item2.code ? index : null,
					)
					.filter((index) => index !== null);

				const sourceId = `carrier-${item.code}-${index}`;
				const targetId = `shipper-${item.networkServiceType}-${matchedIndices}`;

				if (
					sourceId.startsWith("carrier-") &&
					targetId.startsWith("shipper-")
				) {
					return {
						id: `edge-${sourceId}-${targetId}`, // Unique edge ID
						source: sourceId,
						target: targetId,
						style: { stroke: "#22E588", strokeWidth: 3 },
						type: "straight",
						selectable: false,
						sla: item?.sla,
						description: item?.description,
					};
				}

				return null;
			})
			.filter(Boolean); // Remove any null values

		setEdges(prefilledEdges);
	};

	const handleServicesTypeDownload = async () => {
		try {
			const resData = await getServiceType(viewingAs.participantCode, token);

			const response = await fetch(
				"/data-template/orchestro-admin-template.xlsx",
			);
			if (!response.ok)
				throw new Error("Network error, could not download data");

			const arrayBuffer = await response.arrayBuffer();

			const workbook = new ExcelJS.Workbook();
			await workbook.xlsx.load(arrayBuffer);

			const serviceCodesSheetName = "4_ServiceCodes";
			const sheet = workbook.getWorksheet(serviceCodesSheetName);

			const newServiceCodesData = resData?.carrierServiceTypes?.map((item) => [
				item.code,
				item.description,
				item.sla || "",
				item.networkServiceType,
			]);

			let headerRowIndex = -1;
			sheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
				const values = row.values;
				if (
					values[1]?.toString()?.trim() === "Carrier Code" &&
					values[2]?.toString()?.trim() === "Service Type" &&
					values[3]?.toString()?.trim() === "SLA" &&
					values[4]?.toString()?.trim() === "Network Service Type Code" &&
					values[5]?.toString()?.trim() === "Description"
				) {
					headerRowIndex = rowNumber;
				}
			});

			if (headerRowIndex !== -1) {
				const startRow = headerRowIndex + 1;

				// Clear the existing data in the rows below the header
				sheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
					if (rowNumber > headerRowIndex) {
						row.eachCell({ includeEmpty: true }, (cell) => {
							if (cell.value) {
								cell.value = null;
							}
						});
					}
				});

				// Insert new data into the sheet
				newServiceCodesData.forEach((dataRow, rowIndex) => {
					dataRow.forEach((cellValue, colIndex) => {
						const cell = sheet.getCell(startRow + rowIndex, colIndex + 1);
						cell.value = cellValue;

						const headerCell = sheet.getCell(headerRowIndex + 1, colIndex + 1);
						cell.style = { ...headerCell.style };
					});
				});

				sheet.getRow(startRow + newServiceCodesData.length - 1).height = 20;

				const newWorkbookData = await workbook.xlsx.writeBuffer();

				const blob = new Blob([newWorkbookData], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});

				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.download = "services_types_download.xlsx";
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} else {
				console.error("Header row not found");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleClickNode = useCallback(
		(_, node) => {
			const isCarrierNode = node.id.startsWith("carrier-"); // Identify if it's a carrier node
			const isShipperNode = node.id.startsWith("shipper-"); // Identify if it's a shipper node

			if (
				serviceType.some(
					(item, index) => `carrier-${item.code}-${index}` === node.id,
				)
			) {
				if (activeNode?.id !== node.id) {
					setActiveNode(node);
				}
			} else if (activeNode) {
				const isActiveCarrierNode = activeNode.id.startsWith("carrier-");
				const isActiveShipperNode = activeNode.id.startsWith("shipper-");

				if (
					(isActiveCarrierNode && isCarrierNode) ||
					(isActiveShipperNode && isShipperNode)
				) {
					toast({
						title: "Invalid connection",
						description: "Cannot connect the same type of nodes.",
						status: "error",
						duration: 3000,
						isClosable: true,
						position: "top-right",
					});
					return; // Exit early if invalid connection
				}

				// Add edge if the nodes are valid to connect (carrier -> shipper or shipper -> carrier)
				const newEdges = edges.filter((edge) => edge.source !== activeNode?.id);
				setEdges(() =>
					addEdge(
						{
							id: activeNode?.id,
							source: activeNode?.id,
							target: node.id,
							style: { stroke: "#22E588", strokeWidth: 3 },
							type: "straight",
							description: activeNode.data.description,
							sla: activeNode.data.sla,
						},
						newEdges,
					),
				);
			}
		},
		[activeNode, edges, serviceType],
	);

	const onEdgeClick = useCallback((event, edge) => {
		event.stopPropagation();
		setEdges((eds) => eds.filter((e) => e.id !== edge.id));
		setTooltip({ visible: false, position: { x: 0, y: 0 } });
	}, []);

	const onEdgeMouseEnter = useCallback((event) => {
		const { clientX: x, clientY: y } = event;
		setTooltip({ visible: true, position: { x, y } });
	}, []);

	const onEdgeMouseLeave = useCallback(() => {
		setTooltip({ visible: false, position: { x: 0, y: 0 } });
	}, []);

	useEffect(() => {
		if (!viewingAs.participantCode) return;
		setIsLoading(true);
		setCarrierServiceTypes([]);
		getServiceType(viewingAs.participantCode, token)
			.then((response) => {
				if (response?.orchestroNetworkServiceTypes) {
					setCarrierServiceTypes(response.carrierServiceTypes);
				}
			})
			.finally(() => setIsLoading(false));
	}, [token, viewingAs, showServiceTypeModal]);

	useEffect(() => {
		setIsLoading(true);
		setIsServiceTypeModalLoading(true);
		setShipperServiceTypes([]);
		getServiceType(viewingAs.participantCode, token)
			.then((response) => {
				if (response?.orchestroNetworkServiceTypes) {
					setShipperServiceTypes(response.orchestroNetworkServiceTypes);
				}
			})
			.finally(() => {
				setIsLoading(false);
				setIsServiceTypeModalLoading(false);
			});
	}, [token, viewingAs, showServiceTypeModal]);

	const areAllItemsMapped = () => {
		const mappedItems = new Set(edges.map((edge) => edge.source));
		return serviceType.every((item, index) =>
			mappedItems.has(`carrier-${item.code}-${index}`),
		);
	};

	const handleUpdateServiceTypeDetails = async () => {
		if (!areAllItemsMapped()) {
			toast({
				title: "All data should be mapped",
				description:
					"Please ensure all service types are mapped before submitting.",
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "top-right",
			});
			return;
		}

		const updatedFileData = edges.map((item) => ({
			code: item?.source.split("-")[1],
			description: item?.description,
			sla: item?.sla,
			networkServiceType: item?.target.split("-")[1],
		}));

		const response = await submitServiceType(
			carrierCode,
			token,
			updatedFileData,
		);

		if (response && response.ok) {
			toast({
				title: "Uploaded successfully!",
				status: "success",
				duration: 5000,
				isClosable: true,
				position: "top-right",
			});
		} else if (response) {
			const res = await response.json();
			toast({
				title: res?.errors[0]?.message || "Upload failed",
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "top-right",
			});
		} else {
			toast({
				title: "Network error occurred",
				status: "error",
				duration: 5000,
				isClosable: true,
				position: "top-right",
			});
		}
		resetDetails();
	};

	const resetDetails = () => {
		setShowServiceTypeModal(false);
		setServiceType([]);
		setEdges([]);
		setActiveNode(null);
	};
	return (
		<>
			<AccountTabs />
			<Page className={"!min-h-0"}>
				{/* {(viewingAs.isShipper || viewingAs.isCarrier) && (
          <>
            <div className="flex justify-end items-center gap-2">
              {user.isPlatformAdmin && isNonProdEnvironment && (
                <>
                  <ImportFile
                    isDataExists={false}
                    endpoint="submit-service-type"
                    onFileSelected={onFileSelected}
                    carrierCode={viewingAs.participantCode}
                  />
                  <Button
                    context="primary"
                    prefixIcon={<ArrowDownTrayIcon />}
                    onClick={handleServicesTypeDownload}
                  >
                    Download template
                  </Button>
                </>
              )}
            </div>
            <Flex
              alignItems={"center"}
              gap={6}
              marginY={4}
              onClick={() => handleToggleTable("shipper")}
              cursor={
                viewingAs.isCarrier && viewingAs.isShipper
                  ? "pointer"
                  : "initial"
              }
            >
              {viewingAs.isCarrier && viewingAs.isShipper && (
                <>
                  <h2 className="text-xl">Orchestro Network</h2>
                  <IconButton
                    variant={"text"}
                    _hover={{ backgroundColor: "#6861631a" }}
                  >
                    {isOpenTable.shipper ? (
                      <ChevronUpIcon width={24} />
                    ) : (
                      <ChevronDownIcon width={24} />
                    )}
                  </IconButton>
                </>
              )}
            </Flex>

            <TableContainer
              className=" mb-12"
              overflowY={"auto"}
              width={"100%"}
              marginBottom={
                isOpenTable.shipper &&
                viewingAs.isCarrier &&
                viewingAs.isShipper
                  ? 12
                  : 4
              }
              maxHeight={
                isOpenTable.shipper
                  ? `calc(100vh - ${
                      viewingAs.isCarrier && viewingAs.isShipper
                        ? isOpenTable.carrier
                          ? "665px"
                          : "420px"
                        : "320px"
                    })`
                  : "0"
              }
              transition={"max-height ease 0.5s"}
            >
              <Table>
                <Thead>
                  <Tr>
                    <Th width={"30%"}>Network Service Type</Th>
                    <Th>Network Description</Th>
                    <Th>TNT</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoading && <TableLoading data={shipperServiceTypes} />}
                  {!isLoading &&
                    (!shipperServiceTypes ||
                      shipperServiceTypes.length === 0) && (
                      <Tr>
                        <Td colSpan="100">No data found</Td>
                      </Tr>
                    )}
                  {shipperServiceTypes &&
                    shipperServiceTypes.map((serviceType, i) => (
                      <Tr key={i + "-" + serviceType.code}>
                        <Td fontWeight="bold">
                          {serviceType?.networkServiceType ?? "-"}
                        </Td>
                        <Td>{serviceType?.networkDescription ?? "-"}</Td>
                        <Td>{serviceType?.tnt ?? "-"}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )} */}

				{(viewingAs.isShipper || viewingAs.isCarrier) && (
					<>
						<div className="flex justify-end items-center gap-2">
							{user.isPlatformAdmin && isNonProdEnvironment && (
								<>
									<ImportFile
										isDataExists={false}
										endpoint="submit-service-type"
										onFileSelected={onFileSelected}
										carrierCode={viewingAs.participantCode}
									/>
									<Button
										context="primary"
										prefixIcon={<ArrowDownTrayIcon />}
										onClick={handleServicesTypeDownload}
									>
										Download Template
									</Button>
								</>
							)}
						</div>
						<Flex
							alignItems={"center"}
							gap={6}
							marginY={4}
							onClick={() => handleToggleTable("carrier")}
							cursor={
								viewingAs.isCarrier || viewingAs.isShipper
									? "pointer"
									: "initial"
							}
						>
							{(viewingAs.isCarrier || viewingAs.isShipper) && (
								<>
									<h2 className="text-xl">
										{viewingAs.isCarrier ? "Carrier" : "Shipper"}
									</h2>
									<IconButton
										variant={"text"}
										_hover={{ backgroundColor: "#6861631a" }}
									>
										{isOpenTable.carrier ? (
											<ChevronUpIcon width={24} />
										) : (
											<ChevronDownIcon width={24} />
										)}
									</IconButton>
								</>
							)}
						</Flex>
						<TableContainer
							className=" mb-4"
							overflowY={"auto"}
							width={"100%"}
							marginBottom={4}
							maxHeight={
								isOpenTable.carrier
									? `calc(100vh - ${
											viewingAs.isCarrier && viewingAs.isShipper
												? isOpenTable.shipper
													? "665px"
													: "420px"
												: "310px"
									  })`
									: "0"
							}
							transition={"max-height ease 0.5s"}
						>
							<Table>
								<Thead>
									<Tr>
										<Th width={"30%"}>Carrier Service Type</Th>
										<Th>Code</Th>
										<Th>Description</Th>
										<Th>Network Service Type</Th>
										<Th>Network Description</Th>
										<Th>TNT</Th>
									</Tr>
								</Thead>
								<Tbody>
									{isLoading && <TableLoading data={carrierServiceTypes} />}
									{!isLoading &&
										(!carrierServiceTypes ||
											carrierServiceTypes.length === 0) && (
											<Tr>
												<Td colSpan="100">No data found</Td>
											</Tr>
										)}
									{carrierServiceTypes &&
										carrierServiceTypes.map((serviceType, i) => {
											return (
												<Tr key={i + "-" + serviceType.code}>
													<Td fontWeight="bold">
														{serviceType?.carrierServiceType ?? "-"}
													</Td>
													<Td>{serviceType?.code ?? "-"}</Td>
													<Td>{serviceType?.description ?? "-"}</Td>
													<Td>{serviceType?.networkServiceType ?? "-"}</Td>
													<Td>{serviceType?.networkDescription ?? "-"}</Td>
													<Td>{serviceType?.tnt ?? "-"}</Td>
												</Tr>
											);
										})}
								</Tbody>
							</Table>
						</TableContainer>
					</>
				)}
				<Modal
					isOpen={showServiceTypeModal}
					onClose={resetDetails}
					type="white"
				>
					<ModalOverlay />
					<ModalContent className="!w-[90vw] !max-w-[90vw] h-[80vh] pt-9 pb-5 px-14 !bg-[#1E253F] modalContent">
						<ModalHeader className="text-center !m-0 !p-0">
							Service Types
						</ModalHeader>
						<div className="text-center p-0 !mt-0 text-sm font-medium">
							Map the Service Type with Orchestro Service Type
						</div>
						<ModalCloseButton />
						{isServiceTypeModalLoading ? (
							<div className="flex items-center justify-center h-full">
								<Spinner />
							</div>
						) : (
							<ModalBody className="flex flex-col ">
								<div className="relative w-full h-full">
									<div className="absolute z-10 top-2 text-white">
										<h3 className="text-sm font-medium">Service Types</h3>
									</div>
									<div
										className="top-2 text-white z-10 absolute"
										style={{ left: leftPosition }}
									>
										<h3 className="text-sm font-medium">
											Shipper Service Types
										</h3>
									</div>

									<ReactFlowProvider>
										<ReactFlow
											nodes={nodes}
											edges={edges}
											onNodeClick={(e, node) =>
												handleClickNode(e, node, serviceType)
											}
											onEdgeClick={onEdgeClick}
											deleteKeyCode={46}
											className="saved-filter react-flow-style"
											panOnDrag={false}
											zoomOnScroll={false}
											zoomOnPinch={false}
											zoomOnDoubleClick={false}
											onEdgeMouseEnter={onEdgeMouseEnter}
											onEdgeMouseLeave={onEdgeMouseLeave}
											proOptions={{ hideAttribution: true }}
										></ReactFlow>
										{tooltip.visible && (
											<div
												className="absolute bg-gray-800 text-white p-2 rounded-md text-xs z-10"
												style={{
													top: tooltip.position.y - 200,
													left: tooltip.position.x - 200,
												}}
											>
												Click to delete the mapping
											</div>
										)}
									</ReactFlowProvider>
								</div>
								<div className="flex justify-end gap-4 mt-14 mb-2">
									<Button onClick={handleUpdateServiceTypeDetails}>Save</Button>
								</div>
							</ModalBody>
						)}
					</ModalContent>
				</Modal>
			</Page>
		</>
	);
};

export default ServiceTypes;
