import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css-files/style.css";
import { getEventsBasedOnTrackerId, getParcels } from "../services";
import { UserContext } from "../interfaces";
import { useGeoData } from "../contexts/GeoDataProvider";
import Tracker from "../interfaces/TrackTrace/MultiTrack/Tracker";
import { useToast, Box } from "@chakra-ui/react";

function ShipmentDetails() {
	const { trackingNumber } = useParams();
	const navigate = useNavigate();
	const { token, user } = useContext(UserContext);
	const { fetchLocationData, fetchTrackingData, setParcelData } = useGeoData();
	const toast = useToast();

	const [trackingId, setTrackingId] = useState("");
	const [trackingData, setTrackingData] = useState([]);
	const [locationData, setLocationData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [trackerStatus, setTrackerStatus] = useState(true);
	const [error, setError] = useState(false);
	const MIN_INPUT_LENGTH = 6;
	const MAX_INPUT_LENGTH = 30;
	const permission = user.Groups[0];

	const body = {
		shipperCode: user.isShipper && !user.isPlatformAdmin ? [permission] : [],
		carrierCode: user.isCarrier && !user.isPlatformAdmin ? [permission] : [],
	};

	const triggerToast = () => {
		toast({
			status: "error",
			duration: 5000,
			isClosable: true,
			variant: "solid",
			position: "bottom",
			render: () => (
				<Box
					display="grid"
					placeItems="center"
					bg="#EF4444"
					color="white"
					p={4}
					ml={4}
					borderRadius="md"
					maxW={["60vw", "md:max-width: 400px"]}
					textAlign="center"
				>
					<strong>Invalid Tracking Number</strong>
					<p>The tracking number you entered was not found.</p>
				</Box>
			),
		});
	};

	const handleTrackingNumberChange = (value) => {
		const newTrackingNumber = value;
		if (newTrackingNumber === trackingNumber) {
			getParcel();
		} else {
			navigate(`/app/shipments/${newTrackingNumber}`);
		}
	};

	useEffect(() => {
		if (trackingNumber) {
			setTrackingId(trackingNumber);
		}
	}, [trackingNumber]);

	const handleInputChange = (e) => {
		setError(false);
		const tValue = e.target.value;
		if (tValue.length > MIN_INPUT_LENGTH && tValue.length < MAX_INPUT_LENGTH) {
			setTrackingId(tValue);
		} else if (tValue.length === 0) {
			setTrackingId("");
		}
	};

	const getEvents = () => {
		handleTrackingNumberChange(trackingId);
	};

	const fetchData = async () => {
		try {
			const data = await getEventsBasedOnTrackerId(trackingNumber, token);
			if (data.results.length === 0) {
				setError(true);
				setLoading(false);
				setTrackerStatus(false);
				triggerToast();
			} else {
				const { results } = data;
				setError(false);
				setTrackingData(results);
				setTrackerStatus(true);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setError(true);
			setLoading(false);
			setTrackerStatus(false);
			triggerToast();
		}
	};

	const getParcel = () => {
		setLoading(true);
		let requestBody = {
			trackingNumber: trackingNumber,
			limit: 1,
			start: 1,
		};

		if (
			(body && body.shipperCode && body.shipperCode.length > 0) ||
			(body && body.carrierCode && body.carrierCode.length > 0)
		) {
			requestBody = {
				...requestBody,
				...body,
			};
		}
		getParcels(requestBody, token)
			.then((response) => {
				if (response && response.parcels && response.parcels.length > 0) {
					const parcels = response.parcels;
					setParcelData(response.parcels);
					setLocationData({
						shipFrom: parcels[0].shipFrom,
						shipTo: parcels[0].shipTo,
					});
					fetchData(trackingId);
				} else {
					if (response?.parcels.length !== 0) {
						console.error("Unexpected response format:", response);
					}
					setLoading(false);
					setError(true);
					setTrackerStatus(false);
				}
			})
			.catch((error) => {
				console.error("Error fetching data:", error);
				setLoading(false);
				setError(true);
				setTrackerStatus(false);
			});
	};

	useEffect(() => {
		if (trackingNumber) {
			getParcel();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackingNumber, token]);

	useEffect(() => {
		if (locationData) {
			fetchLocationData(locationData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationData]);

	useEffect(() => {
		if (trackingData?.length > 0 && locationData) {
			fetchTrackingData(trackingData, locationData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackingData, locationData]);

	return (
		<div
			className="flex-grow h-[89vh] w-full pb-0 ml-2 mt-2 bg-[#181921]"
			id="tracking-logged-in"
		>
			<div className="tracking_details">
				<Tracker
					isPublicTracking={false}
					error={error}
					handleInputChange={handleInputChange}
					setTrackerStatus={setTrackerStatus}
					setTrackingId={setTrackingId}
					getEvents={getEvents}
					trackerStatus={trackerStatus}
					trackingId={trackingId}
					loading={loading}
				/>
			</div>
		</div>
	);
}

export default ShipmentDetails;
