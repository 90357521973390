import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_API_KEY } from "../../../constants";
import { reverseGeocoding } from "../../../utilities/reverseGeocoding";
import { useGeoData } from "../../../contexts/GeoDataProvider";
import { animateMarker } from "../../../utilities/markerAnimation";
import { useToast, Box } from "@chakra-ui/react";
import { isWebGLAvailable } from "../../../utilities/isWebGLAvailable";
import "./css/mainmap.css";



const defaultCenter = [-100, 40];

let origin = {
	center: defaultCenter,
	zoom: 2.8,
	attributionControl: false,
	transition: {
		duration: 300,
		delay: 0,
	},
};

mapboxgl.accessToken = MAPBOX_API_KEY;

const loadImages = (map) => {
	const imagesToLoad = [
	  { name: 'start-icon', url: '/images/map/start.png' },
	  { name: 'marker_blue', url: '/images/map/marker_blue.png' },
	  { name: 'marker_red', url: '/images/map/marker_red.png' },
	  { name: 'end_route', url: '/images/map/endroute.png' },
	  { name: 'box', url: '/images/map/box.png' },
	];
  
	imagesToLoad.forEach((img) => {
	  map.loadImage(img.url, (error, image) => {
		if (error) throw error;
		map.addImage(img.name, image);
	  });
	});
  };

const MainMap = ({
	route,
	locationData,
	trackingData,
}) => {
	const { geoRoute } = useGeoData();
	const mapContainerRef = useRef();
	const [webglAvailable, setWebglAvailable] = useState(false);
	const mapRef = useRef();

	useEffect(() => {
		setWebglAvailable(isWebGLAvailable());
	  }, []);

	const allCoordinates = useRef([]);

	const toast = useToast();

const triggerToast = (message) => {
	toast({
		status: "error",
		duration: 5000,
		isClosable: true,
		variant: "solid",
		position: "center",
		render: () => (
			<Box
				display="grid"
				placeItems="center"
				bg="#EF4444"
				color="white"
				p={4}
				ml={4}
				borderRadius="md"
				maxW={["60vw", "md:max-width: 400px"]}
				textAlign="center"
			>
				<strong>Route Error</strong>
				<p>{message}</p>
			</Box>
		),
	});
};

	useEffect(() => {
		if (!mapContainerRef.current || mapRef.current || !webglAvailable) return;
		origin.center =
			route && route.origin
				? [route.origin[1], route.origin[0]]
				: defaultCenter;

		mapRef.current = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: "mapbox://styles/paulforai/cm19toikd05cd01pqf5fug7uu",
			...origin,
		});

		mapRef.current.on("load", () => {
			loadImages(mapRef.current);

			mapRef.current.setFog(null);

			mapRef.current.addLayer({
				type: "background",
				id: "dark-layer",
				paint: {
					"background-color": "rgba(0,0,0,0.5)",
				},
				interactive: false,
			});

			if (mapRef.current.getLayer("land")) {
				mapRef.current.setPaintProperty("land", "background-color", "#191A23");
			}

			if (mapRef.current.getLayer("land")) {
				mapRef.current.setPaintProperty("land", "background-color", "#191A23");
			}

			if (mapRef.current.getLayer("admin-0-boundary")) {
				mapRef.current.setPaintProperty(
					"admin-0-boundary",
					"line-color",
					"#858698",
				);
			}

			if (mapRef.current.getLayer("admin-1-boundary")) {
				mapRef.current.setPaintProperty(
					"admin-1-boundary",
					"line-color",
					"#858698",
				);
			}

			mapRef.current.addLayer({
				id: "3d-buildings",
				source: "composite",
				"source-layer": "building",
				filter: ["==", "extrude", "true"],
				type: "fill-extrusion",
				minzoom: 12,
				paint: {
					"fill-extrusion-color": "#858698",

					// Use an 'interpolate' expression to add a smooth transition effect to the
					// buildings as the user zooms in.
					"fill-extrusion-height": [
						"interpolate",
						["linear"],
						["zoom"],
						12,
						["get", "height"],
						15,
						["get", "height"],
					],
					"fill-extrusion-base": [
						"interpolate",
						["linear"],
						["zoom"],
						12,
						["get", "min_height"],
						16.05,
						["get", "min_height"],
					],
					"fill-extrusion-opacity": 0.8,
				},
			});
		});

		mapRef.current.on("load", () => {
			if (geoRoute) {
				const data = geoRoute;
				if (data && data.routes) {
				const jsonGeometry = data?.routes[0]?.geometry;
				let flag = 0;
				const features = jsonGeometry.coordinates.reduce(
					(feature, coordinate, index) => {
						const isRouteCoordinate = data.waypoints.find(
							(point) =>
								JSON.stringify(point.location) === JSON.stringify(coordinate),
						);

						if (index !== 0 && isRouteCoordinate) {
							feature.push({
								type: "LineString",
								coordinates: [
									...jsonGeometry.coordinates.slice(flag, index + 1),
								],
								markerColor:
									index === jsonGeometry.coordinates.length - 1
										? "blue"
										: "blue",
							});
							flag = index;
						}
						return feature;
					},
					[]);

					const markersGeoJSON = {
						type: 'FeatureCollection',
						features: [],
					  };
				  
					
				Promise.all(
					features.map((feature, index) => {
						const coordinates = feature.coordinates[0];
						return new Promise((resolve, reject) => {
							if (index === 0) {
								return new Promise(() => {
									resolve({ ...feature });
								});
							} else {
								reverseGeocoding({
									lat: coordinates[1],
									lng: coordinates[0],
								})
									.then((address) => {
										return new Promise(() => {
											resolve({ ...feature, address });
										});
									})
									.catch((error) => {
										return new Promise(() => {
											reject(new Error(error));
										});
									});
							}
						});
					}),
				)
					.then((response) => {
						response.forEach((feature, index) => {
							const sourceId = `route-${index}`;
							const coordinates = feature.coordinates[0];
							allCoordinates.current = [
								...allCoordinates.current,
								...feature.coordinates,
							];
							let coordIndex = 0;
							mapRef.current.addSource(sourceId, {
								type: "geojson",
								data: {
									type: feature.type,
									coordinates: feature.coordinates,
								},
							});
							const color =
								feature.markerColor === "blue" ? "#3B86ED" : "#DA2C17";
							const marker =
								feature.markerColor === "blue" ? "marker_blue" : "marker_red";
								

							markersGeoJSON.features.push({
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: coordinates,
								},
								properties: {
									icon: index === 0 ? 'start-icon' : marker,
									symbolSortKey: index === 0 ? 2 : 1,
								}
							});	
		

							mapRef.current.addLayer({
								id: `route-${index}`,
								type: "line",
								source: sourceId,
								layout: {
									"line-join": "round",
									"line-cap": "round",
								},
								paint: {
									"line-color": color,
									"line-width": 4,
									"line-dasharray": [2, 2],
								},
							});


							if (index === features.length - 1) {
								let coordinateEnd =
									feature.coordinates[feature.coordinates.length - 1];

									markersGeoJSON.features.push({
										type: 'Feature',
										geometry: {
											type: 'Point',
											coordinates: coordinateEnd,
										},
										properties: {
											icon: marker,
											symbolSortKey: 1,
										}
									});	

									const endRouteMarkerFeature = {
										type: 'Feature',
										geometry: {
										  type: 'Point',
										  coordinates: coordinateEnd,
										},
										properties: {
										  icon: 'end_route',
										  symbolSortKey: 2,
										},
									  };

									  markersGeoJSON.features.push(endRouteMarkerFeature);

								const movingMarkerFeature = {
									type: 'Feature',
									geometry: {
										type: 'Point',
										coordinates: allCoordinates.current[coordIndex],
									},
									properties: {
										icon: 'box',
										symbolSortKey: 4,
									}
								};

								markersGeoJSON.features.push(movingMarkerFeature);

								mapRef.current.addSource('markers', {
									type: 'geojson',
									data: markersGeoJSON,
								  });

								  mapRef.current.addLayer({
									id: 'marker-layer',
									type: 'symbol',
									source: 'markers',
									layout: {
									  'icon-image': ['get', 'icon'],
									  'icon-size': 1,
									  'icon-anchor': 'center',
									  'icon-offset': [
										'case',
										['==', ['get', 'icon'], 'end_route'],
										[0, -30],
										[0, 5], 
										],
									  'icon-pitch-alignment': 'map',
  									   'icon-rotation-alignment': 'map', 
									 'icon-allow-overlap': true,
  										'icon-ignore-placement': true,
										'symbol-sort-key': ['get', 'symbolSortKey'],
									},
								  });

								mapRef.current.setCenter(feature.coordinates[coordIndex]);

								animateMarker(
									mapRef,
									markersGeoJSON,
									movingMarkerFeature,
									allCoordinates.current,
									coordIndex,
									locationData,
									trackingData,
								);
							}
						});
					})
					.catch((error) => {
						console.error("Geocoding failed:", error);
					});
			} else if (data && data.message) {
				triggerToast(data.message);
			} else {
				triggerToast("Route in error");
			}
		}
		});
	}, [route, mapContainerRef.current]);

	return (
		<div className="fullMap-container relative w-full h-[100vh]">
			<div id="map" ref={mapContainerRef} className="w-full h-full" />;
		</div>
	);
};

export default MainMap;
