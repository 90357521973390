import React, { ReactNode } from "react";
import "./modal.css";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { XMarkIcon } from "@heroicons/react/24/solid";
import IconButton from "../IconButton/IconButton";

interface ClassName {
	overlay?: string;
	content?: string;
	title?: string;
	description?: string;
	body?: string;
	controls?: string;
	closeButton?: string;
}

interface ModalProps {
	title?: string;
	type?: "auto" | "always" | "scroll" | "hover";
	description?: string;
	children?: ReactNode;
	controls?: ReactNode;
	defaultOpen?: boolean;
	open?: boolean;
	onOpenChange?(open: boolean): void;
	onClose?: Function;
	blurBackground?: boolean;
	className?: ClassName;
	[key: string]: any;
}

const Modal: React.FC<ModalProps> = ({
	title,
	type = "hover",
	description,
	children,
	controls,
	defaultOpen,
	open,
	onOpenChange,
	onClose,
	blurBackground,
	className = {
		overlay: "",
		content: "",
		title: "",
		description: "",
		body: "",
		controls: "",
		closeButton: "",
	},
	...props
}) => {
	return (
		<Dialog.Root
			defaultOpen={defaultOpen}
			open={open}
			onOpenChange={onOpenChange}
			modal={blurBackground}
		>
			<Dialog.Portal>
				<Dialog.Overlay
					className={clsx("oui-modal__overlay", className.overlay)}
				/>
				<Dialog.Content className={clsx("oui-modal__box", className.content)}>
					<div className="oui-modal__header">
						<Dialog.Title className={clsx("oui-modal__title", className.title)}>
							{title}
						</Dialog.Title>
						<Dialog.Close asChild>
							<IconButton
								context="secondary"
								className={clsx(
									"oui-modal__close-button",
									className.closeButton,
								)}
								aria-label="Close"
								onClick={onClose}
							>
								<XMarkIcon />
							</IconButton>
						</Dialog.Close>
					</div>
					<Dialog.Description
						className={clsx("oui-modal__description", className.description)}
					>
						{description}
					</Dialog.Description>
					<div className={clsx("oui-modal__body", className.body)}>
						{children}
					</div>
					<div className={clsx("oui-modal__controls", className.controls)}>
						{controls}
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export default Modal;
