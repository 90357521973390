import React, { useContext, useEffect, useState } from 'react';
import { fetchCoordinates } from './Coordinates';
import { Button } from '../../../componentLibrary';
import { validateZipCode } from '../../../services';
import { UserContext } from '../../Providers';

const StaticCoverageZipCodes = [75247, 77032, 20009];

const PopupContent = ({ data, colorsCode, setZipCodesData, mapStatus, sortCodeValidation }) => {
  const [zipCodes, setZipCodes] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [saveId, setSaveId] = useState(null);
  const {token} =useContext(UserContext);

  useEffect(() => {
    const zipCode = data.filter((zipCode) => zipCode.coordinates.length===0);
    setZipCodes(zipCode);
    setSaveId(null)
  }, [data]);

  const isZipCodeError = (zipCode) => zipCode?.success === 'zipcode';
  const isStartError = (zipCode) => zipCode?.success === 'start';

  const colorNavigation = (row,coord) => {
    if (coord.length!==0) {
      if (row.Start?.length === 5 && row.Stop?.length === 5) {
        return parseInt(row.Stop) - parseInt(row.Start) >= 0 ? '' : 'start';
      } else {
        return 'start';
      }
    } else {
      return 'zipcode';
    }
  };

  const handleEdit = (id) => {
    setEditingId(id);
    const zipCodeToEdit = zipCodes.find((zipCode) => zipCode.id === id);
    setEditedData({ ...zipCodeToEdit });
    setSaveId(null);
  };

  const cooordinates=async (status,zip)=>{
    if(status){
      const coordinates = await fetchCoordinates(zip);
      return coordinates
    }else{
      return []
    }

  }

  const updatedCodes = async (updatedData, id) => {
    const data = updatedData.find((e) => e.id === id);
    const zipData=await validateZipCode([parseInt(data.ZIP)],token)
    const coordinatesStatus=zipData[0].status==="VALID"
    const coordinateResponse=await cooordinates(coordinatesStatus,data.ZIP)
    const coooodinatesFullfill=await Promise.all(coordinateResponse)
    return {
      ...data,
      coordinates:coooodinatesFullfill,
      success: colorNavigation(data,coooodinatesFullfill),
    };
  };

  const handleSave = async (id) => {
    const updatedData = zipCodes.map((entry) =>
      entry.id === editingId ? { ...entry, ...editedData } : entry
    );
    setZipCodes(updatedData);

    const updated = await Promise.all(
      data.map(async (e) => {
        if (e.id === editingId) {
          return await updatedCodes(updatedData, e.id);
        }
        return e;
      })
    );

    sortCodeValidation(updated);
    setZipCodesData(updated);

    if (mapStatus.current) {
      mapStatus.current.remove();
      mapStatus.current = null;
    }

    setEditingId(null);
    setSaveId(id);
    setEditedData({});
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditedData((prevData) => ({ ...prevData, [id]: value }));
  };

  const createInputField = (labelText, value, id, zipId, isError) => {
    const color = isError ? '#FF263F' : 'white';
    const labelColor = isError ? '#FF263F' : '#858698';
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <label
          style={{
            display: "flex",
            flexBasis: "40%",
            fontSize: "12px",
            color: labelColor,
          }}
        >
          <strong>{labelText}:</strong>
        </label>
          <input
            id={id}
            type="text"
            value={zipId===editingId ?editedData[id] :value}
            className="text-xs"
            style={{
              display: "flex",
              flexBasis: "60%",
              borderRadius: "4px",
              width: "100%",
              padding: "4px",
              marginTop: "4px",
              backgroundColor: "transparent",
              color: color,
              border:zipId===editingId ? "1px solid #414141":"1px solid transparent" 
            }}
            onChange={handleChange}
            disabled={zipId!==editingId}
          />
      </div>
    );
  };

  return (
    <>
      {zipCodes.map((zipCode) => (
        <div
          key={zipCode.id}
          className='mb-2'
          id="popup-content"
          style={{
            padding: '20px',
            width: '300px',
            color: 'white',
            fontFamily: 'Arial, sans-serif',
            borderRadius: '12px',
            backgroundColor: '#21232E',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h3 style={{ margin: '0', fontSize: '14px', fontWeight: 'bold' }}>{zipCode.City}</h3>
            <button
              onClick={() => handleEdit(zipCode.id)}
              style={{ background: 'none', border: 'none', color: '#4FC1FF', cursor: 'pointer', fontSize: '18px' }}
            >
              ✎
            </button>
          </div>
          <div style={{ marginTop: '10px', display: 'flex' }}>
            <div style={{ border: `2px solid ${colorsCode(zipCode.success)}`, marginRight: '16px' }}></div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap:5 }}>
              {createInputField('Code', zipCode.Code, 'Code', zipCode.id)}
              {createInputField('Street Address', zipCode['Street Address'], 'Street Address', zipCode.id,)}
              {createInputField('City', zipCode.City, 'City',zipCode.id)}
              {createInputField('State', zipCode.State, 'State', zipCode.id)}
              {createInputField('Zip', zipCode.ZIP, 'ZIP', zipCode.id, isZipCodeError(zipCode))}
              {createInputField('Start', zipCode.Start, 'Start', zipCode.id, isStartError(zipCode))}
              {createInputField('Stop', zipCode.Stop, 'Stop', zipCode.id, isStartError(zipCode))}
            </div>
          </div>
          {editingId === zipCode.id && (
            <div className='flex justify-center mt-2'>
              <Button onClick={() => handleSave(zipCode.id)} className='w-[100%]'>Save</Button>
            </div>
          )}
          {saveId === zipCode.id && (
            <div className='flex justify-center mt-2'>
              <Button className='w-[100%]'>Saved</Button>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default PopupContent;
