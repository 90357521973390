import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Text } from "@chakra-ui/react";
import { getAllPartners, getCarriersByShipper } from "../../services";
import { SelectField } from "../InputFields";
import { UserContext } from "../Providers";

const Filters = ({ filters, setFilters, shippers }) => {
  const [partners, setPartners] = useState([]);
  const [carriers, setCarriers] = useState([]);

  const { token } = useContext(UserContext);

  const getCarriers = useCallback((shipper = "") => {
    getCarriersByShipper(shipper, token)
      .then((response) => {
        setCarriers(["All", ...response.carriers]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [token]);

  useEffect(() => {
    // fetching all partners from the API
    getAllPartners(token)
      .then((response) => {
        setPartners(["All", ...response.partners]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [token]);

  useEffect(() => {
    // fetching all carriers from the API
    getCarriers(filters.shippers);

    // eslint-disable-next-line
  }, [filters.shippers]);

  const handleFiltersChange = useCallback((e, key) => {
    setFilters((filters) => ({ ...filters, [key]: e.target.value }));
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      marginTop={"18px"}
      display={"flex"}
      columnGap={"40px"}
      flexWrap={"wrap"}
    >
      <div className="mb-3">
        <Text className="p-2 font-medium tracking-[0.28px] text-[#EBEBEB]">
          Partner
        </Text>
        <div className="w-[250px] md:w-[300px] lg:w-[340px]">
          <SelectField
            options={partners.map((partner) => ({
              value: partner,
              name: partner,
            }))}
            className={"!h-[44px] text-[#EBEBEB99]"}
            iconColor={"#7D8488"}
            value={filters.partner}
            onChange={(e) => handleFiltersChange(e, "partner")}
          />
        </div>
      </div>
      <div className="mb-3">
        <Text className="p-2 font-medium tracking-[0.28px] text-[#EBEBEB]">
          Shippers
        </Text>
        <div className="w-[250px] md:w-[300px] lg:w-[340px]">
          <SelectField
            placeholder={"All"}
            options={Object.keys(shippers).map((key) => ({
              value: key,
              name: `${key}: ${shippers[key]}`,
            }))}
            className={"!h-[44px] text-[#EBEBEB99]"}
            iconColor={"#7D8488"}
            value={filters.shippers}
            onChange={(e) => handleFiltersChange(e, "shippers")}
          />
        </div>
      </div>
      <div className="mb-3">
        <Text className="p-2 font-medium tracking-[0.28px] text-[#EBEBEB]">
          Carrier
        </Text>
        <div className="w-[250px] md:w-[300px] lg:w-[340px]">
          <SelectField
            options={carriers.map((carrier) => ({
              value: carrier,
              name: carrier,
            }))}
            className={"!h-[44px] text-[#EBEBEB99]"}
            iconColor={"#7D8488"}
            value={filters.carrier}
            onChange={(e) => handleFiltersChange(e, "carrier")}
          />
        </div>
      </div>
    </Box>
  );
};

export default Filters;

Filters.defaultProps = {
  filters: { partner: "", shippers: "", carrier: "" },
  setFilters: () => {},
  shippers: {},
};

Filters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  shippers: PropTypes.object,
};
