import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { Button, Modal, UploadFile } from "../../../componentLibrary";
import { UserContext } from "../../Providers";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from 'uuid';
import { fetchCoordinates } from "./Coordinates";
import { validateZipCode } from "../../../services";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

export const ImportDownload = ({
  endpoint,
  onUpdateData,
  setParsedData,
  template
}) => {
  const [files, setFiles] = useState();
  const importFileURL = `${apiUrl}/${endpoint}`;
  const { token } = useContext(UserContext);
  const toast=useToast()


  const {
    isOpen: isOpenImportFileModal,
    onClose: onCloseImportFileModal,
    onOpen: onOpenImportFileModal,
  } = useDisclosure();

  const colorNavigation = (row) => {
      if (row[7]?.length===5 && row[8]?.length===5) {
        if (parseInt(row[8]) - parseInt(row[7]) >= 0) {
          return "";
        } else {
          return "start";
        }
      } else {
        return "start";
      }
  }

  const handleFileUpload = async (file) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (fileExtension === "xls" || fileExtension === "xlsx") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target?.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetNames = workbook.SheetNames;
        const sheetName= sheetNames.filter((e)=>e==="2_Facilities")
        const worksheet = workbook.Sheets[sheetName[0]];
        const allRows = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
        let headersIndex = -1;
        for (let i = 0; i < allRows.length; i++) {
            if (allRows[i].includes("Injection facilities")) {
                headersIndex = i + 1;
                break;
            }
        }
        if (headersIndex === -1 || headersIndex >= allRows.length) {
            toast({ title: "Invalid File has been uploaded!", status: "error" });
            return;
        }
        const headers = allRows[headersIndex];
        const rows = [];
        for (let i = headersIndex + 1; i < allRows.length; i++) {
          if(allRows[i].every((cell=>isNaN(Number(cell[0]))))){
            break;
          }
          if(allRows[i][1]!==undefined){
            rows.push(allRows[i]);
          }
        }
        setParsedData(rows);

        const zipcodes=[]
        const zip = await Promise.all(rows.map(async (row, index) => {
          headers.forEach((header, index) => {
            if(header==="ZIP"){
              zipcodes.push(row[index])
            }
          });
        }));
        const zipData=await validateZipCode(zipcodes,token)

        const arrayOfObjects = await Promise.all(rows.map(async (row, index) => {
          const rowObject = {};

          headers.forEach((header, index) => {
            rowObject[header] = row[index];
          });
          const coord=zipData?.filter((e)=>e.zipCode===rowObject['ZIP'])
          const coordinatesStatus=coord[0].status==="VALID"
          if(coordinatesStatus){
          const coordinates = await fetchCoordinates(rowObject['ZIP']);
            rowObject['coordinates'] = coordinates;
          }else{
            rowObject['coordinates'] = [];
          }
          rowObject['success'] = coordinatesStatus?colorNavigation(row):"zipcode";
          rowObject['id'] = uuidv4();
          return rowObject;
        }));
        onUpdateData(arrayOfObjects);
        onCloseImportFileModal();
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
     if(files?.length){
      handleFileUpload(files[0])
     }
  }, [files])

  return (
    <>

        <Button
          prefixIcon={<ArrowUpTrayIcon />}
          onClick={onOpenImportFileModal}
          context="outlined"
        >
          Upload
        </Button>

      <Modal
        title="Import information using your Excel file"
        open={isOpenImportFileModal}
        onClose={onCloseImportFileModal}
        className={{
          content: "!min-w-[964px] !p-11",
          title: "!text-2xl !text-[#fff]",
          controls: "!hidden",
        }}
      >
        <Flex
          mt={8}
          mr={3}
          flexDirection={"column"}
          gap={6}
          alignItems={"center"}
        >
          <Box className="self-start">
            Upload an Excel file with account data to automatically populate the
            information for the selected account.
          </Box>
          {template && (
            <Button
            context="outlined"
            className="text-sm font-medium"
            onClick={template}
            >
              Download template
            </Button>
          )}
          <UploadFile
            className={{
              container: "w-full",
              uploadFile: "h-[372px] !max-w-full",
            }}
            setIsFile={setFiles}
          />
        </Flex>
      </Modal>
    </>
  );
};
