import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Box, Spinner, Text } from "@chakra-ui/react";
import { embedDashboard } from "@preset-sdk/embedded";

import { Page, UserContext } from "../interfaces";

const presetGuestTokenUrl = process.env.REACT_APP_PRESET_GUEST_TOKEN_URL;

const NetworkInsights = () => {
	const { user, token } = useContext(UserContext);
	const location = useLocation();

	const [dashboardId, setDashboardId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [message, setMessage] = useState("");

	// Define fetchToken outside of the useEffect hooks for wider accessibility
	const fetchToken = async () => {
		try {
			const response = await fetch(presetGuestTokenUrl, {
				method: "POST",
				headers: {
					authorization: token,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ dashboard_id: dashboardId }),
			});
			const data = await response.json();
			if (data.error) {
				console.error("Error:", data.error);
				setIsLoading(false);
				return null;
			}
			setIsLoading(false);
			return data;
		} catch (error) {
			console.error(`Error fetching Token:`, error);
			setIsLoading(false);
			return null;
		}
	};

	useEffect(() => {
		const determineDashboardId = async () => {
			if (user?.Groups && user.Groups.length > 0) {
				let newDashboardId = null;
				if (user.isPlatformAdmin) {
					newDashboardId = location.state
						? location.state?.id
						: "03a39a8e-5787-45bb-b0d0-ca534eb7902e";
				}

				if (newDashboardId) {
					setMessage("");
					setDashboardId(newDashboardId);
					setIsLoading(false);
				} else {
					setIsLoading(false);
					setMessage("You are not authorized to view this dashboard");
				}
			} else {
				setMessage("You are not authorized to view this dashboard");
				setIsLoading(false);
			}
		};

		determineDashboardId();
	}, [user, location.state]);

	useEffect(() => {
		if (!isLoading && dashboardId) {
			fetchToken().then((guest_token) => {
				if (!guest_token) return;
				embedDashboard({
					id: dashboardId,
					supersetDomain: "https://ac5f3d67.us2a.app.preset.io",
					mountPoint: document.getElementById("my-superset-container"),
					fetchGuestToken: () => Promise.resolve(guest_token),
					dashboardUiConfig: {
						width: "100%",
						height: "100%",
						filters: {
							expanded: false,
						},
					},
				});
			});
		}
		// eslint-disable-next-line
	}, [dashboardId, isLoading]); // Re-run this effect when dashboardId or loading state changes

	if (isLoading)
		return (
			<Box display="flex" justifyContent="center" my={12}>
				<Spinner />
			</Box>
		);

	if (message)
		return (
			<Page>
				<Box display="flex" justifyContent="center" my={12}>
					<Text>{message}</Text>
				</Box>
			</Page>
		);

	return <div id="my-superset-container"></div>;
};

export default NetworkInsights;
