import React, { useEffect, useState, useContext, useCallback } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { get, isEmpty } from "lodash";
import { CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Card,
  Box,
  Flex,
  FormControl,
  Heading,
  Spinner,
  Text,
  InputRightElement,
  InputGroup,
  FormErrorMessage,
  Switch,
  useToast,
} from "@chakra-ui/react";

import {
  AccountTabs,
  DropdownIndicator,
  InputField,
  PreferencesForm,
  Page,
  Select,
  UserContext,
} from "../interfaces";
import { renderDate, renderTime } from "../utilities";
import { Button, Label } from "../componentLibrary";
import { PencilIcon } from "@heroicons/react/24/outline";
import {
  fetchPreferenceValues,
  getParticipant,
  getPreferences,
  updateParticipantPreference,
} from "../services";
import { useLocation } from "react-router-dom";

const inputClassName =
  "!h-[52px] mt-2.5 disabled:!bg-transparent disabled:!text-[#EBEBEB99] disabled:!border disabled:!border-[#85869833] focus:!bg-transparent";

const labelClassName = (isError) =>
  `text-sm ${isError ? "text-[#FC8181]" : "text-[#EBEBEB]"} font-medium`;

const preferencesSchema = yup.object({
  language: yup.string().required(),
  currency: yup.string().required(),
  timezone: yup.string().required(),
  timeFormat: yup.string().required(),
  // lengthUnit: yup.string(),
  weightUnit: yup.string().required(),
  distanceUnit: yup.string().required(),
  // numberFormat: yup.string(),
  // retransmitsAllowed: yup.boolean().required(),
  // dimFactor: yup.string(),
  fuelSurchargeRegion: yup.string().required(),
  invoiceFrequency: yup.string().required(),
  paymentTerms: yup.string().required(),
  // paymentPreference: yup.string(),
  settlementPreference: yup.string().required(),
  quoteValidity: yup.string().required(),
  manifestTransmitMethod: yup.string().required(),
  triggerScheduleTime: yup.string().required(),
  // outboundTriggerTrackCodes: yup.string(),
  // labelPreference: yup.string().required(),
  defaultInvoiceFormat: yup.string(),
  toleranceLimit: yup.string(),
});

const preferencesIntlValues = yup.object({
  language: "",
  currency: "",
  timezone: "",
  timeFormat: "",
  lengthUnit: "",
  weightUnit: "",
  distanceUnit: "",
  numberFormat: "",
  retransmitsAllowed: false,
  dimFactor: "",
  fuelSurchargeRegion: "",
  invoiceFrequency: "",
  paymentTerms: "",
  paymentPreference: "",
  quoteValidity: "",
  defaultInvoiceFormat: "",
  toleranceLimit: "",
  settlementPreference: "",
  manifestTransmitMethod: "",
  triggerScheduleTime: "",
  outboundTriggerTrackCodes: "",
  // labelPreference: "",
});

const InputCloseIcon = ({ handleDismissFieldChanges, name }) => {
  return (
    <InputRightElement
      top={4}
      right={1}
      onClick={() => {
        handleDismissFieldChanges(name);
      }}
    >
      <CloseIcon w={3} h={3} />
    </InputRightElement>
  );
};

function Preferences() {
  //   const [preferenceData, setPreferenceData] = useState(null);
  const { token, user, viewingAsByCode, setAccountOptions, viewingAs } =
    useContext(UserContext);
  const location = useLocation();
  const toast = useToast();

  const [data, setData] = useState({});
  const [preferenceValues, setPreferenceValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [focusedField, setFocusedField] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isNewAccountUser, setIsNewAccountUser] = useState(false);

  const formik = useFormik({
    initialValues: preferencesIntlValues,
    validationSchema: preferencesSchema,
    onSubmit: async (values) => {
      setSubmitLoader(true);
      const payload = {
        ...values,
        retransmitsAllowed:
          values.retransmitsAllowed === "true" ? "true" : "false",
      };

      try {
        const isResponseOk = await updateParticipantPreference(
          JSON.stringify(payload),
          isNewAccountUser ? location.state?.carrierCode : viewingAsByCode,
          token
        );
        if (isResponseOk) {
          setIsEditMode(false);
          await getPreferencesApi();

          if (isNewAccountUser) {
            toast({
              status: "success",
              description: "Preferences created successfully!",
              position: "top-right",
            });

            const response = await getParticipant(token);
            setAccountOptions(response);
          } else {
            toast({
              status: "success",
              description: "Preferences details updated!",
              position: "top-right",
            });
          }
        }
      } catch (error) {
        toast({
          status: "error",
          description: error,
          position: "top-right",
        });
        console.error("API Error:", error);
      } finally {
        setSubmitLoader(false);
      }
    },
  });

  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleBlur,
    resetForm,
    touched,
    setValues,
  } = formik;

  useEffect(() => {
    if (location.state?.isNewAccountUser) {
      setIsEditMode(true);
      setIsNewAccountUser(true);
    }
  }, []);

  useEffect(() => {
    if (location.state?.carrierCode !== viewingAsByCode) {
      setIsEditMode(false);
      setIsNewAccountUser(false);
    }
  }, [viewingAsByCode]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPreferenceValues(token);
        setPreferenceValues(response);
      } catch (error) {
        console.error("Error fetching preference values:", error);
      }
    })();
  }, []);

  const getPreferencesApi = async () => {
    let responseData = await getPreferences(viewingAsByCode, token);

    if (!responseData.preferredCarriers) {
      // Temp dummy data
      responseData.preferredCarriers = [
        {
          serviceType: "-",
          carrier: "-",
          defaultPickupResponsibility: "-",
          useAllSortHubsOfCarrier: "-",
        },
      ];
    }

    setData(responseData);
    setValues({
      ...responseData,
      preferenceLabel: "Standard Orchestro Label",
    });
  };

  useEffect(() => {
    if (!isNewAccountUser) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          if (!viewingAsByCode) return;

          await getPreferencesApi();
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line
  }, [viewingAsByCode, isNewAccountUser]);

  const handleClickEdit = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const handleFieldFocus = useCallback((e) => {
    e.preventDefault();
    setTimeout(() => {
      setFocusedField(() => e.target.name);
    }, 140);
  }, []);

  const handleFieldBlur = useCallback((e) => {
    e.preventDefault();
    setTimeout(() => {
      handleBlur(e);
      setFocusedField(() => "");
    }, 130);
    // eslint-disable-next-line
  }, []);

  const handleEnterPressed = useCallback((e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
    // eslint-disable-next-line
  }, []);

  const handleDismissFieldChanges = useCallback(
    (key) => {
      setFieldValue(key, get(data, key));
    },
    // eslint-disable-next-line
    [data]
  );

  const handleSelectChanges = useCallback((option, key) => {
    setFieldValue(key, option.label);
    // eslint-disable-next-line
  }, []);

  const handleSwitchChange = useCallback((event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
    // eslint-disable-next-line
  }, []);

  const handleSelectBlur = useCallback((key) => {
    setFieldTouched(key);
    // eslint-disable-next-line
  }, []);

  const handleDiscardChanges = useCallback(() => {
    resetForm();
    if (!isEmpty(data)) {
      setValues({
        ...data,
        preferenceLabel: "Standard Orchestro Label",
      });
    }
    setIsEditMode(false);
    // eslint-disable-next-line
  }, [data]);

  const findOptionFromPreference = (key) => {
    if (preferenceValues?.length) {
      return preferenceValues
        ?.find((value) => value.name === key)
        ?.value.map((val) => ({ label: val, value: val }));
    }
  };

  return (
    <>
      <AccountTabs />
      <Page className={"!pt-7"}>
        <div className="flex justify-end items-center gap-2 mb-5">
          {user.isPlatformAdmin && (
            <>
              {!isNewAccountUser && (
                <Button
                  context="outlined"
                  prefixIcon={<PencilIcon className="!h-[18px]" />}
                  className="text-sm font-medium"
                  onClick={handleClickEdit}
                  disabled={isEditMode}
                >
                  Edit
                </Button>
              )}

              <Button
                context="primary"
                prefixIcon={<DownloadIcon className="!h-[15px]" />}
              >
                Download
              </Button>
            </>
          )}
        </div>

        {isLoading ? (
          <Box display="flex" justifyContent="center" my={"10em"}>
            <Spinner />
          </Box>
        ) : !data ? (
          <Text>No preferences information</Text>
        ) : (
          <>
            {/********************* General ***********************/}
            <Card
              mb={7}
              borderRadius="20px"
              borderWidth="1px"
              borderColor="rgba(133, 134, 152, 0.2)"
              py={9}
              px={"44px"}
            >
              <Heading size="md" mb={"30px"}>
                General
              </Heading>

              <Flex flexDirection={"column"} className="gap-11">
                <Flex className="gap-6">
                  <FormControl
                    isInvalid={!!errors?.language && touched?.language}
                  >
                    <Label
                      htmlFor="language"
                      className={labelClassName(
                        !!errors?.language && touched?.language
                      )}
                    >
                      Language
                    </Label>
                    <Select
                      value={{
                        label: values.language,
                        value: values.language,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "language")
                      }
                      onBlur={() => handleSelectBlur("language")}
                      name={`language`}
                      id={`language`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("LANGUAGE")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.language && touched?.language}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                            name={`language`}
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Language is required
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors?.currency && touched?.currency}
                  >
                    <Label
                      htmlFor="currency"
                      className={labelClassName(
                        !!errors?.currency && touched?.currency
                      )}
                    >
                      Currency
                    </Label>
                    <Select
                      value={{
                        label: values.currency,
                        value: values.currency,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "currency")
                      }
                      onBlur={() => handleSelectBlur("currency")}
                      name={`currency`}
                      id={`currency`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("CURRENCY")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.currency && touched?.currency}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Currency is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                <Flex className="gap-6">
                  <FormControl
                    isInvalid={!!errors?.timezone && touched?.timezone}
                  >
                    <Label
                      htmlFor="timezone"
                      className={labelClassName(
                        !!errors?.timezone && touched?.timezone
                      )}
                    >
                      Time Zone
                    </Label>
                    <Select
                      value={{
                        label: values.timezone,
                        value: values.timezone,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "timezone")
                      }
                      onBlur={() => handleSelectBlur("timezone")}
                      name={`timezone`}
                      id={`timezone`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("DISPLAY_TIMEZONE")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.timezone && touched?.timezone}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Timezone is required
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors?.timeFormat && touched?.timeFormat}
                  >
                    <Label
                      htmlFor="timeFormat"
                      className={labelClassName(
                        !!errors?.timeFormat && touched?.timeFormat
                      )}
                    >
                      Time Format
                    </Label>
                    <Select
                      value={{
                        label: values.timeFormat,
                        value: values.timeFormat,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "timeFormat")
                      }
                      onBlur={() => handleSelectBlur("timeFormat")}
                      name={`timeFormat`}
                      id={`timeFormat`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("TIME_FORMAT")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.timeFormat && touched?.timeFormat}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Time format is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                <Flex className="gap-6">
                  <FormControl
                    isInvalid={!!errors?.lengthUnit && touched?.lengthUnit}
                  >
                    <Label
                      htmlFor="lengthUnit"
                      className={labelClassName(
                        !!errors?.lengthUnit && touched?.lengthUnit
                      )}
                    >
                      Unit of Length
                    </Label>
                    <Select
                      value={{
                        label: values.lengthUnit,
                        value: values.lengthUnit,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "lengthUnit")
                      }
                      onBlur={() => handleSelectBlur("lengthUnit")}
                      name={`lengthUnit`}
                      id={`lengthUnit`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("UNIT_OF_LENGTH")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.lengthUnit && touched?.lengthUnit}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Unit of length is required
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors?.weightUnit && touched?.weightUnit}
                  >
                    <Label
                      htmlFor="weightUnit"
                      className={labelClassName(
                        !!errors?.weightUnit && touched?.weightUnit
                      )}
                    >
                      Unit of Weight
                    </Label>
                    <Select
                      value={{
                        label: values.weightUnit,
                        value: values.weightUnit,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "weightUnit")
                      }
                      onBlur={() => handleSelectBlur("weightUnit")}
                      name={`weightUnit`}
                      id={`weightUnit`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("UNIT_OF_WEIGHT")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.weightUnit && touched?.weightUnit}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Unit of weight is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                <Flex className="gap-6">
                  <FormControl
                    isInvalid={!!errors?.distanceUnit && touched?.distanceUnit}
                  >
                    <Label
                      htmlFor="distanceUnit"
                      className={labelClassName(
                        !!errors?.distanceUnit && touched?.distanceUnit
                      )}
                    >
                      Unit of Distance
                    </Label>
                    <Select
                      value={{
                        label: values.distanceUnit,
                        value: values.distanceUnit,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "distanceUnit")
                      }
                      onBlur={() => handleSelectBlur("distanceUnit")}
                      name={`distanceUnit`}
                      id={`distanceUnit`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference("UNIT_OF_DISTANCE")}
                      isDisabled={!isEditMode}
                      isError={!!errors?.distanceUnit && touched?.distanceUnit}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Unit of Distance is required
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={!!errors?.dimFactor && touched?.dimFactor}
                  >
                    <Label
                      htmlFor="dimFactor"
                      className={labelClassName(
                        !!errors?.dimFactor && touched?.dimFactor
                      )}
                    >
                      Dimensional Factor
                    </Label>
                    <InputGroup>
                      <InputField
                        isDisabled={!isEditMode}
                        value={values.dimFactor ?? ""}
                        className={inputClassName}
                        onChange={handleChange}
                        onBlur={handleFieldBlur}
                        onFocus={handleFieldFocus}
                        onKeyUp={handleEnterPressed}
                        name={`dimFactor`}
                        id={`dimFactor`}
                      />
                      {focusedField === "dimFactor" && (
                        <InputCloseIcon
                          handleDismissFieldChanges={handleDismissFieldChanges}
                          name={`dimFactor`}
                        />
                      )}
                    </InputGroup>
                    <FormErrorMessage className="!mt-3">
                      Dimensional factor is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                <Flex className="gap-6">
                  <FormControl
                    isInvalid={!!errors?.numberFormat && touched?.numberFormat}
                  >
                    <Label
                      htmlFor="numberFormat"
                      className={labelClassName(
                        !!errors?.numberFormat && touched?.numberFormat
                      )}
                    >
                      Number Format
                    </Label>
                    <InputGroup>
                      <InputField
                        isDisabled={!isEditMode}
                        value={values.numberFormat ?? ""}
                        className={inputClassName}
                        onChange={handleChange}
                        onBlur={handleFieldBlur}
                        onFocus={handleFieldFocus}
                        onKeyUp={handleEnterPressed}
                        name={`numberFormat`}
                        id={`numberFormat`}
                      />
                      {focusedField === "numberFormat" && (
                        <InputCloseIcon
                          handleDismissFieldChanges={handleDismissFieldChanges}
                          name={`numberFormat`}
                        />
                      )}
                    </InputGroup>
                    {/* <Select
                      value={{
                        label: values.numberFormat,
                        value: values.numberFormat,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "numberFormat")
                      }
                      onBlur={() => handleSelectBlur("numberFormat")}
                      name={`numberFormat`}
                      id={`numberFormat`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={[
                        {
                          label: data.numberFormat,
                          value: data.numberFormat,
                        },
                      ]}
                      isDisabled={!isEditMode}
                      customStyles={selectStyles(
                        !!errors?.numberFormat && touched?.numberFormat
                      )}
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    /> */}
                    <FormErrorMessage className="!mt-3">
                      Number format is required
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!errors?.fuelSurchargeRegion &&
                      touched?.fuelSurchargeRegion
                    }
                  >
                    <Label
                      htmlFor="fuelSurchargeRegion"
                      className={labelClassName(
                        !!errors?.fuelSurchargeRegion &&
                          touched?.fuelSurchargeRegion
                      )}
                    >
                      Last Mile Fuel Surcharge Region
                    </Label>
                    <Select
                      value={{
                        label: values.fuelSurchargeRegion,
                        value: values.fuelSurchargeRegion,
                      }}
                      onChange={(options) =>
                        handleSelectChanges(options, "fuelSurchargeRegion")
                      }
                      onBlur={() => handleSelectBlur("fuelSurchargeRegion")}
                      name={`fuelSurchargeRegion`}
                      id={`fuelSurchargeRegion`}
                      onFocus={handleFieldFocus}
                      onKeyUp={handleEnterPressed}
                      options={findOptionFromPreference(
                        "LAST_MILE_FUEL_SURCHARGE_REGION"
                      )}
                      isDisabled={!isEditMode}
                      isError={
                        !!errors?.fuelSurchargeRegion &&
                        touched?.fuelSurchargeRegion
                      }
                      components={{
                        DropdownIndicator: (props) => (
                          <DropdownIndicator
                            {...props}
                            handleDismissFieldChanges={
                              handleDismissFieldChanges
                            }
                          />
                        ),
                      }}
                    />
                    <FormErrorMessage className="!mt-3">
                      Last mile fuel surcharge region is required
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex className="gap-6">
                  <FormControl
                    isInvalid={
                      !!errors?.retransmitsAllowed &&
                      touched?.retransmitsAllowed
                    }
                  >
                    <Label
                      htmlFor="retransmitsAllowed"
                      className={labelClassName(
                        !!errors?.retransmitsAllowed &&
                          touched?.retransmitsAllowed
                      )}
                    >
                      Manifest Retransmits Allowed
                    </Label>
                    <Switch
                      id="retransmitsAllowed"
                      name="retransmitsAllowed"
                      className="ml-[14px]"
                      defaultChecked={values?.retransmitsAllowed === "true"}
                      onChange={handleSwitchChange}
                    />
                  </FormControl>
                </Flex>
              </Flex>
            </Card>

            <PreferencesForm
              formik={formik}
              isEditMode={isEditMode}
              labelClassName={labelClassName}
              inputClassName={inputClassName}
              handleSelectChanges={handleSelectChanges}
              handleSelectBlur={handleSelectBlur}
              handleFieldFocus={handleFieldFocus}
              handleFieldBlur={handleFieldBlur}
              handleEnterPressed={handleEnterPressed}
              data={data}
              handleDismissFieldChanges={handleDismissFieldChanges}
              isCarrier={viewingAs.isCarrier}
              isShipper={viewingAs.isShipper}
              InputCloseIcon={InputCloseIcon}
              focusedField={focusedField}
              findOptionFromPreference={findOptionFromPreference}
            />

            {/********************* Network Preferences *********************/}
            {/* {viewingAs.isShipper && (
                <Card
                  mb={7}
                  borderRadius="20px"
                  borderWidth="1px"
                  borderColor="rgba(133, 134, 152, 0.2)"
                  p={8}
                >
                  <Heading size="md">Network Preferences</Heading>
                  <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>Use Open Network</FormLabel>
                      <Select disabled>
                        <option value="Yes">
                          {data.subNetworkConfigured === "true" ? "Yes" : "No"}
                        </option>
                      </Select>
                    </FormControl>
                  </Flex>
                  <Text fontSize={"sm"}>Preferred Carriers</Text>
                  <TableContainer>
                    <Table>
                      <Thead>
                        <Tr>
                          <Th>Service Type</Th>
                          <Th>Carrier</Th>
                          <Th>Default Pickup Responsibility</Th>
                          <Th>Use All Sort Hubs of Carrier?</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data?.preferredCarriers?.map((carrier) => (
                          <Tr>
                            <Td>{carrier.serviceType}</Td>
                            <Td>{carrier.carrier}</Td>
                            <Td>{carrier.defaultPickupResponsibility}</Td>
                            <Td>{carrier.useAllSortHubsOfCarrier}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Card>
              )} */}

            {/********************* API Configuration *********************/}
            {/* {viewingAs.isCarrier && (
                <Card
                  mb={7}
                  borderRadius="20px"
                  borderWidth="1px"
                  borderColor="rgba(133, 134, 152, 0.2)"
                  p={8}
                >
                  <Heading size="md">API Configuration</Heading>
                  <Flex gap={7} my={5} width={"calc(50% - 1rem)"}>
                    <FormControl>
                      <FormLabel fontSize={"sm"}>
                        API Endpoint Configuration
                      </FormLabel>
                      <Input disabled />
                    </FormControl>
                  </Flex>
                </Card>
              )} */}

            {/* <Text
                as={"small"}
                size={"xs"}
                mb={2}
              >
                Last Modified
              </Text>
              <Text size={"sm"}>
                {data?.updatedAt
                  ? renderDate(data?.updatedAt) +
                    ", " +
                    renderTime(data?.updatedAt)
                  : "No data"}
              </Text> */}

            {isEditMode && (
              <Flex justifyContent={"flex-end"} gap={6} py={4}>
                <Button
                  context="textSecondary"
                  className="text-sm font-medium min-w-[142px]"
                  onClick={handleDiscardChanges}
                >
                  Cancel
                </Button>
                <Button
                  context="primary"
                  className="text-sm font-medium min-w-[142px]"
                  type="submit"
                  onClick={handleSubmit}
                  isLoading={submitLoader}
                  disabled={submitLoader}
                >
                  Save
                </Button>
              </Flex>
            )}
          </>
        )}
      </Page>
    </>
  );
}

export default Preferences;
