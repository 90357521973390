import { useState, useEffect, useContext, useMemo } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Text,
} from "@chakra-ui/react";

import { getPresetDashboard } from "../../services";
import classes from "./Nav.module.css";
import {
	AccountIcon,
	CaretDownIcon,
	ContainerIcon,
	DashboardIcon,
	DollarIcon,
	InsightsIcon,
	InvoicesIcon,
	LaneIcon,
	OrderIcon,
	ParcelIntelIcon,
	RateDiscoveryIcon,
	ShipmentIcon,
	SupportIcon,
	UserContext,
	BidConfigurationIcon,
} from "../";
import Logo from "../../componentLibrary/components/Logo";

const appRoot = "/app";

export const Nav = () => {
	const location = useLocation();
	const { user, isDemo, token } = useContext(UserContext);

	const [presetDashboard, setPresetDashboard] = useState([]);

	const linkClass = ({ isActive }) =>
		isActive ? `${classes.links} ${classes.active}` : classes.links;

	useEffect(() => {
		if (!isDemo) return;
		getPresetDashboard(token)
			.then((response) => {
				setPresetDashboard(response);
			})
			.catch((error) => {
				console.error("Error fetching Preset dashboard data:", error);
			});
	}, [isDemo, token]);

	const isNetworkInsightsOpen = useMemo(() => {
		const presetPaths =
			presetDashboard &&
			presetDashboard.length > 0 &&
			presetDashboard.map((preset) => preset.path);
		const pathname = location.pathname.split("/");

		if (presetPaths) {
			return presetPaths.includes(pathname[pathname.length - 1]) ? 0 : null;
		}
		return null;
	}, [presetDashboard, location]);

	return (
		<Box className={classes.sidebar}>
			<div className={classes.logo}>
				{isDemo ? (
					<Link to={"/demo"}>
						<Logo width="98px" mode="light" />
					</Link>
				) : (
					<Link to={"/"}>
						<Logo width="98px" mode="light" />
					</Link>
				)}
			</div>
			<nav className={classes.scrollBox} role="navigation">
				<ul className="list-none flex flex-col h-[inherit]">
					<li>
						<NavLink className={linkClass} to={appRoot + "/dashboard"}>
							<DashboardIcon />
							<Text>Dashboard</Text>
						</NavLink>
					</li>

					{(user.isPlatformAdmin || user.isShipper || user.isCarrier) && (
						<li>
							<NavLink className={linkClass} to={appRoot + "/shipments"}>
								<ShipmentIcon />
								<Text>Shipments</Text>
							</NavLink>
						</li>
					)}

					{(user.isPlatformAdmin ||
						user.isCarrier ||
						user.isShipper ||
						user.isFinance) && (
						<li>
							<Accordion allowToggle defaultIndex={isNetworkInsightsOpen}>
								<AccordionItem border={0}>
									{({ isExpanded }) => (
										<>
											<AccordionButton p={0}>
												<div className={`${classes.links} justify-between`}>
													<div className="flex">
														<AccountIcon />
														<Text>Account</Text>
													</div>
													<CaretDownIcon
														className={`!mr-0 !w-7 !h-7 ${
															isExpanded ? "rotate-180" : ""
														}`}
													/>
												</div>
											</AccordionButton>
											<AccordionPanel p={0}>
												<ul>
													<li>
														<NavLink
															className={linkClass}
															to={appRoot + "/account/configuration"}
														>
															<Text className="pl-9">Configuration</Text>
														</NavLink>
													</li>
													{isDemo && (
														<li>
															<NavLink
																className={linkClass}
																to={appRoot + "/account/integration"}
															>
																<Text className="pl-9">Integration</Text>
															</NavLink>
														</li>
													)}
												</ul>
											</AccordionPanel>
										</>
									)}
								</AccordionItem>
							</Accordion>
						</li>
					)}

					{(user.isPlatformAdmin || user.isShipper || user.isCarrier) && (
						<li>
							<NavLink className={linkClass} to={appRoot + "/invoices"}>
								<InvoicesIcon />
								<Text>Invoices</Text>
							</NavLink>
						</li>
					)}

					{/* Demo related pages below here */}

					{isDemo && user?.isPlatformAdmin && (
						<>
							<li>
								<NavLink
									className={linkClass}
									to={appRoot + "/account-request"}
								>
									<AccountIcon />
									<Text>Account Request</Text>
								</NavLink>
							</li>
							<li>
								<NavLink className={linkClass} to={appRoot + "/containers"}>
									<ContainerIcon />
									<Text>Containers</Text>
								</NavLink>
							</li>
							<li>
								<NavLink
									className={linkClass}
									to={appRoot + "/middle-mile-lanes"}
								>
									<LaneIcon />
									<Text>Middle Mile Lanes</Text>
								</NavLink>
							</li>
							<li>
								<NavLink
									className={linkClass}
									to={appRoot + "/middle-mile-orders"}
								>
									<OrderIcon />
									<Text>Middle Mile Orders</Text>
								</NavLink>
							</li>
							<li>
								<Accordion allowToggle defaultIndex={isNetworkInsightsOpen}>
									<AccordionItem border={0}>
										{({ isExpanded }) => (
											<>
												<AccordionButton p={0}>
													<div className={`${classes.links} justify-between`}>
														<div className="flex">
															<InsightsIcon />
															<Text>Network Insights</Text>
														</div>
														<CaretDownIcon
															className={`!mr-0 !w-7 !h-7 ${
																isExpanded ? "rotate-180" : ""
															}`}
														/>
													</div>
												</AccordionButton>
												<AccordionPanel p={0}>
													<ul>
														{presetDashboard &&
															presetDashboard.length > 0 &&
															presetDashboard.map((preset) => (
																<li key={preset.id}>
																	<NavLink
																		className={linkClass}
																		to={
																			appRoot +
																			"/network-insights/" +
																			preset.path
																		}
																		state={{ id: preset.id }}
																	>
																		<Text className="pl-9">{preset.title}</Text>
																	</NavLink>
																</li>
															))}
													</ul>
												</AccordionPanel>
											</>
										)}
									</AccordionItem>
								</Accordion>
							</li>
							<li>
								<NavLink className={linkClass} to={appRoot + "/sales-tools"}>
									<RateDiscoveryIcon />
									<Text>Rate Discovery</Text>
								</NavLink>
							</li>
							<li>
								<NavLink
									className={linkClass}
									to={appRoot + "/parcel-intelligence"}
								>
									<ParcelIntelIcon />
									<Text>Parcel Intelligence</Text>
								</NavLink>
							</li>
							<Accordion allowToggle>
								<AccordionItem border={0}>
									{({ isExpanded }) => (
										<>
											<AccordionButton p={0}>
												<div className={`${classes.links} justify-between`}>
													<div className="flex">
														<DollarIcon />
														<Text>Scenario Analysis</Text>
													</div>
													<CaretDownIcon
														className={`!mr-0 !w-7 !h-7 ${
															isExpanded ? "rotate-180" : ""
														}`}
													/>
												</div>
											</AccordionButton>
											<AccordionPanel p={0}>
												<ul>
													<li>
														<NavLink
															className={linkClass}
															to={appRoot + "/carrier-addition"}
														>
															<Text className="pl-9">Carrier Addition</Text>
														</NavLink>
													</li>
													<li>
														<NavLink
															className={linkClass}
															to={appRoot + "/shipper-addition"}
														>
															<Text className="pl-9">Shipper Addition</Text>
														</NavLink>
													</li>
												</ul>
											</AccordionPanel>
										</>
									)}
								</AccordionItem>
							</Accordion>
							<li>
								<NavLink
									className={linkClass}
									to={appRoot + "/bid-performance"}
								>
									<ParcelIntelIcon />
									<Text>Bid Performance</Text>
								</NavLink>
							</li>
							<li>
								<NavLink
									className={linkClass}
									to={appRoot + "/bid-configuration"}
								>
									<BidConfigurationIcon />
									<Text>Bid Configuration</Text>
								</NavLink>
							</li>
						</>
					)}

					{/* This Link has to stay last */}

					<li className="mt-auto">
						<NavLink
							className={linkClass}
							to="mailto:support@orchestro.ai?subject=Orchestro%20App%20Support%20Request:"
							target="_blank"
						>
							<SupportIcon />
							<Text>Support</Text>
						</NavLink>
					</li>
				</ul>
			</nav>
		</Box>
	);
};
