import { format } from "date-fns";
import PropTypes from "prop-types";

const CurrentDate = ({ className, dateFormat = "MMMM do yyyy, h:mm a" }) => {
	let currentDate = new Date();
	return (
		<div className={`text-xs ${className}`}>
			<time dateTime={currentDate.toISOString()}>
				{format(currentDate, dateFormat)}
			</time>
		</div>
	);
};

export default CurrentDate;

CurrentDate.propTypes = {
	className: PropTypes.string,
	dateFormat: PropTypes.string,
};
