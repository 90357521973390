import React, { createContext, useContext, useState, useEffect } from "react";
import { geocodeLocation } from "../utilities";

//import mock data
//import { mockParcelDetails } from "../data/mockParcelDetails";
import { mockTrackingData } from "../data/mockTrackingData";
import { mockPublicData } from "../data/mockPublicData";

const GeoDataContext = createContext();

export const GeoDataProvider = ({ children }) => {
	const [geoTrackingData, setGeoTrackingData] = useState([]);
	const [isDemo] = useState(false);
	const [isPublic, setIsPublic] = useState(false);
	const [geoRoute, setGeoRoute] = useState({});
	const [parcelData, setParcelData] = useState(null);
	const [isMapLoading, setIsMapLoading] = useState({
		routes: false,
		origin: false,
	});
	const [route, setRoute] = useState({
		origin: [0, 0],
		destination: [0, 0],
		routes: [],
	});

	const resetGeoData = () => {
		setGeoTrackingData([]);
		setParcelData(null);
		setGeoRoute({});
		setRoute({
			origin: [0, 0],
			destination: [0, 0],
			routes: [],
		});
		setIsMapLoading({
			routes: false,
			origin: false,
		});
	};

	useEffect(() => {
		if (isDemo && mockPublicData) {
			setParcelData(mockPublicData);
		}
	}, [isDemo]);

	const fetchLocationData = (locationData) => {
		if (isDemo) {
			locationData = {
				shipFrom: mockPublicData.shipFrom,
				shipTo: mockPublicData.shipTo,
			};
		} 
		if (locationData?.shipFrom) {
			setIsMapLoading((loadings) => ({
				...loadings,
				origin: true,
			}));
			geocodeLocation({
				street: locationData.shipFrom.address1 ?? "",
				city: locationData.shipFrom.city ?? "",
				state: locationData.shipFrom.state ?? "",
				zip: locationData.shipFrom.zip ?? "",
			})
				.then((coords) => {
					if (coords) {
						setRoute((route) => ({
							...route,
							origin: coords.coords.reverse(),
						}));
					} else {
						console.error("Geocoding failed:", coords);
						setIsMapLoading((loadings) => ({ ...loadings, origin: false }));
					}
				})
				.catch((error) => {
					console.error("Geocoding failed:", error);
					setIsMapLoading((loadings) => ({ ...loadings, origin: false }));
				})
				.finally(() => {
					setIsMapLoading((loadings) => ({ ...loadings, origin: false }));
				});
		}
	};

	const fetchTrackingData = async (trackingData, locationData) => {
		if (isDemo) {
			locationData = {
				shipFrom: mockPublicData.shipFrom,
				shipTo: mockPublicData.shipTo,
			};
			trackingData = mockTrackingData.results;
		} else if(isPublic) {
			locationData.trackingNumber = trackingData[0].trackingNumber;
			setParcelData(locationData)
		}
		const trackingPromises = trackingData.map((item, index) => {
			if (
				index === trackingData.length - 1 ||
				item.uiStatus === "Label Created"
			) {
				if (item.city === null) {
					item.city = locationData.shipFrom.city;
					item.state = locationData.shipFrom.state;
				}
			}
			if (item.city || (item.state && item.state !== "NA") || item.zip) {
				return geocodeLocation({
					city: item.city ?? "",
					state: item.state ?? "",
					zip: item.zip ?? "",
				})
					.then((coords) => {
						if (coords) {
							item.latitude = coords.coords[1];
							item.longitude = coords.coords[0];
						} else {
							console.error("Geocoding failed:", coords);
						}
						return item;
					})
					.catch((error) => {
						console.error("Geocoding failed:", error);
						return item;
					});
			} else {
				return Promise.resolve(item);
			}
		});
		const updatedTrackingHistory = await Promise.all(trackingPromises);
		setGeoTrackingData(updatedTrackingHistory);
	};

	return (
		<GeoDataContext.Provider
			value={{
				isMapLoading,
				route,
				geoTrackingData,
				geoRoute,
				parcelData,
				isDemo,
				setIsPublic,
				setGeoRoute,
				setParcelData,
				setRoute,
				fetchLocationData,
				fetchTrackingData,
				resetGeoData,
			}}
		>
			{children}
		</GeoDataContext.Provider>
	);
};

export const useGeoData = () => useContext(GeoDataContext);
