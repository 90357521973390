import { useEffect, useRef, useState } from "react";
import MobileNav from "./MobileNav";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const Nav = () => {
	const [isOpen, setIsOpen] = useState(false);
	const popoverRef = useRef(null);

	const togglePopover = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popoverRef.current && !popoverRef.current.contains(event.target)) {
				setIsOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [popoverRef]);
	return (
		<nav className="w-full z-30 flex justify-between items-center pb-2 md:px-16">
			<div className="logo hidden md:block">
				<img
					src="/images/tracking/Light_logo.svg"
					alt="Logo"
					className="h-[90px] md:h-[181px]"
				/>
			</div>
			<ul
				className={`hidden md:flex absolute top-[74px] right-0 w-full md:static md:items-center md:w-auto md:bg-transparent md:text-white`}
			>
				<li className="border-b md:border-none">
					<a
						href="https://www.orchestro.ai"
						className={`block text-white hover:text-gray-300 text-md font-normal md:text-[20px]   px-4 py-2 md:px-6 md:py-3`}
						target="_top"
					>
						Home
					</a>
				</li>
				<li className="border-b md:border-none">
					<a
						href="https://www.orchestro.ai/#about"
						className={`block text-white hover:text-gray-300 text-md font-normal md:text-[20px]   px-4 py-2 md:px-6 md:py-3`}
						target="_top"
					>
						About
					</a>
				</li>
				<li className="border-b md:border-none">
					<a
						href="https://www.orchestro.ai/#contact"
						className={`block text-white hover:text-gray-300 text-md font-normal md:text-[20px]   px-4 py-2 md:px-6 md:py-3`}
						target="_top"
					>
						Contact
					</a>
				</li>
				<li className="border-b md:border-none">
					<a
						href="https://track.orchestro.ai/id"
						className={`block text-[#fbb03b] text-md font-normal md:text-[20px] px-4 py-2 md:px-6 md:py-3`}
						target="_top"
					>
						Track
					</a>
				</li>
				<li className="border-b md:border-none relative" ref={popoverRef}>
					<button
						onClick={togglePopover}
						className="flex items-center text-white hover:text-gray-300 text-md font-normal md:text-[20px] px-4 py-2 md:px-6 md:py-3"
					>
						Members
						{isOpen ? (
							<ChevronUpIcon className="ml-2 w-6" />
						) : (
							<ChevronDownIcon className="ml-2 w-6" />
						)}
					</button>
					{isOpen && (
						<div
							className="absolute right-0 mt-[-8px] bg-white rounded-md shadow-lg z-50 py-2 w-[160px]"
							style={{
								backgroundImage:
									"linear-gradient(to bottom, #000, #000, #343990)",
							}}
						>
							<a
								href="https://www.orchestro.ai/carriers"
								className="block px-2 py-[5px] text-white hover:bg-[#343990] text-[20px]"
								target="_top"
							>
								Carriers
							</a>
							<a
								href="https://www.orchestro.ai/shipper"
								className="block px-2  py-[5px] text-white hover:bg-[#343990] text-[20px]"
								target="_top"
							>
								Shippers
							</a>
							<a
								href="https://developer.orchestro.ai"
								className="block px-2  py-[5px] text-white hover:bg-[#343990] text-[20px]"
								target="_top"
							>
								Developers
							</a>
						</div>
					)}
				</li>
				<li className="border-b md:border-none">
					<a
						href="https://www.orchestro.ai/partner"
						className={`block text-white hover:text-gray-300 text-md font-normal md:text-[20px]   px-4 py-2 md:px-6 md:py-3`}
						target="_top"
					>
						Create&nbsp;Account
					</a>
				</li>
			</ul>
			<div className="md:hidden w-full">
				<MobileNav />
			</div>
		</nav>
	);
};

export default Nav;
