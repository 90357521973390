import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { useGeoData } from "../../../contexts/GeoDataProvider";
import ArcSection from "./ArcSection";
import MapTrack from "./MapTrack";
import DataSlide from "./DataSlide";
import TrackingModal from "./TrackingModal";
import MainMap from "./MainMap";
import HistoryMap from "./HistoryMap";
import ParcelDetailScreen from "./ParcelDetailScreen";
import ExceptionDetails from "./ExceptionDetails";
import ParcelInvoice from "../../Shipments/ParcelInvoice";
import Button from "../../../componentLibrary/components/Button";
import { isWebGLAvailable } from "../../../utilities/isWebGLAvailable";

const Tracker = ({
	isPublicTracking,
	handleInputChange,
	setTrackingId,
	getEvents,
	trackingId,
	locationData,
	loading,
	trackerStatus,
	setTrackerStatus,
	reset,
	setReset,
	calledOnce,
	error,
}) => {
	const { resetGeoData, route, isMapLoading, geoTrackingData, parcelData } =
		useGeoData();
	const navigate = useNavigate();
	const [trackingOpen, setTrackingOpen] = useState(false);
	const [trackAgain, setTrackAgain] = useState(false);
	const [parcelOpen, setParcelOpen] = useState(false);
	const [exceptionOpen, setExceptionOpen] = useState(false);
	const [invoiceOpen, setInvoiceOpen] = useState(false);
	const [sortedTrackingData, setSortedTrackingData] = useState([]);
	const [parcelInfo, setParcelInfo] = useState([]);
	const [showFullScreen, setShowFullScreen] = useState(false);
	const [updatedSlides, setUpdatedSlides] = useState([]);
	const [miniMapHasLoaded, setMiniMapHasLoaded] = useState(false);
	const [webglAvailable, setWebglAvailable] = useState(false);

	useEffect(() => {
		setWebglAvailable(isWebGLAvailable());
	  }, []);

	const inputChangeHandler = (e) => {
		handleInputChange(e);
		setTrackingId(e.target.value);
		setTrackAgain(true);
	};

	const handleTrackAgain = () => {
		setMiniMapHasLoaded(false);
		setTrackerStatus(false);
		setTrackingId("");
		setTrackAgain(true);
		resetGeoData();
		setSortedTrackingData([]);
		setParcelInfo([]);
		navigate('/tracking/');
	};
	useEffect(() => {
		if (reset) {
			handleTrackAgain();
			setReset(false);
		}

	}, [reset, setReset]);

	const openTrackingHistory = () => {
		setTrackingOpen(true);
	};

	const closeTrackingHistory = () => {
		setTrackingOpen(false);
	};

	const openParcelDetails = () => {
		setParcelOpen(true);
	};

	const closeParcelDetails = () => {
		setParcelOpen(false);
	};

	const openExceptionDetails = () => {
		setExceptionOpen(true);
	};

	const closeExceptionDetails = () => {
		setExceptionOpen(false);
	};

	const openInvoiceDetails = () => {
		setInvoiceOpen(true);
	};

	const closeInvoiceDetails = () => {
		setInvoiceOpen(false);
	};

	const closeMainMap = () => {
		setShowFullScreen(false);
	};

	useEffect(() => {
		if (trackAgain) {
			setSortedTrackingData([]);
		}
	}, [trackAgain]);

	useEffect(() => {
		if (isPublicTracking === true) {
			setTrackAgain(true);
		}
	}, [isPublicTracking]);

	const slideItems = useMemo(
		() =>
			[
				{
					type: "id",
					title: "Tracking ID",
					data: sortedTrackingData,
					event: handleTrackAgain,
				},
				{
					type: "destination",
					title: "Destination",
					data: parcelData
				},
				{
					type: "status",
					title: "Current Status",
					data: sortedTrackingData,
					event: openTrackingHistory,
				},
				{
					type: "details",
					title: "Parcel Details",
					data: sortedTrackingData,
					event: openParcelDetails,
				},
				// {
				// 	type: "edt",
				// 	title: "Estimated Delivery",
				// 	data: parcelInfo,
				// },
				!isPublicTracking && {
					type: "details",
					title: "Invoices",
					data: sortedTrackingData,
					event: openInvoiceDetails,
				},
				// !isMobile && {
				// 	type: "exceptions",
				// 	title: "Exception",
				// 	event: openExceptionDetails,
				// 	data: parcelInfo,
				// },
			].filter(Boolean),
		[parcelData, sortedTrackingData, parcelInfo],
	);

	useEffect(() => {
		if (geoTrackingData.length > 0) {
			const trackingSorted = geoTrackingData;
			setSortedTrackingData(trackingSorted);
		}
	}, [geoTrackingData]);

	useEffect(() => {
		if (
			parcelData &&
			sortedTrackingData.length > 0 &&
			sortedTrackingData[sortedTrackingData.length - 1]?.city
		) {
			setParcelInfo(parcelData);

			// const trimParcelCity = parcelData[0]?.shipTo?.city
			// 	.replace(/,$/, "")
			// 	.trim();
			// const sortedCity = sortedTrackingData[sortedTrackingData.length - 1]?.city
			// 	.replace(/,$/, "")
			// 	.trim();

			// const isCityMatch =
			// 	trimParcelCity && sortedCity && trimParcelCity === sortedCity;

			const newSlideItems = [...slideItems];
			// newSlideItems[4].title =
			// 	parcelData[0]?.actualDeliveryDate && isCityMatch
			// 		? "Delivered"
			// 		: "Estimated Delivery";

			setUpdatedSlides(newSlideItems);
		} else {
			setUpdatedSlides(slideItems);
		}
	}, [parcelData, sortedTrackingData, slideItems]);

	const inputClassName = `w-full max-w-[264px] h-[63px] text-center bg-black border-2 ${
		error
			? "border-red-500 text-red-500 focus:border-red-500 focus:text-red-500"
			: "border-[#EBEBEB] text-white focus:border-[#06D8D8]"
	} border-opacity-40 focus:border-2 focus:bg-black focus:border-[#06D8D8] focus:border-opacity-20 focus:outline-none placeholder-white placeholder-opacity-60 text-[11px] md:text-base font-semibold tracking-normal p-2 px-6 mb-4`;

	return (
		<>
			{loading ? (
				<div className="flex justify-center my-12">
					<Spinner />
				</div>
			) : (
				<div
					className={`tracking relative flex flex-col lg:flex-row justify-center items-center flex-grow ${
						!calledOnce ? "pt-16" : "pt-0"
					}`}
				>
					<div
						className={`flex flex-col h-full left-0 justify-start ml-0 md:ml-20`}
					>
						{sortedTrackingData.length > 0 && (
							<>
								{updatedSlides.map((slide, index) => (
									<DataSlide
										isPublicTracking={isPublicTracking}
										isWebGL={webglAvailable}
										key={index}
										direction="left"
										type={slide.type}
										data={slide.data}
										compareData={slide.compareData}
										title={slide.title}
										event={slide.event}
									/>
								))}
							</>
						)}
					</div>
					{/* Centered Content */}
					<div
						className={`flex relative mx-auto sm:w-[78%] w-[80%] h-auto justify-center items-center ${
							!isPublicTracking && !trackAgain ? "-ml-14" : "pl-4"
						} pr-6 pb-4 ${
							!isPublicTracking
								? "sm:mt-10"
								: !calledOnce
								? "md:-mt-24"
								: "mt-[58px] sm:mt-4"
						}`}
						style={{ marginLeft: "auto", marginRight: "auto" }}
					>
						<div
							className={` relative w-[85%] sm:w-[78%] md:w-[80%] lg:w-[65%] md:max-w-md `}
						>
							{/* Base Dial Image */}
							{sortedTrackingData.length === 0 &&
							(isPublicTracking === true || trackAgain === true) ? (
								<img
									src="/images/tracker/dials.png"
									alt="Tracker Dial"
									className="w-full h-auto"
								/>
							) : (
								<img
									src="/images/tracker/dials-selected-a.png"
									alt="Tracker Dial"
									className="w-full h-auto"
								/>
							)}

							{/* Rotating Dial (*/}
							<div className="absolute top-0 left-0 overflow-hidden">
								<img
									src="/images/tracker/dial_lines.png"
									alt="Dial Lines"
									className={` w-full h-auto ${
										sortedTrackingData.length === 0 && isPublicTracking
											? "animate-spin-slow"
											: ""
									}`}
								/>
							</div>

							{/* Curved Segments */}
							<div className="absolute -inset-14 flex items-center justify-center overflow-hidden">
								<ArcSection
									data={sortedTrackingData}
									status={trackerStatus}
									trackAgain={trackAgain}
									reset={reset}
									isPublicTracking={isPublicTracking}
								/>
							</div>

							{/* Input field */}
							<div className="absolute inset-0 flex items-center justify-center">
								{sortedTrackingData.length === 0 &&
								(isPublicTracking === true ||
									error === true ||
									trackAgain === true) ? (
									<div className="rounded-full w-[90%] md:w-[80%] max-w-[320px] h-auto bg-[#000000] border-[#E0E1EC] border-2 border-opacity-40 z-10 flex items-center justify-center aspect-square p-4">
										<div className="flex flex-col mt-10">
											<style>
												{`
                    input:-webkit-autofill {
                      -webkit-box-shadow: 0 0 0px 1000px black inset !important;
                      box-shadow: 0 0 0px 1000px black inset !important;
                      -webkit-text-fill-color: white !important;
                    }
                  `}
											</style>
											<input
												type="text"
												id="trackingIdInput"
												value={trackingId}
												className={inputClassName}
												placeholder="Enter Your Tracking ID"
												onChange={inputChangeHandler}
												onKeyDown={(e) => {
													if (e.key === "Enter") {
														setTrackAgain(false);
														getEvents();
													}
												}}
											/>
											<div className="h-10 w-full flex items-center justify-center">
												{trackingId && (
													<Button
														className="w-32 h-full mx-auto text-white font-semibold"
														type="submit"
														context="text"
														style={{
															borderRadius: "8px",
															opacity: "60",
														}}
														onClick={() => {
															setTrackAgain(false);
															getEvents();
														}}
													>
														Track
													</Button>
												)}
											</div>
										</div>
									</div>
								) : (
									<>
										{isMapLoading.origin ||
										isMapLoading.destination ||
										isMapLoading.routes ? (
											<div className="flex justify-center my-12">
												<Spinner />
											</div>
										) : (
											<div>
												{showFullScreen ? (
													<TrackingModal
														open={true}
														type="none"
														blurBackground={true}
														onClose={closeMainMap}
														onOpenChange={(open) => {
															setTrackingOpen(open);
														}}
													>
														<MainMap
															route={route}
															locationData={locationData}
															trackingData={sortedTrackingData}
														/>
													</TrackingModal>
												) : (
													<MapTrack
														route={route}
														trackingData={sortedTrackingData}
														setShowFullScreen={setShowFullScreen}
														miniMapHasLoaded={miniMapHasLoaded}
														setMiniMapHasLoaded={setMiniMapHasLoaded}
													/>
												)}
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>

					<TrackingModal
						open={trackingOpen}
						type="none"
						blurBackground={true}
						onClose={closeTrackingHistory}
						onOpenChange={(open) => {
							setTrackingOpen(open);
						}}
					>
						<HistoryMap
							route={route}
							trackingId={trackingId}
							trackingData={sortedTrackingData}
						/>
					</TrackingModal>

					<TrackingModal
						open={parcelOpen}
						blurBackground={true}
						onClose={closeParcelDetails}
						onOpenChange={(open) => {
							setParcelOpen(open);
						}}
					>
						<ParcelDetailScreen
							trackingId={trackingId}
							parcelDetails={parcelData}
							trackingDetails={sortedTrackingData}
							isPublicTracking={isPublicTracking}
						/>
					</TrackingModal>

					<TrackingModal
						open={invoiceOpen}
						type="none"
						blurBackground={true}
						classProp="mid-modal"
						onClose={closeInvoiceDetails}
						onOpenChange={(open) => {
							setInvoiceOpen(open);
						}}
					>
						<ParcelInvoice trackingId={trackingId} />
					</TrackingModal>

					<TrackingModal
						open={exceptionOpen}
						type="none"
						blurBackground={true}
						classProp="mid-modal"
						onClose={closeExceptionDetails}
						onOpenChange={(open) => {
							setExceptionOpen(open);
						}}
					>
						<ExceptionDetails trackingId={trackingId} />
					</TrackingModal>
				</div>
			)}
		</>
	);
};

export default Tracker;
