import React, { useState, useEffect } from "react";

import {
	Card,
	Heading,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Text,
} from "@chakra-ui/react";

function EstimatedCharges({ parcelData }) {
	const [parcel, setParcel] = useState(null);

	useEffect(() => {
		if (parcelData && parcelData.length > 0) {
			setParcel(parcelData[0]);
		}
	}, [parcelData]);

	const hrStyle = {
		border: "0",
		height: "1px",
		width: "100%",
		background: "rgba(133, 134, 152, 0.6)",
		margin: "15px 0 10px",
	};

	return (
		<Card
			borderRadius="20px"
			borderWidth="1px"
			borderColor="rgba(133, 134, 152, 0.36)"
			p={6}
			mb={4}
		>
			<Heading as={"h3"} color="#EBEBEB" size="sm">
				Initial Estimated Charges
			</Heading>
			<hr style={hrStyle} />

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Service Type
				</Text>
				<Text flex="1">{parcel?.serviceType || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Zone
				</Text>
				<Text flex="1">{parcel?.zone || "-"}</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Billable Weight
				</Text>
				<Text flex="1">
					{parcel?.billableWeight
						? `${parcel?.billableWeight.toFixed(2)} lbs`
						: "-"}
				</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Base Rate
				</Text>
				<Text flex="1">
					{parcel?.baseRate ? `$${parcel?.baseRate.toFixed(2)}` : "-"}
				</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Total Rate
				</Text>
				<Text flex="1">
					{parcel?.totalRate ? `$${parcel?.totalRate.toFixed(2)}` : "-"}
				</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Shipper Invoice Status
				</Text>
				<Text flex="1">
					{parcel?.shipperInvoiceStatus === "INVOICED"
						? "Invoiced"
						: "Not Invoiced"}
				</Text>
			</Flex>

			<Flex my={2}>
				<Text flex="1" opacity="0.8">
					Validity
				</Text>
				<Text flex="1">{parcel?.validity || "-"}</Text>
			</Flex>

			<Flex my={3}>
				<Text flex="1" fontWeight="semibold">
					Additional Charges:
				</Text>
			</Flex>
			<TableContainer className="!overflow-y-auto w-full mb-4">
				<Table>
					<Thead>
						<Tr>
							<Th>Description</Th>
							<Th>Code</Th>
							<Th>Fee</Th>
						</Tr>
					</Thead>
					<Tbody>
						{parcel?.surcharges?.map((item, i) => (
							<Tr key={i}>
								<Td>{item?.description}</Td>
								<Td>{item?.code}</Td>
								<Td>${item?.rate}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</Card>
	);
}

export default EstimatedCharges;
