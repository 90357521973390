import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
	Card,
	Flex,
	Heading,
	FormControl,
	Box,
	Spinner,
	Text,
	FormErrorMessage,
	Grid,
	useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import {
	AccountTabs,
	ContactForm,
	InputField,
	Page,
	Select,
	UserContext,
} from "../interfaces";
import {
	createParticipant,
	getCarrierInfo,
	getParticipant,
	updateParticipant,
} from "../services";
import { Label, Button } from "../componentLibrary";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

const inputClassName =
	"!h-[52px] mt-2.5 disabled:!bg-transparent disabled:!text-[#EBEBEB99] disabled:!border disabled:!border-[#85869833] focus:!bg-transparent";

const labelClassName = (isError) =>
	`text-sm ${isError ? "text-[#FC8181]" : "text-[#EBEBEB]"} font-medium`;

const selectStyles = (isError) => ({
	container: (styles) => ({
		...styles,
		width: "100% !important",
		marginTop: "10px",
	}),
	control: (styles, { isDisabled, isFocused }) => {
		return {
			...styles,
			height: "52px !important",
			borderRadius: "4px !important",
			backgroundColor: `${
				isDisabled || isFocused ? "transparent" : "#85869833"
			} !important`,
			border: `1px solid ${
				isDisabled
					? "#85869833"
					: isFocused
					? "#63b3ed"
					: isError
					? "#FC8181"
					: "transparent"
			} !important`,
			opacity: isDisabled ? "0.75" : "1",
		};
	},
	input: (styles) => ({
		...styles,
		marginLeft: "0 !important",
	}),
	singleValue: (styles) => ({
		...styles,
		marginLeft: "0 !important",
	}),
	placeholder: (styles, { isDisabled }) => ({
		...styles,
		marginLeft: "0 !important",
		color: `${isDisabled ? "#EBEBEB99" : ""} !important`,
	}),
	dropdownIndicator: (styles, { isDisabled }) => ({
		...styles,
		color: `${isDisabled ? "#EBEBEB99" : ""} !important`,
	}),
});

const selectFieldSchema = yup.object().shape({
	label: yup.string().required(),
	value: yup.string().required(),
});

const informationSchemaForNew = yup.object({
	name: yup.string().required(),
	code: yup.string().required(),
	accountType: selectFieldSchema,
	accountSubType: selectFieldSchema,
	address: yup.object().shape({
		address1: yup.string().required(),
		state: yup.string().required(),
		zip: yup.string().required(),
		country: yup.string().required(),
		city: yup.string().required(),
	}),
	contacts: yup.array().of(
		yup.object().shape({
			firstName: yup.string().required(),
			lastName: yup.string().required(),
		}),
	),
});

const informationSchema = yup.object({
	address: yup.object().shape({
		address1: yup.string().required(),
		state: yup.string().required(),
		zip: yup.string().required(),
		country: yup.string().required(),
		city: yup.string().required(),
	}),
	contacts: yup.array().of(
		yup.object().shape({
			firstName: yup.string().required(),
			lastName: yup.string().required(),
		}),
	),
});

const AccountInfo = () => {
	const {
		token,
		user,
		viewingAsByCode,
		setViewingAsByCode,
		setAccountOptions,
	} = useContext(UserContext);
	const location = useLocation();
	const toast = useToast();
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isNewAccountUser, setIsNewAccountUser] = useState(false);

	// Update the state structure to accommodate the API response
	const [carrierInfo, setCarrierInfo] = useState();
	const [submitLoader, setSubmitLoader] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: "",
			code: "",
			registrationNumber: "",
			employerIdentificationNumber: "",
			standardCarriercode: "",
			url: "",
			accountType: "",
			accountSubType: "",
			status: "",
			address: {
				address1: "",
				state: "",
				zip: "",
				country: "",
				city: "",
				address2: "",
			},
			contacts: [],
		},
		validationSchema: isNewAccountUser
			? informationSchemaForNew
			: informationSchema,
		onSubmit: async (values) => {
			try {
				setSubmitLoader(true);
				if (isNewAccountUser) {
					const body = {
						name: values.name,
						code: values.code,
						registrationNumber: values.registrationNumber,
						employerIdentificationNumber: values.employerIdentificationNumber,
						standardCarriercode: values.standardCarriercode,
						url: values.url,
						participantType: values.accountType.value,
						participantSubType: values.accountSubType.value,
						status: values.status?.value ?? "",
						roles: [getRole(values.accountType.value)],
						address: values.address,
						contacts: values.contacts,
					};

					const isResponseOk = await createParticipant(
						JSON.stringify(body),
						token,
					);

					if (isResponseOk) {
						getCarrierInfo(values.code, token).then((data) => {
							setCarrierInfo(data);
						});
						setIsEditMode(false);
						setIsNewAccountUser(false);
						toast({
							status: "success",
							description: "Account created successfully!",
							position: "top-right",
						});
						navigate("/app/account/configuration/preferences", {
							state: { isNewAccountUser: true, carrierCode: values.code },
						});
						setViewingAsByCode(values.code);

						const response = await getParticipant(token);
						setAccountOptions(response);
					}
				} else {
					const isResponseOk = await updateParticipant(
						JSON.stringify({ ...values, code: carrierInfo.code }),
						token,
					);

					if (isResponseOk) {
						getCarrierInfo(viewingAsByCode, token).then((data) => {
							setCarrierInfo(data);
						});

						toast({
							status: "success",
							description: "Account details updated!",
							position: "top-right",
						});
						setIsEditMode(false);
					}
				}
			} catch (error) {
				toast({
					status: "error",
					description: error,
					position: "top-right",
				});
				if (isNewAccountUser) {
					console.error("Error while updating participant:", error);
				} else {
					console.error("Error while updating participant:", error);
				}
			} finally {
				setSubmitLoader(false);
			}
		},
	});

	const {
		values,
		handleSubmit,
		resetForm,
		setValues,
		errors,
		touched,
		handleChange,
		handleBlur,
		setFieldValue,
		setFieldTouched,
	} = formik;

	const getRole = (accountType) => {
		if (
			accountType === "BRAND" ||
			accountType === "RSE" ||
			accountType === "WMS"
		) {
			return "SHIPPER";
		} else if (accountType === "CARRIER") {
			return "CARRIER";
		}

		return "";
	};

	useEffect(() => {
		if (location.state?.isNewUser) {
			setIsNewAccountUser(true);
			setIsEditMode(true);
			const formData = {
				name: location.state?.newAccountData?.name,
				code: "",
				registrationNumber: "",
				accountType: {
					value: location.state?.newAccountData?.accountType,
					label: location.state?.newAccountData?.accountType,
				},
				accountSubType: {
					value: location.state?.newAccountData?.accountSubType,
					label: location.state?.newAccountData?.accountSubType,
				},
				employerIdentificationNumber:
					location.state?.newAccountData?.employerIdentificationNumber,
				standardCarriercode: "",
				role: getRole(location.state?.newAccountData?.accountType),
				url: location.state?.newAccountData?.url,
				address: location.state?.newAccountData?.address,
				contacts: [],
			};
			setValues(formData);
			setCarrierInfo(formData);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state]);

	useEffect(() => {
		setFieldValue("role", getRole(values.accountType?.value));
		// eslint-disable-next-line
	}, [values.accountType]);

	useEffect(() => {
		if (location.state?.newAccountData?.name !== viewingAsByCode) {
			setIsEditMode(false);
			setIsNewAccountUser(false);
		}
	}, [viewingAsByCode]);

	useEffect(() => {
		if (!viewingAsByCode) return;
		if (location.state?.newAccountData?.name !== viewingAsByCode) {
			setIsLoading(true);
			getCarrierInfo(viewingAsByCode, token)
				.then((data) => {
					setCarrierInfo(data);
					setValues({
						name: data.name,
						code: data.code,
						status: { value: data.status, label: data.status },
						address: { ...values.address, ...data.address },
						contacts: data.contacts,
					});
				})
				.catch((error) => console.error("Error fetching carrier info:", error))
				.finally(() => setIsLoading(false));
		}
		// eslint-disable-next-line
	}, [viewingAsByCode, token, isNewAccountUser]);

	const handleClickEdit = useCallback(() => {
		setIsEditMode(true);
	}, []);

	const handleDiscardChanges = useCallback(() => {
		resetForm();
		if (carrierInfo?.address && carrierInfo?.contacts) {
			setValues({
				address: { ...values.address, ...carrierInfo.address },
				contacts: carrierInfo.contacts,
			});
		}
		setIsEditMode(false);
		// eslint-disable-next-line
	}, [carrierInfo]);

	return (
		<>
			<AccountTabs />
			<Page className={"!pt-7"}>
				{/*  <div className="flex justify-end items-center gap-2">
              {user.isPlatformAdmin && isNonProdEnvironment && (
                  <>
                    <ImportFile
                      isDataExists={carrierServiceAttributes.length !== 0}
                    />
                    <Button
                      context="primary"
                      prefixIcon={<ArrowDownTrayIcon />}
                    >
                      Download
                    </Button>
                  </>
                )}
            </div> */}
				<div className="flex justify-end items-center gap-2 mb-5">
					{user.isPlatformAdmin && (
						<>
							{!isNewAccountUser && (
								<Button
									context="outlined"
									prefixIcon={<EditIcon className="!w-[18px] !h-[18px]" />}
									className="text-sm font-medium"
									onClick={handleClickEdit}
									disabled={isEditMode}
								>
									Edit
								</Button>
							)}

							<Button
								context="primary"
								prefixIcon={<ArrowDownTrayIcon />}
								className="text-sm font-medium"
							>
								Download Template
							</Button>
						</>
					)}
				</div>

				{isLoading ? (
					<Box display="flex" justifyContent="center" my={"10em"}>
						<Spinner />
					</Box>
				) : !carrierInfo ? (
					<Text>No account information</Text>
				) : (
					<>
						<Card
							mb={7}
							borderRadius="20px"
							borderWidth="1px"
							borderColor="rgba(133, 134, 152, 0.2)"
							py={9}
							px={"44px"}
						>
							<Heading size="md" mb={"30px"}>
								General
							</Heading>

							<Flex flexDirection={"column"} className="gap-11">
								<Flex className="gap-6">
									<FormControl isInvalid={!!errors?.name && touched?.name}>
										<Label htmlFor="name" className={labelClassName()}>
											Name
										</Label>
										<InputField
											isDisabled={!isNewAccountUser}
											value={values.name ?? ""}
											className={inputClassName}
											onChange={handleChange}
											onBlur={handleBlur}
											name={`name`}
											id={`name`}
										/>
										<FormErrorMessage className="!mt-3">
											Name is required
										</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={!!errors?.code && touched?.code}>
										<Label htmlFor="code" className={labelClassName()}>
											Code
										</Label>
										<InputField
											className={inputClassName}
											isDisabled={!isNewAccountUser}
											value={values.code ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
											name={`code`}
											id={`code`}
										/>
										<FormErrorMessage className="!mt-3">
											Code is required
										</FormErrorMessage>
									</FormControl>
								</Flex>
								<Flex className="gap-6">
									<FormControl>
										<Label
											htmlFor="registrationNumber"
											className={labelClassName()}
										>
											US Company Registration Number
										</Label>
										<InputField
											className={inputClassName}
											isDisabled={!isNewAccountUser}
											value={values.registrationNumber ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
											name={`registrationNumber`}
											id={`registrationNumber`}
										/>
									</FormControl>
									<FormControl>
										<Label
											htmlFor="employerIdentificationNumber"
											className={labelClassName()}
										>
											Employer Identification Number
										</Label>
										<InputField
											className={inputClassName}
											isDisabled={!isNewAccountUser}
											value={values.employerIdentificationNumber ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
											name={`employerIdentificationNumber`}
											id={`employerIdentificationNumber`}
										/>
									</FormControl>
								</Flex>
								<Flex className="gap-6">
									<FormControl>
										<Label
											htmlFor="standardCarriercode"
											className={labelClassName()}
										>
											Standard Carrier Alpha Code™
										</Label>
										<InputField
											className={inputClassName}
											isDisabled={!isNewAccountUser}
											value={values.standardCarriercode ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
											name={`standardCarriercode`}
											id={`standardCarriercode`}
										/>
									</FormControl>
									<FormControl>
										<Label htmlFor="url" className={labelClassName()}>
											Company URL
										</Label>
										<InputField
											className={inputClassName}
											isDisabled={!isNewAccountUser}
											value={values.url ?? ""}
											onChange={handleChange}
											onBlur={handleBlur}
											name={`url`}
											id={`url`}
										/>
									</FormControl>
								</Flex>
								<Grid templateColumns="repeat(2, 1fr)" className="gap-6">
									<Flex
										flexDirection={"column"}
										justifyContent={"center"}
										alignItems={"flex-start"}
										gap={2.5}
										flexShrink={0}
										w={"100%"}
									>
										<Label
											htmlFor="accountType"
											className="text-sm font-medium"
										>
											Account Type
										</Label>
										<FormControl
											isInvalid={!!errors?.accountType && touched?.accountType}
										>
											<Select
												isSearchable={false}
												name={"accountType"}
												id={"accountType"}
												placeholder={"Select Account Type"}
												customStyles={selectStyles(
													!!errors?.accountType && touched?.accountType,
												)}
												value={{
													label: values.accountType?.value ?? "",
													value: values.accountType?.value ?? "",
												}}
												options={[
													{ label: "BRAND", value: "BRAND" },
													{ label: "RSE", value: "RSE" },
													{ label: "WMS", value: "WMS" },
													{ label: "CARRIER", value: "CARRIER" },
												]}
												onBlur={() => setFieldTouched("accountType")}
												onChange={(options) => {
													setFieldValue("accountType", options);
												}}
												isDisabled={!isNewAccountUser}
											/>
											<FormErrorMessage className="!mt-3">
												Account Type is required
											</FormErrorMessage>
										</FormControl>
									</Flex>
									<Flex
										flexDirection={"column"}
										justifyContent={"center"}
										alignItems={"flex-start"}
										gap={2.5}
										flexShrink={0}
										w={"100%"}
									>
										<Label
											htmlFor="accountSubType"
											className="text-sm font-medium"
										>
											Account Sub Type
										</Label>
										<FormControl
											isInvalid={
												!!errors?.accountSubType && touched?.accountSubType
											}
										>
											<Select
												isSearchable={false}
												name={"accountSubType"}
												id={"accountSubType"}
												placeholder={"Select Account Sub Type"}
												customStyles={selectStyles(
													!!errors?.accountSubType && touched?.accountSubType,
												)}
												value={{
													label: values.accountSubType?.value ?? "",
													value: values.accountSubType?.value ?? "",
												}}
												options={[
													{ label: "REGIONAL", value: "REGIONAL" },
													{ label: "NATIONAL", value: "NATIONAL" },
													{ label: "MIDDLEMILE", value: "MIDDLEMILE" },
													{ label: "RETAIL", value: "RETAIL" },
													{ label: "MEDICAL", value: "MEDICAL" },
													{ label: "RSE", value: "RSE" },
												]}
												onBlur={() => setFieldTouched("accountSubType")}
												onChange={(options) => {
													setFieldValue("accountSubType", options);
												}}
												isDisabled={!isNewAccountUser}
											/>
											<FormErrorMessage className="!mt-3">
												Account Sub Type is required
											</FormErrorMessage>
										</FormControl>
									</Flex>
								</Grid>
								<Grid templateColumns="repeat(2, 1fr)" className="gap-6">
									<div className="flex gap-6">
										<FormControl
											isInvalid={!!errors?.carrierType && touched?.carrierType}
										>
											<Label htmlFor="carrierType" className={labelClassName()}>
												Carrier Type
											</Label>
											<InputField
												value={carrierInfo.carrierType}
												className={inputClassName}
												isDisabled={!isNewAccountUser}
											/>
										</FormControl>

										<FormControl>
											<Label htmlFor="role" className={labelClassName()}>
												Role
											</Label>
											<InputField
												value={values.role ?? ""}
												className={inputClassName}
												isDisabled
											/>
										</FormControl>
									</div>
									<FormControl>
										<Label htmlFor="status" className={labelClassName()}>
											Account Status
										</Label>
										<Select
											isSearchable={false}
											name={"status"}
											id={"status"}
											placeholder={"Select Account status"}
											customStyles={selectStyles(
												!!errors?.status && touched?.status,
											)}
											value={{
												label: values.status?.value ?? "",
												value: values.status?.value ?? "",
											}}
											options={[
												{ label: "Active", value: "Active" },
												{ label: "Inactive", value: "Inactive" },
											]}
											onBlur={() => setFieldTouched("status")}
											onChange={(options) => {
												setFieldValue("status", options);
											}}
											isDisabled={!isNewAccountUser}
										/>
									</FormControl>
								</Grid>
							</Flex>
						</Card>

						<ContactForm
							formik={formik}
							carrierInfo={carrierInfo}
							isEditMode={isEditMode}
							labelClassName={labelClassName}
							inputClassName={inputClassName}
							selectStyles={selectStyles}
						/>

						{isEditMode && (
							<Flex justifyContent={"flex-end"} gap={6} py={4}>
								<Button
									context="textSecondary"
									className="text-sm font-medium min-w-[142px]"
									onClick={handleDiscardChanges}
								>
									Cancel
								</Button>
								<Button
									context="primary"
									className="text-sm font-medium min-w-[142px]"
									type="submit"
									onClick={handleSubmit}
									disabled={submitLoader}
									isLoading={submitLoader}
								>
									{isNewAccountUser ? "Create Account" : "Save"}
								</Button>
							</Flex>
						)}
					</>
				)}
			</Page>
		</>
	);
};

export default AccountInfo;
