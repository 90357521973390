import React, { useContext, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_API_KEY } from "../../../constants";
import PopupContent from "./PopupContent";
import "./Facilities.css";
import { fetchCoordinates } from "./Coordinates";
import { validateZipCode } from "../../../services";
import { UserContext } from "../../Providers";
mapboxgl.accessToken = MAPBOX_API_KEY;

const Mapbox = ({ data,setZipCodesData,sortCodeValidation }) => {
  const mapContainerRef = useRef();
  const mapRef = useRef();
  const [show,setShow]=useState(false)
  const {token} =useContext(UserContext);

  const colorsCode=(success)=>{
    if(success==="zipcode" || success==="start"){
        return "#FF263F"
    }else {
        return "#22E588"
    }
    
  }


  const colorNavigation = (row,coordinates) => {
    if (coordinates.length!==0) {
      if (row[5]?.length===5 && row[6]?.length===5) {
        if (parseInt(row[6]) - parseInt(row[5]) >= 0) {
          return "";
        } else {
          return "start";
        }
      } else {
        return "start";
      }
    } else {
      return "zipcode";
    }
  }

  const createMarkerElement = (success) => {
    const el = document.createElement("div");
    el.style.width = "16px";
    el.style.height = "16px";
    el.style.cursor = "pointer";
    el.innerHTML = `<svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="30"
      fill="none"
      viewBox="0 0 20 30"
    >
      <path
        fill=${colorsCode(success)}
        d="M10 14.292a3.542 3.542 0 110-7.083 3.542 3.542 0 010 7.083zM10 .834a9.917 9.917 0 00-9.917 9.917C.083 18.188 10 29.167 10 29.167s9.916-10.979 9.916-18.416A9.916 9.916 0 0010 .834z"
      ></path>
    </svg>`;

    return el;
  };


 
  const addMarker = (coordinates, zipCode) => {
    const popupContent = `
     <div id="popup-content" style="padding: 10px; width: 300px; color: white; font-family: Arial, sans-serif; border-radius: 12px;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <h3 style="margin: 0; font-size: 14px; font-weight:bold">${zipCode.City}</h3>
            <button id="edit-button"
                style="background: none; border: none; color: #4FC1FF; cursor: pointer; font-size: 18px;">✎</button>
        </div>
        <div style="margin-top: 10px;display: flex;">
            <div style="border: 2px solid ${colorsCode(zipCode.success)}; margin-right: 16px;"></div>
            <div style="display: flex;flex-direction: column; width: 100%;">
                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px;color:#858698;"><strong>Code:</strong></label>
                    <input id="code" type="text" value=${zipCode.Code}
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color: white;"
                        disabled />
                </div>

                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px;color:#858698;"><strong>Street Address:</strong></label>
                    <input id="street-address" type="text" value="${zipCode["Street Address"]}"
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color: white;"
                        disabled />
                </div>

                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px;color:#858698;"><strong>City:</strong></label>
                    <input id="city" type="text" value=${zipCode.City}
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color: white;"
                        disabled />
                </div>
                
                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px;color:#858698;"><strong>State:</strong></label>
                    <input id="state" type="text" value=${zipCode.State}
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color: white;"
                        disabled />
                </div>

                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px; color:${zipCode.success==="zipcode"?"#FF263F":"#858698"}"><strong>Zip:</strong></label>
                    <input id="zip" type="text" value=${zipCode.ZIP}
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color:${zipCode.success==="zipcode"?"#FF263F":"white"};"
                        disabled />
                </div>

                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px;  color:${zipCode.success==="start"?"#FF263F":"#858698"}; "><strong>Start:</strong></label>
                    <input id="start" type="text" value=${zipCode.Start}
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color:${zipCode.success==="start"?"#FF263F":"white"};"
                        disabled />
                </div>

                <div style="display: flex;width: 100%;align-items: center;justify-content: center;">
                    <label style="display: flex;flex-basis: 40%;font-size: 12px; color:${zipCode.success==="start"?"#FF263F":"#858698"};"><strong>Stop:</strong></label>
                    <input id="stop" type="text" value=${zipCode.Stop}
                        style="display: flex;flex-basis: 60%; border-radius: 4px; width: 100%; padding: 4px; margin-top: 4px; background-color: transparent; border: 1px solid transparent; color:${zipCode.success==="start"?"#FF263F":"white"};"
                        disabled />
                </div>
            </div>
        </div>
        <div id="save-button-container" style="display: none; text-align: center; margin-top: 10px; width: 100%;">
            <button id="save-button"
                class="oui-button--primary"
                style="padding: 6px 12px; cursor: pointer; border-radius: 8px; width: 100%; height:40px; font-size:16px;">Save</button>
        </div>
        <div id="saved-button-container" style="display: none; text-align: center; margin-top: 10px; width: 100%;">
            <button
                class="oui-button--primary"
                style="padding: 6px 12px; cursor: pointer; border-radius: 8px; width: 100%; height:40px; font-size:16px;">Saved</button>
        </div>
    </div>
   `;
    const popup = new mapboxgl.Popup({ offset: 25, anchor: "bottom" }).setHTML(
      popupContent
    );

    const el = createMarkerElement(zipCode?.success);
    const marker = new mapboxgl.Marker({ element: el })
      .setLngLat(coordinates && coordinates)
      .setPopup(popup)
      .addTo(mapRef.current);

    marker.getElement().addEventListener("click", () => {
      setTimeout(() => {
        const editButton = document.getElementById("edit-button");
        const saveButton = document.getElementById("save-button");
        const saveButtonContainer = document.getElementById(
          "save-button-container"
        );
        const savedButtonContainer=document.getElementById("saved-button-container");

        const inputFields = [
          document.getElementById("code"),
          document.getElementById("street-address"),
          document.getElementById("city"),
          document.getElementById("state"),
          document.getElementById("zip"),
          document.getElementById("start"),
          document.getElementById("stop"),
        ];

        editButton.addEventListener("click", () => {
          inputFields.forEach((input) => {
            input.disabled = false;
            input.style.border = "1px solid #414141";
          });
          saveButtonContainer.style.display = "block";
          savedButtonContainer.style.display="none"
        });

        saveButton.addEventListener("click", async() => {
          inputFields.forEach((input) => {
            input.disabled = true;
            input.style.border = "none";
          });
          saveButtonContainer.style.display = "none";
          savedButtonContainer.style.display="block"
          const updatedData=inputFields.map((e)=>e.value)
          const updateData = data.map(async (entry) => {
            if (zipCode?.id === entry?.id) {
              const getCoordinates= async ()=>{
                if(updatedData[4]){
                  const zipData=await validateZipCode([updatedData[4]],token)
                  const coordinatesStatus=zipData[0].status==="VALID"
                  if(coordinatesStatus){
                    const coordinates = await fetchCoordinates(updatedData[4]);
                    return coordinates
                  }else{
                    return []
                  }
                }else{
                  return entry.coordinates
                }
              }      
              const coordinates=await getCoordinates()
              return {
                ...entry,
                "City":updatedData[2] ? updatedData[2]:entry["City"],
                "Code":updatedData[0] ? updatedData[0]:entry["Code"],
                "State":updatedData[3] ? updatedData[3]:entry["State"],
                "Street Address":updatedData[1] ? updatedData[1]:entry["Street Address"],
                "ZIP":updatedData[4] ? updatedData[4]:entry["ZIP"],
                "Start":updatedData[5] ? updatedData[5]:entry["Start"], 
                "Stop":updatedData[6] ? updatedData[6]:entry["Stop"], 
                "coordinates":coordinates,
                "success":colorNavigation(updatedData,coordinates)      
              };
            }else{
              return {...entry}
            }
        });
        const promiseAll=await Promise.all(updateData)
        sortCodeValidation(promiseAll)
        setZipCodesData(promiseAll)
        if (mapRef.current) {
          mapRef.current.remove();
          mapRef.current = null;
        }
        });
      }, 0);
    });

    el.addEventListener("click", () => {
      popup.setLngLat(coordinates).addTo(mapRef.current);
    });
    return marker;
  };

  const addCarrierMarkers = () => {
    const addedLocations = new Set();
    const carrierMarkers = [];

    data.forEach((zipCode) => {
      const coord = zipCode?.coordinates;
      if(coord.length!==0){
        if (!addedLocations.has(coord.toString())) {
          addedLocations.add(coord.toString());
          const marker = addMarker(coord, zipCode);
          carrierMarkers.push(marker);
        }
      }else{
        setShow(true)
      }
    });
  };

  useEffect(() => {
    const defaultBounds = [
      [-134.0, 24.396308],
      [-58.93457, 50.384358],
    ];

    const texasBounds = [
      [-106.645646, 25.837164],
      [-93.508292, 36.500704],
    ];

    if (!mapRef.current) {
      const mapBounds = defaultBounds;

      const center = [
        (mapBounds[0][0] + mapBounds[1][0]) / 2,
        (mapBounds[0][1] + mapBounds[1][1]) / 2,
      ];
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/dark-v10",
        center: center,
        maxBounds: mapBounds,
        zoom: 3,
      });

      mapRef.current.on("load", () => {
        mapRef.current.setPaintProperty("water", "fill-color", "#191A23");

        mapRef.current.addLayer({
          id: "all-land",
          type: "fill",
          source: {
            type: "geojson",
            data: "https://raw.githubusercontent.com/datasets/geo-boundaries-world-110m/master/countries.geojson",
          },
          paint: {
            "fill-color": "#191A23",
          },
          filter: ["!=", "ADMIN", "United States of America"],
        });

        mapRef.current.addLayer({
          id: "us-states-fill",
          type: "fill",
          source: {
            type: "geojson",
            data: "https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json",
          },
          paint: {
            "fill-color": "#191A23",
          },
        });

        mapRef.current.addLayer({
          id: "us-states-borders",
          type: "line",
          source: {
            type: "geojson",
            data: "https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json",
          },
          paint: {
            "line-color": "#3A406C",
            "line-width": 1,
          },
        });

        mapRef.current.addLayer({
          id: "texas-highlight",
          type: "fill",
          source: {
            type: "geojson",
            data: "https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json",
          },
          paint: {
            "fill-color": "#858698",
            "fill-opacity": 0.6,
          },
          filter: ["==", "name", "Texas"],
        });

        mapRef.current.fitBounds(texasBounds, {
          padding: 20,
          maxZoom: 8,
        });

        const layersToRemove = [
          "country-label",
          "state-label",
          "settlement-label",
          "water-label",
          "poi-label",
          "road-label",
          "transit-label",
        ];

        layersToRemove.forEach((layer) => {
          if (mapRef.current.getLayer(layer)) {
            mapRef.current.removeLayer(layer);
          }
        });
        addCarrierMarkers();
      });
    }
  }, [data]);

  return (
    <>
    <div
      id="map"
      ref={mapContainerRef}
      style={{ height: "100%", width: "100%" }}
    />
    {show && <div className="p-3 scrollBox" style={{position:"absolute", height:"70%", overflow:"auto",}}>
      <PopupContent data={data} colorsCode={colorsCode} setZipCodesData={setZipCodesData} mapStatus={mapRef} sortCodeValidation={sortCodeValidation}/>
      </div>}
    </>
  );
};

export default Mapbox;
