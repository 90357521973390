import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Flex, FormControl, FormErrorMessage, Grid } from "@chakra-ui/react";

import { Button, Label, Modal } from "../../componentLibrary";
import { InputField } from "../InputFields";
import { Select } from "../ReactSelect";
import { UserContext } from "../Providers";
import { GetCountries, GetState } from "react-country-state-city";

const customSelectStyles = (isError) => ({
	container: (styles) => ({
		...styles,
		width: "100% !important",
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: "16px !important",
		fontWeight: "500 !important",
		color: "#ebebeb99",
		margin: "0 !important",
	}),
	control: (styles) => ({
		...styles,
		backgroundColor: "#85869833 !important",
		border: `1px solid ${isError ? "#FC8181" : "transparent"} !important`,
		borderRadius: "4px !important",
		cursor: "pointer !important",
		minHeight: "52px !important",
		boxShadow: "none !important",
		":hover": {
			border: `1px solid ${isError ? "#FC8181" : "transparent"} !important`,
		},
		":focus-within": {
			border: "1px solid #63b3ed !important",
		},
	}),
	singleValue: (styles) => ({
		...styles,
		margin: "0 !important",
	}),
});

const CreateAccount = ({ open = false, onClose }) => {
	const navigate = useNavigate();
	const { setViewingAsByCode } = useContext(UserContext);

	const [countriesList, setCountriesList] = useState([]);
	const [stateList, setStateList] = useState([]);

	const {
		values,
		errors,
		handleChange,
		setFieldValue,
		handleSubmit,
		handleBlur,
		resetForm,
		touched,
		setFieldTouched,
	} = useFormik({
		initialValues: {
			name: "",
			employerIdentificationNumber: "",
			accountType: "",
			accountSubType: "",
			url: "",
			address: {
				address1: "",
				address2: "",
				city: "",
				state: "",
				country: { value: 233, label: "United States" },
				zip: "",
			},
		},
		onSubmit: async (values) => {
			setViewingAsByCode(values?.name);
			navigate("/app/account/configuration/information", {
				state: {
					newAccountData: {
						...values,
						accountType: values.accountType.value ?? "",
						accountSubType: values.accountSubType.value ?? "",
					},
					isNewUser: true,
				},
			});
			onClose();
			resetForm();
		},
	});

	useEffect(() => {
		GetCountries().then((result) => {
			setCountriesList(result);
		});
		if (values.address.country)
			GetState(values.address.country.value).then((result) => {
				setStateList(result);
			});
	}, []);

	return (
		<Modal
			open={open}
			onClose={onClose}
			title="Create a New Account"
			className={{
				content:
					"min-w-[560px] md:min-w-[730px] lg:min-w-[992px] xl:min-w-[1200px] !z-[999] !p-12",
				title: "!text-2xl !text-[#fff]",
				closeButton: "top-2",
			}}
			blurBackground={true}
			controls={
				<Flex
					paddingBottom={4}
					marginTop={"-8px"}
					justifyContent={"flex-end"}
					gap={6}
					pr={4}
				>
					<Button
						context="textSecondary"
						className="min-w-[142px] text-sm font-medium"
						onClick={() => {
							resetForm();
							onClose();
						}}
					>
						Cancel
					</Button>
					<Button
						context="primary"
						className="min-w-[142px] text-sm font-medium"
						onClick={handleSubmit}
						type="submit"
					>
						Create
					</Button>
				</Flex>
			}
		>
			<Flex marginY={6} flexDirection={"column"} gap={10} px={0.5}>
				<Grid
					className="grid-cols-1 md:grid-cols-2 gap-6"
					alignItems={"flex-start"}
				>
					<Flex
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"flex-start"}
						gap={2.5}
						flexShrink={0}
						w={"100%"}
					>
						<Label
							htmlFor="name"
							className="cursor-pointer text-sm font-medium"
						>
							Company
						</Label>
						<FormControl isInvalid={!!errors?.name && touched?.name}>
							<InputField
								placeholder={"Company Name"}
								className="!h-[52px]"
								_placeholder={{ color: "rgba(235, 235, 235, 0.6) !important" }}
								name={"name"}
								id="name"
								value={values.name}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<FormErrorMessage className="!mt-3">
								Company is required
							</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"flex-start"}
						gap={2.5}
						flexShrink={0}
						w={"100%"}
					>
						<Label
							htmlFor="employerIdentificationNumber"
							className="cursor-pointer text-sm font-medium"
						>
							Employer Identification Number
						</Label>
						<FormControl
							isInvalid={
								!!errors?.employerIdentificationNumber &&
								touched?.employerIdentificationNumber
							}
						>
							<InputField
								placeholder={"Employer Identification Number"}
								className="!h-[52px]"
								_placeholder={{ color: "rgba(235, 235, 235, 0.6) !important" }}
								name={"employerIdentificationNumber"}
								id="employerIdentificationNumber"
								value={values.employerIdentificationNumber}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<FormErrorMessage className="!mt-3">
								Employer Identification Number is required
							</FormErrorMessage>
						</FormControl>
					</Flex>
				</Grid>
				<Grid
					className="grid-cols-1 md:grid-cols-2 gap-6"
					alignItems={"flex-start"}
				>
					<Grid
						className="grid-cols-1 md:grid-cols-2 gap-6"
						alignItems={"flex-start"}
					>
						<Flex
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"flex-start"}
							gap={2.5}
							flexShrink={0}
							w={"100%"}
						>
							<Label htmlFor="accountType" className="text-sm font-medium">
								Account Type
							</Label>
							<FormControl
								isInvalid={!!errors?.accountType && touched?.accountType}
							>
								<Select
									isSearchable={false}
									name={"accountType"}
									id={"accountType"}
									placeholder={"Select Account Type"}
									customStyles={customSelectStyles(
										!!errors?.accountType && touched?.accountType,
									)}
									value={values.accountType}
									options={[
										{ label: "BRAND", value: "BRAND" },
										{ label: "RSE", value: "RSE" },
										{ label: "WMS", value: "WMS" },
										{ label: "CARRIER", value: "CARRIER" },
									]}
									onBlur={() => setFieldTouched("accountType")}
									onChange={(options) => {
										setFieldValue("accountType", options);
									}}
								/>
								<FormErrorMessage className="!mt-3">
									Account Type is required
								</FormErrorMessage>
							</FormControl>
						</Flex>
						<Flex
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"flex-start"}
							gap={2.5}
							flexShrink={0}
							w={"100%"}
						>
							<Label htmlFor="accountSubType" className="text-sm font-medium">
								Account Sub Type
							</Label>
							<FormControl
								isInvalid={!!errors?.accountSubType && touched?.accountSubType}
							>
								<Select
									isSearchable={false}
									name={"accountSubType"}
									id={"accountSubType"}
									placeholder={"Select Sub Type"}
									customStyles={customSelectStyles(
										!!errors?.accountSubType && touched?.accountSubType,
									)}
									value={values.accountSubType}
									options={[
										{ label: "REGIONAL", value: "REGIONAL" },
										{ label: "NATIONAL", value: "NATIONAL" },
										{ label: "MIDDLEMILE", value: "MIDDLEMILE" },
										{ label: "RETAIL", value: "RETAIL" },
										{ label: "MEDICAL", value: "MEDICAL" },
										{ label: "RSE", value: "RSE" },
									]}
									onBlur={() => setFieldTouched("accountSubType")}
									onChange={(options) => {
										setFieldValue("accountSubType", options);
									}}
								/>
								<FormErrorMessage className="!mt-3">
									Account Sub Type is required
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</Grid>

					<Flex
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"flex-start"}
						gap={2.5}
						flexShrink={0}
						w={"100%"}
					>
						<Label htmlFor="url" className="cursor-pointer text-sm font-medium">
							Company URL
						</Label>
						<FormControl isInvalid={!!errors?.url && touched?.url}>
							<InputField
								placeholder={"Company URL"}
								className="!h-[52px]"
								_placeholder={{ color: "rgba(235, 235, 235, 0.6) !important" }}
								name={"url"}
								id="url"
								value={values.url}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<FormErrorMessage className="!mt-3">
								Company URL is required
							</FormErrorMessage>
						</FormControl>
					</Flex>
				</Grid>
				<Grid
					className="grid-cols-1 md:grid-cols-2 gap-6"
					alignItems={"flex-start"}
				>
					<Flex
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"flex-start"}
						gap={2.5}
						flexShrink={0}
						w={"100%"}
					>
						<Label
							htmlFor="address.address1"
							className="cursor-pointer text-sm font-medium"
						>
							Address 1
						</Label>
						<FormControl
							isInvalid={
								!!errors?.address?.address1 && touched?.address?.address1
							}
						>
							<InputField
								placeholder={"Address 1"}
								className="!h-[52px]"
								_placeholder={{ color: "rgba(235, 235, 235, 0.6) !important" }}
								name={"address.address1"}
								id="address.address1"
								value={values.address?.address1}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<FormErrorMessage className="!mt-3">
								Address 1 is required
							</FormErrorMessage>
						</FormControl>
					</Flex>
					<Flex
						flexDirection={"column"}
						justifyContent={"center"}
						alignItems={"flex-start"}
						gap={2.5}
						flexShrink={0}
						w={"100%"}
					>
						<Label
							htmlFor="address.address2"
							className="cursor-pointer text-sm font-medium"
						>
							Address 2
						</Label>
						<FormControl
							isInvalid={
								!!errors?.address?.address2 && touched?.address?.address2
							}
						>
							<InputField
								placeholder={"Address 2"}
								className="!h-[52px]"
								_placeholder={{ color: "rgba(235, 235, 235, 0.6) !important" }}
								name={"address.address2"}
								id="address.address2"
								value={values.address?.address2}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<FormErrorMessage className="!mt-3">
								Address 2 is required
							</FormErrorMessage>
						</FormControl>
					</Flex>
				</Grid>
				<Grid
					className="grid-cols-1 md:grid-cols-2 gap-6"
					alignItems={"flex-start"}
				>
					<Grid
						className="grid-cols-1 md:grid-cols-2 gap-6"
						alignItems={"flex-start"}
					>
						<Flex
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"flex-start"}
							gap={2.5}
							flexShrink={0}
							w={"100%"}
						>
							<Label
								htmlFor="address.city"
								className="cursor-pointer text-sm font-medium"
							>
								City
							</Label>
							<FormControl
								isInvalid={!!errors?.address?.city && touched?.address?.city}
							>
								<InputField
									placeholder={"City"}
									className="!h-[52px]"
									_placeholder={{
										color: "rgba(235, 235, 235, 0.6) !important",
									}}
									name={"address.city"}
									id="address.city"
									value={values.address?.city}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								<FormErrorMessage className="!mt-3">
									City is required
								</FormErrorMessage>
							</FormControl>
						</Flex>
						<Flex
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"flex-start"}
							gap={2.5}
							flexShrink={0}
							w={"100%"}
						>
							<Label
								htmlFor="address.state"
								className="cursor-pointer text-sm font-medium"
							>
								State
							</Label>
							<FormControl
								isInvalid={!!errors?.address?.state && touched?.address?.state}
							>
								<Select
									isSearchable={true}
									name={"address.state"}
									id={"address.state"}
									placeholder={"State"}
									customStyles={customSelectStyles(
										!!errors?.address?.state && touched?.address?.state,
									)}
									value={values.address?.state}
									options={stateList.map((item, index) => ({
										value: item.id,
										label: item.name,
									}))}
									onBlur={() => setFieldTouched("address.state")}
									onChange={(options) => {
										setFieldValue("address.state", options);
									}}
									menuPlacement="top"
								/>
								<FormErrorMessage className="!mt-3">
									State is required
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</Grid>
					<Grid
						className="grid-cols-1 md:grid-cols-2 gap-6"
						alignItems={"flex-start"}
					>
						<Flex
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"flex-start"}
							gap={2.5}
							flexShrink={0}
							w={"100%"}
						>
							<Label
								htmlFor="address.zip"
								className="cursor-pointer text-sm font-medium"
							>
								Zip
							</Label>
							<FormControl
								isInvalid={!!errors?.address?.zip && touched?.address?.zip}
							>
								<InputField
									placeholder={"Zip"}
									className="!h-[52px]"
									_placeholder={{
										color: "rgba(235, 235, 235, 0.6) !important",
									}}
									name={"address.zip"}
									id="address.zip"
									value={values.address?.zip}
									onBlur={handleBlur}
									onChange={handleChange}
								/>
								<FormErrorMessage className="!mt-3">
									Zip is required
								</FormErrorMessage>
							</FormControl>
						</Flex>
						<Flex
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"flex-start"}
							gap={2.5}
							flexShrink={0}
							w={"100%"}
						>
							<Label
								htmlFor="address.country"
								className="cursor-pointer text-sm font-medium"
							>
								Country
							</Label>
							<FormControl
								isInvalid={
									!!errors?.address?.country && touched?.address?.country
								}
							>
								<Select
									isSearchable={true}
									name={"address.country"}
									id={"address.country"}
									placeholder={"Country"}
									customStyles={customSelectStyles(
										!!errors?.address?.country && touched?.address?.country,
									)}
									value={values.address?.country}
									options={countriesList.map((item, index) => ({
										value: item.id,
										label: item.name,
									}))}
									onBlur={() => setFieldTouched("address.country")}
									onChange={(options) => {
										setFieldValue("address.country", options);
										setFieldValue("address.state", "");
										GetState(options.value).then((result) => {
											setStateList(result);
										});
									}}
									menuPlacement="top"
								/>
								<FormErrorMessage className="!mt-3">
									Country is required
								</FormErrorMessage>
							</FormControl>
						</Flex>
					</Grid>
				</Grid>
			</Flex>
		</Modal>
	);
};

export default CreateAccount;

CreateAccount.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};
