export const SearchIcon = ({ fill = "#000", className = "" }) => (
  <svg
    className={`w-4 h-4 absolute left-2 top-1/2 transform -translate-y-1/2 ${className}`}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0687 13.3939C10.5659 14.5619 8.67466 15.113 6.78004 14.9348C4.88542 14.7566 3.1299 13.8627 1.87093 12.435C0.611965 11.0072 -0.0557828 9.1531 0.00365277 7.25008C0.0630884 5.34706 0.845238 3.53826 2.19084 2.19196C3.53645 0.84567 5.34433 0.0631207 7.24637 0.00365464C9.14842 -0.0558114 11.0016 0.612277 12.4286 1.87189C13.8556 3.1315 14.7491 4.88792 14.9272 6.78351C15.1052 8.6791 14.5545 10.5713 13.3871 12.0749L17.7243 16.3981C18.0914 16.7641 18.092 17.3585 17.7255 17.7252C17.359 18.0919 16.7645 18.0916 16.3984 17.7245L12.0781 13.3939H12.0687ZM7.48724 13.0946C8.22396 13.0946 8.95346 12.9494 9.6341 12.6673C10.3147 12.3853 10.9332 11.9718 11.4541 11.4506C11.975 10.9294 12.3883 10.3107 12.6702 9.62967C12.9521 8.94869 13.0972 8.21881 13.0972 7.48172C13.0972 6.74463 12.9521 6.01475 12.6702 5.33377C12.3883 4.65279 11.975 4.03403 11.4541 3.51283C10.9332 2.99162 10.3147 2.57818 9.6341 2.29611C8.95346 2.01404 8.22396 1.86885 7.48724 1.86885C5.99938 1.86885 4.57246 2.46021 3.52038 3.51283C2.4683 4.56544 1.87725 5.9931 1.87725 7.48172C1.87725 8.97034 2.4683 10.398 3.52038 11.4506C4.57246 12.5032 5.99938 13.0946 7.48724 13.0946Z"
      fill={fill}
    />
  </svg>
);
