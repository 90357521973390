import React from "react";

const ErrorIcon=()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <g clipPath="url(#clip0_4457_20604)">
        <path
          fill="#FF263F"
          d="M15 2.5C8.1 2.5 2.5 8.1 2.5 15S8.1 27.5 15 27.5 27.5 21.9 27.5 15 21.9 2.5 15 2.5zm1.25 18.75h-2.5v-2.5h2.5v2.5zm0-5h-2.5v-7.5h2.5v7.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_4457_20604">
          <path fill="#fff" d="M0 0H30V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ErrorIcon