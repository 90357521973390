import { useCallback, useContext, useEffect, useState } from "react";
import {
	Flex,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	IconButton,
} from "@chakra-ui/react";

import Button from "../componentLibrary/components/Button";
import { Page, Pagination, TableLoading, UserContext } from "../interfaces";
import { downloadFileFromBlob, renderDate } from "../utilities";
import { getInvoice } from "../services";
import { InvoiceTabs } from "../interfaces/InvoiceTabs/InvoiceTabs";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Badge } from "../componentLibrary";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

const Invoices = () => {
	const PAGE_OPTIONS = [25, 50, 100];

	const { token, user } = useContext(UserContext);

	const [data, setData] = useState([]);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [isInvoiceDownloading, setIsInvoiceDownloading] = useState({});
	const [isProcessedInvoiceDownloading, setIsProcessedInvoiceDownloading] =
		useState({});
	const [invoiceDownloaded, setInvoiceDownloaded] = useState([]);
	const [processedInvoiceDownloaded, setProcessedInvoiceDownloaded] = useState(
		[],
	);
	const [paginationConfig, setPaginationConfig] = useState({
		options: PAGE_OPTIONS,
		perPage: PAGE_OPTIONS[0],
	});

	const [selectedTab, setSelectedTab] = useState(
		user.isCarrier && !(user.isPlatformAdmin || user.isShipper)
			? "CARRIER"
			: "SHIPPER",
	);

	const handleTabChange = (memberType) => {
		setSelectedTab(memberType);
	};

	const callGetInvoice = useCallback(
		async (pageNumber, limit) => {
			if (!user || !user.Groups || !token) {
				console.error("User data or Groups not available");
				return;
			}

			let partnerCode = user.Groups[0];

			if (!partnerCode) return;

			// If the user is a Platform Admin (Internal), remove the memberCode as endpoint expects ""
			if (user.isPlatformAdmin) {
				partnerCode = "";
			}

			setIsDataLoading(true);
			setData([]);

			try {
				const data = await getInvoice(
					token,
					partnerCode,
					selectedTab,
					pageNumber,
					limit,
				);
				setData(data);
			} catch (error) {
				if (error.response?.status === 403) {
					console.error("403 Forbidden: Access denied for this user.");
				} else {
					console.error("An error occurred:", error);
				}
			} finally {
				setIsDataLoading(false);
			}
		},
		[selectedTab, token, user],
	);

	useEffect(() => {
		callGetInvoice(1, paginationConfig.perPage);
		// eslint-disable-next-line
	}, [selectedTab]);

	const handleIndexChange = useCallback(
		(start, limit) => {
			if (
				(start === data?.currentPage && limit === paginationConfig.perPage) ||
				!start ||
				!limit
			) {
				return;
			}
			// Switch from index based pagination to page based pagination
			const pageNumber = (start - 1) / paginationConfig.perPage + 1;
			callGetInvoice(pageNumber, limit);
		},
		// eslint-disable-next-line
		[data, paginationConfig.perPage],
	);

	const downloadInvoice = useCallback(
		async (invoiceNumber, participantType, user, fileReference) => {
			if (participantType === "CARRIER" && fileReference !== "original") {
				setIsProcessedInvoiceDownloading((isProcessedInvoiceDownloading) => ({
					...isProcessedInvoiceDownloading,
					[invoiceNumber]: true,
				}));
			} else {
				setIsInvoiceDownloading((isInvoiceDownloading) => ({
					...isInvoiceDownloading,
					[invoiceNumber]: true,
				}));
			}

			await downloadFileFromBlob(
				`${apiUrl}/invoice-download?invoiceNumber=${invoiceNumber}&participantType=${participantType}${
					user ? `&user=${user}` : ""
				}${fileReference ? `&fileReference=${fileReference}` : ""}`,
				token,
				`Orchestro_${
					fileReference ? "original" : "processed"
				}_${participantType.toLowerCase()}_invoice_${invoiceNumber}`,
			);

			if (participantType === "CARRIER" && fileReference !== "original") {
				setIsProcessedInvoiceDownloading((isProcessedInvoiceDownloading) => ({
					...isProcessedInvoiceDownloading,
					[invoiceNumber]: false,
				}));
			} else {
				setIsInvoiceDownloading((isInvoiceDownloading) => ({
					...isInvoiceDownloading,
					[invoiceNumber]: false,
				}));
			}
		},
		[token],
	);

	return (
		<Page>
			<Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
				<InvoiceTabs onTabChange={handleTabChange} selectedTab={selectedTab} />
			</Flex>
			<TableContainer className="max-h-[calc(100vh_-_260px)] !overflow-y-auto w-full mb-4">
				<Table>
					<Thead>
						<Tr>
							{selectedTab === "SHIPPER" && (
								<>
									<Th>Name</Th>
									<Th>Invoice ID</Th>
									<Th>From</Th>
									<Th>To</Th>
									<Th>Total Amount</Th>
									<Th textAlign="center">Shipper Invoice</Th>
								</>
							)}
							{selectedTab === "CARRIER" && (
								<>
									<Th>Name</Th>
									<Th>Invoice ID</Th>
									<Th>Date</Th>
									<Th>Rate Total</Th>
									<Th>No. of Parcels</Th>
									<Th>Verification</Th>
									<Th>Settlement</Th>
									<Th textAlign="center">Carrier Invoice</Th>
									<Th textAlign="center">Processed Invoice</Th>
								</>
							)}
						</Tr>
					</Thead>
					<Tbody>
						{isDataLoading && <TableLoading />}
						{((!isDataLoading &&
							data?.invoices &&
							data.invoices.length === 0) ||
							!data) && (
							<Tr>
								<Td colSpan="100">No data available</Td>
							</Tr>
						)}
						{data?.invoices &&
							data.invoices.map((invoice, index) => {
								let isInvoiceDownloaded = invoiceDownloaded.includes(
									invoice.invoiceNumber,
								);
								let isProcessedInvoiceDownloaded =
									processedInvoiceDownloaded.includes(invoice.invoiceNumber);
								return (
									<Tr key={index}>
										{selectedTab === "SHIPPER" && (
											<>
												<Td>{invoice?.shipperName ?? "-"}</Td>
												<Td>{invoice?.invoiceNumber ?? "-"}</Td>
												<Td>
													{invoice?.billingStartDate ? (
														<time dateTime={invoice.billingStartDate}>
															{renderDate(
																invoice.billingStartDate,
																"MM/dd/yyyy",
															)}
														</time>
													) : (
														"-"
													)}
												</Td>
												<Td>
													{invoice?.billingEndDate ? (
														<time dateTime={invoice.billingEndDate}>
															{renderDate(invoice.billingEndDate, "MM/dd/yyyy")}
														</time>
													) : (
														"-"
													)}
												</Td>
												<Td>
													{invoice?.totalAmount
														? `$${invoice.totalAmount}`
														: "-"}
												</Td>
												<Td textAlign="center" className="h-[60px]">
													{invoice?.processedFileUploadIdExternal &&
													invoice?.invoiceNumber ? (
														isInvoiceDownloading[invoice.invoiceNumber] ? (
															<Spinner />
														) : invoiceDownloaded.includes(
																invoice.invoiceNumber,
														  ) ? (
															<Flex justifyContent="center" alignItems="center">
																<Button
																	context="text"
																	className="text-[14px] items-center py-2 w-[130px]"
																	onClick={() => {
																		downloadInvoice(
																			invoice.invoiceNumber,
																			"SHIPPER",
																		);
																	}}
																>
																	Re-download
																</Button>
															</Flex>
														) : (
															<IconButton
																aria-label="Download"
																bg="transparent"
																_hover={{
																	bg: "rgb(255 179 35)",
																}}
																_active={{ bg: "transparent" }}
																onClick={() => {
																	downloadInvoice(
																		invoice.invoiceNumber,
																		"SHIPPER",
																	);
																	setInvoiceDownloaded([
																		...invoiceDownloaded,
																		invoice.invoiceNumber,
																	]);
																}}
																icon={
																	<ArrowDownTrayIcon className="size-6 text-[#2294FF]" />
																}
															/>
														)
													) : (
														<p className="text-sm py-[10px] text-[#2489ff] cursor-default">
															No Invoice File
														</p>
													)}
												</Td>
											</>
										)}

										{selectedTab === "CARRIER" && (
											<>
												<Td>
													{invoice?.carrierCode ? (
														<p title={invoice.carrierName}>
															{invoice.carrierCode}
														</p>
													) : (
														"-"
													)}
												</Td>
												<Td>{invoice?.invoiceNumber ?? "-"}</Td>
												<Td>
													{invoice?.invoiceDate ? (
														<time dateTime={invoice.invoiceDate}>
															{renderDate(invoice.invoiceDate, "MM/dd/yyyy")}
														</time>
													) : (
														"-"
													)}
												</Td>
												<Td>
													{invoice?.totalRate ? `$${invoice.totalRate}` : "-"}
												</Td>
												<Td>{invoice?.noOfParcels ?? "-"}</Td>
												<Td>
													{invoice?.verificationStatus === "NOT_VERIFIED" ? (
														<Badge context="error" text="Not Verified" />
													) : (
														<Badge context="success" text="Verified" />
													)}
												</Td>
												<Td>{invoice?.settlementStatus}</Td>
												<Td textAlign="center" className="h-[60px]">
													{invoice?.originalFileUploadId &&
													invoice?.invoiceNumber ? (
														isInvoiceDownloading[invoice.invoiceNumber] ? (
															<Spinner />
														) : invoiceDownloaded.includes(
																invoice.invoiceNumber,
														  ) ? (
															<Flex justifyContent="center" alignItems="center">
																<Button
																	context="text"
																	className="text-[14px] items-center py-2 w-[130px]"
																	onClick={() => {
																		downloadInvoice(
																			invoice.invoiceNumber,
																			"CARRIER",
																			user.isPlatformAdmin ? "internal" : "",
																			"original",
																		);
																	}}
																>
																	Re-download
																</Button>
															</Flex>
														) : (
															<IconButton
																aria-label="Download"
																bg="transparent"
																_hover={{
																	bg: "rgb(255 179 35)",
																}}
																_active={{ bg: "transparent" }}
																onClick={() => {
																	downloadInvoice(
																		invoice.invoiceNumber,
																		"CARRIER",
																		user.isPlatformAdmin ? "internal" : "",
																		"original",
																	);
																	setInvoiceDownloaded([
																		...invoiceDownloaded,
																		invoice.invoiceNumber,
																	]);
																}}
																icon={
																	<ArrowDownTrayIcon className="size-6 text-[#2294FF]" />
																}
															/>
														)
													) : (
														<p className="text-sm py-[10px] text-[#2489ff] cursor-default">
															No Invoice File
														</p>
													)}
												</Td>
												<Td textAlign="center">
													{((user.isPlatformAdmin &&
														invoice?.processedFileUploadIdInternal) ||
														(!user.isPlatformAdmin &&
															invoice?.processedFileUploadIdExternal)) &&
													invoice?.invoiceNumber ? (
														isProcessedInvoiceDownloading[
															invoice.invoiceNumber
														] ? (
															<Spinner />
														) : processedInvoiceDownloaded.includes(
																invoice.invoiceNumber,
														  ) ? (
															<Flex justifyContent="center" alignItems="center">
																<Button
																	context="text"
																	className="text-[14px] py-2 w-[130px]"
																	onClick={() => {
																		downloadInvoice(
																			invoice.invoiceNumber,
																			"CARRIER",
																			user.isPlatformAdmin ? "internal" : "",
																		);
																	}}
																>
																	Re-download
																</Button>
															</Flex>
														) : (
															<IconButton
																aria-label="Download"
																bg="transparent"
																_hover={{
																	bg: "rgb(255 179 35)",
																}}
																_active={{ bg: "transparent" }}
																onClick={() => {
																	downloadInvoice(
																		invoice.invoiceNumber,
																		"CARRIER",
																		user.isPlatformAdmin ? "internal" : "",
																	);
																	setProcessedInvoiceDownloaded([
																		...invoiceDownloaded,
																		invoice.invoiceNumber,
																	]);
																}}
																icon={
																	<ArrowDownTrayIcon className="size-6 text-[#2294FF]" />
																}
															/>
														)
													) : (
														<p className="text-sm py-[10px] text-[#2489ff] cursor-default">
															No Invoice File
														</p>
													)}
												</Td>
											</>
										)}
									</Tr>
								);
							})}
					</Tbody>
				</Table>
			</TableContainer>
			{data?.totalItems && data?.totalItems > 0 ? (
				<Pagination
					config={paginationConfig}
					start={(data.currentPage - 1) * paginationConfig.perPage + 1}
					count={data.totalItems}
					onIndexChange={handleIndexChange}
					setPaginationConfig={setPaginationConfig}
					labelText="Number of invoices"
				/>
			) : null}
		</Page>
	);
};

export default Invoices;
