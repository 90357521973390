import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../css-files/style.css";
import {
	getEventsBasedOnTrackerIdPublic
} from "../services";
import { useGeoData } from "../contexts/GeoDataProvider";
import Nav from "../interfaces/PublicTracking/Nav";
import BackgroundLayer from "../interfaces/PublicTracking/BackgroundLayer";
import Tracker from "../interfaces/TrackTrace/MultiTrack/Tracker";
import { useToast, Box } from "@chakra-ui/react";

const PublicTracking = () => {
	const {
		fetchLocationData,
		fetchTrackingData,
		resetGeoData,
		setIsPublic,
	} = useGeoData();
	const { trackingNumber } = useParams();
	const navigate = useNavigate();
	const [trackingData, setTrackingData] = useState([]);
	const [trackingId, setTrackingId] = useState("");
	const [locationData, setLocationData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [trackerStatus, setTrackerStatus] = useState(false);
	const [calledOnce, setCalledOnce] = useState(false);
	const [reset, setReset] = useState(false);
	const [error, setError] = useState(false);
	const MIN_INPUT_LENGTH = 6;
	const MAX_INPUT_LENGTH = 30;

	const toast = useToast();

	const triggerToast = () => {
		toast({
			status: "error",
			duration: 5000,
			isClosable: true,
			variant: "solid",
			position: "bottom",
			render: () => (
				<Box
					display="grid"
					placeItems="center"
					bg="#EF4444"
					color="white"
					p={4}
					ml={4}
					borderRadius="md"
					maxW={["60vw", "md:max-width: 400px"]}
					textAlign="center"
				>
					<strong>Invalid Tracking Number</strong>
					<p>The tracking number you entered was not found.</p>
				</Box>
			),
		});
	};

	const handleTrackingNumberChange = (value) => {
		const newTrackingNumber = value;
		if (newTrackingNumber === trackingNumber) {
			fetchData();
		} else {
			navigate(`/tracking/${newTrackingNumber}`);
		}
	};

	const handleInputChange = (e) => {
		setError(false);
		const tValue = e.target.value;
		if (tValue.length > MIN_INPUT_LENGTH && tValue.length < MAX_INPUT_LENGTH) {
			setTrackingId(tValue);
		} else if (tValue.length === 0) {
			setTrackingId("");
		}
	};

	const fetchData = async () => {
		setLoading(true);
		try {
			const data = await getEventsBasedOnTrackerIdPublic(trackingNumber);
			if (data.results.length === 0) {
				setError(true);
				setLoading(false);
				setTrackerStatus(false);
				setCalledOnce(false);
				triggerToast();
			} else {
				const { results, ...rest } = data;
				setError(false);
				setTrackingData(results);
				setLocationData(rest);
				setTrackerStatus(true);
				setCalledOnce(true);
			}
			setLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setError(true);
			setLoading(false);
			setCalledOnce(false);
			setTrackerStatus(false);
			triggerToast();
		}
	};
	
	const getEvents = () => {
		handleTrackingNumberChange(trackingId);
	};

	useEffect(() => {
		if (trackingNumber) {
			setIsPublic(true);
			setTrackingId(trackingNumber);
			fetchData();
		} else {
			resetGeoData();
			setTrackerStatus(false);
			setReset(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackingNumber]);

	useEffect(() => {
		if (locationData) {
			fetchLocationData(locationData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationData]);

	useEffect(() => {
		if (trackingData?.length > 0) {
			fetchTrackingData(trackingData, locationData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackingData, locationData]);

	return (
		<div className="pb-0">
			<div className="flex flex-col">
				<BackgroundLayer />
				<div className="content-layer">
					<Nav />
					<section id="tracking">
						{!calledOnce && (
							<div>
								<div className="flex flex-col mx-10 md:flex-row items-center md:items-start">
									<div className="w-full md:w-1/2 p-4 ml-6">
										<h2 className="text-[24px] md:text-[30px] font-normal mb-4 md:mb-[27px] leading-tight w-full mx-auto md:mx-0 md:text-left text-center">
											Track your package with Orchestro.AI
										</h2>
										<p className="text-[16px] font-normal w-2/3 mx-auto md:mx-0 md:text-left text-center">
											Stay informed with Orchestro.AI, offering visibility and
											reliability for peace of mind with every package.
										</p>
									</div>
									<div className="w-full flex items-center justify-center md:justify-right md:w-1/2 p-0 pt-0 text-left">
										<img
											src="/images/tracking/TrackingImage.png"
											alt="Illustration of a Parcel"
											className="w-[221px] h-auto"
										/>
									</div>
								</div>
								<div className="flex items-center justify-center h-[10px] sm:h-[10px] md:h-[100px] lg:h-[40px] xl:h-[0px]"></div>
							</div>
						)}
						<Tracker
							key="tracker1"
							isPublicTracking={true}
							handleInputChange={handleInputChange}
							getEvents={getEvents}
							reset={reset}
							setReset={setReset}
							error={error}
							setTrackerStatus={setTrackerStatus}
							setTrackingId={setTrackingId}
							trackerStatus={trackerStatus}
							trackingId={trackingId}
							loading={loading}
							calledOnce={calledOnce}
						/>
					</section>
				</div>
			</div>
		</div>
	);
};

export default PublicTracking;
