import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_API_KEY } from "../../../constants";
import TrackHistoryPanel from "./TrackHistoryPanel";
import { isWebGLAvailable } from "../../../utilities/isWebGLAvailable";
import { LabelLayers } from "./MapLabels";

const carrierColors = {
	Carrier: "#FFB323",
};

mapboxgl.accessToken = MAPBOX_API_KEY;

const HistoryMap = ({
	route,
	trackingData
}) => {
	const mapContainerRef = useRef();
	const mapRef = useRef();
	const [isMapLoaded, setIsMapLoaded] = useState(false);
	const [lines, setLines] = useState([]);
	const [boundaries, setBoundaries] = useState([]);
	const [trackingHistory, setTrackingHistory] = useState([]);
	const [geojsonFeatures, setGeojsonFeatures] = useState(null);
	const [webglAvailable, setWebglAvailable] = useState(false);
	const [markers, setMarkers] = useState([]);
	const [ogMarkers, setOgMarkers] = useState([]);

	useEffect(() => {
		if (trackingData.length > 0) {
			trackingData.forEach((item) => {
				item.carrier = "Carrier";
			});
			//const updatedTrackingData = [...trackingData].reverse();
			setTrackingHistory(trackingData);
		}
	}, [trackingData]);

	useEffect(() => {
		setWebglAvailable(isWebGLAvailable());
	  }, []);

	const createTooltip = () => {
		const tooltip = document.createElement("div");
		tooltip.className = "custom-tooltip";
		tooltip.style.position = "absolute";
		tooltip.style.background = "#21232E";
		tooltip.style.border = "1px solid #3BA0E666";
		tooltip.style.padding = "10px";
		tooltip.style.borderRadius = "18px";
		tooltip.style.boxShadow = "4px 4px 4px 0px #3BA0E640, -2px -2px 4px 0px #00000040";
		tooltip.style.zIndex = "999";
		tooltip.style.minWidth = "260px";
		tooltip.style.minHeight = "100px";
		tooltip.style.pointerEvents = "none";
		tooltip.style.display = "none"; // Initially hidden

	
		document.body.appendChild(tooltip);
		return tooltip;
	};

	const tooltip = createTooltip();
	

	function generateLineColorExpression(carrierColors) {
		const expression = ["case"];
		for (const [carrier, color] of Object.entries(carrierColors)) {
			expression.push(["==", ["get", "name"], carrier], color);
		}
		expression.push("#FFB323");
		return expression;
	}

	const lineColorExpression = generateLineColorExpression(carrierColors);

	const createMarkerElement = () => {
		const el = document.createElement("div");
		el.innerHTML = `<img src="/images/map/marker_2.png" />`;
		return el;
	};

		const lastMarkerElement = () => {
			const el = document.createElement("div");
			const img = document.createElement("img");
			img.src = "/images/map/history_destination.png";
			img.style.marginTop = "-30px";
			img.style.marginLeft = "-2px";
			el.appendChild(img);
			return el;
		}

	const addCarrierMarkers = () => {
		const addedLocations = [];
		const carrierMarkers = [];
		let lastMarker = null;

		const addMarkerIfNotPresent = (longitude, latitude, item, index) => {
			const isPresent = addedLocations.some(
				(coord) => coord[0] === longitude && coord[1] === latitude,
			);
			if (!isPresent) {
				let el = createMarkerElement(addedLocations.length);

				el.addEventListener('mouseenter', (e) => {
					tooltip.innerHTML = `<div><div style="padding: 4px">
						<strong style="font-size: 14px;">
						${item?.carrierName?.city || "NA"}${item?.carrierName?.city && item?.carrierName?.state ? ", " : ""}${item?.carrierName?.state || ""}
						</strong></div>
						<div style="margin-top: 4px; margin-left: 4px; display: flex;  justify-content: space-between; align-items: center;">
							<div style="color: #858698; font-size: 12px;">Arrival Time</div>
							<div style="color: white; font-size: 12px;">${item?.carrierName?.timestamp || "NA"}</div>
						</div>
						</div>`;
					tooltip.style.display = 'block';
					tooltip.style.position = 'absolute';
                	tooltip.style.zIndex = '9999';
				});
	
				el.addEventListener('mouseleave', () => {
					tooltip.style.display = 'none';
				});

				el.addEventListener('mousemove', (e) => {
					tooltip.style.left = e.pageX + 10 + 'px';
					tooltip.style.top = e.pageY + 10 + 'px';
				});
	

				addedLocations.push([longitude, latitude]);
				const marker = new mapboxgl.Marker({ element: el })
					.setLngLat([longitude, latitude])
					.addTo(mapRef.current);
				carrierMarkers.push(marker);
				lastMarker = marker;
			}
		};
		
		[...lines].reverse().forEach((line, index) => {
			addMarkerIfNotPresent(line.toLongitude, line.toLatitude, line, index);
		});

		if (ogMarkers.length > 0) {
			[...ogMarkers].reverse().forEach((markerData, index) => {
				addMarkerIfNotPresent(markerData.position.lng, markerData.position.lat, markerData, index);
			});
		}

		if (lastMarker) {
			const lastEl = lastMarkerElement();
			lastMarker.getElement().innerHTML = lastEl.innerHTML;
		}

		setMarkers(carrierMarkers);
	};

	const fitBounds = (bounds) => {
		const clientWidth = mapContainerRef.current.clientWidth;
		const historyWidth = clientWidth - 520;
		const calcWidth = clientWidth / 3;

		if (mapRef.current && bounds && bounds.length === 2) {
			mapRef.current.fitBounds(bounds, {
				padding: {
					top: 40,
					bottom: 40,
					left: 40,
					right: historyWidth < calcWidth ? historyWidth : calcWidth,
				},
				maxZoom: 6,
				duration: 1000,
			});
		}
	};

	useEffect(() => {
		if (!webglAvailable) return;
		
		if (geojsonFeatures?.length && !mapRef.current) {
			mapRef.current = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: "mapbox://styles/mapbox/dark-v10",
				center: [-96.53732525590827, 39.0383480494191],
				zoom: 1,
				//projection: "globe",
			});

			mapRef.current.on("load", () => {
				setIsMapLoaded(true);

				if (mapRef.current.getLayer("land")) {
					mapRef.current.setPaintProperty(
						"land",
						"background-color",
						"#191A23",
					);
				}

				LabelLayers.forEach((layer) => {
					if (mapRef.current.getLayer(layer)) {
						mapRef.current.setLayoutProperty(layer, "visibility", "none");
					}
				});

				if (mapRef.current.getLayer("road")) {
					mapRef.current.setPaintProperty("road", "line-color", "#FF5733");
				}
				if (mapRef.current.getLayer("admin-0-boundary")) {
					mapRef.current.setPaintProperty(
						"admin-0-boundary",
						"line-color",
						"#858698",
					);
				}

				if (mapRef.current.getLayer("admin-1-boundary")) {
					mapRef.current.setPaintProperty(
						"admin-1-boundary",
						"line-color",
						"#858698",
					);
				}

				mapRef.current.setPaintProperty("water", "fill-color", "#191A23");
				mapRef.current.addSource("multiple-lines-source", {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: geojsonFeatures,
					},
				});

				if (trackingHistory.length > 1) {
					mapRef.current.addLayer({
						type: "line",
						source: "multiple-lines-source",
						id: "line-glow",
						paint: {
							"line-width": 4,
							"line-opacity": 0.4,
							"line-color": "rgba(0,0,0,0.6)",
							"line-offset": 4,
						},
					});

					mapRef.current.addLayer({
						type: "line",
						source: "multiple-lines-source",
						id: "line-background",
						paint: {
							"line-width": 2,
							"line-dasharray": [5, 5],
							"line-color": lineColorExpression,
						},
					});
				}

				addCarrierMarkers();
				setTimeout(() => {
					fitBounds(boundaries);
				}, 300);
			});
		}
	}, [geojsonFeatures, boundaries]);

	const createArc = (start, end) => {

		if (start[0] === end[0] && start[1] === end[1]) {
	
			return {
				coordinates: [start],
				mid: [start[0] + 0.4, start[1] + 0.4], 
			};
		}

		const mid = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

		const distance = turf.distance(turf.point(start), turf.point(end));

		let arcHeightFactor = 3;
		if (distance * 0.5 < 100) {
			arcHeightFactor = 1;
		} else if (distance * 0.5 < 200) {
			arcHeightFactor = 2;
		}

		const controlPoint = [mid[0], mid[1] + arcHeightFactor];

		const line = turf.lineString([start, controlPoint, end]);
		const curved = turf.bezierSpline(line, {
			resolution: 10000,
			sharpness: 1,
		});
		const totalLength = turf.length(curved);

		const midpointDistance = totalLength / 2;

		const midpoint = turf.along(curved, midpointDistance);
		const midpointCoordinates = midpoint.geometry.coordinates;
		const offsetLatitude = 0.4;
		const offsetLongitude = 0.4;

		const adjustedCoordinates = [
			midpointCoordinates[0] + offsetLongitude,
			midpointCoordinates[1] + offsetLatitude,
		];
		return {
			coordinates: curved.geometry.coordinates,
			mid: adjustedCoordinates,
		};
	};

	useEffect(() => {
		if (!isMapLoaded) return;

		mapRef.current.flyTo({
			zoom: 1,
			duration: 1000,
			essential: true,
		});
		let features = lines.map((line, index) => {
			const { coordinates } = createArc(
				[line.fromLongitude, line.fromLatitude],
				[line.toLongitude, line.toLatitude],
			);
			return {
				type: "Feature",
				properties: {
					width: 2,
				},
				geometry: {
					type: "LineString",
					coordinates: coordinates,
				},
			};
		});
		markers.forEach((marker) => marker.remove());
		mapRef.current.getSource("multiple-lines-source").setData({
			type: "FeatureCollection",
			features: features,
		});

		setTimeout(() => {
			addCarrierMarkers();
		}, 1000);
	}, [isMapLoaded, lines]);

	useEffect(() => {
		if (route && trackingHistory.length > 0) {
			const filteredTrackingHistory = trackingHistory.filter(
				(point) => point.latitude !== 0 && point.longitude !== 0,
			);

			if (filteredTrackingHistory.length > 0) {
				const startPoint = [
					filteredTrackingHistory[0].longitude,
					filteredTrackingHistory[0].latitude,
				];
				const endPoint =
					filteredTrackingHistory.length > 1
						? [
								filteredTrackingHistory[filteredTrackingHistory.length - 1]
									.longitude,
								filteredTrackingHistory[filteredTrackingHistory.length - 1]
									.latitude,
							]
						: startPoint;

				setBoundaries([startPoint, endPoint]);

				const lines =
					filteredTrackingHistory.length > 1
						? filteredTrackingHistory.map((point, index, arr) => {
							const comparePoint = arr[index + 1];
								if (index < arr.length - 1 && (point.latitude !== comparePoint.latitude && point.longitude !== comparePoint.longitude)) {
									return {
										fromLatitude: point.latitude,
										fromLongitude: point.longitude,
										toLatitude: comparePoint.latitude,
										toLongitude: comparePoint.longitude,
										carrierName: point,
										parcelCount: 1,
									};
								} else {
									return { fromLatitude: point.latitude,
										fromLongitude: point.longitude,
										toLatitude: point.latitude,
										toLongitude: point.longitude,
										carrierName: point,
										parcelCount: 1,
									};
								}
							})
						: [
								{
									fromLatitude: filteredTrackingHistory[0].latitude,
									fromLongitude: filteredTrackingHistory[0].longitude,
									toLatitude: filteredTrackingHistory[0].latitude,
									toLongitude: filteredTrackingHistory[0].longitude,
									carrierName: filteredTrackingHistory[0].carrierName,
									parcelCount: 1,
								},
							];

				setLines(lines.filter(Boolean));

				let features =
					filteredTrackingHistory.length > 1
						? lines.filter(Boolean).map((line) => {
								const { coordinates } = createArc(
									[line.fromLongitude, line.fromLatitude],
									[line.toLongitude, line.toLatitude],
								);

								return {
									type: "Feature",
									properties: {
										width: 2,
										name: line?.carrier,
									},
									geometry: {
										type: "LineString",
										coordinates: coordinates,
									},
								};
							})
						: [
								{
									type: "Feature",
									properties: {
										width: 2,
										name: filteredTrackingHistory[0]?.carrierName,
									},
									geometry: {
										type: "Point",
										coordinates: [
											filteredTrackingHistory[0].longitude,
											filteredTrackingHistory[0].latitude,
										],
									},
								},
							];

				const originMarkers = lines.filter(Boolean).map((line) => ({
					position: {
						lat: line.fromLatitude,
						lng: line.fromLongitude,
					},
					parcelCount: line.parcelCount,
					carrierName: line.carrierName,
				}));

				setGeojsonFeatures(features);
				setOgMarkers(originMarkers);
			}
		}
	}, [trackingHistory]);

	return (
		<div className="fullMap-container relative w-full h-[100vh]">
			<div id="map" ref={mapContainerRef} className="w-full h-full" />;
			<TrackHistoryPanel data={trackingHistory} />
		</div>
	);
};

export default HistoryMap;
