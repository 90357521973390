import React, { useState, useEffect } from "react";

import { Box, Card, Heading, Flex, Text } from "@chakra-ui/react";

function ParcelDetails({ data, isPublicTracking }) {
	const [parcelData, setParcelData] = useState(null);
	const [message, setMessage] = useState("");

	useEffect(() => {
		if (isPublicTracking && data) {
			setParcelData(data);
			setMessage("");
		} else if (!isPublicTracking && data && data?.length > 0) {
			setParcelData(data[0]);
			setMessage("");
		} else {
			setMessage("No data found");
		}
	}, [data]);

	const hrStyle = {
		border: "0",
		height: "1px",
		width: "100%",
		background: "rgba(133, 134, 152, 0.6)",
		margin: "15px 0 10px",
	};

	function formatValue(value, unit) {
		if (value) {
			return value + (unit ? "\u202F" + unit : ""); // \u202F is a short non-breaking space
		} else {
			return "-";
		}
	}

	return (
		<Card
			borderRadius="20px"
			borderWidth="1px"
			borderColor="rgba(133, 134, 152, 0.36)"
			p={6}
			mb={3}
		>
			<Heading as={"h3"} color="#EBEBEB" size="sm">
				Parcel Details
			</Heading>
			<hr style={hrStyle} />

			{message && (
				<Box my={5}>
					<Text opacity="0.8">{message}</Text>
				</Box>
			)}

			{parcelData?.trackingNumber && (
				<>
					<Flex mb={3} mt={5}>
						<Text flex="1" opacity="0.8">
							Shipment Tracking ID
						</Text>
						<Text flex="1">{parcelData.trackingNumber || ""}</Text>
					</Flex>
					<Flex mb={3}>
						<Text flex="1" opacity="0.8">
							Carrier Tracking ID
						</Text>
						<Text flex="1">
							{parcelData.participant1TrackingId ||
								parcelData.carrierTrackingNumber ||
								""}
						</Text>
					</Flex>
					<Flex mb={3}>
						<Text flex="1" opacity="0.8">
							USPS Tracking ID
						</Text>
						<Text flex="1">
							{parcelData.uspsTrackingId || parcelData.uspsTrackingNumber || ""}
						</Text>
					</Flex>
					{!isPublicTracking && (
						<Flex mb={3}>
							<Text flex="1" opacity="0.8">
								Shipper
							</Text>
							<Text flex="1">{parcelData?.originCode || "Not Available"}</Text>
						</Flex>
					)}
					<Flex mb={3}>
						<Text flex="1" opacity="0.8">
							From Address
						</Text>
						<Text flex="1">
							{!parcelData.shipFrom && "-"}
							{parcelData?.shipFrom?.address1 && !isPublicTracking && (
								<>
									{parcelData.shipFrom.address1},
									<br />
								</>
							)}
							{parcelData?.shipFrom?.address2 && !isPublicTracking && (
								<>
									{parcelData.shipFrom.address2},
									<br />
								</>
							)}
							{[
								parcelData?.shipFrom?.city,
								parcelData?.shipFrom?.state,
								parcelData?.shipFrom?.zip,
							]
								.filter((i) => i)
								.join(", ")}
						</Text>
					</Flex>
					<Flex mb={3}>
						<Text flex="1" opacity="0.8">
							To Address
						</Text>
						<Text flex="1">
							{!parcelData.shipTo && "-"}
							{parcelData?.shipTo?.address1 && !isPublicTracking && (
								<>
									{parcelData.shipTo.address1},
									<br />
								</>
							)}
							{parcelData?.shipTo?.address2 && !isPublicTracking && (
								<>
									{parcelData.shipTo.address2},
									<br />
								</>
							)}
							{[
								parcelData?.shipTo?.city,
								parcelData?.shipTo?.state,
								parcelData?.shipTo?.zip,
							]
								.filter((i) => i)
								.join(", ")}
						</Text>
					</Flex>
					{!isPublicTracking && (
						<Flex mb={3}>
							<Text flex="1" opacity="0.8">
								Line Haul Destination
							</Text>
							<Text flex="1">
								From {parcelData?.originCode || "-"} to{" "}
								{parcelData?.destinationCode || "-"}
							</Text>
						</Flex>
					)}
					{!isPublicTracking && (
						<Flex mb={3}>
							<Text flex="1" opacity="0.8">
								Sender Contact Number
							</Text>
							<Text flex="1">{parcelData?.shipFrom?.phone || "-"}</Text>
						</Flex>
					)}
					{!isPublicTracking && (
						<Flex mb={3}>
							<Text flex="1" opacity="0.8">
								Consignee Name
							</Text>
							<Text flex="1">{parcelData?.shipTo?.name || "-"}</Text>
						</Flex>
					)}
					{!isPublicTracking && (
						<Flex mb={3}>
							<Text flex="1" opacity="0.8">
								Consignee Contact Number
							</Text>
							<Text flex="1">{parcelData?.shipTo?.phone || "-"}</Text>
						</Flex>
					)}
					<Flex mb={3}>
						<Text flex="1" opacity="0.8">
							Dimensions
						</Text>
						<Text flex="1">
							{parcelData?.dimensions?.length || parcelData?.dimension?.length}
							{" X "}
							{parcelData?.dimensions?.width || parcelData?.dimension?.width}
							{" X "}
							{formatValue(
								parcelData?.dimensions?.height || parcelData?.dimension?.height,
								parcelData?.dimensions?.unit || parcelData?.dimension?.unit,
							)}
						</Text>
					</Flex>
					<Flex>
						<Text flex="1" opacity="0.8">
							Weight
						</Text>
						<Text flex="1">
							{formatValue(parcelData?.weight.value, parcelData?.weight.unit)}
						</Text>
					</Flex>
				</>
			)}
		</Card>
	);
}

export default ParcelDetails;
