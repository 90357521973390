import React, { useEffect, useState, useContext, useCallback } from "react";
import {
	TableContainer,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	Flex,
	IconButton,
} from "@chakra-ui/react";
import {
	AccountTabs,
	ImportFile,
	Page,
	TableLoading,
	UserContext,
} from "../interfaces";
import { getTrackingCodes } from "../services";
import Button from "../componentLibrary/components/Button";
import {
	ArrowDownTrayIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@heroicons/react/24/solid";

const TrackingCodes = () => {
	const [trackingCodes, setTrackingCodes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { isNonProdEnvironment, token, user, viewingAs } =
		useContext(UserContext); // Use UserContext

	const [isOpenTable, setIsOpenTable] = useState({
		carrier: true,
		shipper: true,
	});

	const handleToggleTable = useCallback(
		(key) => {
			if (viewingAs.isCarrier && viewingAs.isShipper)
				setIsOpenTable((tables) => ({ ...tables, [key]: !tables[key] }));
		},
		[viewingAs],
	);

	useEffect(() => {
		if (!viewingAs.participantCode) return;

		const fetchTrackingCodes = async () => {
			try {
				setIsLoading(true);
				const data = await getTrackingCodes(viewingAs.participantCode, token);
				if (data?.trackingCodes) {
					setTrackingCodes(data.trackingCodes);
				}
			} finally {
				setIsLoading(false);
			}
		};

		fetchTrackingCodes();
	}, [token, viewingAs]);

	return (
		<>
			<AccountTabs />
			<Page className={"!min-h-0"}>
				<div className="flex justify-end items-center gap-2">
					{user.isPlatformAdmin && isNonProdEnvironment && (
						<>
							<ImportFile isDataExists={trackingCodes.length !== 0} />
							<Button context="primary" prefixIcon={<ArrowDownTrayIcon />}>
								Download
							</Button>
						</>
					)}
				</div>

				{viewingAs.isShipper && (
					<>
						<Flex
							alignItems={"center"}
							gap={6}
							marginY={4}
							onClick={() => handleToggleTable("shipper")}
							cursor={
								viewingAs.isCarrier && viewingAs.isShipper
									? "pointer"
									: "initial"
							}
						>
							{viewingAs.isCarrier && viewingAs.isShipper && (
								<>
									<h2 className="text-xl">Orchestro Network</h2>
									<IconButton
										variant={"text"}
										_hover={{ backgroundColor: "#6861631a" }}
									>
										{isOpenTable.shipper ? (
											<ChevronUpIcon width={24} />
										) : (
											<ChevronDownIcon width={24} />
										)}
									</IconButton>
								</>
							)}
						</Flex>

						<TableContainer
							overflowY={"auto"}
							width={"100%"}
							marginBottom={
								isOpenTable.shipper &&
								viewingAs.isCarrier &&
								viewingAs.isShipper
									? 12
									: 4
							}
							maxHeight={
								isOpenTable.shipper
									? `calc(100vh - ${
											viewingAs.isCarrier && viewingAs.isShipper
												? isOpenTable.carrier
													? "665px"
													: "420px"
												: "320px"
									  })`
									: "0"
							}
							transition={"max-height ease 0.5s"}
						>
							<Table>
								<Thead>
									<Tr>
										<Th>Network Track Code</Th>
										<Th>Category Name</Th>
										<Th>Network Description</Th>
										<Th>Network Modifier Code</Th>
									</Tr>
								</Thead>
								<Tbody>
									{isLoading && <TableLoading data={trackingCodes} />}
									{!isLoading && trackingCodes.length === 0 && (
										<Tr>
											<Td colSpan="100">No data found</Td>
										</Tr>
									)}
									{trackingCodes &&
										trackingCodes.map((code, i) => (
											<Tr key={i}>
												<Td>{code.networkTrackCode}</Td>
												<Td>{code.categoryName || "-"}</Td>
												<Td>{code.networkDescription || "-"}</Td>
												<Td>{code.networkModifierCode || "-"}</Td>
											</Tr>
										))}
								</Tbody>
							</Table>
						</TableContainer>
					</>
				)}

				{viewingAs.isCarrier && (
					<>
						<Flex
							alignItems={"center"}
							gap={6}
							marginY={4}
							onClick={() => handleToggleTable("carrier")}
							cursor={
								viewingAs.isCarrier && viewingAs.isShipper
									? "pointer"
									: "initial"
							}
						>
							{viewingAs.isCarrier && viewingAs.isShipper && (
								<>
									<h2 className="text-xl">Carrier</h2>
									<IconButton
										variant={"text"}
										_hover={{ backgroundColor: "#6861631a" }}
									>
										{isOpenTable.carrier ? (
											<ChevronUpIcon width={24} />
										) : (
											<ChevronDownIcon width={24} />
										)}
									</IconButton>
								</>
							)}
						</Flex>

						<TableContainer
							overflowY={"auto"}
							width={"100%"}
							marginBottom={4}
							maxHeight={
								isOpenTable.carrier
									? `calc(100vh - ${
											viewingAs.isCarrier && viewingAs.isShipper
												? isOpenTable.shipper
													? "665px"
													: "420px"
												: "310px"
									  })`
									: "0"
							}
							transition={"max-height ease 0.5s"}
						>
							<Table>
								<Thead>
									<Tr>
										<Th>Carrier Service Type</Th>
										<Th>Code</Th>
										<Th>Description</Th>
										<Th>Status</Th>

										<Th>Network Track Code</Th>
										<Th>Category Name</Th>
										<Th>Network Description</Th>
										<Th>Network Modifier Code</Th>
									</Tr>
								</Thead>
								<Tbody>
									{isLoading && <TableLoading data={trackingCodes} />}
									{!isLoading && trackingCodes.length === 0 && (
										<Tr>
											<Td colSpan="100">No data found</Td>
										</Tr>
									)}
									{trackingCodes &&
										trackingCodes.map((code, i) => (
											<Tr key={i}>
												<Td>{code.carrierServiceType || "-"}</Td>
												<Td>{code.code}</Td>
												<Td>{code.description}</Td>
												<Td>{code.status}</Td>
												<Td>{code.networkTrackCode}</Td>
												<Td>{code.categoryName || "-"}</Td>
												<Td>{code.networkDescription || "-"}</Td>
												<Td>{code.networkModifierCode || "-"}</Td>
											</Tr>
										))}
								</Tbody>
							</Table>
						</TableContainer>
					</>
				)}
			</Page>
		</>
	);
};

export default TrackingCodes;
