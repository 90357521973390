import { MAPBOX_API_KEY } from "../../../constants";

export const fetchCoordinates = async (zipcode) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${zipcode}.json?access_token=${MAPBOX_API_KEY}`
      );
      const data = await response.json();
      const coordinates = data.features[0].center;
      return coordinates;
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
};
