import { useContext, useEffect } from "react";
import { BrowserRouter, useRoutes, Navigate } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";

import {
	Auth0WithRedirectProvider,
	RolesAuth as Only,
	UserContext,
	UserProvider,
} from "./interfaces";
import { useDarkMode } from "./hooks";
import { GeoDataProvider } from "./contexts/GeoDataProvider";

import { RateCardCalculator } from "./pages/RateCardCalculator";
import AccountInfo from "./pages/AccountInfo";
import AccountRequest from "./pages/AccountRequest";
import BidConfiguration from "./pages/BidConfiguration";
import BidPerformance from "./pages/BidPerformance";
import CarrierAddition from "./pages/CarrierAddition";
import Containers from "./pages/Containers";
import Dashboard from "./pages/Dashboard";
import Demo from "./pages/Demo";
import DetailedTracking from "./pages/DetailedTracking";
import DispatchDetails from "./pages/DispatchDetails";
import Facilities from "./pages/Facilities";
import Invoices from "./pages/Invoices";
import LastMileCarrier from "./pages/LastMileCarries";
import Layout from "./pages/Layout";
import LayoutDemo from "./pages/LayoutDemo";
import MiddleMileLanes from "./pages/MiddleMileLanes";
import MiddleMileOrders from "./pages/MiddleMileOrders";
import NetworkInsights from "./pages/NetworkInsights";
import NewMMO from "./pages/NewMMO";
import OrderEvents from "./pages/OrderEvents";
import PageNotFound from "./pages/PageNotFound";
import ParcelIntelligence from "./pages/ParcelIntelligence";
import PldCalculator from "./pages/PldCalculator";
import Preferences from "./pages/Preferences";
import PublicTracking from "./pages/PublicTracking";
import Rates from "./pages/Rates";
import RateDiscovery from "./pages/RateDiscovery";
import SalesTools from "./pages/SalesTools";
import ServiceAttributes from "./pages/ServiceAttributes";
import ServiceTypes from "./pages/ServiceTypes";
import ShipmentDetails from "./pages/ShipmentDetails";
import Shipments from "./pages/Shipments";
import ShipperAddition from "./pages/ShipperAddition";
import Surcharges from "./pages/Surcharges";
import TrackTrace from "./pages/TrackTrace";
import TrackingCodes from "./pages/TrackingCodes";
import UsCoverageMap from "./pages/UsCoverageMap";
import ZipCoverage from "./pages/ZipCoverage";

const baseURL = process.env.REACT_APP_ORCHESTRO_BASE_URL;
const appContext = process.env.REACT_APP_CONTEXT_URI;
console.log("baseURL + appContext:", baseURL + appContext);

const RoutesComponent = () => {
	const { isDemo } = useContext(UserContext);

	return useRoutes([
		{ path: "/", element: <Navigate to={"app"} /> },
		{ path: "/app/login", element: <Login /> },
		{ path: "/app/logout", element: <Logout /> },
		{
			path: "/tracking",
			children: [
				{
					index: true,
					element: (
						<GeoDataProvider>
							<PublicTracking />
						</GeoDataProvider>
					),
				},
				{
					path: ":trackingNumber",
					element: (
						<GeoDataProvider>
							<PublicTracking />
						</GeoDataProvider>
					),
				},
			],
		},
		{
			path: "demo",
			element: (
				<SecureRoute>
					<LayoutDemo />
				</SecureRoute>
			),
			children: [
				{
					index: true,
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<Demo />
						</Only>
					),
				},
				{
					path: "rate-discovery",
					children: [
						{
							index: true,
							element: <Navigate to={"start"} />,
						},
						{
							path: "start",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"start"} />
								</Only>
							),
						},
						{
							path: "step/1",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"1"} />
								</Only>
							),
						},
						{
							path: "step/2",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"2"} />
								</Only>
							),
						},
						{
							path: "step/3",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"3"} />
								</Only>
							),
						},
						{
							path: "step/4",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"4"} />
								</Only>
							),
						},
						{
							path: "step/5",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"5"} />
								</Only>
							),
						},
						{
							path: "step/6",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"6"} />
								</Only>
							),
						},
						{
							path: "step/7",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"7"} />
								</Only>
							),
						},
						{
							path: "step/8",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"8"} />
								</Only>
							),
						},
						{
							path: "step/9",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"9"} />
								</Only>
							),
						},
						{
							path: "step/10",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateDiscovery step={"10"} />
								</Only>
							),
						},
					],
				},
			],
		},
		{
			path: "/app",
			element: (
				<SecureRoute>
					<Layout />
				</SecureRoute>
			),
			children: [
				{
					index: true,
					element: <Navigate to={"dashboard"} />,
				},

				{
					path: "dashboard",
					element: (
						<Only roles={["INTERNAL", "CARRIER", "SHIPPER"]}>
							<Dashboard />
						</Only>
					),
				},

				{
					path: "containers",
					element: (
						<Only
							flags={[isDemo]}
							roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}
						>
							<Containers />
						</Only>
					),
				},

				{
					path: "shipments",
					children: [
						{
							index: true,
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<Shipments />
								</Only>
							),
						},
						{
							path: ":trackingNumber",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<GeoDataProvider>
										<ShipmentDetails />
									</GeoDataProvider>
								</Only>
							),
						},
						{
							path: "track-trace",
							element: (
								<Only
									flags={[isDemo]}
									roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}
								>
									<TrackTrace />
								</Only>
							),
						},
					],
				},

				{
					path: "middle-mile-lanes",
					children: [
						{
							index: true,
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<MiddleMileLanes />
								</Only>
							),
						},
						{
							path: ":selectedOpenOrder",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<DispatchDetails />
								</Only>
							),
						},
						{
							path: "new-order",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<NewMMO />
								</Only>
							),
						},
					],
				},

				{
					path: "middle-mile-orders",
					children: [
						{
							index: true,
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<MiddleMileOrders />
								</Only>
							),
						},
						{
							path: ":orderId",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<OrderEvents />
								</Only>
							),
						},
						{
							path: ":trackingNumber/tracking",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<DetailedTracking />
								</Only>
							),
						},
					],
				},

				{
					path: "account/configuration",
					children: [
						{
							index: true,
							element: <Navigate to={"information"} />,
						},
						{
							path: "information",
							element: (
								<Only
									roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER", "FINANCE"]}
								>
									<AccountInfo />
								</Only>
							),
						},
						{
							path: "preferences",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<Preferences />
								</Only>
							),
						},
						{
							path: "service-attributes",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<ServiceAttributes />
								</Only>
							),
						},
						{
							path: "service-types",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<ServiceTypes />
								</Only>
							),
						},
						{
							path: "tracking-codes",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<TrackingCodes />
								</Only>
							),
						},
						{
							path: "facilities",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<Facilities />
								</Only>
							),
						},
						{
							path: "zip-coverage",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<ZipCoverage />
								</Only>
							),
						},
						{
							path: "rates",
							element: (
								<Only
									roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER", "FINANCE"]}
								>
									<Rates />
								</Only>
							),
						},
						{
							path: "surcharges",
							element: (
								<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
									<Surcharges />
								</Only>
							),
						},
					],
				},
				{
					path: "account/integration",
					children: [
						{
							index: true,
							element: <Navigate to={"last-mile-carrier"} />,
						},
						{
							path: "last-mile-carrier",
							element: (
								<Only flags={[isDemo]} roles={["CARRIER", "SHIPPER"]}>
									<LastMileCarrier />
								</Only>
							),
						},
						{
							path: "shipper",
							element: (
								<Only flags={[isDemo]} roles={["CARRIER", "SHIPPER"]}>
									<LastMileCarrier />
								</Only>
							),
						},
					],
				},

				{
					path: "invoices",
					element: (
						<Only roles={["PLATFORM_ADMIN", "CARRIER", "SHIPPER"]}>
							<Invoices />
						</Only>
					),
				},

				{
					/* Demo related pages below here */
				},

				{
					path: "account-request",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<AccountRequest />
						</Only>
					),
				},

				{
					path: "network-insights/",
					children: [
						{
							path: ":path",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<NetworkInsights />
								</Only>
							),
						},
					],
				},
				{
					path: "sales-tools",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<SalesTools />
						</Only>
					),
				},
				{
					path: "sales-tools/",
					children: [
						{
							index: true,
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<SalesTools />
								</Only>
							),
						},
						{
							path: "pld-calculator",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<PldCalculator />
								</Only>
							),
						},
						{
							path: "network-rate-card-calculator",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<RateCardCalculator />,
								</Only>
							),
						},
						{
							path: "us-coverage-map",
							element: (
								<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
									<UsCoverageMap />
								</Only>
							),
						},
					],
				},
				{
					path: "parcel-intelligence",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<ParcelIntelligence />
						</Only>
					),
				},
				{
					path: "carrier-addition",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<CarrierAddition />
						</Only>
					),
				},
				{
					path: "shipper-addition",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<ShipperAddition />
						</Only>
					),
				},
				{
					path: "bid-performance",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<BidPerformance />
						</Only>
					),
				},
				{
					path: "bid-configuration",
					element: (
						<Only flags={[isDemo]} roles={["PLATFORM_ADMIN"]}>
							<BidConfiguration />
						</Only>
					),
				},
			],
		},
		{
			path: "*",
			element: <Layout />,
			children: [
				{
					path: "*",
					element: <PageNotFound code={"404"} />,
				},
			],
		},
	]);
};

function AppLoading() {
	return (
		<Box display="flex" justifyContent="center" my={"10em"}>
			<Spinner />
		</Box>
	);
}

function SecureRoute({ children }) {
	const { isAuthenticated, isLoading } = useAuth0();
	if (isLoading) return <AppLoading />;
	if (!isAuthenticated) return <Login />;
	return children;
}

function Login() {
	const { loginWithRedirect } = useAuth0();
	loginWithRedirect({
		appState: {
			returnTo: window.location.pathname,
		},
	});
	return null;
}

function Logout() {
	const { logoutUser } = useContext(UserContext);
	const logout = async () => await logoutUser();
	logout();
	return null;
}

function LoggingOut({ children }) {
	const { isLoggingOut } = useContext(UserContext);
	if (isLoggingOut) return <AppLoading />;
	return children;
}

function App() {
	useDarkMode();
	return (
		<BrowserRouter basename={appContext}>
			<Auth0WithRedirectProvider>
				<UserProvider>
					<LoggingOut>
						<RoutesComponent />
					</LoggingOut>
				</UserProvider>
			</Auth0WithRedirectProvider>
		</BrowserRouter>
	);
}

export default App;
