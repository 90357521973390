import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Button } from "../../componentLibrary";
import { getBidTierConfiguration, updateBidConfig } from "../../services";
import { BaseModal } from "../BaseModal";
import { InputField } from "../InputFields";
import { UserContext } from "../Providers";

const BiddingTierModal = ({ selectedBid, isOpen, onClose, getBidConfigs }) => {
  const [isEditBiddingTier, setIsEditBiddingTier] = useState(false);
  const [biddingTier, setBiddingTier] = useState([]);

  const { token } = useContext(UserContext);

  useEffect(() => {
    if (selectedBid) {
      setBiddingTier(selectedBid?.adjustments?.configuration);
    }
  }, [selectedBid]);

  const handleEditBiddingTier = useCallback(() => {
    setIsEditBiddingTier(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    onClose();
    setIsEditBiddingTier(false);
    setBiddingTier(selectedBid?.adjustments?.configuration);
  }, [onClose, selectedBid?.adjustments?.configuration]);

  const handleChangeBiddingTier = useCallback((value, range, key) => {
    setBiddingTier((biddingTier) =>
      biddingTier.map((tier) => {
        if (range === `${tier?.startRange}-${tier?.endRange}`) {
          if (key === "loss") {
            return { ...tier, bidDecrementForLoss: parseFloat(value) };
          } else {
            return { ...tier, bidIncrementForWon: parseFloat(value) };
          }
        } else {
          return tier;
        }
      })
    );
  }, []);

  const handleBiddingTierSave = useCallback(async () => {
    try {
      await updateBidConfig(selectedBid?.uuid, {
        ...selectedBid,
        adjustments: {
          ...selectedBid?.adjustments,
          configuration: biddingTier,
        },
      }, token);
      getBidConfigs();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    handleCloseModal();
  }, [selectedBid, biddingTier, handleCloseModal, getBidConfigs, token]);

  const handleResetToDefault = useCallback(async () => {
    try {
      const tiers = await getBidTierConfiguration(
        selectedBid?.risk,
        selectedBid?.adjustments?.biddingTier,
        token
      );
      setBiddingTier(tiers.configuration);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [selectedBid, token]);

  return (
    <BaseModal
      modalHeader={
        <div className="flex justify-between w-full mt-4 mx-[72px]">
          <p className="text-2xl font-normal">Manage Tier</p>
          <Button
            context="text"
            className="text-sm font-medium"
            onClick={handleEditBiddingTier}
          >
            Edit
          </Button>
        </div>
      }
      shouldDisplayCloseIcon={false}
      open={isOpen}
      onClose={handleCloseModal}
      size="xl"
      headerClasses={"!pb-0"}
    >
      <div className="mx-[68px]">
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>% Range</Th>
                <Th>Loss</Th>
                <Th>Win</Th>
              </Tr>
            </Thead>
            <Tbody>
              {biddingTier &&
                biddingTier?.length > 0 &&
                biddingTier.map((tier, index) => {
                  return (
                    <Tr key={index}>
                      <Td>
                        {tier?.startRange}-{tier?.endRange}
                      </Td>
                      <Td className="w-[110px] !py-0">
                        {isEditBiddingTier ? (
                          <InputField
                            type="number"
                            value={tier?.bidDecrementForLoss}
                            className="!w-1/2 !h-[30px] mr-2 !ps-2 !pe-0"
                            onChange={(e) => {
                              handleChangeBiddingTier(
                                e.target.value,
                                `${tier?.startRange}-${tier?.endRange}`,
                                "loss"
                              );
                            }}
                          />
                        ) : (
                          tier?.bidDecrementForLoss
                        )}
                        %
                      </Td>
                      <Td className="w-[110px] !py-0">
                        {isEditBiddingTier ? (
                          <InputField
                            type="number"
                            value={tier?.bidIncrementForWon}
                            className="!w-1/2 !h-[30px] mr-2 !ps-2 !pe-0"
                            onChange={(e) => {
                              handleChangeBiddingTier(
                                e.target.value,
                                `${tier?.startRange}-${tier?.endRange}`,
                                "won"
                              );
                            }}
                          />
                        ) : (
                          tier?.bidIncrementForWon
                        )}
                        %
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
        <div className="flex mt-7 mb-2 justify-between text-sm font-medium">
          <Button context="text" onClick={handleResetToDefault}>
            Reset to Default
          </Button>
          <Button context="primary" onClick={handleBiddingTierSave}>
            Save
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default BiddingTierModal;

BiddingTierModal.defaultProps = {
  selectedBid: [],
  isOpen: false,
  onClose: () => {},
  getBidConfigs: () => {},
};

BiddingTierModal.propTypes = {
  selectedBid: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  getBidConfigs: PropTypes.func,
};
