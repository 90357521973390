import { useState, useEffect, useRef, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Popover from "./popover";
import { getLocationsSummary } from "../../services/apiServices";
import { MAPBOX_API_KEY } from "../../constants";
import { UserContext } from "../Providers";

mapboxgl.accessToken = MAPBOX_API_KEY;

function MapView() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [popover, setPopover] = useState({
    isVisible: false,
    content: {
      title: "",
      shipments: {},
    },
    position: { x: 0, y: 0 },
  });

  const { token } = useContext(UserContext);

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [-96.53732525590827, 39.0383480494191],
      zoom: 3,
      scrollZoom: false,
    });

    const fullScreenButton = document.createElement("button");

    function isFullscreen() {
      return document.fullscreenElement !== null;
    }

    function requestFullscreen() {
      fullScreenButton.innerHTML =
        "<img src='/images/map/minzoom.svg' alt='Min zoom'/>";
      if (map.current._container.requestFullscreen) {
        map.current._container.requestFullscreen();
      } else if (map.current._container.mozRequestFullScreen) {
        map.current._container.mozRequestFullScreen();
      } else if (map.current._container.webkitRequestFullscreen) {
        map.current._container.webkitRequestFullscreen();
      }
    }

    function exitFullscreen() {
      fullScreenButton.innerHTML =
        "<img src='/images/map/maxzoom.svg' alt='Max zoom'/>";
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    function getUserLocation() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => resolve(position.coords),
          (error) => reject(error)
        );
      });
    }

    function zoomIn() {
      map.current.zoomIn();
    }

    function zoomOut() {
      map.current.zoomOut();
    }

    // Create custom control elements
    const zoomInButton = document.createElement("button");
    zoomInButton.innerHTML =
      "<img src='/images/map/zoomin.svg' alt='Zoom in'/>";
    zoomInButton.addEventListener("click", zoomIn);

    const zoomOutButton = document.createElement("button");
    zoomOutButton.innerHTML =
      "<img src='/images/map/zoomout.svg' alt='Zoom out'/>";
    zoomOutButton.addEventListener("click", zoomOut);

    const nearButton = document.createElement("button");
    nearButton.innerHTML = "<img src='/images/map/near.svg' alt='Near'/>";
    nearButton.addEventListener("click", async () => {
      try {
        const coords = await getUserLocation();
        map.current.flyTo({
          center: [coords.longitude, coords.latitude],
          zoom: 15,
        });
      } catch (error) {
        console.error("Error getting location:", error);
      }
    });

    fullScreenButton.innerHTML =
      "<img src='/images/map/maxzoom.svg' alt='Max zoom'/>";
    fullScreenButton.addEventListener("click", () => {
      if (isFullscreen()) {
        exitFullscreen();
      } else {
        requestFullscreen();
      }
    });

    const toolbar = document.createElement("div");
    toolbar.className = "map-toolbar";

    toolbar.appendChild(nearButton);
    toolbar.appendChild(zoomInButton);
    toolbar.appendChild(zoomOutButton);
    toolbar.appendChild(fullScreenButton);

    // Add the toolbar to the map container
    mapContainer.current.appendChild(toolbar);

    map.current.on("click", () =>
      setPopover((popover) => ({ ...popover, isVisible: false }))
    );

    map.current.on("load", () => {
      addLocationMarkers();
    });
  }, []);

  const addLocationMarkers = async () => {
    try {
      const data = await getLocationsSummary(token);

      data.features.forEach((feature) => {
        const { coordinates } = feature.geometry;
        const { title, highRisk, mediumRisk, value, badge, shipments } =
          feature.properties;

        const el = document.createElement("div");
        el.setAttribute("class", "marker");

        if (badge > 0) {
          el.innerHTML = `<div class="custom-marker"><div class="data"><div class="badge"><div class="alerts"><img src="/images/alerts.png" alt="Alerts" width="24px" /></div><div class="badge-label">${badge}</div></div><div class="label">${value}</div></div></div>`;
        } else {
          el.innerHTML = `<div class="custom-marker"><div class="data"><div class="label">${value}</div></div></div>`;
        }

        el.addEventListener("click", (e) => {
          e.stopPropagation();
          setPopover({
            isVisible: true,
            content: { badge, highRisk, mediumRisk, title, shipments },
            position: { x: e.clientX, y: e.clientY + 250 },
          });
        });

        new mapboxgl.Marker(el).setLngLat(coordinates).addTo(map.current);
      });
    } catch (error) {
      console.error("Error loading markers:", error);
    }
  };

  return (
    <div className="mt-4 rounded-lg">
      <div ref={mapContainer} style={{ height: "380px" }} data-testid="map" />
      <Popover
        isVisible={popover.isVisible}
        content={popover.content}
        position={popover.position}
      />
    </div>
  );
}

export default MapView;
