import { useCallback, useContext, useState } from "react";
import {
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Flex,
	IconButton,
} from "@chakra-ui/react";
import { Badge, Button } from "../../componentLibrary";
import { TableLoading, UserContext } from "..";
import { downloadFileFromBlob, renderDate } from "../../utilities";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

function ParcelCarrierInvoice({ data, isOpen = true, isLoading }) {
	const { token, user } = useContext(UserContext);

	const [isInvoiceDownloading, setIsInvoiceDownloading] = useState({});
	const [isProcessedInvoiceDownloading, setIsProcessedInvoiceDownloading] =
		useState({});
	const [invoiceDownloaded, setInvoiceDownloaded] = useState([]);
	const [processedInvoiceDownloaded, setProcessedInvoiceDownloaded] = useState(
		[],
	);

	const downloadInvoice = useCallback(
		async (invoiceNumber, user, fileReference) => {
			if (fileReference !== "original") {
				setIsProcessedInvoiceDownloading((isProcessedInvoiceDownloading) => ({
					...isProcessedInvoiceDownloading,
					[invoiceNumber]: true,
				}));
			} else {
				setIsInvoiceDownloading((isInvoiceDownloading) => ({
					...isInvoiceDownloading,
					[invoiceNumber]: true,
				}));
			}

			await downloadFileFromBlob(
				`${apiUrl}/invoice-download?invoiceNumber=${invoiceNumber}&participantType=CARRIER${
					user ? `&user=${user}` : ""
				}${fileReference ? `&fileReference=${fileReference}` : ""}`,
				token,
				`Orchestro_${
					fileReference ? "original" : "processed"
				}_carrier_invoice_${invoiceNumber}`,
			);

			if (fileReference !== "original") {
				setIsProcessedInvoiceDownloading((isProcessedInvoiceDownloading) => ({
					...isProcessedInvoiceDownloading,
					[invoiceNumber]: false,
				}));
			} else {
				setIsInvoiceDownloading((isInvoiceDownloading) => ({
					...isInvoiceDownloading,
					[invoiceNumber]: false,
				}));
			}
		},
		[apiUrl, token],
	);

	return (
		<TableContainer className="w-full mb-10">
			<Table>
				<Thead>
					<Tr>
						<Th></Th>
						<Th>Invoice No.</Th>
						<Th>Date</Th>
						<Th>Amount</Th>
						<Th>Base Rate</Th>
						<Th>Fuel</Th>
						<Th>Correction</Th>
						<Th>Add Sign</Th>
						<Th>Handling</Th>
						<Th>Total Rate</Th>
						<Th>Status</Th>
						<Th textAlign="center">Carrier Invoice</Th>
						<Th textAlign="center">Processed</Th>
					</Tr>
				</Thead>
				{isOpen && (
					<Tbody>
						{isLoading && <TableLoading />}
						{((!isLoading && data && data.length === 0) || !data) && (
							<Tr>
								<Td colSpan="100">No data available</Td>
							</Tr>
						)}
						{data &&
							data.map((invoice, index) => (
								<Tr key={index}>
									<Td>
										<strong>{invoice?.rowTitle ?? "-"}</strong>
									</Td>
									<Td>{invoice?.invoiceNumber ?? "-"}</Td>
									<Td>
										{invoice?.invoiceDate ? (
											<time dateTime={invoice.invoiceDate}>
												{renderDate(invoice.invoiceDate, "MM/dd/yyyy")}
											</time>
										) : (
											"-"
										)}
									</Td>
									<Td>
										{invoice?.totalAmount
											? `$${Number(invoice.totalAmount).toFixed(2)}`
											: "-"}
									</Td>
									<Td>
										{invoice?.baseRate
											? `$${Number(invoice.baseRate).toFixed(2)}`
											: "-"}
									</Td>
									<Td>
										{invoice?.fuel
											? `$${Number(invoice.fuel).toFixed(2)}`
											: "$0.0"}
									</Td>
									<Td>
										{invoice?.totalSurcharge
											? `$${invoice.totalSurcharge}`
											: "-"}
									</Td>
									<Td>
										{invoice?.totalSurcharge
											? `$${invoice.totalSurcharge}`
											: "-"}
									</Td>
									<Td>
										{invoice?.totalSurcharge
											? `$${invoice.totalSurcharge}`
											: "-"}
									</Td>
									<Td>
										{invoice?.totalRate
											? `$${Number(invoice.totalRate).toFixed(2)}`
											: "-"}
									</Td>
									<Td>
										{invoice?.verificationStatus === "NOT_VERIFIED" ? (
											<Badge context="error" text="Not Verified" />
										) : invoice?.verificationStatus === "VERIFIED" ? (
											<Badge context="success" text="Verified" />
										) : (
											"-"
										)}
									</Td>
									<Td textAlign="center">
										{invoice?.originalFileUploadId && invoice?.invoiceNumber ? (
											isInvoiceDownloading[invoice.invoiceNumber] ? (
												<Spinner />
											) : invoiceDownloaded.includes(invoice.invoiceNumber) ? (
												<Flex justifyContent="center" alignItems="center">
													<Button
														context="text"
														className="text-[14px] items-center py-2 w-[130px]"
														onClick={() => {
															downloadInvoice(
																invoice.invoiceNumber,
																user.isPlatformAdmin ? "internal" : "",
																"original",
															);
														}}
													>
														Re-download
													</Button>
												</Flex>
											) : (
												<IconButton
													aria-label="Download"
													bg="transparent"
													_hover={{
														bg: "rgb(255 179 35)",
													}}
													_active={{ bg: "transparent" }}
													onClick={() => {
														downloadInvoice(
															invoice.invoiceNumber,
															user.isPlatformAdmin ? "internal" : "",
															"original",
														);
														setInvoiceDownloaded([
															...invoiceDownloaded,
															invoice.invoiceNumber,
														]);
													}}
													icon={
														<ArrowDownTrayIcon className="size-6 text-[#2294FF]" />
													}
												/>
											)
										) : (
											<p className="text-sm py-[10px] text-[#2489ff] cursor-default">
												{invoice?.rowTitle.includes("Total")
													? "-"
													: "No Invoice File"}
											</p>
										)}
									</Td>
									<Td textAlign="center" className="h-[60px]">
										{((user.isPlatformAdmin &&
											invoice?.processedFileUploadIdInternal) ||
											(!user.isPlatformAdmin &&
												invoice?.processedFileUploadIdExternal)) &&
										invoice?.invoiceNumber ? (
											isProcessedInvoiceDownloading[invoice.invoiceNumber] ? (
												<Spinner />
											) : processedInvoiceDownloaded.includes(
													invoice.invoiceNumber,
											  ) ? (
												<Flex justifyContent="center" alignItems="center">
													<Button
														context="text"
														className="text-[14px] items-center py-2 w-[130px]"
														onClick={() => {
															downloadInvoice(
																invoice.invoiceNumber,
																user.isPlatformAdmin ? "internal" : "",
															);
														}}
													>
														Re-download
													</Button>
												</Flex>
											) : (
												<IconButton
													aria-label="Download"
													bg="transparent"
													_hover={{
														bg: "rgb(255 179 35)",
													}}
													_active={{ bg: "transparent" }}
													onClick={() => {
														downloadInvoice(
															invoice.invoiceNumber,
															user.isPlatformAdmin ? "internal" : "",
														);
														setProcessedInvoiceDownloaded([
															...invoiceDownloaded,
															invoice.invoiceNumber,
														]);
													}}
													icon={
														<ArrowDownTrayIcon className="size-6 text-[#2294FF]" />
													}
												/>
											)
										) : (
											<p className="text-sm py-[10px] text-[#2489ff] cursor-default">
												{invoice?.rowTitle.includes("Total")
													? "-"
													: "No Invoice File"}
											</p>
										)}
									</Td>
								</Tr>
							))}
					</Tbody>
				)}
			</Table>
		</TableContainer>
	);
}

export default ParcelCarrierInvoice;
