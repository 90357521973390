import React, { useContext, useEffect, useState } from "react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { Alert, Card } from "@chakra-ui/react";

import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { AccountTabs, ImportFile, Page, UserContext } from "../interfaces";
import { downloadFileFromUrl } from "../utilities";
import { Button, Divider } from "../componentLibrary";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

function ZipCoverage() {
	const [isLoading, setIsLoading] = useState({});
	const [error, setError] = useState({});
	const { isNonProdEnvironment, token, user, viewingAs } =
		useContext(UserContext);

	const handleFetchZipCodes = async (permission) => {
		if (!permission) return;
		try {
			const nextLoading = { ...isLoading, [permission]: true };
			setIsLoading(nextLoading);
			const nextError = { ...error, [permission]: "" };
			setError(nextError);
			const response = await fetch(apiUrl + "/get-node-coverage", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify({ permission: permission }),
			});

			if (response.ok) {
				const zipCodesUrl = await response.text();
				downloadFileFromUrl(zipCodesUrl);
			} else {
				console.error("Error fetching zone coverage:", response.statusText);
				const nextError = {
					...error,
					[permission]: "Error fetching zone coverage",
				};
				setError(nextError);
			}
		} catch (error) {
			console.error("Error fetching zone coverage:", error.message);
			const nextError = {
				...error,
				[permission]: "Error fetching zone coverage",
			};
			setError(nextError);
		} finally {
			const nextLoading = { [permission]: false, ...isLoading[permission] };
			setIsLoading(nextLoading);
		}
	};

	useEffect(() => {
		setIsLoading({});
		setError({});
	}, [viewingAs]);

	return (
		<>
			<AccountTabs />
			<Page>
				<h2 className="text-xl">Orchestro Network</h2>
				<Card
					mt="20px"
					border="1px"
					borderRadius="20px"
					borderWidth="1px"
					borderColor="rgba(133, 134, 152, 0.2)"
					p={8}
				>
					<h3 className="text-xl font-bold mb-2">Zip Coverage</h3>
					<p className="mb-6">
						Download the .xls file to view the Orchestro network zip coverage
						information.
					</p>
					<div className="flex items-center gap-3">
						<Button
							isLoading={isLoading.ORCHESTRO}
							onClick={() => handleFetchZipCodes("ORCHESTRO")}
							prefixIcon={<ArrowDownTrayIcon />}
							disabled={viewingAs.participantCode === "ORCHESTRO"}
						>
							Download
						</Button>
						{/* {user.isPlatformAdmin &&
              isNonProdEnvironment && <ImportFile />} */}
					</div>
					{error.ORCHESTRO && (
						<Alert status="error" mt={6}>
							<WarningTwoIcon />
							<p>{error.ORCHESTRO}</p>
						</Alert>
					)}
				</Card>

				<Divider
					orientation="horizontal"
					className="!border-[#85869833] mt-4 mb-4"
				/>

				{viewingAs?.participantCode && (
					<>
						<h2 className="text-xl">Carrier</h2>
						<Card
							mt="20px"
							border="1px"
							borderRadius="20px"
							borderWidth="1px"
							borderColor="rgba(133, 134, 152, 0.2)"
							p={8}
						>
							<h3 className="text-xl font-bold mb-2">
								Zip Coverage: {viewingAs?.name}
							</h3>
							<p className="mb-6">
								Upload .xls Download the .xls file to view the Carrier zip
								coverage information.
							</p>
							<div className="flex items-center gap-3">
								<Button
									isLoading={isLoading[viewingAs.participantCode]}
									onClick={() => handleFetchZipCodes(viewingAs.participantCode)}
									prefixIcon={<ArrowDownTrayIcon />}
								>
									Download
								</Button>
								{user.isPlatformAdmin && isNonProdEnvironment && <ImportFile />}
							</div>

							{error[viewingAs.participantCode] && (
								<Alert status="error" mt={6}>
									<WarningTwoIcon />
									<p>{error[viewingAs.participantCode]}</p>
								</Alert>
							)}
						</Card>
					</>
				)}
			</Page>
		</>
	);
}

export default ZipCoverage;
