import React, { useState, useEffect } from "react";

const ArcSection = ({ data, status, isPublicTracking, trackAgain, reset }) => {
	const labels = [
		{
			text: "Label Created",
			status: "Label Created",
			rotation: -32,
		},
		{ text: "Picked Up", status: "Picked Up", rotation: -77 },
		{ text: "In Transit", status: "In Transit", rotation: -120 },
		{ text: "Out for Delivery", status: "Out for Delivery", rotation: -163 },
		{ text: "Delivered", status: "Delivered", rotation: -205 },
	];

	const [packageStatus, setPackageStatus] = useState(null);
	const [activeSection, setActiveSection] = useState(null);
	const [rotation, setRotation] = useState(-120);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		if (reset || trackAgain) {
			setActiveSection(null);
			setRotation(-120);
		}
	}, [reset, trackAgain]);

	useEffect(() => {
		const latest = 0;
		if (data && data[latest] && data[latest].uiStatus) {
			setPackageStatus(data[latest].uiStatus);
			let currentSection = labels.find((label) => {
				if (packageStatus === "In Transit" || packageStatus === "Possessed") {
					return label.status === "In Transit";
				}
				if (
					packageStatus === "Out for Delivery" ||
					packageStatus === "Out for delivery" ||
					packageStatus === "On Route"
				) {
					return label.status === "Out for Delivery";
				}
				return label.status === packageStatus;
			});
			if (currentSection) {
				setActiveSection(currentSection);
				setRotation(currentSection.rotation);
			} else {
				setActiveSection(null);
				setRotation(-120);
			}
		} else {
			setPackageStatus(null);
		}
	}, [data, packageStatus]);

	useEffect(() => {
		if (!isPublicTracking) {
			setIsReady(true);
		}
	}, [rotation, isPublicTracking]);

	const radius = 49;
	const outerRadius = 44;
	const innerRadius = 40;
	const selectedTextOuterRadius = 43;
	const selectedOuterRadius = 50; // Larger outer radius for selected segment
	const selectedInnerRadius = 38;
	const totalArcAngle = 180;
	const arcLength = totalArcAngle / labels.length - 2;
	const gap = 8 / ((Math.PI * radius) / 180);

	// const activeColor = "#2294FF";
	// const activeTextColor = "#74BCFF";
	// const activeOpacity = 0.1;
	// const color = status ? "#858698" : "#7B7778";
	// const activeStroke = "#2294FF";
	// const inactiveStroke = status ? "#707173" : "#7B7778";
	const colorOpacity = 0.8;

	const activeColor = "#FFB323";
	const activeStroke = "#FFB323";
	const color = status ? "#000000" : "#000000";
	const inactiveStroke = status ? "#7B7778" : "#7B7778";

	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				visibility: !isPublicTracking && !isReady ? "hidden" : "visible",
			}}
		>
			<svg
				viewBox="0 0 100 100"
				//transform={`rotate(${rotation}, 0, 0)`}
				style={{
					transition:
						isPublicTracking || trackAgain
							? "transform 2s ease-in-out"
							: "none",
					transform: `rotate(${rotation}deg)`,
				}}
				xmlns="http://www.w3.org/2000/svg"
			>
				{labels.map((label, index) => {
					const isSelected = activeSection && label.text === activeSection.text;
					const angle = -totalArcAngle / 2 + index * (arcLength + gap);

					const pathOuterRadius = isSelected ? selectedOuterRadius : radius;
					const pathInnerRadius = isSelected
						? selectedInnerRadius
						: innerRadius;
					const pathTextRadius = isSelected
						? selectedTextOuterRadius
						: outerRadius;

					return (
						<g
							key={index}
							transform={`rotate(${angle + arcLength / 2}, 50, 50)`}
						>
							<path
								d={`
                M ${50 + pathOuterRadius}, 50
                A ${pathOuterRadius},${pathOuterRadius} 0 0,1 ${
									50 + pathOuterRadius * Math.cos((arcLength * Math.PI) / 180)
								}, ${
									50 + pathOuterRadius * Math.sin((arcLength * Math.PI) / 180)
								}
                L ${
									50 + pathInnerRadius * Math.cos((arcLength * Math.PI) / 180)
								}, ${
									50 + pathInnerRadius * Math.sin((arcLength * Math.PI) / 180)
								}
                A ${pathInnerRadius},${pathInnerRadius} 0 0,0 ${
									50 + pathInnerRadius
								}, 50
                Z
              `}
								fill={isSelected ? activeColor : color}
								stroke={isSelected ? activeStroke : inactiveStroke}
								strokeWidth={isSelected ? 2 : 1}
								fillOpacity={isSelected ? 1 : colorOpacity}
								vectorEffect="non-scaling-stroke"
							/>

							<path
								id={`textPath${index}`}
								fill="none"
								stroke="none"
								d={`
                M ${50 + pathTextRadius}, 50
                A ${pathTextRadius},${pathTextRadius} 0 0,1 ${
									50 + pathTextRadius * Math.cos((arcLength * Math.PI) / 180)
								}, ${
									50 + pathTextRadius * Math.sin((arcLength * Math.PI) / 180)
								}
              `}
							/>

							{/* Text curved along the outer edge */}
							<text
								fontFamily="Montserrat"
								fontSize={isSelected ? "2.9" : "2.6"}
								fontWeight={isSelected ? 600 : 400}
								fill="white"
								//fill={isSelected ? activeTextColor : "white"}
							>
								<textPath
									href={`#textPath${index}`}
									startOffset="50%"
									textAnchor="middle"
								>
									{label.text}
								</textPath>
							</text>
						</g>
					);
				})}
			</svg>
		</div>
	);
};

export default ArcSection;
