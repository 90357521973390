import React, { useState, useContext, useEffect } from "react";
import {
	Alert,
	Card,
	FormControl,
	Radio,
	RadioGroup,
	useToast,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { AccountTabs, Page, UserContext } from "../interfaces";
import "../interfaces/AccountTabs/facilities/Facilities.css";
import { downloadFileFromUrl } from "../utilities";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Button, Divider, Modal } from "../componentLibrary";
import Mapbox from "../interfaces/AccountTabs/facilities/Map";
import { CloseIcon } from "@chakra-ui/icons";
import ErrorIcon from "../interfaces/CustomIcons/Error";
import { uploadSortCodeFacilityDetails } from "../services";
import { ImportDownload } from "../interfaces/AccountTabs/facilities/ImportDownload";
import SuccessIcon from "../interfaces/CustomIcons/Success";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = `${coreServiceUrl}${coreServicePath}`;

function Facilities() {
	const [isShipperLoading, setIsShipperLoading] = useState(false);
	const [isNodeAvailabilityLoading, setIsNodeAvailabilityLoading] =
		useState(false);
	const [isCarrierLoading, setIsCarrierLoading] = useState(false);
	const [isCarrierNodeLoading, setIsCarrierNodeLoading] = useState(false);
	const [shipperError, setShipperError] = useState("");
	const [nodeAvailabilityError, setNodeAvailabilityError] = useState("");
	const [carrierError, setCarrierError] = useState("");
	const [carrierNodeError, setCarrierNodeError] = useState("");
	const { isNonProdEnvironment, token, user, viewingAs } =
		useContext(UserContext);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [error, setError] = useState("");
	const [zipCodes, setZipCodes] = useState([]);
	const [parsedData, setParsedData] = useState(null);
	const [errorDescription, setErrorDescription] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [toggleLabel, setToggleLabel] = useState(false);
	const [successView, setSuccessView] = useState(false);
	const [savedStatus, setSavedStatus] = useState(false);
	const [selectedOption, setSelectedOption] = useState("CARRIER");
	const toast = useToast();

	const options = [
		{ value: "CARRIER", name: "Carrier Facilities" },
		{ value: "SHIPPER", name: "Shipper Facilities" },
	];

	const filterDataByZipCode = (data) => {
		const zipCodeChecks = data.map((item) => {
			const isValid = item?.coordinates.length !== 0;
			return { item, isValid };
		});
		const filteredData = zipCodeChecks
			.filter((result) => result.isValid)
			.map((result) => result.item);
		return filteredData;
	};

	const sortCodeValidation = async (data) => {
		const ImproperTimeFormat = data.filter((item) =>
			item?.Start
				? item?.Start?.length === 5 && item?.Stop?.length === 5
				: item[7]?.length === 5 && item[8]?.length === 5,
		);
		const StopTimeBeforeStartTime = data.filter((item) =>
			item?.Start
				? parseInt(item?.Stop) - parseInt(item?.Start) >= 0
				: parseInt(item[8]) - parseInt(item[7]) >= 0,
		);
		const MissingRequiredFields = data.filter((item) =>
			item?.Start
				? item?.ZIP.length !== 0
				: item[1]?.length !== 0 && item[6]?.length !== 0,
		);
		const ValidZipCodeData = filterDataByZipCode(data);
		setToggleLabel(false);

		if (ValidZipCodeData.length !== data.length) {
			setError("This is an error message");
			setErrorDescription(
				"There are some zip codes that does not exist. Please check for errors and update.",
			);
			setIsValid(false);
			setSuccessView(false);
			setSavedStatus(false);
		} else if (ImproperTimeFormat.length !== data.length) {
			setError("This is an error message");
			setErrorDescription(
				"There are some start and stop time that are incorrect. Please check for errors and update.",
			);
			setIsValid(false);
			setSuccessView(false);
			setSavedStatus(false);
		} else if (StopTimeBeforeStartTime.length !== data.length) {
			setError("This is an error message");
			setErrorDescription(
				"There are some start and stop time that are incorrect. Please check for errors and update.",
			);
			setIsValid(false);
			setSuccessView(false);
			setSavedStatus(false);
		} else if (MissingRequiredFields.length !== data.length) {
			setError("This is an error message");
			setErrorDescription("Missing Required Fields. Please check and update");
			setIsValid(false);
			setSuccessView(false);
			setSavedStatus(false);
		} else {
			setSavedStatus(true);
		}
	};

	useEffect(() => {
		if (zipCodes?.length !== 0) {
			sortCodeValidation(zipCodes);
			if (!error) {
				setSuccessView(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error, zipCodes]);

	const bodyData = async () => {
		setLoading(true);
		const data = zipCodes.map((e) => {
			return {
				facilityName: e?.Name,
				code: e?.Code,
				zipCode: e?.ZIP,
				address: e["Street Address"],
				avgCapacity: e?.AvgVol,
				maxCapacity: e?.MaxVol,
				availableCapacity: null,
				startTime: e?.Start,
				endTime: e?.Stop,
				timeZone: e?.Timezone,
				city: e?.City,
				state: e?.State,
			};
		});
		const response = await uploadSortCodeFacilityDetails(
			data,
			viewingAs.participantCode,
			token,
		);
		if (response.ok) {
			toast({ title: "File submitted successfully!", status: "success" });
		} else {
			const res = await response?.json();
			toast({ title: res?.errors[0].message, status: "error" });
		}
		setIsValid(false);
		setSuccessView(false);
		setLoading(false);
		setSavedStatus(false);
	};

	const handleSave = () => {
		sortCodeValidation(zipCodes);
		setToggleLabel(true);
		if (savedStatus) {
			setError("");
			setSuccessView(true);
			setIsValid(true);
		}
	};

	const handleDismiss = () => {
		setError("");
		setZipCodes("");
	};

	const fetchData = async (endpoint, participantType, setLoading, setError) => {
		if (!participantType || !viewingAs.participantCode) return;

		try {
			setLoading(true);
			setError("");

			const response = await fetch(`${apiUrl}${endpoint}`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: token,
				},
				body: JSON.stringify({
					carrierCode: viewingAs.participantCode,
					participantType,
				}),
			});

			if (response.ok) {
				const arrayBuffer = await response.arrayBuffer();
				const blob = new Blob([arrayBuffer], {
					type: "application/vnd.ms-excel",
				});
				const sortCodeUrl = window.URL.createObjectURL(blob);
				downloadFileFromUrl(sortCodeUrl, "Orchestro_Facilities");
			} else {
				console.error(`Error fetching ${endpoint}:`, response.statusText);
				setError(`Error fetching ${endpoint}`);
			}
		} catch (error) {
			console.error(`Error fetching ${endpoint}:`, error);
			setError(`Error fetching ${endpoint}`);
		} finally {
			setLoading(false);
		}
	};

	const generateSortCodes = (participantType, setLoading, setError) =>
		fetchData("/get-sort-codes", participantType, setLoading, setError);

	const generateNodeAvailability = (participantType, setLoading, setError) =>
		fetchData("/get-node-availability", participantType, setLoading, setError);

	const renderCard = (
		title,
		description,
		participantType,
		isLoading,
		setLoading,
		fetchFunction,
		error,
		setError,
		showImportFile,
		endpoint,
		carrierCode,
	) => {
		return (
			<Card
				mt="20px"
				border="1px"
				borderRadius="20px"
				borderWidth="1px"
				borderColor="rgba(133, 134, 152, 0.2)"
				p={8}
			>
				<h3 className="text-xl font-bold mb-2">{title}</h3>
				<p className="mb-6">{description}</p>
				<FormControl>
					<RadioGroup
						fontWeight={500}
						className="flex flex-col gap-5 mb-5"
						value={selectedOption}
						onChange={(value) => setSelectedOption(value)}
					>
						{!viewingAs.isCarrier &&
							!viewingAs.isShipper &&
							options.map(({ value, name }, i) => (
								<Radio
									key={i}
									value={value}
									className={`!w-[24px] !h-[24px] !border-[#E0E1EC]`}
									_checked={{
										backgroundColor: "unset",
										borderColor: "#3BA0E6 !important",
										_before: {
											background: "linear-gradient(to right, #3BA0E6, #3B6EF3)",
											content: "''",
											height: "60%",
											width: "60%",
											borderRadius: "50%",
										},
									}}
								>
									{name}
								</Radio>
							))}
					</RadioGroup>
				</FormControl>
				<div className="flex items-center gap-3">
					<Button
						isLoading={isLoading}
						onClick={() => fetchFunction(participantType, setLoading, setError)}
						prefixIcon={<ArrowDownTrayIcon />}
					>
						Download
					</Button>
					{showImportFile && user.isPlatformAdmin && isNonProdEnvironment && (
						<ImportDownload
							endpoint={endpoint}
							selectedOption={participantType}
							carrierCode={carrierCode}
							onUpdateData={setZipCodes}
							setParsedData={setParsedData}
							// template={()=>fetchFunction(participantType, setLoading, setError)}
						/>
					)}
				</div>
				{error && (
					<Alert status="error" mt={6}>
						<WarningTwoIcon />
						<p>{error}</p>
					</Alert>
				)}
			</Card>
		);
	};

	return (
		<>
			<AccountTabs />
			<Page>
				<h2 className="text-xl">
					{viewingAs.isCarrier
						? "Carrier"
						: viewingAs.isShipper
						? "Shipper"
						: selectedOption}
				</h2>
				{renderCard(
					"Facilities Sort Codes",
					"Manage and organize information about various logistics facilities within your network.",
					"CARRIER",
					isCarrierLoading,
					setIsCarrierLoading,
					generateSortCodes,
					carrierError,
					setCarrierError,
					true,
					"import-sort-codes",
					viewingAs.participantCode,
				)}
				{error && (
					<div className="flex py-4 px-8 rounded-lg bg-[#FF263F1A] justify-between mt-4">
						<div className="flex items-center justify-start gap-3">
							<div>
								<ErrorIcon />
							</div>
							<div className="flex flex-col">
								<div className="text-white font-bold">{error}</div>
								<div className="text-[#C8C5C5] text-sm">{errorDescription}</div>
							</div>
						</div>
						<div className="flex gap-4 items-center">
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => {
									setShowErrorModal(true);
								}}
							>
								View
							</Button>
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => handleDismiss()}
							>
								Dismiss
							</Button>
						</div>
					</div>
				)}
				{!error && successView && (
					<div className="flex py-4 px-8 rounded-lg bg-[#192D2B] justify-between mt-4">
						<div className="flex items-center justify-start gap-3">
							<div>
								<SuccessIcon />
							</div>
							<div className="flex flex-col">
								<div className="text-white font-bold">
									File uploaded successfully
								</div>
								<div className="text-[#C8C5C5] text-sm">
									File uploaded successfully! Please review{" "}
									{isValid ? "" : "and save"} your file.
								</div>
							</div>
						</div>
						<div className="flex gap-4 items-center">
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => {
									setShowErrorModal(true);
								}}
							>
								View
							</Button>
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => {
									setSuccessView(false);
								}}
							>
								Dismiss
							</Button>
						</div>
					</div>
				)}
				<Modal
					open={showErrorModal}
					onClose={setShowErrorModal}
					className={{
						content: "!min-w-[90vw] !min-h-[90vh] facility-wrapper",
						title: "!hidden",
						closeButton: "!hidden",
						controls: "!hidden",
						body: "min-w-full min-h-full",
					}}
				>
					<div className="flex flex-col flex-1">
						<div className="flex items-center justify-between">
							<div></div>
							<div className=" text-lg text-white font-bold">Facilities</div>
							<div
								className="text-white font-bold flex items-center justify-center cursor-pointer"
								onClick={() => {
									setShowErrorModal(false);
									setToggleLabel(false);
								}}
							>
								<CloseIcon color="#2294FF" />
							</div>
						</div>
						<div className="facility-map flex flex-col flex-1 mt-5 overflow-auto items-end">
							<Mapbox
								data={zipCodes}
								setZipCodesData={setZipCodes}
								sortCodeValidation={sortCodeValidation}
							/>
						</div>
						<div className="flex justify-end gap-4 my-2">
							<Button
								context="outlined"
								onClick={() => {
									setShowErrorModal(false);
									setToggleLabel(false);
								}}
							>
								Cancel
							</Button>
							<Button onClick={() => handleSave()}>
								{toggleLabel ? "Saved" : "Save"}
							</Button>
						</div>
					</div>
				</Modal>
				<div className="flex justify-end my-2">
					<Button
						context={isValid ? "primary" : "outlinedSecondary"}
						onClick={bodyData}
						isLoading={loading}
						disabled={!isValid}
					>
						Submit
					</Button>
				</div>
			</Page>
		</>
	);
}

export default Facilities;
