import React, { useContext, useEffect, useState } from "react";
import { Page, Select, UserContext } from "../interfaces";
import { IntegrationTabs } from "../interfaces/IntegrationTabs";
import {
  Box,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Button,
  Input,
  Flex,
  Card,
  InputGroup,
  InputRightElement,
  IconButton,
  CardBody,
  Grid,
  Spinner,
} from "@chakra-ui/react";
import { CopyIcon, EditIcon } from "@chakra-ui/icons";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { Button as Button2 } from "../componentLibrary";
import { fetchPreferenceValues, getPreferences } from "../services";

const LastMileCarrier = () => {
  //   const [preferenceData, setPreferenceData] = useState(null);
  const { token, viewingAsByCode } = useContext(UserContext);
  const [data, setData] = useState({});
  const [values, setValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [preferenceValues, setPreferenceValues] = useState([]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (!viewingAsByCode) return;

        await getPreferencesApi();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [viewingAsByCode]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchPreferenceValues(token);
        setPreferenceValues(response);
      } catch (error) {
        console.error("Error fetching preference values:", error);
      }
    })();
  }, [token]);

  const getPreferencesApi = async () => {
    let responseData = await getPreferences(viewingAsByCode, token);
    setData(responseData);
    setValues({
      ...responseData,
      preferenceLabel: "Standard Orchestro Label",
    });
  };

  const findOptionFromPreference = (key) => {
    if (preferenceValues?.length) {
      return preferenceValues
        ?.find((value) => value.name === key)
        ?.value.map((val) => ({ label: val, value: val }));
    }
  };

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={"10em"}>
          <Spinner />
        </Box>
      ) : !data ? (
        <Text>No preferences information</Text>
      ) : (
        <div>
          <IntegrationTabs />
          <Page>
            <div className="flex justify-end gap-4 mt-3 mb-2">
              <Button2
                context="outlined"
                prefixIcon={<EditIcon className="!w-[18px] !h-[18px]" />}
                className="text-sm font-medium"
              >
                Edit
              </Button2>

              <Button2 context="outlined" prefixIcon={<ArrowDownTrayIcon />}>
                Download
              </Button2>
            </div>
            <Card
              mb={7}
              borderRadius="20px"
              borderWidth="1px"
              borderColor="rgba(133, 134, 152, 0.2)"
              p={8}
            >
              <Text className="text-xl mb-8 mt-4 font-bold">
                Integration Preferences
              </Text>
              <Box className="w-1/4">
                <Box className="mb-4 grid grid-cols-2">
                  <Text className="mb-2">Manifest</Text>
                  <RadioGroup defaultValue="Webhook">
                    <Stack direction="row" spacing={5}>
                      <Radio value="STPF" colorScheme="blue">
                        STPF
                      </Radio>
                      <Radio value="Webhook" colorScheme="blue">
                        Webhook
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
                <Box className="mb-4 grid grid-cols-2">
                  <Text className="mb-2">Tracking</Text>
                  <RadioGroup defaultValue="Webhook">
                    <Stack direction="row" spacing={5}>
                      <Radio value="STPF" colorScheme="blue">
                        STPF
                      </Radio>
                      <Radio value="Webhook" colorScheme="blue">
                        Webhook
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
                <Box className="mb-4 grid grid-cols-2">
                  <Text className="mb-2">Error</Text>
                  <RadioGroup defaultValue="Webhook">
                    <Stack direction="row" spacing={5}>
                      <Radio value="STPF" colorScheme="blue">
                        STPF
                      </Radio>
                      <Radio value="Webhook" colorScheme="blue">
                        Webhook
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
                <Box className="mb-4 grid grid-cols-2">
                  <Text className="mb-2">Invoice</Text>
                  <RadioGroup defaultValue="Email">
                    <Stack direction="row" spacing={5}>
                      <Radio value="STPF" colorScheme="blue">
                        STPF
                      </Radio>
                      <Radio value="Email" colorScheme="blue">
                        Email
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </Box>
              </Box>
              <Flex justify="flex-end" p={4}>
                <Button size="md" className="chakra-primary-button">
                  Request Token
                </Button>
              </Flex>
            </Card>
            <Grid templateColumns="repeat(2, 1fr)" gap={7}>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    API Key
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    Key
                  </Text>
                  <InputGroup>
                    <Input
                      value={"apiKey"}
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>
                  <Flex justify="flex-end" mt={4}>
                    <Button className="chakra-primary-button">
                      Re-generate Token
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Manifest
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    End point
                  </Text>
                  <InputGroup>
                    <Input
                      value={"endpoint"}
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() => handleCopy("endpoint")}
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </CardBody>
              </Card>
            </Grid>
            <Grid templateColumns="repeat(2, 1fr)" gap={7}>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
                width={"100%"}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Tracking
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    SFTP Login
                  </Text>
                  <InputGroup>
                    <Input
                      value="bt@3.85.244.70"
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() => handleCopy("bt@3.85.244.70")}
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color="white"
                    mt={4}
                    mb={2}
                  >
                    SFTP Password
                  </Text>
                  <InputGroup>
                    <Input
                      value="3aa2932f-0f0d-4a49-bfb7-3252ae08ac44"
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() =>
                          handleCopy("3aa2932f-0f0d-4a49-bfb7-3252ae08ac44")
                        }
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </CardBody>
              </Card>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Invoice
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    SFTP Login
                  </Text>
                  <InputGroup>
                    <Input
                      value="bt@3.85.244.70"
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() => handleCopy("bt@3.85.244.70")}
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>

                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color="white"
                    mt={4}
                    mb={2}
                  >
                    SFTP Password
                  </Text>
                  <InputGroup>
                    <Input
                      value="3aa2932f-0f0d-4a49-bfb7-3252ae08ac44"
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() =>
                          handleCopy("3aa2932f-0f0d-4a49-bfb7-3252ae08ac44")
                        }
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </CardBody>
              </Card>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Error
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    Email
                  </Text>
                  <InputGroup>
                    <Input
                      value={"xyz@company.com"}
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() => handleCopy("endpoint")}
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </CardBody>
              </Card>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Outbound Manifest
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    Transmit Method
                  </Text>
                  <Select
                    isDisabled={true}
                    value={{
                      label: values.manifestTransmitMethod,
                      value: values.manifestTransmitMethod,
                    }}
                    options={findOptionFromPreference(
                      "MANIFEST_TRANSMIT_METHOD"
                    )}
                  />
                </CardBody>
              </Card>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Outbound Manifest
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    Trigger Schedule Time
                  </Text>
                  <InputGroup>
                    <Input
                      value={values.triggerScheduleTime ?? ""}
                      readOnly
                      sx={{
                        height: "52px !important",
                        borderRadius: "4px !important",
                        backgroundColor: "transparent !important",
                        border: "1px solid #85869833 !important",
                        opacity: "1",
                      }}
                    />
                    <InputRightElement width="3rem" mt={2}>
                      <IconButton
                        icon={<CopyIcon />}
                        onClick={() => handleCopy("endpoint")}
                        bg="gray-600"
                        color="white"
                        _hover={{ bg: "gray-500" }}
                        _active={{ bg: "gray-700" }}
                      />
                    </InputRightElement>
                  </InputGroup>
                </CardBody>
              </Card>
              <Card
                mb={7}
                borderRadius="20px"
                borderWidth="1px"
                borderColor="rgba(133, 134, 152, 0.2)"
                p={5}
              >
                <CardBody>
                  <Text fontSize="lg" fontWeight="bold" color="white" mb={4}>
                    Outbound Manifest
                  </Text>

                  <Text fontSize="sm" fontWeight="medium" color="white" mb={2}>
                    Trigger Track Codes
                  </Text>
                  <Select
                    isDisabled={true}
                    value={{
                      label: values.outboundTriggerTrackCodes,
                      value: values.outboundTriggerTrackCodes,
                    }}
                    options={findOptionFromPreference(
                      "OUTBOUND_MANIFEST_TRIGGER_TRACK_CODES"
                    )}
                  />
                </CardBody>
              </Card>
            </Grid>
          </Page>
        </div>
      )}
    </>
  );
};

export default LastMileCarrier;
