import React from "react";
import {
  Card,
  Flex,
  Heading,
  FormControl,
  FormErrorMessage,
  InputGroup,
  FormLabel,
  Input,
} from "@chakra-ui/react";

import { Label } from "../../../componentLibrary";
import { InputField } from "../../InputFields";
import { Select } from "../../ReactSelect";
import { DropdownIndicator } from "./DropdownIndicator";

export const PreferencesForm = ({
  formik,
  isEditMode,
  labelClassName,
  inputClassName,
  handleSelectChanges,
  handleSelectBlur,
  handleFieldFocus,
  handleFieldBlur,
  handleEnterPressed,
  data,
  handleDismissFieldChanges,
  isCarrier,
  isShipper,
  InputCloseIcon,
  focusedField,
  findOptionFromPreference,
}) => {
  const { values, errors, handleChange, touched } = formik;

  return (
    <>
      {/********************* Invoicing *********************/}
      <Card
        mb={7}
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        py={9}
        px={"44px"}
      >
        <Heading size="md" mb={"30px"}>
          Invoicing
        </Heading>

        <Flex flexDirection={"column"} className="gap-11">
          <Flex className="gap-6">
            <FormControl
              isInvalid={
                !!errors?.invoiceFrequency && touched?.invoiceFrequency
              }
            >
              <Label
                htmlFor="invoiceFrequency"
                className={labelClassName(
                  !!errors?.invoiceFrequency && touched?.invoiceFrequency
                )}
              >
                Invoice Frequency
              </Label>
              <Select
                value={{
                  label: values.invoiceFrequency,
                  value: values.invoiceFrequency,
                }}
                onChange={(options) =>
                  handleSelectChanges(options, "invoiceFrequency")
                }
                onBlur={() => handleSelectBlur("invoiceFrequency")}
                name={`invoiceFrequency`}
                id={`invoiceFrequency`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
                options={findOptionFromPreference("INVOICE_FREQUENCY")}
                isDisabled={!isEditMode}
                isError={
                  !!errors?.invoiceFrequency && touched?.invoiceFrequency
                }
                components={{
                  DropdownIndicator: (props) => (
                    <DropdownIndicator
                      {...props}
                      handleDismissFieldChanges={handleDismissFieldChanges}
                    />
                  ),
                }}
              />
              <FormErrorMessage className="!mt-3">
                Invoice frequency is required
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={!!errors?.paymentTerms && touched?.paymentTerms}
            >
              <Label
                htmlFor="paymentTerms"
                className={labelClassName(
                  !!errors?.paymentTerms && touched?.paymentTerms
                )}
              >
                Payment Terms (Days)
              </Label>
              <Select
                value={{
                  label: values.paymentTerms,
                  value: values.paymentTerms,
                }}
                onChange={(options) =>
                  handleSelectChanges(options, "paymentTerms")
                }
                onBlur={() => handleSelectBlur("paymentTerms")}
                name={`paymentTerms`}
                id={`paymentTerms`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
                options={findOptionFromPreference("PAYMENT_TERMS")}
                isDisabled={!isEditMode}
                isError={!!errors?.paymentTerms && touched?.paymentTerms}
                components={{
                  DropdownIndicator: (props) => (
                    <DropdownIndicator
                      {...props}
                      handleDismissFieldChanges={handleDismissFieldChanges}
                    />
                  ),
                }}
              />
              <FormErrorMessage className="!mt-3">
                Payment terms (Days) is required
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <>
            <Flex className="gap-6">
              <FormControl
                isInvalid={
                  !!errors?.paymentPreference && touched?.paymentPreference
                }
              >
                <Label
                  htmlFor="paymentPreference"
                  className={labelClassName(
                    !!errors?.paymentPreference && touched?.paymentPreference
                  )}
                >
                  Payment Preference
                </Label>
                <Select
                  value={{
                    label: values.paymentPreference,
                    value: values.paymentPreference,
                  }}
                  onChange={(options) =>
                    handleSelectChanges(options, "paymentPreference")
                  }
                  onBlur={() => handleSelectBlur("paymentPreference")}
                  name={`paymentPreference`}
                  id={`paymentPreference`}
                  onFocus={handleFieldFocus}
                  onKeyUp={handleEnterPressed}
                  options={findOptionFromPreference("PAYMENT_PREFERENCE")}
                  isDisabled={!isEditMode}
                  isError={
                    !!errors?.paymentPreference && touched?.paymentPreference
                  }
                  components={{
                    DropdownIndicator: (props) => (
                      <DropdownIndicator
                        {...props}
                        handleDismissFieldChanges={handleDismissFieldChanges}
                      />
                    ),
                  }}
                />
                <FormErrorMessage className="!mt-3">
                  Payment preference is required
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors?.quoteValidity && touched?.quoteValidity}
              >
                <Label
                  htmlFor="quoteValidity"
                  className={labelClassName(
                    !!errors?.quoteValidity && touched?.quoteValidity
                  )}
                >
                  Quote Validity Period (Days)
                </Label>
                <InputGroup>
                  <InputField
                    isDisabled={!isEditMode}
                    value={values.quoteValidity ?? ""}
                    className={inputClassName}
                    onChange={handleChange}
                    onBlur={handleFieldBlur}
                    onFocus={handleFieldFocus}
                    onKeyUp={handleEnterPressed}
                    name={`quoteValidity`}
                    id={`quoteValidity`}
                  />
                  {focusedField === "quoteValidity" && (
                    <InputCloseIcon
                      handleDismissFieldChanges={handleDismissFieldChanges}
                      name={`quoteValidity`}
                    />
                  )}
                </InputGroup>
                <FormErrorMessage className="!mt-3">
                  Quote validity period (Days) is required
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex className="gap-6">
              <FormControl
                isInvalid={
                  !!errors?.defaultInvoiceFormat &&
                  touched?.defaultInvoiceFormat
                }
              >
                <Label
                  htmlFor="defaultInvoiceFormat"
                  className={labelClassName(
                    !!errors?.defaultInvoiceFormat &&
                      touched?.defaultInvoiceFormat
                  )}
                >
                  Default Invoice Format
                </Label>
                <Select
                  value={{
                    label: values.defaultInvoiceFormat,
                    value: values.defaultInvoiceFormat,
                  }}
                  onChange={(options) =>
                    handleSelectChanges(options, "defaultInvoiceFormat")
                  }
                  onBlur={() => handleSelectBlur("defaultInvoiceFormat")}
                  name={`defaultInvoiceFormat`}
                  id={`defaultInvoiceFormat`}
                  onFocus={handleFieldFocus}
                  onKeyUp={handleEnterPressed}
                  options={findOptionFromPreference("DEFAULT_INVOICE_FORMAT")}
                  isDisabled={!isEditMode}
                  isError={
                    !!errors?.defaultInvoiceFormat &&
                    touched?.defaultInvoiceFormat
                  }
                  components={{
                    DropdownIndicator: (props) => (
                      <DropdownIndicator
                        {...props}
                        handleDismissFieldChanges={handleDismissFieldChanges}
                      />
                    ),
                  }}
                />
                <FormErrorMessage className="!mt-3">
                  Invice format is required
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors?.toleranceLimit && touched?.toleranceLimit}
              >
                <Label
                  htmlFor="toleranceLimit"
                  className={labelClassName(
                    !!errors?.toleranceLimit && touched?.toleranceLimit
                  )}
                >
                  Tolerance Limit
                </Label>
                <InputGroup>
                  <InputField
                    isDisabled={!isEditMode}
                    value={values.toleranceLimit ?? ""}
                    className={inputClassName}
                    onChange={handleChange}
                    onBlur={handleFieldBlur}
                    onFocus={handleFieldFocus}
                    onKeyUp={handleEnterPressed}
                    name={`toleranceLimit`}
                    id={`toleranceLimit`}
                  />
                  {focusedField === "toleranceLimit" && (
                    <InputCloseIcon
                      handleDismissFieldChanges={handleDismissFieldChanges}
                      name={`toleranceLimit`}
                    />
                  )}
                </InputGroup>
                <FormErrorMessage className="!mt-3">
                  Tolerance limit is required
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex className="gap-6" width={"calc(50% - 0.7rem)"}>
              <FormControl
                isInvalid={
                  !!errors?.settlementPreference &&
                  touched?.settlementPreference
                }
              >
                <Label
                  htmlFor="settlementPreference"
                  className={labelClassName(
                    !!errors?.settlementPreference &&
                      touched?.settlementPreference
                  )}
                >
                  Settlement Preference
                </Label>
                <Select
                  value={{
                    label: values.settlementPreference,
                    value: values.settlementPreference,
                  }}
                  onChange={(options) =>
                    handleSelectChanges(options, "settlementPreference")
                  }
                  onBlur={() => handleSelectBlur("settlementPreference")}
                  name={`settlementPreference`}
                  id={`settlementPreference`}
                  onFocus={handleFieldFocus}
                  onKeyUp={handleEnterPressed}
                  options={findOptionFromPreference("SETTLEMENT_PREFERENCE")}
                  isDisabled={!isEditMode}
                  isError={
                    !!errors?.settlementPreference &&
                    touched?.settlementPreference
                  }
                  components={{
                    DropdownIndicator: (props) => (
                      <DropdownIndicator
                        {...props}
                        handleDismissFieldChanges={handleDismissFieldChanges}
                      />
                    ),
                  }}
                />
                <FormErrorMessage className="!mt-3">
                  Settlement preference is required
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </>
        </Flex>
      </Card>

      {/********************* Integration *********************/}
      {/* <Card
        mb={7}
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        py={9}
        px={"44px"}
      >
        <Heading size="md" mb={"30px"}>
          Integration
        </Heading>

        {/* {isShipper && (
          <>
            <Heading size={"sm"} mb={4}>
              Inbound (To Orchestro)
            </Heading>

            <Flex className="gap-11" flexDirection={"column"} marginBottom={6}>
              <Flex className="gap-11" width={"calc(50% - 1.3rem)"}>
                <FormControl
                  isInvalid={
                    !!errors?.manifestTransmitMethod &&
                    touched?.manifestTransmitMethod
                  }
                >
                  <Label
                    htmlFor="manifestTransmitMethod"
                    className={labelClassName(
                      !!errors?.manifestTransmitMethod &&
                        touched?.manifestTransmitMethod
                    )}
                  >
                    Inbound Manifest Transit Method
                  </Label>
                  <Select
                    value={{
                      label: values.manifestTransmitMethod,
                      value: values.manifestTransmitMethod,
                    }}
                    onChange={(options) =>
                      handleSelectChanges(options, "manifestTransmitMethod")
                    }
                    onBlur={() => handleSelectBlur("manifestTransmitMethod")}
                    name={`manifestTransmitMethod`}
                    id={`manifestTransmitMethod`}
                    onFocus={handleFieldFocus}
                    onKeyUp={handleEnterPressed}
                    options={[
                      {
                        label: data.manifestTransmitMethod,
                        value: data.manifestTransmitMethod,
                      },
                    ]}
                    isDisabled={!isEditMode}
                    customStyles={selectStyles(
                      !!errors?.manifestTransmitMethod &&
                        touched?.manifestTransmitMethod
                    )}
                    components={{
                      DropdownIndicator: (props) => (
                        <DropdownIndicator
                          {...props}
                          handleDismissFieldChanges={handleDismissFieldChanges}
                        />
                      ),
                    }}
                  />
                  <FormErrorMessage className="!mt-3">
                    Inbound manifest transit method is required
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </Flex>
          </>
        )} -- nested comment end

        <>
          <Flex flexDirection={"column"} className="gap-11">
            <Flex className="gap-11">
              <FormControl
                isInvalid={
                  !!errors?.manifestTransmitMethod &&
                  touched?.manifestTransmitMethod
                }
              >
                <Label
                  htmlFor="manifestTransmitMethod"
                  className={labelClassName(
                    !!errors?.manifestTransmitMethod &&
                      touched?.manifestTransmitMethod
                  )}
                >
                  Outbound Manifest Transmit Method
                </Label>
                <Select
                  value={{
                    label: values.manifestTransmitMethod,
                    value: values.manifestTransmitMethod,
                  }}
                  onChange={(options) =>
                    handleSelectChanges(options, "manifestTransmitMethod")
                  }
                  onBlur={() => handleSelectBlur("manifestTransmitMethod")}
                  name={`manifestTransmitMethod`}
                  id={`manifestTransmitMethod`}
                  onFocus={handleFieldFocus}
                  onKeyUp={handleEnterPressed}
                  options={findOptionFromPreference("MANIFEST_TRANSMIT_METHOD")}
                  isDisabled={!isEditMode}
                  customStyles={selectStyles(
                    !!errors?.manifestTransmitMethod &&
                      touched?.manifestTransmitMethod
                  )}
                  components={{
                    DropdownIndicator: (props) => (
                      <DropdownIndicator
                        {...props}
                        handleDismissFieldChanges={handleDismissFieldChanges}
                      />
                    ),
                  }}
                />
                <FormErrorMessage className="!mt-3">
                  Outbound manifest transmit method is required
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  !!errors?.triggerScheduleTime && touched?.triggerScheduleTime
                }
              >
                <Label
                  htmlFor="triggerScheduleTime"
                  className={labelClassName(
                    !!errors?.triggerScheduleTime &&
                      touched?.triggerScheduleTime
                  )}
                >
                  Outbound Manifest Trigger Schedule Time
                </Label>
                <InputGroup>
                  <InputField
                    isDisabled={!isEditMode}
                    value={values.triggerScheduleTime ?? ""}
                    className={inputClassName}
                    onChange={handleChange}
                    onBlur={handleFieldBlur}
                    onFocus={handleFieldFocus}
                    onKeyUp={handleEnterPressed}
                    name={`triggerScheduleTime`}
                    id={`triggerScheduleTime`}
                  />
                  {focusedField === "triggerScheduleTime" && (
                    <InputCloseIcon
                      handleDismissFieldChanges={handleDismissFieldChanges}
                      name={`triggerScheduleTime`}
                    />
                  )}
                </InputGroup>
                <FormErrorMessage className="!mt-3">
                  Outbound manifest trigger schedule time is required
                </FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex className="gap-11" width={"calc(50% - 1.3rem)"}>
              <FormControl
                isInvalid={
                  !!errors?.outboundTriggerTrackCodes &&
                  touched?.outboundTriggerTrackCodes
                }
              >
                <Label
                  htmlFor="outboundTriggerTrackCodes"
                  className={labelClassName(
                    !!errors?.outboundTriggerTrackCodes &&
                      touched?.outboundTriggerTrackCodes
                  )}
                >
                  Outbound Manifest Trigger Track Codes
                </Label>
                <Select
                  value={{
                    label: values.outboundTriggerTrackCodes,
                    value: values.outboundTriggerTrackCodes,
                  }}
                  onChange={(options) =>
                    handleSelectChanges(options, "outboundTriggerTrackCodes")
                  }
                  onBlur={() => handleSelectBlur("outboundTriggerTrackCodes")}
                  name={`outboundTriggerTrackCodes`}
                  id={`outboundTriggerTrackCodes`}
                  onFocus={handleFieldFocus}
                  onKeyUp={handleEnterPressed}
                  options={findOptionFromPreference(
                    "OUTBOUND_MANIFEST_TRIGGER_TRACK_CODES"
                  )}
                  isDisabled={!isEditMode}
                  customStyles={selectStyles(
                    !!errors?.outboundTriggerTrackCodes &&
                      touched?.outboundTriggerTrackCodes
                  )}
                  components={{
                    DropdownIndicator: (props) => (
                      <DropdownIndicator
                        {...props}
                        handleDismissFieldChanges={handleDismissFieldChanges}
                      />
                    ),
                  }}
                />
                <FormErrorMessage className="!mt-3">
                  Outbound manifest trigger track codes is required
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </Flex>
        </>
      </Card> */}

      {/********************* Label *********************/}

      {/* <Card
        mb={7}
        borderRadius="20px"
        borderWidth="1px"
        borderColor="rgba(133, 134, 152, 0.2)"
        py={9}
        px={"44px"}
      >
        <Heading size="md" mb={"30px"}>
          Label
        </Heading>

        <Flex flexDirection={"column"} className="gap-11">
          <Flex className="gap-11" width={"calc(50% - 1.3rem)"}>
            <FormControl
              isInvalid={!!errors?.labelPreference && touched?.labelPreference}
            >
              <Label
                htmlFor="labelPreference"
                className={labelClassName(
                  !!errors?.labelPreference && touched?.labelPreference
                )}
              >
                Label Style
              </Label>
              <Select
                value={{
                  label: values.labelPreference,
                  value: values.labelPreference,
                }}
                onChange={(options) =>
                  handleSelectChanges(options, "labelPreference")
                }
                onBlur={() => handleSelectBlur("labelPreference")}
                name={`labelPreference`}
                id={`labelPreference`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
                options={findOptionFromPreference("LABEL_PREFERENCE")}
                isDisabled={!isEditMode}
                customStyles={selectStyles(
                  !!errors?.labelPreference && touched?.labelPreference
                )}
                components={{
                  DropdownIndicator: (props) => (
                    <DropdownIndicator
                      {...props}
                      handleDismissFieldChanges={handleDismissFieldChanges}
                    />
                  ),
                }}
              />
              <FormErrorMessage className="!mt-3">
                Label is required
              </FormErrorMessage>
            </FormControl>
          </Flex>
        </Flex>
      </Card> */}
    </>
  );
};
