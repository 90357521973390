import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Heading,
  Input,
  Select,
  Flex,
  Text,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Page,
  Pagination,
  PldSavingsCard,
  PldSavingsGraph,
  PldSavingsTable,
  UserContext,
} from "../interfaces";
import { getCarriers, downloadPldData, getPldChartData } from "../services";
import style from "../css-files/PldCalculator.module.css";

function PldCalculator() {
  const PAGE_OPTIONS = [25, 50, 100];
  const [paginationConfig, setPaginationConfig] = useState({
    options: PAGE_OPTIONS,
    perPage: PAGE_OPTIONS[0],
  });
  const [options, setOptions] = useState([]);
  const [response, setResponse] = useState({});
  const [isLoadingPld, setIsLoadingPld] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [error, setError] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState({
    pldTransactionId: "",
    dimFactor: 143,
    compareWithCarrierCode: [],
    discount: 20,
    sendOnlyShipmentCost: false,
    start: 1,
    limit: PAGE_OPTIONS[0],
  });

  const { token } = useContext(UserContext);

  useEffect(() => {
    (async function () {
      const options = await getCarriers(token);
      if (options) {
        setOptions(options?.carriers);
      } else {
        setError("Something went wrong, please try again later");
      }
      if (options?.carriers[0]?.code) {
        setData((data) => ({
          ...data,
          compareWithCarrierCode: [options?.carriers[0]?.code],
        }));
      }
    })();
  }, []);

  function handleChange(e) {
    let nextData = { ...data };
    if (e.target.name === "compareWithCarrierCode") {
      nextData[e.target.name] = [e.target.value];
    } else if (e.target.name === "file") {
      nextData[e.target.name] = e.target.files[0];
    } else {
      nextData[e.target.name] = e.target.value;
    }
    setData(nextData);
  }

  function handleFile(e) {
    setFile(e.target.files[0]);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const nextData = { ...data };
    nextData.pldTransactionId = crypto.randomUUID();
    nextData.sendOnlyShipmentCost = false;
    setData(nextData);
    (async function () {
      const resp = await getPldChartData(file, nextData, setIsLoadingPld, token);
      if (resp) {
        setResponse(resp);
      } else {
        setError("Something went wrong, please try again later");
      }
    })();
  }

  function handleDownload() {
    downloadPldData(data.pldTransactionId, setIsLoadingCsv, token);
  }

  function handleIndexChange(start, limit) {
    if (
      (start === response.shipmentCost.start &&
        limit === response.shipmentCost.limit) ||
      !start ||
      !limit
    ) {
      return;
    }
    const payload = { ...data };
    payload.start = start;
    payload.limit = limit;
    payload.sendOnlyShipmentCost = true;
    (async function () {
      const resp = await getPldChartData(file, payload, token);
      if (resp) {
        const newResp = { ...response };
        newResp.shipmentCost = resp.shipmentCost;
        setResponse(newResp);
      }
    })();
  }

  return (
    <Page>
      <Flex as={"header"} gap={5} mb={5}>
        <svg
          width="71"
          height="71"
          viewBox="0 0 71 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="35.269" cy="35.269" r="35.269" fill="#FF97C4" />
          <path
            d="M54.6101 43.2918V26.3526C54.6093 25.61 54.4103 24.8806 54.0331 24.2377C53.6559 23.5947 53.1138 23.0608 52.4611 22.6895L37.418 14.2199C36.7646 13.8482 36.0235 13.6525 35.269 13.6525C34.5146 13.6525 33.7734 13.8482 33.12 14.2199L18.077 22.6895C17.4242 23.0608 16.8821 23.5947 16.5049 24.2377C16.1277 24.8806 15.9288 25.61 15.928 26.3526V43.2918C15.9288 44.0344 16.1277 44.7638 16.5049 45.4067C16.8821 46.0497 17.4242 46.5836 18.077 46.9549L33.12 55.4245C33.7734 55.7962 34.5146 55.9919 35.269 55.9919C36.0235 55.9919 36.7646 55.7962 37.418 55.4245L52.4611 46.9549C53.1138 46.5836 53.6559 46.0497 54.0331 45.4067C54.4103 44.7638 54.6093 44.0344 54.6101 43.2918Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5081 24.1505L35.2689 34.8433L54.0297 24.1505"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.269 56.1655V34.8221"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.269 15.4811V32.1675"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.0612 18.515H33.3727C32.6686 18.515 31.9934 18.7946 31.4955 19.2925C30.9977 19.7903 30.718 20.4655 30.718 21.1696C30.718 21.8737 30.9977 22.5489 31.4955 23.0467C31.9934 23.5446 32.6686 23.8243 33.3727 23.8243H37.165C37.8691 23.8243 38.5443 24.1039 39.0422 24.6018C39.54 25.0996 39.8197 25.7749 39.8197 26.4789C39.8197 27.183 39.54 27.8582 39.0422 28.356C38.5443 28.8539 37.8691 29.1336 37.165 29.1336H30.718"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <div>
          <Heading as="h1" size="md" mt={3}>
            PLD Calculator
          </Heading>
          <Text>Savings per delivery calculator</Text>
        </div>
      </Flex>
      <hr />
      <Flex
        as="section"
        gap={5}
        pt={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <div>
          <form className={style.form} onSubmit={(e) => handleSubmit(e)}>
            <FormControl mb={5}>
              <FormLabel>Upload File</FormLabel>
              <Input
                type="file"
                name="file"
                required
                accept="text/csv"
                onChange={(e) => handleFile(e)}
              />
            </FormControl>
            <Flex gap={5}>
              <FormControl>
                <FormLabel>DIM Factor</FormLabel>
                <Input
                  name="dimFactor"
                  required
                  pattern="\d{1,3}"
                  min="0"
                  value={data.dimFactor}
                  max="450"
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Compare With</FormLabel>
                {options && (
                  <Select
                    name="compareWithCarrierCode"
                    value={data.compareWithCarrierCode[0]}
                    onChange={(e) => handleChange(e)}
                  >
                    {options.map((option) => (
                      <option key={option.code} value={option.code}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Discount Range</FormLabel>
                <Input
                  name="discount"
                  required
                  pattern="\d{1,2}"
                  min="0"
                  value={data.discount}
                  max="90"
                  onChange={(e) => handleChange(e)}
                />
              </FormControl>
            </Flex>
            <Flex mt={7}>
              <Button
                type="submit"
                isLoading={isLoadingPld}
                loadingText="Calculating..."
                className="chakra-primary-button"
              >
                Calculate PLD
              </Button>
            </Flex>
            {isLoadingPld && file.size > 10 * 1024 && (
              <Text mt={6}>
                We are running thousands of cost calculations, this may take a
                minute...
              </Text>
            )}
            {error && (
              <Alert status="error" mt={6}>
                <WarningTwoIcon />
                <Text>{error}</Text>
              </Alert>
            )}
          </form>
          {response.chartData && <PldSavingsGraph data={response.chartData} />}
        </div>
        <PldSavingsCard data={response} />
      </Flex>
      <section>
        {response.shipmentCost?.parcels[0]?.rates && (
          <>
            <Heading as={"h3"} size={"sm"} fontWeight={"normal"} mt={7}>
              Shipment Cost Table
            </Heading>
            <PldSavingsTable data={response.shipmentCost} />
            <Pagination
              config={paginationConfig}
              start={response.shipmentCost.start}
              count={response.shipmentCost.total}
              onIndexChange={handleIndexChange}
              setPaginationConfig={setPaginationConfig}
              labelText="Number of rows"
            />
          </>
        )}
        <NoCoverageMicrocopy response={response} />
        {response.shipmentCost && (
          <Button
            onClick={handleDownload}
            isLoading={isLoadingCsv}
            loadingText="Downloading..."
            className="chakra-primary-button"
          >
            Download CSV
          </Button>
        )}

        {response.shipmentCost && (
          <>
            <Box my={8}>
              <img alt="demo data" src="/images/demo/demo-data-1.png" />
            </Box>
            <Box my={8}>
              <img alt="demo data" src="/images/demo/demo-data-2.png" />
            </Box>
            <Box my={8}>
              <img alt="demo data" src="/images/demo/demo-data-3.png" />
            </Box>
          </>
        )}
      </section>
    </Page>
  );
}

export default PldCalculator;

function NoCoverageMicrocopy({ response }) {
  if (!response?.noCoverageData) {
    return null;
  }

  return (
    <Text mb={6}>
      <strong>
        {response.totalNumberOfParcels +
          response.noCoverageData.carrierNoCovData +
          response.noCoverageData.orchestroNoCovData}
      </strong>{" "}
      parcels uploaded.
      {response.noCoverageData.carrierNoCovData > 0 && (
        <>
          {" "}
          <strong>{response.noCoverageData.carrierNoCovData}</strong> Not{" "}
          covered by FedEx.
        </>
      )}
      {response.noCoverageData.orchestroNoCovData > 0 && (
        <>
          {" "}
          <strong>{response.noCoverageData.orchestroNoCovData}</strong>{" "}
          currently not covered by Orchestro.
        </>
      )}
    </Text>
  );
}
