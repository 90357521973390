import React, { useState } from "react";
import { StepCounter, StepMap, StepNav, StepPage } from ".";

export function Step6({ data, setData }) {
	const [status, setStatus] = useState(false);
	const onSubmit = () => {
		setStatus(true);
	};

	return (
		<StepPage>
			<div className="flex flex-col w-full text-white font-['Montserrat']">
				<StepCounter step={5} />
				<h3 className="text-4xl font-light leading-10 tracking-wide mt-12">
					Orchestro’s Coverage & Benefits Proposal
				</h3>

				<div className="flex gap-4 my-8 mt-12 p-5  w-full bg-[#00152E] h-44 opacity-80 rounded-lg justify-start items-start overflow-auto">
					<div>
						<p className="w-80 text-slate-300 text-base font-normal font-['Montserrat'] leading-loose tracking-tight">
							Percentage of population served:
							<br />
							Zip code coverage:
							<br />
							Transit time estimate:
							<br />
							Proposed Rates:
						</p>
					</div>
					<div className="w-96">
						<p className="text-white text-base font-medium font-['Montserrat'] leading-loose tracking-tight">
							7.5% approx
							<br />
							2,490
							<br />3 days (With 99.95% reliability)
							<br />
							$3.95 for a 0.5lb parcel
						</p>
						{/* <a
              className="hover:border-sky-400 w-80 h-11 px-4 mt-8 rounded-lg border border-zinc-200 justify-center items-center gap-2 inline-flex cursor-pointer"
              href="/data-dummy/rate-card.csv"
              download="rate-card.csv"
            >
              <DownloadIcon className="text-sky-400" />
              <span className="text-sky-400 text-base font-semibold font-['Montserrat'] leading-snug tracking-tight">
                Download Orchestro Rate Card
              </span>
            </a> */}
					</div>
				</div>
				<p className="w-[952px] h-[57px] text-white text-base font-normal font-['Montserrat'] leading-[57px] tracking-tight">
					Please enter your email address so we can send you the Rate Card and
					quickly spin up a test account.
				</p>
				<div className="flex items-center justify-start w-[850px] mt-3 gap-3 mb-3">
					<input
						defaultValue={data.rateCardEmail}
						onChange={(e) =>
							setData({ ...data, rateCardEmail: e.target.value })
						}
						className="w-2/3 border-b-2 border-indigo-500 h-12 focus:bg-transparent bg-[#85869833] focus:border-indigo-500 outline-none rounded text-base p-1"
						type="text"
					/>
					{data.rateCardEmail && (
						<div class="w-8 h-8 rounded-full border border-blue-400 items-center flex justify-center">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M6 12L10.2426 16.2426L18.727 7.75732"
									stroke="#6FB0F0"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
					)}
				</div>

				<div className="flex gap-12 w-[600px]">
					<div className="mt-8 mb-[-60px] w-2/3">
						<StepMap data={data} setData={setData} allGolden={true} />
					</div>

					<div className="w-1/3 mt-20">
						<div className="flex gap-2 mb-2">
							<div className="w-5 h-5 mt-1 bg-[#A27C3E] rounded-full" />
							<p className="text-white text-sm font-normal font-['Montserrat'] leading-loose tracking-tight">
								Orchestro Coverage
							</p>
						</div>
						<div className="flex gap-2 mb-2">
							<div className="w-5 h-5 mt-1 bg-gradient-to-r from-[#3BA0E6] to-[#3B6EF3] rounded-full" />
							<p className="text-white text-sm font-normal font-['Montserrat'] leading-loose tracking-tight">
								Your current coverage
							</p>
						</div>
					</div>
				</div>
				{status && (
					<div>
						<div className="mt-12 p-5 w-full bg-[#00152E] h-36 opacity-80 rounded-lg flex-col justify-start items-start gap-2.5 inline-flex overflow-auto">
							<p className="leading-[26px] text-base tracking-wide">
								An invite has been sent to your email address.
							</p>
							<p className="leading-[27px] tracking-wide text-sm">
								Login to the Orchestro platform and upload your PLD to receive
								detail rates. We will will contact you within 2 days for test
								run.
							</p>
						</div>

						<div className=" mt-12 w-full text-left flex gap-1">
							<span className="text-white text-sm font-normal font-['Montserrat'] leading-relaxed tracking-tight">
								For support, please reach out to{" "}
							</span>
							<a
								href="mailto:support@orchestro.ai"
								className="text-sky-400 text-sm font-normal font-['Montserrat'] leading-relaxed tracking-tight"
							>
								support@orchestro.ai
							</a>
						</div>
					</div>
				)}
			</div>
			<StepNav
				prevLink="../step/4"
				nextLink={status ? null : "../step/6"}
				label={status ? "" : "Submit"}
				onClick={onSubmit}
				nextLinkDisabled={!data.rateCardEmail}
			/>
		</StepPage>
	);
}
