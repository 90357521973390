import { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Button from "../../../componentLibrary/components/Button";
import { LabelLayers } from "./MapLabels";
import { useGeoData } from "../../../contexts/GeoDataProvider";
import { MAPBOX_API_KEY } from "../../../constants";
import { isWebGLAvailable } from "../../../utilities/isWebGLAvailable";

mapboxgl.accessToken = MAPBOX_API_KEY;

function MapTrack({ route, trackingData, setShowFullScreen, miniMapHasLoaded, setMiniMapHasLoaded }) {
	const { setGeoRoute } = useGeoData();
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [isAnimating, setIsAnimating] = useState(false);
	const [showOverlay, setShowOverlay] = useState(false);
	const [zoom] = useState(1);
	const [foundIndex, setFoundIndex] = useState(0);
	const [foundIndexSet, setFoundIndexSet] = useState(false);
	const [webglAvailable, setWebglAvailable] = useState(false);
	


	const elPackage = document.createElement("div");
	elPackage.innerHTML = '<div><img src="/images/map/marker_red.svg" /></div>';

	const onMapEnlarge = () => {
		setIsAnimating(true);
	};

	const handleAnimationEnd = () => {
		setShowOverlay(true);
		setTimeout(() => {
			setShowFullScreen(true);
			setIsAnimating(false);
		}, 300);
	};

	useEffect(() => {
		setWebglAvailable(isWebGLAvailable());
	  }, []);

	useEffect(() => {
		if (!miniMapHasLoaded) {
		if (
			route.destination[0] === 0 && 
			route.destination[1] === 0 &&
			trackingData &&
			Array.isArray(trackingData) &&
			trackingData.length > 0
		) {
			let foundValidCoords = false;
			
			for (let i = 0; i < trackingData.length; i++) {
				const item = trackingData[i];
				if (item.latitude && item.longitude && item.uiStatus !== "Label Created") {
					route.destination[0] = item.latitude;
					route.destination[1] = item.longitude;
					setFoundIndex(i + 1);
					setFoundIndexSet(true); 
					foundValidCoords = true;
					break;
				}
			}

			if (!foundValidCoords) {
				route.destination[0] = route.origin[0];
				route.destination[1] = route.origin[1];
				setFoundIndex(1);
				setFoundIndexSet(true); 
			}
		}
	} else {
		setFoundIndexSet(true);
	}
	}, [trackingData, route]);

	

	useEffect(() => {

		if (!foundIndexSet) return;

	
		if (
			!mapContainer.current ||
			!webglAvailable ||
			map.current ||
			(route.origin && route.origin[0] === 0 && route.origin[1] === 0) ||
			!trackingData ||
			!Array.isArray(trackingData) ||
			trackingData.length === 0
		)
			return;

		const defaultCenter = [-74.5, 40];
		const center =
			route && route.origin
				? [route.destination[1], route.destination[0]]
				: defaultCenter;

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style:  "mapbox://styles/paulforai/cm19toikd05cd01pqf5fug7uu",
			center: center,
			zoom: zoom,
			attributionControl: false,
			transition: {
				duration: 300,
				delay: 0,
			},
		});

		map.current.on("load", () => {
			map.current.setFog(null);
			const attributionElement = document.querySelector(
				".mapboxgl-ctrl-attrib",
			);
			if (attributionElement) {
				attributionElement.style.display = "none";
			}

			map.current.addLayer({
				type: "background",
				id: "dark-layer",
				paint: {
					"background-color": "rgba(0,0,0,0.5)",
				},
				interactive: false,
			});

			if (map.current.getLayer("land")) {
				map.current.setPaintProperty("land", "background-color", "#000000");
			}

			LabelLayers.forEach((layer) => {
				if (map.current.getLayer(layer)) {
					map.current.setLayoutProperty(layer, "visibility", "none");
				}
			});

			if (map.current.getLayer("admin-0-boundary")) {
				map.current.setPaintProperty(
					"admin-0-boundary",
					"line-color",
					"#858698",
				);
			}

			if (map.current.getLayer("admin-1-boundary")) {
				map.current.setPaintProperty(
					"admin-1-boundary",
					"line-color",
					"#858698",
				);
			}
		});

		if (!miniMapHasLoaded) {
		const startingPoint = [route.origin[1], route.origin[0]];
		const endPoint = [route.destination[1], route.destination[0]];
		let middlePoints = "";
		if (
			trackingData &&
			Array.isArray(trackingData) &&
			trackingData.length > 2
		) {
			middlePoints = trackingData
				.slice(foundIndex, trackingData.length - 1)
				.reverse()
				.reduce((middlePoint, item) => {
					if (
						item.latitude !== undefined &&
						item.latitude !== 0 &&
						item.longitude !== undefined &&
						item.longitude !== 0
					) {
						return `${middlePoint}${item.longitude},${item.latitude};`;
					}
					return middlePoint;
				}, "");
		}

		map.current.on("load", () => {
			map.current.resize();
			fetch(
				`https://api.mapbox.com/directions/v5/mapbox/driving/${startingPoint[0]},${startingPoint[1]};${middlePoints}${endPoint[0]},${endPoint[1]}.json?geometries=geojson&access_token=${mapboxgl.accessToken}`,
			)
				.then((response) => response.json())
				.then((data) => {
					setGeoRoute(data);
					setMiniMapHasLoaded(true);
				})
				.catch((error) => {
					console.error("Error fetching data:", error);
				});
		});
	}
	}, [route, trackingData, foundIndex, zoom, setGeoRoute, foundIndexSet]);

	return (
		<div className="relative w-full h-screen flex items-center justify-center">
			{webglAvailable && (
				<div className="absolute inset-0 flex items-center justify-center z-10">
				<Button
					className="mt-2 text-base"
					onClick={onMapEnlarge}
					context="primary"
				>
					Click to view map
				</Button>
			</div>
			)}
			
			<div
				ref={mapContainer}
				className={`w-[50vw] h-[50vw] md:w-[20vw] md:h-[20vw] rounded-full overflow-hidden  ${
					isAnimating ? "animate-scale" : ""
				}`}
				onAnimationEnd={handleAnimationEnd}
				style={{ zIndex: "0" }}
			/>
			{showOverlay && (
				<div className={`overlay ${showOverlay ? "show" : ""}`}></div>
			)}
		</div>
	);
}

export default MapTrack;
