import { useEffect, useState, useCallback, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Heading,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { Page, OrderIcon, TableLoading, UserContext } from "../interfaces";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

function MiddleMileLanes() {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { token } = useContext(UserContext);

  const handleDispatchClick = (selectedEntry) => {
    const selectedOpenOrder = selectedEntry.openOrder;
    const selectedLane = selectedEntry.lane; // Added line to get the selected lane

    if (selectedOpenOrder) {
      // Use useNavigate to navigate and pass state
      navigate(`/app/middle-mile-lanes/${selectedOpenOrder}`, {
        state: { selectedOpenOrder, selectedLane }, // Include selectedLane in the state
      });
    } else {
      console.error("Invalid selectedOpenOrder:", selectedOpenOrder);
    }
  };

  const fetchData = useCallback(() => {
    setIsLoading(true);

    fetch(apiUrl + "/get-middle-mile-lanes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((response) => {
        if (response && response.lanes) {
          setData(response.lanes);
        } else {
          console.error("Unexpected response format:", response);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Page>
      <Heading as={"h1"} size={"md"} mb={5}>
        Middle Mile Lane Fill Rate
      </Heading>
      <TableContainer className="max-h-[calc(100vh_-_190px)] !overflow-y-auto w-full mb-4">
        <Table>
          <Thead>
            <Tr>
              <Th>Lane</Th>
              <Th>Origin</Th>
              <Th>Destination</Th>
              <Th>Containers</Th>
              <Th>Shipments</Th>
              <Th>Open Order</Th>
              <Th>Fill %</Th>
              <Th>Planned Pickup</Th>
              <Th>BOL</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading && <TableLoading data={data} />}
            {!isLoading && data && data.length === 0 && (
              <Tr>
                <Td colSpan="100">No data available</Td>
              </Tr>
            )}
            {data &&
              data.map((entry, index) => (
                <Tr key={index}>
                  <Td>{entry.lane}</Td>
                  <Td>{entry.origin}</Td>
                  <Td>{entry.destination}</Td>
                  <Td>{entry.containers}</Td>
                  <Td>{entry.parcels} </Td>
                  <Td>{entry.openOrder}</Td>
                  <Td>{entry.fillPercentage}</Td>
                  <Td>
                    {entry.plannedPickup && (
                      <time dateTime={entry.plannedPickup}>
                        {entry.plannedPickup}
                      </time>
                    )}
                  </Td>
                  <Td>
                    {entry.bolPath && (
                      <IconButton
                        aria-label="View BOL in new tab"
                        as="a"
                        size={"sm"}
                        variant={"ghost"}
                        href={entry.bolPath}
                        target="_blank"
                        rel="noopener noreferrer"
                        icon={<OrderIcon />}
                      />
                    )}
                  </Td>
                  <Td>
                    {entry.action === "Order" ? (
                      <Button
                        variant="ghost"
                        as={Link}
                        size={"sm"}
                        minW={"10ch"}
                        to="/app/middle-mile-lanes/new-order"
                        state={{ selectedLane: entry.lane }}
                      >
                        {" "}
                        {entry.action}
                      </Button>
                    ) : (
                      <Button
                        variant="ghost"
                        size={"sm"}
                        minW={"10ch"}
                        onClick={() => handleDispatchClick(entry)}
                      >
                        {entry.action}
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Page>
  );
}

export default MiddleMileLanes;
