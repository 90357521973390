import React, { useState, useEffect, useContext } from "react";
import {
	TableContainer,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	useToast,
	FormControl,
	RadioGroup,
	Radio,
} from "@chakra-ui/react";
import { AccountTabs, Page, TableLoading, UserContext } from "../interfaces";
import {
	getServiceAttributes,
	uploadServiceAttributesDetails,
	uploadSurchargesDetails,
} from "../services";
import Button from "../componentLibrary/components/Button";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import { ImportDownload } from "../interfaces/AccountTabs/serviceAtributes/ImportFile";
import { CloseIcon } from "@chakra-ui/icons";
import ErrorIcon from "../interfaces/CustomIcons/Error";
import { Modal } from "../componentLibrary";
import ServiceTable from "../interfaces/AccountTabs/serviceAtributes/serviceValidations";
import SuccessIcon from "../interfaces/CustomIcons/Success";

const ServiceAttributes = () => {
	const { isNonProdEnvironment, token, user, viewingAs } =
		useContext(UserContext);
	const toast = useToast();

	const [carrierServiceAttributes, setCarrierServiceAttributes] = useState([]);
	const [shipperServiceAttributes, setShipperServiceAttributes] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [error, setError] = useState("");
	const [bodyData, setBodyData] = useState(null);
	const [serviceBodyData, setServiceBodyData] = useState(null);
	const [tableData, setTableData] = useState(null);
	const [serviceTableData, setServiceTableData] = useState(null);
	const [errorDescription, setErrorDescription] = useState("");
	const [isValid, setIsValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [saveStatus, setSaveStatus] = useState(false);
	const [successView, setSuccessView] = useState(false);
	const [selectedOption, setSelectedOption] = useState("CARRIER");

	const options = [
		{ value: "CARRIER", name: "Carrier Rates" },
		{ value: "SHIPPER", name: "Shipper Rates" },
	];

	useEffect(() => {
		if (!viewingAs.isCarrier) return;
		setIsLoading(true);
		setCarrierServiceAttributes([]);
		getServiceAttributes(viewingAs.participantCode, token)
			.then((response) => {
				if (response?.serviceAttributes) {
					setCarrierServiceAttributes(response.serviceAttributes);
				}
			})
			.finally(() => setIsLoading(false));
	}, [viewingAs, token]);

	useEffect(() => {
		if (!viewingAs.isShipper) return;
		setIsLoading(true);
		setShipperServiceAttributes([]);
		getServiceAttributes("ORCHESTRO", token)
			.then((response) => {
				if (response?.serviceAttributes) {
					setShipperServiceAttributes(response.ServiceAttributes);
				}
			})
			.finally(() => setIsLoading(false));
	}, [viewingAs, token]);

	const handleDismiss = () => {
		setError("");
	};

	const ValidationData = (surcharges, services) => {
		const ocnCode = surcharges.filter((e) => e["OCN Code"] !== undefined);
		const servicesOcnCode = services.filter((e) => e["OCN Code"] !== undefined);
		if (
			ocnCode.length !== surcharges.length ||
			servicesOcnCode.length !== services.length
		) {
			setError("This is an error message");
			setErrorDescription(
				"The OCN Code tab field is empty. Please check for errors and update.",
			);
			setIsValid(false);
			setSuccessView(false);
		} else {
			setSuccessView(true);
			if (!saveStatus) {
				return true;
			} else {
				return false;
			}
		}
	};

	const handleSave = () => {
		const data = ValidationData(
			bodyData !== null ? bodyData : tableData,
			serviceBodyData !== null ? serviceBodyData : serviceTableData,
		);
		setSaveStatus(true);
		if (data) {
			setError("");
			setSuccessView(true);
			setIsValid(true);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		const surcharges = bodyData !== null ? bodyData : tableData;
		const services =
			serviceBodyData !== null ? serviceBodyData : serviceTableData;
		const data = surcharges.map((e) => {
			return {
				code: e["Carrier Code"],
				description: e["Other Fees / Surcharges"],
				rate: e["Fee"] ? parseFloat(e["Fee"].replace(/[$%]/g, "")) : 0,
				currency: "USD",
				networkSurchargeCode: e["OCN Code"],
			};
		});
		const data1 = services.map((e) => {
			return {
				code: e["Carrier Code"],
				description: e["Other Fees / Surcharges"],
				rate: e["Fee"] ? parseFloat(e["Fee"].replace(/[$%]/g, "")) : 0,
				currency: "USD",
				networkServiceCode: e["OCN Code"],
			};
		});
		const response = await uploadSurchargesDetails(
			data,
			viewingAs.participantCode,
			token,
		);
		const response1 = await uploadServiceAttributesDetails(
			data1,
			viewingAs.participantCode,
			token,
		);
		if (response.ok && response1.ok) {
			toast({ title: "File submitted successfully!", status: "success" });
		} else {
			toast({ title: "Server Issue", status: "error" });
		}
		setIsValid(false);
		setSuccessView(false);
		setLoading(false);
	};

	useEffect(() => {
		if (tableData !== null) {
			ValidationData(tableData, serviceTableData);
		}
	}, [tableData]);

	return (
		<>
			<AccountTabs />
			<Page className={"!min-h-0"}>
				<FormControl>
					<RadioGroup
						fontWeight={500}
						className="flex flex-col gap-5 mb-5"
						value={selectedOption}
						onChange={(value) => setSelectedOption(value)}
					>
						{!viewingAs.isCarrier &&
							!viewingAs.isShipper &&
							options.map(({ value, name }, i) => (
								<Radio
									key={i}
									value={value}
									className={`!w-[24px] !h-[24px] !border-[#E0E1EC]`}
									_checked={{
										backgroundColor: "unset",
										borderColor: "#3BA0E6 !important",
										_before: {
											background: "linear-gradient(to right, #3BA0E6, #3B6EF3)",
											content: "''",
											height: "60%",
											width: "60%",
											borderRadius: "50%",
										},
									}}
								>
									{name}
								</Radio>
							))}
					</RadioGroup>
				</FormControl>
				{(viewingAs.isShipper && !viewingAs.isCarrier) ||
				selectedOption !== "CARRIER" ? (
					<>
						<div className="flex justify-end items-center gap-2">
							{user.isPlatformAdmin && isNonProdEnvironment && (
								<>
									<ImportDownload
										updateTableData={setTableData}
										updateServicesData={setServiceTableData}
									/>
									<Button context="primary" prefixIcon={<ArrowDownTrayIcon />}>
										Download
									</Button>
								</>
							)}
						</div>
						<h2 className="text-xl my-5">
							{viewingAs.isCarrier
								? "Carrier"
								: viewingAs.isShipper
								? "Shipper"
								: selectedOption}
						</h2>
						<TableContainer className="max-h-[calc(100vh_-_245px)] !overflow-y-auto w-full mb-4">
							<Table>
								<Thead>
									<Tr>
										<Th>Network Service Type</Th>
										<Th>Network Service Code</Th>
										<Th>Network Description</Th>
										<Th>Network Rate</Th>
										<Th>Type</Th>
									</Tr>
								</Thead>
								<Tbody>
									{isLoading && (
										<TableLoading data={shipperServiceAttributes} />
									)}
									{!isLoading &&
										(!shipperServiceAttributes ||
											shipperServiceAttributes.length === 0) && (
											<Tr>
												<Td colSpan="100">No service attributes found</Td>
											</Tr>
										)}
									{shipperServiceAttributes &&
										shipperServiceAttributes.map((attribute, i) => (
											<Tr key={i + "-" + attribute.code}>
												<Td>{attribute.networkServiceType}</Td>
												<Td>{attribute.networkServiceCode}</Td>
												<Td>{attribute.description}</Td>
												<Td>{attribute.networkRate}</Td>
												<Td>{attribute.type}</Td>
											</Tr>
										))}
								</Tbody>
							</Table>
						</TableContainer>
					</>
				) : (
					<>
						{user.isPlatformAdmin && isNonProdEnvironment && (
							<div className="flex justify-end items-center gap-2">
								<ImportDownload
									updateTableData={setTableData}
									updateServicesData={setServiceTableData}
								/>
								<Button context="primary" prefixIcon={<ArrowDownTrayIcon />}>
									Download
								</Button>
							</div>
						)}
						<h2 className="text-xl my-5">
							{viewingAs.isCarrier
								? "Carrier"
								: viewingAs.isShipper
								? "Shipper"
								: selectedOption}
						</h2>
						<TableContainer className="max-h-[calc(100vh_-_245px)] !overflow-y-auto w-full mb-4">
							<Table>
								<Thead>
									<Tr>
										<Th>Code</Th>
										<Th>Description</Th>
										<Th>Rate</Th>
										<Th>Network Service Code</Th>
										<Th>Network Description</Th>
									</Tr>
								</Thead>
								<Tbody>
									{isLoading && (
										<TableLoading data={carrierServiceAttributes} />
									)}
									{!isLoading &&
										(!carrierServiceAttributes ||
											carrierServiceAttributes.length === 0) && (
											<Tr>
												<Td colSpan="100">No service attributes found</Td>
											</Tr>
										)}
									{carrierServiceAttributes &&
										carrierServiceAttributes.map((attribute) => (
											<Tr key={attribute.code}>
												<Td fontWeight="bold">{attribute.code}</Td>
												<Td>{attribute.description}</Td>
												<Td>{attribute.rate}</Td>
												<Td>{attribute.networkServiceCode}</Td>
												<Td>{attribute.networkDescription}</Td>
											</Tr>
										))}
								</Tbody>
							</Table>
						</TableContainer>
					</>
				)}

				{error && (
					<div className="flex py-4 px-8 rounded-lg bg-[#FF263F1A] justify-between mt-4">
						<div className="flex items-center justify-start gap-3">
							<div>
								<ErrorIcon />
							</div>
							<div className="flex flex-col">
								<div className="text-white font-bold">{error}</div>
								<div className="text-[#C8C5C5] text-sm">{errorDescription}</div>
							</div>
						</div>
						<div className="flex gap-4 items-center">
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => {
									setShowErrorModal(true);
								}}
							>
								View
							</Button>
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => handleDismiss()}
							>
								Dismiss
							</Button>
						</div>
					</div>
				)}
				{!error && successView && (
					<div className="flex py-4 px-8 rounded-lg bg-[#192D2B] justify-between mt-4">
						<div className="flex items-center justify-start gap-3">
							<div>
								<SuccessIcon />
							</div>
							<div className="flex flex-col">
								<div className="text-white font-bold">
									File uploaded successfully
								</div>
								<div className="text-[#C8C5C5] text-sm">
									{" "}
									File uploaded successfully! Please review{" "}
									{isValid ? "" : "and save"} your file.
								</div>
							</div>
						</div>
						<div className="flex gap-4 items-center">
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => {
									setShowErrorModal(true);
								}}
							>
								View
							</Button>
							<Button
								context="text"
								className="text-sm text-[#4FC1FF] font-semibold"
								onClick={() => {
									setSuccessView(false);
								}}
							>
								Dismiss
							</Button>
						</div>
					</div>
				)}
				<Modal
					open={showErrorModal}
					onClose={setShowErrorModal}
					className={{
						content:
							"min-w-full md:!min-w-[964px] !min-h-[90vh] facility-wrapper",
						title: "!hidden",
						closeButton: "!hidden",
						controls: "!hidden",
						body: "min-h-full",
					}}
				>
					<div className="flex flex-col flex-1">
						<div className="flex items-center justify-between">
							<div></div>
							<div className=" text-lg text-white font-bold">
								Service Attribute and Surcharges
							</div>
							<div
								className="text-white font-bold flex items-center justify-center cursor-pointer"
								onClick={() => {
									setShowErrorModal(false);
									setSaveStatus(false);
								}}
							>
								<CloseIcon color="#2294FF" />
							</div>
						</div>
						<div className="h-[520px] overflow-auto my-3 p-4">
							<ServiceTable
								tableData={tableData}
								setBodyData={setBodyData}
								serviceTableData={serviceTableData}
								setServiceBodyData={setServiceBodyData}
								setSaveStatus={setSaveStatus}
							/>
						</div>
						<div className="flex justify-end gap-4 my-4">
							<Button
								context="outlined"
								onClick={() => {
									setShowErrorModal(false);
									setSaveStatus(false);
								}}
							>
								Cancel
							</Button>
							<Button onClick={() => handleSave()}>
								{saveStatus ? "Saved" : "Save"}
							</Button>
						</div>
					</div>
				</Modal>
				<div className="flex justify-end my-2">
					<Button
						context={isValid ? "primary" : "outlinedSecondary"}
						isLoading={loading}
						disabled={!isValid}
						onClick={() => handleSubmit()}
					>
						Submit
					</Button>
				</div>
			</Page>
		</>
	);
};

export default ServiceAttributes;
