import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { FILTER_PATHS } from "../constants";
import { SearchIcon } from "../interfaces";

const SearchField = ({ onSearch, filterHandler }) => {
  const { pathname } = useLocation();

  const [searchQuery, setSearchQuery] = useState("");

  const isShowFilter = useMemo(
    () => FILTER_PATHS.some((path) => pathname === path),
    [pathname]
  );

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    onSearch(query); // Propagate the search query to the parent component
  };

  return (
    <div className="flex gap-2">
      <div className="search-field relative">
        <SearchIcon fill="#7D8488" />
        <input
          type="text"
          placeholder="Parcel ID, sorting number, hub"
          value={searchQuery}
          onChange={handleInputChange}
          className="font-light text-sm text-sm::placeholder-sm border pl-8 py-3 pr-8 rounded-md border-none w-[380px] bg-[rgba(133, 134, 152, 0.2) placeholder-[rgba(125, 132, 136, 1)];"
        />
      </div>
      {isShowFilter && (
        <img
          alt="filter"
          onClick={() => {
            filterHandler();
          }}
          className="ml-4 cursor-pointer"
          src="/images/filter.svg"
        />
      )}
    </div>
  );
};

export default SearchField;
