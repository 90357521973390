import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Auth0WithRedirectProvider = ({ children }) => {
	const navigate = useNavigate();

	const domain = process.env.REACT_APP_AUTH0_DOMAIN;
	const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
	const redirectUri = `${process.env.REACT_APP_ORCHESTRO_BASE_URL}/app`;
	const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.pathname);
	};

	if (!(domain && clientId && redirectUri && audience)) {
		console.error("Auth0WithRedirectProvider missing .env.[env] config");
		return null;
	}

	return (
		<Auth0Provider
			domain={domain}
			clientId={clientId}
			authorizationParams={{
				audience: audience,
				redirect_uri: redirectUri,
			}}
			onRedirectCallback={onRedirectCallback}
			cacheLocation="localstorage"
			useRefreshTokens={true}
		>
			{children}
		</Auth0Provider>
	);
};
