import "../../css-files/PublicTracking.css";

const BackgroundLayer = () => {
	return (
		<div className="background-layer">
			<div className="glow-up"></div>
			 <div className="right-glow h-full"></div>
			 <div className="blue-glow h-full"></div>
		</div>
	);
};

export default BackgroundLayer;
