import React from "react";
import { createRoot } from "react-dom/client";
import { ColorModeScript, ChakraProvider } from "@chakra-ui/react";
import "./index.css";
import App from "./App";
import theme from "./chakraTheme/theme";

import { Provider } from "react-redux";
import store from "./interfaces/redux/store/store";

const root = createRoot(document.getElementById("root"));

// Remove the cached Okta session from local storage
// TODO: Remove this a month after going into production, roughly the end of Oct 2024
localStorage.removeItem("okta-cache-storage");
localStorage.removeItem("okta-original-uri-storage");
localStorage.removeItem("okta-shared-transaction-storage");
localStorage.removeItem("okta-token-storage");

root.render(
	<Provider store={store}>
		<ChakraProvider theme={theme}>
			{/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
			<ColorModeScript initialColorMode="dark" />
			<App />
		</ChakraProvider>
	</Provider>,
);
