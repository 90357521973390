import { useCallback, useContext, useEffect, useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

import { IconButton } from "../../componentLibrary";
import { UserContext } from "../Providers";
import { getParcelInvoice } from "../../services";

import ParcelDifferenceInvoice from "./ParcelDifferenceInvoice";
import ParcelShipperInvoice from "./ParcelShipperInvoice";
import ParcelCarrierInvoice from "./ParcelCarrierInvoice";

function ParcelInvoice({ trackingId }) {
	const { token, user } = useContext(UserContext);

	const [carrierData, setCarrierData] = useState([]);
	const [shipperData, setShipperData] = useState([]);
	const [differenceData, setDifferenceData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [expandedTables, setExpandedTables] = useState({
		shipper: true,
		carrier: user.isPlatformAdmin ? false : true,
		difference: user.isPlatformAdmin ? false : true,
	});

	const buildDifferenceData = (carrierData, shipperData) => {
		const carrierTotals = carrierData[carrierData.length - 1];
		const shipperTotals = shipperData[shipperData.length - 1];
		carrierTotals.rowTitle = "Carrier Total";
		shipperTotals.rowTitle = "Shipper Total";
		const difference = [
			shipperTotals,
			carrierTotals,
			calculateDifferenceRow(shipperTotals, carrierTotals),
		];
		return difference;
	};

	const calculateDifferenceRow = (shipperTotals, carrierTotals) => {
		const differenceRow = { ...shipperTotals };
		Object.keys(shipperTotals).forEach((key) => {
			if (
				key === "totalAmount" ||
				key === "baseRate" ||
				key === "fuel" ||
				key === "totalRate"
			) {
				differenceRow[key] = shipperTotals[key] - carrierTotals[key];
			} else {
				differenceRow[key] = null;
			}
		});
		differenceRow["rowTitle"] = "Difference";
		return differenceRow;
	};

	const calculateTotalRow = (data) => {
		if (!data || data.length === 0) return {};
		const totalRow = { ...data[0] };
		Object.keys(data[0]).forEach((key) => {
			if (
				key === "totalAmount" ||
				key === "baseRate" ||
				key === "fuel" ||
				key === "totalRate"
			) {
				try {
					totalRow[key] = data.reduce((acc, curr) => {
						if (curr[key] === null || curr[key] === undefined) {
							return acc;
						}
						return acc + curr[key];
					}, 0);
				} catch (error) {
					console.error("Error calculating total row:", error);
				}
			} else {
				totalRow[key] = null;
			}
		});
		totalRow["rowTitle"] = "Total";
		return totalRow;
	};

	const buildTableData = (invoiceData) => {
		if (!invoiceData || (!invoiceData.regular && !invoiceData.adjustment)) {
			return [];
		}

		let tableData = [];

		// Set the Original Invoice (regular)
		if (invoiceData.regular && invoiceData.regular.length > 0) {
			invoiceData.regular[0]["rowTitle"] = "Original Invoice";
			tableData.push(invoiceData.regular[0]);
		}

		// Set the Adjustment Invoice (adjustment)
		if (invoiceData.adjustment && invoiceData.adjustment.length > 0) {
			invoiceData.adjustment.map(
				(row) => (row["rowTitle"] = "Adjustment Invoice"),
			);
			tableData = [...tableData, ...invoiceData.adjustment];
		}

		// If you have "Claim Credit" data (e.g., a third item in the future), add logic for it here
		// For now, we assume Claim Credit is not available.

		return [...tableData, calculateTotalRow(tableData)];
	};

	const getParcelInvoiceData = useCallback(async () => {
		if (!user || !user.Groups || !token || !trackingId) {
			console.error("User data or Groups not available");
			return;
		}

		setIsLoading(true);
		setCarrierData([]);
		setShipperData([]);
		setDifferenceData([]);

		getParcelInvoice(token, trackingId)
			.then((res) => {
				setShipperData(
					user.isShipper || user.isPlatformAdmin
						? buildTableData(res?.shipperInvoice) || []
						: [],
				);
				setCarrierData(
					user.isCarrier || user.isPlatformAdmin
						? buildTableData(res?.carrierInvoice) || []
						: [],
				);
			})
			.catch((error) => {
				if (error.response?.status === 403) {
					console.error("403 Forbidden: Access denied for this user.");
				} else {
					console.error("An error occurred:", error);
				}
			})
			.finally(() => setIsLoading(false));
		// eslint-disable-next-line
	}, [trackingId, user]);

	useEffect(() => {
		getParcelInvoiceData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (
			user.isPlatformAdmin &&
			carrierData &&
			shipperData &&
			carrierData.length > 0 &&
			shipperData.length > 0
		) {
			setDifferenceData(buildDifferenceData(carrierData, shipperData));
		}
		// eslint-disable-next-line
	}, [carrierData, user, shipperData, user]);

	// Toggle function to expand/collapse a specific table
	const toggleTable = (tableName) => {
		setExpandedTables((prevState) => ({
			...prevState,
			[tableName]: !prevState[tableName],
		}));
	};

	return (
		<div className="my-5">
			{(user.isShipper || user.isPlatformAdmin) && (
				<>
					{user.isPlatformAdmin && (
						<ParcelInvoiceTitle
							title="Shipper"
							onClick={() => toggleTable("shipper")}
							isOpen={expandedTables.shipper}
						/>
					)}
					<ParcelShipperInvoice
						data={shipperData}
						isLoading={isLoading}
						isOpen={expandedTables.shipper}
						user={user}
					/>
				</>
			)}

			{(user.isCarrier || user.isPlatformAdmin) && (
				<>
					{user.isPlatformAdmin && (
						<ParcelInvoiceTitle
							title="Carrier"
							onClick={() => toggleTable("carrier")}
							isOpen={expandedTables.carrier}
						/>
					)}
					<ParcelCarrierInvoice
						data={carrierData}
						isLoading={isLoading}
						isOpen={expandedTables.carrier}
					/>
				</>
			)}

			{user.isPlatformAdmin && (
				<>
					<ParcelInvoiceTitle
						title="Difference"
						onClick={() => toggleTable("difference")}
						isOpen={expandedTables.difference}
					/>
					<ParcelDifferenceInvoice
						data={differenceData}
						isLoading={isLoading}
						isOpen={expandedTables.difference}
					/>
				</>
			)}
		</div>
	);
}

function ParcelInvoiceTitle({ title, onClick, isOpen }) {
	return (
		<div className="flex justify-between mt-5">
			<h1 className="font-bold">{title}</h1>
			<IconButton onClick={() => onClick()} context="secondary">
				{isOpen ? <ChevronUpIcon width={18} /> : <ChevronDownIcon width={18} />}
			</IconButton>
		</div>
	);
}

export default ParcelInvoice;
