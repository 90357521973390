import React from "react";
import { components } from "react-select";
import { CloseIcon } from "@chakra-ui/icons";

export const DropdownIndicator = ({
  children,
  handleDismissFieldChanges,
  name,
  ...props
}) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.isFocused ? (
        <CloseIcon
          w={3}
          h={3}
          onClick={() => {
            props.selectProps.onMenuClose();
            handleDismissFieldChanges(name);
          }}
        />
      ) : (
        children
      )}
    </components.DropdownIndicator>
  );
};
