import React, { useCallback } from "react";
import PropTypes from "prop-types";

const Pod = ({ pod }) => {
	const getDisplayAmount = useCallback((amount, type) => {
		if (amount !== null || amount !== undefined) {
			if (type === "percent") {
				return isNaN(amount) ? "0%" : `${amount}%`;
			} else {
				if (isNaN(amount)) {
					return 0;
				}
				if (amount >= 1000000) {
					return `${(amount / 1000000).toFixed(0)}`;
				} else if (amount >= 1000) {
					return `${(amount / 1000).toFixed(0)}`;
				} else {
					return (Math.round(amount * 100) / 100).toFixed(
						type === "fixed" ? 0 : 2,
					);
				}
			}
		}
	}, []);

	return (
		<>
			<div className="items-center active rounded-md p-3 min-h-full">
				<div className="ml-2">
					<div className="text-sm capitalize">{pod.label}</div>
					<div className="flex items-start mt-3">
						{(pod.amount !== null || pod.amount !== undefined) &&
							(pod?.variant === "list" ? (
								<div>
									{Object.keys(pod.amount).length > 0 ? (
										Object.entries(pod.amount).map(([label, amount], index) => (
											<div key={index} className="flex mb-1">
												<p className="text-xs w-[120px]">{label}</p>
												<p className="text-xs font-medium">
													{(
														Math.round(
															typeof amount === "string"
																? Number(amount)
																: amount * 100,
														) / 100
													).toFixed(2)}
													%
												</p>
											</div>
										))
									) : (
										<div className="mt-2 text-base font-medium">
											<p>No data available</p>
										</div>
									)}
								</div>
							) : (
								<div className="text-5xl mr-1 font-medium flex items-baseline mt-[14px] mb-[10px]">
									<div className="text-[40px] leading-[30px] translateY-[-2px]">
										{pod.amountPrefix ?? ""}
									</div>
									<div className="leading-[38px]">
										{getDisplayAmount(pod.amount, pod.type)?.toLocaleString()}
									</div>
									<div className="ml-1 text-[30px] leading-[20px]">
										{pod.amountPostfix ?? ""}
									</div>
								</div>
							))}

						{pod.trend &&
							(pod.trend === "up" ? (
								<img
									alt="up"
									width="20px"
									className="mt-[14px]"
									src="/images/trends/up.svg"
								/>
							) : (
								<img
									alt="down"
									width="20px"
									className="mt-[14px]"
									src="/images/trends/down.svg"
								/>
							))}
					</div>
					{pod.value && pod.total && (
						<div className="text-xs">
							{pod.value.toLocaleString()} of {pod.total.toLocaleString()}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Pod;

Pod.propTypes = {
	pod: PropTypes.object,
};
