import React, { useContext, useEffect, useState } from "react";
import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { Button, Modal, UploadFile } from "../../../componentLibrary";
import { UserContext } from "../../Providers";
import * as XLSX from "xlsx";
import { v4 as uuidv4 } from 'uuid';


const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;


export const ImportDownload = ({
  endpoint,
  updateTableData,
  updateServicesData,
  template
}) => {
  const [files, setFiles] = useState();
  const importFileURL = `${apiUrl}/${endpoint}`;
  const { token } = useContext(UserContext);
  const toast=useToast()


  const {
    isOpen: isOpenImportFileModal,
    onClose: onCloseImportFileModal,
    onOpen: onOpenImportFileModal,
  } = useDisclosure();

  const colorNavigation=(code)=>{
    console.log(code)
    if(code===undefined){
        return "code"
    }else{
        return ""
    }

  }


  const handleFileUpload = async (file) => {
    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (fileExtension === "xls" || fileExtension === "xlsx") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target?.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetNames = workbook.SheetNames;
        const sheetName= sheetNames.filter((e)=>e.includes("SrvAttrb&Srcharge_"))
        const worksheet = workbook.Sheets[sheetName[0]];
        const allRows = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false });
        let headersIndex = -1;
        for (let i = 0; i < allRows.length; i++) {
            if (allRows[i].includes("Other Fees / Surcharges")) {
                headersIndex = i;
                break;
            }
        }
        if (headersIndex === -1 || headersIndex >= allRows.length) {
            toast({ title: "Invalid File has been uploaded!", status: "error" });
            return;
        }
        const headers = allRows[headersIndex];
        const rows = [];
        for (let i = headersIndex + 1; i < allRows.length; i++) {
        if(allRows[i].every((cell)=>cell.includes("Service Attributes"))){
            break
        }else{
            rows.push(allRows[i]);
        }
        }

        const arrayOfObjects = await Promise.all(rows.map(async (row, index) => {
          const rowObject = {};
          headers.forEach((header, index) => {
            if(index!==1){
            rowObject[header] = row[index];
            }
          });
          rowObject['success'] =colorNavigation(row[4])
          rowObject['id'] = uuidv4();
          return rowObject;
        }));
        updateTableData(arrayOfObjects);

        let servicesHeadersIndex = -1;
        for (let i = 0; i < allRows.length; i++) {
            if (allRows[i].includes("Service Attributes")) {
                servicesHeadersIndex = i + 1;
                break;
            }
        }
        if (servicesHeadersIndex === -1 || headersIndex >= allRows.length) {
            console.error('Service Attributes header not found or no headers row found.');
            return;
        }

        const servicesHeaders = allRows[servicesHeadersIndex];
        const servicesRows = [];
        for (let i = servicesHeadersIndex; i < allRows.length; i++) {
          if (allRows[i].every((cell) => cell === undefined)) {
            break;
          } else {
            servicesRows.push(allRows[i]);
          }
        }


        const servicesArrayOfObjects = await Promise.all(servicesRows.map(async (row, index) => {
          const servicesRowObject = {};
          headers.forEach((header, index) => {
            if(index!==1){
            servicesRowObject[header] = row[index];
            }
          });
          servicesRowObject['success'] =colorNavigation(row[4])
          servicesRowObject['id'] = uuidv4();
          return servicesRowObject;
        }));
        updateServicesData(servicesArrayOfObjects)

        onCloseImportFileModal();
      };
      reader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
     if(files?.length){
      handleFileUpload(files[0])
     }
  }, [files])

  return (
    <>

        <Button
          prefixIcon={<ArrowUpTrayIcon />}
          onClick={onOpenImportFileModal}
          context="outlined"
        >
          Upload
        </Button>

      <Modal
        title="Import information using your Excel file"
        open={isOpenImportFileModal}
        onClose={onCloseImportFileModal}
        className={{
          content: "!min-w-[964px] !p-11",
          title: "!text-2xl !text-[#fff]",
          controls: "!hidden",
        }}
      >
        <Flex
          mt={8}
          mr={3}
          flexDirection={"column"}
          gap={6}
          alignItems={"center"}
        >
          <Box className="self-start">
            Upload an Excel file with account data to automatically populate the
            information for the selected account.
          </Box>
          {template && (
            <Button
              context="outlined"
              className="text-sm font-medium"
              onClick={template}
            >
              Download template
            </Button>
          )}
          <UploadFile
            className={{
              container: "w-full",
              uploadFile: "h-[372px] !max-w-full",
            }}
            setIsFile={setFiles}
          />
        </Flex>
      </Modal>
    </>
  );
};
