import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { format, subDays, formatDate, subWeeks, subMonths } from "date-fns";
import { useFormik } from "formik";
import { isEqual, omitBy } from "lodash";
import {
	AddIcon,
	DownloadIcon,
	SearchIcon,
	SettingsIcon,
} from "@chakra-ui/icons";
import {
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	InputGroup,
	InputLeftElement,
	Table,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	TableContainer,
	Text,
	VisuallyHidden,
	IconButton,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";

import { Button, Popover, Tooltip } from "../componentLibrary";
import { CARRIER_CODES, SHIPPER_CODES } from "../constants";
import {
	FilterPanel,
	InfoIcon,
	Page,
	Pagination,
	TableLoading,
	UserContext,
} from "../interfaces";
import classes from "../css-files/shipments.module.css";

import { useDispatch } from "react-redux";
import { setFilters } from "../interfaces/redux/store/filtersSlice";
import TableColumnPreferences from "../interfaces/Shipments/TableColumnPreferences";
import { deleteFilter, getParticipants, saveFilter } from "../services";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

const initialValues = {
	shipmentDateOption: "",
	shipStartDate: "",
	shipEndDate: "",
	deliveryDateOption: "",
	deliveryStartDate: "",
	deliveryEndDate: "",
	originCarriers: [],
	destinationCarriers: [],
	tntStatus: "",
	shipmentStatus: "",
	toZip: "",
	fromZip: "",
	serviceType: "",
	additionalFilters: [],
	selectedServiceType: [],
	selectedSavedFilter: null,
	// Details of shipment date options
	shipmentDateDetails: {
		"Within the last": {
			n: 0,
			type: "day",
		},
		"More than": {
			n: 0,
			type: "day",
		},
		Between: {
			startDate: "",
			endDate: "",
		},
		"In the range": {
			startDate: "",
			endDate: "",
		},
	},
	// Details of delivery date options
	deliveryDateDetails: {
		"Now overdue": {
			n: 0,
			type: "day",
		},
		"Within the last": {
			n: 0,
			type: "day",
		},
		"More than": {
			n: 0,
			type: "day",
		},
		Between: {
			startDate: "",
			endDate: "",
		},
		"In the range": {
			startDate: "",
			endDate: "",
		},
	},
};

function Shipments() {
	const { isDemo, user, filters, shipmentFilters, setShipmentFilters, token } =
		useContext(UserContext);
	const {
		isOpen: isOpenAddFieldPopover,
		onOpen: onOpenAddFieldPopover,
		onClose: onCloseAddFieldPopover,
	} = useDisclosure();
	const dispatch = useDispatch();
	const toast = useToast();

	const PAGE_OPTIONS = [25, 50, 100];
	const [sortConfig, setSortConfig] = useState({ field: "", order: "" });
	const [isExpandIds, setIsExpandIds] = useState(false);
	const [searchInput, setSearchInput] = useState("");
	const [searchedValue, setSearchedValue] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [displayedData, setDisplayedData] = useState([]);
	const [paginationConfig, setPaginationConfig] = useState({
		options: PAGE_OPTIONS,
		perPage: PAGE_OPTIONS[0],
	});
	const [fetchMaxParcels, setFetchMaxParcels] = useState(0);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [additionalTableFields, setAdditionalTableFields] = useState([]);
	const [selectedFields, setSelectedFields] = useState([]);
	const [participant, setParticipant] = useState([]);
	const [appliedFilter, setAppliedFilter] = useState(null);
	const [selectedFilter, setSelectedFilter] = useState(null);
	const [shipmentFilterName, setShipmentFilterName] = useState(null);

	const [previousFilteredValue, setPreviousFilteredValue] =
		useState(initialValues);

	const formik = useFormik({
		initialValues,
		// initialValues: loadFilters(), // Load initial values from local storage
		onSubmit: (values) => {
			fetchData("", 1, values);
			setIsFilterOpen(false);
			setPreviousFilteredValue(values);
		},
	});

	const [additionalFilterFields, setAdditionalFilterFields] = useState({
		filters: formik?.values?.additionalFilters || [],
		serviceType: formik?.values?.selectedServiceType || [],
	});

	useEffect(() => {
		// On component mount, check local storage for saved filters
		const savedFilters = JSON.parse(localStorage.getItem("shipmentFilters"));
		if (savedFilters) {
			dispatch(setFilters(savedFilters)); // Update Redux state with saved filters
		}
	}, [dispatch]);

	const handleOpenFilter = () => {
		setIsFilterOpen(true);
		if (appliedFilter) {
			const additionalFilter = [];
			let additionalFields = {};
			if (appliedFilter.filterState.serviceType) {
				additionalFilter.push("Service Type");
				additionalFields = {
					serviceType: ["Ground", "Deferred Ground", "RETURN"],
				};

				formik.setFieldValue("selectedServiceType", [
					"Ground",
					"Deferred Ground",
					"RETURN",
				]);
			}
			if (appliedFilter.filterState.toZip) {
				additionalFilter.push("To Zip");
			}
			if (appliedFilter.filterState.fromZip) {
				additionalFilter.push("From Zip");
			}
			setAdditionalFilterFields({
				...additionalFields,
				filters: additionalFilter,
			});
			formik.setFieldValue("additionalFilters", additionalFilter);
		}
	};

	const handleCancelFilter = () => {
		formik.setValues({
			...previousFilteredValue,
			additionalFilters: formik.values.additionalFilters,
			selectedServiceType: formik.values.selectedServiceType,
		});
		setIsFilterOpen(false);
		setSelectedFilter(null);
	};

	const handleApplySelectedFilters = () => {
		const selectedFilter = (filters || []).find(
			(filter) => filter.name === formik.values.selectedSavedFilter,
		);

		// early return if none of the saved filter selected
		if (!selectedFilter) {
			setAppliedFilter(null);
			return;
		}

		setAppliedFilter(selectedFilter);

		Object.keys(selectedFilter).forEach((key) => {
			formik.setFieldValue(key, selectedFilter[key]);
		});
	};

	const permission = user.Groups[0];

	const isCirro = useMemo(() => permission === "CR", [permission]);

	useEffect(() => {
		if (!user || !token) return;

		(async () => {
			try {
				// Determine the participant code based on the user role
				if (user.isPlatformAdmin) {
					// No participant code for admin role
					const response = await getParticipants(token);
					setParticipant(response);
				} else {
					const response = await getParticipants(token, permission);
					setParticipant(response);
				}
			} catch (error) {
				console.error("Error fetching participant:", error);
			}
		})();
	}, [permission, token, user]);

	const fetchData = useCallback(
		(trackingNumber = "", page = 1, values = {}) => {
			return new Promise((resolve, reject) => {
				// Rest of the code
				if (user.Groups.length > 0) {
					const carrierCode = user.Groups.find((group) =>
						[...CARRIER_CODES, ...SHIPPER_CODES].includes(group),
					);

					if (!carrierCode) {
						console.error("User does not have a viable carrier code.");
						return Promise.reject(
							new Error("User does not have a viable carrier code."),
						);
					}

					setIsLoading(true);

					const requestBody = createRequestBody(trackingNumber, page, values);

					if (requestBody.delivered) {
						requestBody.delivered =
							requestBody.delivered === "Delivered" ? true : false;
					}

					if (sortConfig.field && sortConfig.order) {
						requestBody.sortField = sortConfig.field;
						requestBody.sortOrder = sortConfig.order;
					} else {
						delete requestBody.sortField;
						delete requestBody.sortOrder;
					}

					return fetch(apiUrl + "/get-parcels", {
						method: "POST",
						headers: {
							"Content-Type": "application/json; charset=UTF-8",
							Authorization: token,
						},
						body: JSON.stringify(requestBody),
					})
						.then((response) => {
							if (!response.ok) {
								throw new Error("Network response was not ok");
							}
							return response.json();
						})
						.then((response) => {
							if (response && response.parcels) {
								setFetchMaxParcels(response.count);
								setTotalPages(
									Math.ceil(response.count / paginationConfig.perPage),
								);
								setDisplayedData(response.parcels);
							} else {
								console.error("Unexpected response format:", response);
								return Promise.reject(new Error("Unexpected response format"));
							}
						})
						.catch((error) => {
							console.error("Error:", error);
							return Promise.reject(error);
						})
						.finally(() => {
							setIsLoading(false);
						});
				} else {
					return Promise.reject(new Error("User has no groups."));
				}
			});
		},
		// eslint-disable-next-line
		[
			user,
			paginationConfig.perPage,
			searchInput,
			previousFilteredValue,
			sortConfig,
			token,
		],
	);

	const subtractFromTodaysDate = (type, n) => {
		if (type === "day") {
			return subDays(new Date(), n);
		} else if (type === "week") {
			return subWeeks(new Date(), n);
		} else if (type === "month") {
			return subMonths(new Date(), n);
		}
	};

	const createRequestBody = (trackingNumber, page, values, doOmit = true) => {
		const {
			originCarriers,
			destinationCarriers,
			serviceType,
			shipmentStatus,
			shipStartDate,
			shipEndDate,
			deliveryStartDate,
			deliveryEndDate,
			tntStatus,
			toZip,
			fromZip,
			shipmentDateOption,
			shipmentDateDetails,
			deliveryDateOption,
			deliveryDateDetails,
		} = values;

		// Derive the shipment start date and end date
		let shipmentStartDate = shipStartDate;
		let shipmentEndDate = shipEndDate;
		if (shipmentDateOption) {
			switch (shipmentDateOption) {
				case "Within the last":
					shipmentStartDate = subtractFromTodaysDate(
						shipmentDateDetails[shipmentDateOption].type,
						shipmentDateDetails[shipmentDateOption].n,
					);
					shipmentEndDate = new Date();
					break;
				case "More than":
					shipmentStartDate = null;
					shipmentEndDate = subtractFromTodaysDate(
						shipmentDateDetails[shipmentDateOption].type,
						shipmentDateDetails[shipmentDateOption].n,
					);
					break;
				case "Between":
					shipmentStartDate = shipmentDateDetails[shipmentDateOption].startDate;
					shipmentEndDate = shipmentDateDetails[shipmentDateOption].endDate;
					break;
				case "In the range":
					shipmentStartDate = shipmentDateDetails[shipmentDateOption].startDate;
					shipmentEndDate = shipmentDateDetails[shipmentDateOption].endDate;
					break;
				default:
					break;
			}
		}

		// Derive the delivery start date and end date
		let derivedDeliveryStartDate = deliveryStartDate;
		let derivedDeliveryEndDate = deliveryEndDate;

		if (deliveryDateOption) {
			switch (deliveryDateOption) {
				case "Now overdue":
					derivedDeliveryStartDate = subtractFromTodaysDate(
						deliveryDateDetails[deliveryDateOption].type,
						deliveryDateDetails[deliveryDateOption].n,
					);
					derivedDeliveryEndDate = new Date();
					break;
				case "Within the last":
					derivedDeliveryStartDate = subtractFromTodaysDate(
						deliveryDateDetails[deliveryDateOption].type,
						deliveryDateDetails[deliveryDateOption].n,
					);
					derivedDeliveryEndDate = new Date();
					break;
				case "More than":
					derivedDeliveryStartDate = null;
					derivedDeliveryEndDate = subtractFromTodaysDate(
						deliveryDateDetails[deliveryDateOption].type,
						deliveryDateDetails[deliveryDateOption].n,
					);
					break;
				case "Between":
					derivedDeliveryStartDate =
						deliveryDateDetails[deliveryDateOption].startDate;
					derivedDeliveryEndDate =
						deliveryDateDetails[deliveryDateOption].endDate;
					break;
				case "In the range":
					derivedDeliveryStartDate =
						deliveryDateDetails[deliveryDateOption].startDate;
					derivedDeliveryEndDate =
						deliveryDateDetails[deliveryDateOption].endDate;
					break;
				default:
					break;
			}
		}

		const body = {
			shipperCode:
				user.isShipper && !user.isPlatformAdmin
					? [permission]
					: originCarriers || [],
			carrierCode:
				user.isCarrier && !user.isPlatformAdmin
					? [permission]
					: destinationCarriers || [],
			trackingNumber: searchInput || trackingNumber,
			serviceType:
				serviceType === "Ground"
					? "GRD"
					: serviceType === "Deferred Ground"
					? "DGRD"
					: serviceType === "RETURN"
					? "RETURN"
					: "",
			shipStartDate:
				shipmentStartDate && formatDate(shipmentStartDate, "yyyy-MM-dd"),
			shipEndDate: shipmentEndDate && formatDate(shipmentEndDate, "yyyy-MM-dd"),
			deliveryStartDate:
				derivedDeliveryStartDate &&
				formatDate(derivedDeliveryStartDate, "yyyy-MM-dd"),
			deliveryEndDate:
				derivedDeliveryEndDate &&
				formatDate(derivedDeliveryEndDate, "yyyy-MM-dd"),
			tntStatus,
			delivered: shipmentStatus,
			toZip,
			fromZip,
			start: page,
			limit: paginationConfig.perPage,
		};

		if (doOmit) {
			return omitBy(body, (val) => {
				return !val;
			});
		} else {
			return body;
		}
	};

	// Object generation for save filter operation
	const createSaveFilterDetailsObject = (values) => {
		const {
			destinationCarriers,
			originCarriers,
			serviceType,
			shipmentStatus,
			// shipStartDate,
			// shipEndDate,
			// deliveryStartDate,
			// deliveryEndDate,
			tntStatus,
			toZip,
			fromZip,
			shipmentDateOption,
			shipmentDateDetails,
			deliveryDateOption,
			deliveryDateDetails,
		} = values;

		const obj = {
			originCarriers: originCarriers || [],
			destinationCarriers: destinationCarriers || [],
			serviceType:
				serviceType === "Ground"
					? "GRD"
					: serviceType === "Deferred Ground"
					? "DGRD"
					: serviceType === "RETURN"
					? "RETURN"
					: "",
			shipmentDateOption,
			shipmentDateValues: ["Within the last", "More than"].includes(
				shipmentDateOption,
			)
				? [
						shipmentDateDetails[shipmentDateOption].n,
						shipmentDateDetails[shipmentDateOption].type,
				  ]
				: ["Between", "In the range"].includes(shipmentDateOption)
				? [
						formatDate(
							shipmentDateDetails[shipmentDateOption].startDate,
							"yyyy-MM-dd",
						),
						formatDate(
							shipmentDateDetails[shipmentDateOption].endDate,
							"yyyy-MM-dd",
						),
				  ]
				: [],
			// shipStartDate: shipStartDate && formatDate(shipStartDate, "yyyy-MM-dd"),
			// shipEndDate: shipEndDate && formatDate(shipEndDate, "yyyy-MM-dd"),
			deliveryDateOption: deliveryDateOption,
			deliveryDateValues: [
				"Within the last",
				"More than",
				"Now overdue",
			].includes(deliveryDateOption)
				? [
						deliveryDateDetails[deliveryDateOption].n,
						deliveryDateDetails[deliveryDateOption].type,
				  ]
				: ["Between", "In the range"].includes(deliveryDateOption)
				? [
						formatDate(
							deliveryDateDetails[deliveryDateOption].startDate,
							"yyyy-MM-dd",
						),
						formatDate(
							deliveryDateDetails[deliveryDateOption].endDate,
							"yyyy-MM-dd",
						),
				  ]
				: [],
			// deliveryStartDate:
			//   deliveryStartDate && formatDate(deliveryStartDate, "yyyy-MM-dd"),
			// deliveryEndDate:
			//   deliveryEndDate && formatDate(deliveryEndDate, "yyyy-MM-dd"),
			tntStatus,
			deliveryStatus: shipmentStatus,
			toZip,
			fromZip,
		};

		return obj;
	};

	const handleSaveFilter = async (filter, shouldApply = false) => {
		const requestBody = {
			...filter,
			username: user.email,
			filterState: createSaveFilterDetailsObject(formik.values),
		};

		if (!token) return;

		try {
			await saveFilter(requestBody, token);
			setPreviousFilteredValue(formik.values);
			if (shouldApply) {
				fetchData("", 1, formik.values);
			}
			toast({ title: "Filter saved successfully!", status: "success" });
			dispatch(setFilters(formik.values));
			if (selectedFilter) {
				setIsFilterOpen(false);
			}
		} catch (error) {
			toast({ title: "Failed to save filter!", status: "error" });
			console.error(`Error fetching data:`, error);
		}
	};

	useEffect(() => {
		if (!isEqual(initialValues, formik.values)) {
			fetchData(searchInput, currentPage, formik.values);
		} else {
			fetchData(searchInput, currentPage);
		}
		// eslint-disable-next-line
	}, [searchInput, currentPage, paginationConfig.perPage, sortConfig]);

	const handleIndexChange = useCallback((start, limit) => {
		const newPage = Math.floor((start - 1) / limit) + 1;
		setCurrentPage(newPage);
	}, []);

	const handleSearchChange = useCallback((e) => {
		if (e.target.value === "") {
			setSearchInput("");
		}
		setSearchedValue(() => e.target.value);
	}, []);

	const handleSearchSubmit = useCallback(
		(e) => {
			if (e.key === "Enter") {
				e.preventDefault();
				setSearchInput(searchedValue);
			}
		},
		[searchedValue],
	);

	const handleClearFilter = () => {
		Object.keys(initialValues).forEach((key) => {
			if (!(key === "additionalFilters" || key === "selectedServiceType")) {
				formik.setFieldValue(key, initialValues[key]);
			}
		});
	};

	const handleClearAllFilter = () => {
		fetchData("", 1, formik.resetForm());
		setPreviousFilteredValue({});
		setAppliedFilter(null);
	};

	const handleOpenSavedFilter = (filter) => {
		setIsFilterOpen(true);
		setSelectedFilter(filter);

		const additionalFilter = [];
		let additionalFields = {};
		if (filter.filterState.serviceType) {
			additionalFilter.push("Service Type");
			additionalFields = {
				serviceType: ["Ground", "Deferred Ground", "RETURN"],
			};

			formik.setFieldValue("selectedServiceType", [
				"Ground",
				"Deferred Ground",
				"RETURN",
			]);
		}
		if (filter.filterState.toZip) {
			additionalFilter.push("To Zip");
		}
		if (filter.filterState.fromZip) {
			additionalFilter.push("From Zip");
		}
		setAdditionalFilterFields({
			...additionalFields,
			filters: additionalFilter,
		});
		formik.setFieldValue("additionalFilters", additionalFilter);
	};

	const deleteFilterHandler = async (filterName) => {
		setShipmentFilterName(filterName);
		const requestBody = {
			username: user.email,
			filterNames: [filterName],
		};
		try {
			await deleteFilter(requestBody);
			toast({ title: "Filter deleted successfully!", status: "success" });
		} catch (error) {
			console.error(`Error deleting filter:`, error);
			toast({ title: "Failed to delete filter!", status: "error" });
		}
	};

	const handleSort = (field) => {
		setSortConfig((prevSortConfig) => {
			const order =
				prevSortConfig.field === field
					? prevSortConfig.order === "ASC"
						? "DESC"
						: prevSortConfig.order === "DESC"
						? ""
						: "ASC"
					: "ASC";
			return { field, order };
		});
	};

	const getSortArrow = (field) => {
		if (sortConfig.field !== field) return "";
		return sortConfig.order === "ASC"
			? "↑"
			: sortConfig.order === "DESC"
			? "↓"
			: "";
	};

	useEffect(() => {
		if (shipmentFilters && appliedFilter?.name === shipmentFilterName) {
			setAppliedFilter(null);
			fetchData("", 1, formik.resetForm());
			setPreviousFilteredValue({});
			setShipmentFilterName(null);
		}
		setShipmentFilters(false);
		// eslint-disable-next-line
	}, [shipmentFilters]);

	return (
		<Page>
			<Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
				<Heading as={"h1"} size={"md"}>
					Shipments
				</Heading>
				{isDemo && (
					<Flex gap={2}>
						<Button
							prefixIcon={<SettingsIcon />}
							context="textSecondary"
							className="font-semibold [&>svg]:!w-[1em]"
						>
							Configure
						</Button>
						<Button
							prefixIcon={<DownloadIcon />}
							className="font-semibold [&>svg]:!w-[1em] !rounded"
						>
							Export
						</Button>
					</Flex>
				)}
			</Flex>
			<div className="flex items-center justify-between mb-5">
				<div className="flex items-center flex-grow gap-3">
					<FormControl maxW={"380px"} display={"flex"} gap={3}>
						<FormLabel as={VisuallyHidden}>Search</FormLabel>
						<InputGroup>
							<InputLeftElement>
								<SearchIcon />
							</InputLeftElement>
							<Input
								placeholder="Enter Tracking ID"
								type="search"
								onKeyDown={handleSearchSubmit}
								value={searchedValue}
								onChange={handleSearchChange}
							/>
						</InputGroup>
					</FormControl>
					<div className="flex items-center gap-2">
						{appliedFilter && (
							<Button
								context="text"
								className="bg-[#6FC3FD33] !text-[#EBEBEB] rounded-[5px] !h-[34px] text-sm font-medium"
								onClick={() => handleOpenSavedFilter(appliedFilter)}
							>
								{appliedFilter.name}
							</Button>
						)}
					</div>
				</div>
				<div className="flex justify-center gap-2.5">
					{appliedFilter?.name && (
						<Button
							context="outlined"
							className="text-sm font-medium w-[142px]"
							onClick={handleClearAllFilter}
						>
							Clear Filter
						</Button>
					)}
					<Button
						context="outlined"
						className="!gap-3 text-sm font-medium"
						onClick={handleOpenFilter}
						suffixIcon={<img alt="filter" src="/images/filter.svg" />}
					>
						Filter
					</Button>
				</div>
			</div>
			<div className="relative">
				<TableContainer className="max-h-[calc(100vh_-_294px)] !overflow-y-auto w-full mb-4">
					<Table>
						<Thead>
							<Tr className="h-[52px]">
								<Th>
									<Tooltip
										content={`Click here to ${
											isExpandIds ? "collapse" : "expand"
										} "Carrier Tracking ID" & "USPS ID"`}
										align="center"
										side="bottom"
										delayDuration={0}
										className="!bg-[#22252f] z-[999]"
									>
										<div
											className="flex"
											onClick={() =>
												setIsExpandIds((isExpandIds) => !isExpandIds)
											}
										>
											Tracking ID
											<div className="mt-[-16px]">
												<InfoIcon />
											</div>
										</div>
									</Tooltip>
								</Th>
								{isExpandIds && (
									<>
										<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>
											Carrier Tracking ID
										</Th>
										<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>
											USPS ID
										</Th>
									</>
								)}
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>Date</Th>
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>From</Th>
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>To</Th>
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>
									Origin {isCirro ? "" : "Carrier"}
								</Th>
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>
									Destination Carrier
								</Th>
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>
									Dimensions
								</Th>
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>
									Weight
								</Th>
								{isDemo && <Th>Fleet</Th>}
								<Th style={{ fontFamily: '"Montserrat", sans-serif' }}>ETA</Th>
								<Th
									style={{
										fontFamily: '"Montserrat", sans-serif',
										cursor: "pointer",
									}}
									onClick={() => handleSort("Shipment Date")}
								>
									Shipment Date {getSortArrow("Shipment Date")}
								</Th>
								<Th
									style={{
										fontFamily: '"Montserrat", sans-serif',
										cursor: "pointer",
									}}
									onClick={() => handleSort("Actual Delivery Date")}
								>
									Actual Delivery Date {getSortArrow("Actual Delivery Date")}
								</Th>

								{additionalTableFields.map((field) => (
									<Th key={field}>{field}</Th>
								))}
							</Tr>
						</Thead>
						<Tbody>
							{isLoading && <TableLoading data={displayedData} />}
							{!isLoading && displayedData.length === 0 && (
								<Tr>
									<Td colSpan="100">
										No parcels, try a different search or clear the filter
									</Td>
								</Tr>
							)}
							{!isLoading &&
								displayedData &&
								displayedData.map((entry) => (
									<Tr className={classes.tr} key={entry.trackingNumber}>
										<Td>
											<Text
												as={Link}
												to={`/app/shipments/${entry.trackingNumber}`}
											>
												{entry.trackingNumber}
											</Text>
										</Td>
										{isExpandIds && (
											<>
												<Td>
													<Text>{entry.participant1TrackingId}</Text>
												</Td>
												<Td>
													<Text>{entry.uspsTrackingId}</Text>
												</Td>
											</>
										)}
										<Td>
											{entry.createdAt && (
												<time dateTime={format(entry.createdAt, "yyyy-MM-dd")}>
													{entry.createdAt}
												</time>
											)}
										</Td>
										<Td>{entry.shipFrom ? entry.shipFrom.zip : ""}</Td>
										<Td>{entry.shipTo ? entry.shipTo.zip : ""}</Td>
										<Td>{entry.originCode} </Td>
										<Td>{entry.destinationCode}</Td>
										<Td>
											{entry.dimensions
												? `${entry.dimensions.length} x
                         ${entry.dimensions.width} x
                         ${entry.dimensions.height}
                         ${
														entry.dimensions.unit === "inch"
															? `${entry.dimensions.unit}es`
															: entry.dimensions.unit
													}`
												: ""}
										</Td>
										<Td>
											{entry.weight ? entry.weight.value : ""}{" "}
											{entry.weight ? entry.weight.unit : ""}
										</Td>
										{isDemo && (
											<Td>
												<Text as={Link} to={`/app/shipments/track-trace`}>
													View
												</Text>
											</Td>
										)}
										<Td>
											{entry.expectedDate && (
												<time
													dateTime={format(entry.expectedDate, "yyyy-MM-dd")}
												>
													{entry.expectedDate}
												</time>
											)}
										</Td>
										<Td>
											{entry.shipDate && (
												<time dateTime={format(entry.shipDate, "yyyy-MM-dd")}>
													{entry.shipDate}
												</time>
											)}
										</Td>
										<Td>
											{entry.actualDeliveryDate && (
												<time
													dateTime={format(
														entry.actualDeliveryDate,
														"yyyy-MM-dd",
													)}
												>
													{entry.actualDeliveryDate}
												</time>
											)}
										</Td>
										{additionalTableFields.map((field) => (
											<Td key={field}>
												{field === "Service Type" ? (
													entry.serviceType
												) : field === "Shipment Date" ? (
													<time dateTime={format(entry.shipDate, "yyyy-MM-dd")}>
														{entry.shipDate}
													</time>
												) : field === "Delivered" ? (
													entry.delivered ? (
														"Delivered"
													) : (
														"Not Delivered"
													)
												) : field === "Actual Delivery Date" ? (
													entry.actualDeliveryDate && (
														<time
															dateTime={format(
																entry.actualDeliveryDate,
																"yyyy-MM-dd",
															)}
														>
															{entry.actualDeliveryDate}
														</time>
													)
												) : (
													""
												)}
											</Td>
										))}
									</Tr>
								))}
						</Tbody>
					</Table>
				</TableContainer>
				<Popover
					content={
						<TableColumnPreferences
							onClose={onCloseAddFieldPopover}
							setAdditionalTableFields={setAdditionalTableFields}
							setSelectedFields={setSelectedFields}
							selectedFields={selectedFields}
						/>
					}
					align="end"
					hasArrow={false}
					side="bottom"
					className="!bg-transparent"
					open={isOpenAddFieldPopover}
					triggerClassName="absolute top-0 right-0 z-[99]"
				>
					<IconButton
						as="span"
						className="!bg-[#003F72] !rounded-none !h-[51.5px] !min-w-[26px]"
						onClick={onOpenAddFieldPopover}
					>
						<AddIcon w={3.5} h={3.5} />
					</IconButton>
				</Popover>
			</div>
			<Pagination
				config={paginationConfig}
				start={(currentPage - 1) * paginationConfig.perPage + 1}
				count={fetchMaxParcels}
				onIndexChange={handleIndexChange}
				setPaginationConfig={setPaginationConfig}
				totalPages={totalPages}
				labelText="Number of rows"
			/>
			<FilterPanel
				isOpen={isFilterOpen}
				onClose={handleCancelFilter}
				formik={formik}
				participant={participant}
				additionalFilterFields={additionalFilterFields}
				setAdditionalFilterFields={setAdditionalFilterFields}
				handleClearFilter={handleClearFilter}
				handleSaveFilter={handleSaveFilter}
				onDeleteFilter={deleteFilterHandler}
				handleApplySelectedFilters={handleApplySelectedFilters}
				selectedFilter={selectedFilter}
			/>
		</Page>
	);
}

export default Shipments;
