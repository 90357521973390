import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Button,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import PopUpModal from "../interfaces/PopUpModal";
import { Page, TableLoading, UserContext } from "../interfaces";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const apiUrl = coreServiceUrl + coreServicePath;

function Containers() {
  const [isLoading, setIsLoading] = useState(true);
  const [containerData, setContainerData] = useState([]);
  const { user, token } = useContext(UserContext);

  const fetchContainerData = useCallback(() => {
    if (!user || !user.Groups) {
      console.error("User data or Groups not available");
      return;
    }

    const permission = user.Groups[0];

    if (!permission) return;

    if (user.isCarrier) {
      setIsLoading(true);

      fetch(apiUrl + `/containers?carrierCode=${permission}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setContainerData(data.containers);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      }
  }, [user, token]);

  useEffect(() => {
    fetchContainerData();
  }, [fetchContainerData]);

  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false); // State to manage the popup visibility
  const openCreatePopup = () => setCreatePopupOpen(true);
  const closeCreatePopup = () => setCreatePopupOpen(false);

  const downloadLabel = (e, labelName) => {
    e.preventDefault();
    const link = document.createElement("a");
    link.href = labelName;
    link.download = `Container-${labelName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Page>
        <Flex justifyContent={"space-between"} alignItems={"center"} mb={3}>
          <Heading as={"h1"} size={"md"}>
            Containers
          </Heading>
          <Button onClick={openCreatePopup} className="chakra-primary-button">
            Create
          </Button>
        </Flex>
        <TableContainer className="max-h-[calc(100vh_-_200px)] !overflow-y-auto w-full mb-4">
          <Table>
            <Thead>
              <Tr>
                <Th>Container ID</Th>
                <Th>Middle MIle Lane</Th>
                <Th>Destination</Th>
                <Th>Shipments</Th>
                <Th>Status</Th>
                <Th>Label</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && <TableLoading />}
              {!isLoading && containerData && containerData.length === 0 && (
                <Tr>
                  <Td colSpan="100">No data available</Td>
                </Tr>
              )}
              {containerData &&
                containerData.map((container) => (
                  <Tr key={container.containerTrackingId}>
                    <Td>{container.containerTrackingId}</Td>
                    <Td>{container.lane}</Td>
                    <Td>{container.destinationLocation}</Td>
                    <Td>{container.parcels}</Td>
                    <Td>{container.status}</Td>
                    <Td>
                      {container.labelName && (
                        <Button
                          variant="ghost"
                          size={"sm"}
                          color="blue.500"
                          onClick={(e) => downloadLabel(e, container.labelName)}
                        >
                          Download
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Page>

      <PopUpModal
        isOpen={isCreatePopupOpen}
        onClose={() => {
          closeCreatePopup();
          fetchContainerData(); // Refresh table after closing modal
        }}
      />
    </>
  );
}

export default Containers;
