import React, { useEffect, useState, useContext, useRef } from "react";
import {
	Alert,
	AlertDescription,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	AlertIcon,
	Card,
	FormControl,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	NumberInput,
	NumberInputField,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useToast,
} from "@chakra-ui/react";
import {
	AccountTabs,
	ExpandIcon,
	ImportFile,
	Page,
	UserContext,
} from "../interfaces";
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import Button from "../componentLibrary/components/Button";
import * as XLSX from "xlsx";
import { getRateCardDetails, updateRateCardDetails } from "../services";
import { RadioGroup, Radio } from "../componentLibrary";
import { format, isValid } from "date-fns";
import ExcelJS from "exceljs";

const WEIGHT_TYPES = {
	LBS: "LB",
	OZ: "OZ",
};

function Rates() {
	const toast = useToast();
	const { isNonProdEnvironment, token, user, viewingAs } =
		useContext(UserContext);
	const [selectedOption, setSelectedOption] = useState("CARRIER");
	const [showRateCardModal, setShowRateCardModal] = useState(false);
	const [zonesForWeightTypes, setZonesForWeightTypes] = useState({
		[WEIGHT_TYPES.OZ]: [],
		[WEIGHT_TYPES.LBS]: [],
	});
	const [zoneWiseDetails, setZoneWiseDetails] = useState({});
	const [isFirstTime, setIsFirstTime] = useState(true);
	const [isAnomalyCheckPassed, setIsAnomalyCheckPassed] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isRateDownloading, setIsRateDownloading] = useState(false);
	const [isRateFetching, setIsRateFetching] = useState(false);
	const [isRateSaving, setIsRateSaving] = useState(false);
	const [serviceType, setServiceType] = useState("");
	const [existingRatesZoneWiseDetails, setExistingRatesZoneWiseDetails] =
		useState({});
	const [alertDetails, setAlertDetails] = useState(null);
	const [selectedShowTableOption, setSelectedShowTableOption] = useState(
		WEIGHT_TYPES.LBS,
	);
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const cancelRef = useRef();
	const permission = viewingAs?.Groups[0];

	useEffect(() => {
		if (viewingAs?.isCarrier) {
			setSelectedOption("CARRIER");
		} else if (viewingAs?.isShipper) {
			setSelectedOption("SHIPPER");
		}
	}, [user, viewingAs]);

	useEffect(() => {
		if (zoneWiseDetails && isAnyDataAvailable() && isFirstTime) {
			setIsFirstTime(false);
			checkAnomaly();
			// If OZ data available then show OZ table initially, else LBS table
			setSelectedShowTableOption(
				isOZDataAvailable() ? WEIGHT_TYPES.OZ : WEIGHT_TYPES.LBS,
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zoneWiseDetails]);

	useEffect(() => {
		const fetchRates = async () => {
			if (!serviceType) {
				return;
			}
			setIsRateFetching(true);
			const resData = await getRateCardDetails(
				viewingAs.participantCode,
				selectedOption,
				token,
			);

			if (resData && resData.length) {
				// Find the existing data with the file data service type
				const existingDataByServiceType = resData.find(
					(item) => item.serviceType === serviceType,
				);
				// Set zone wise data with the existing values
				if (existingDataByServiceType) {
					const existingData = existingDataByServiceType.rateCardRecords;
					const existingZWDetails = {};
					existingZWDetails[WEIGHT_TYPES.LBS] = [];
					existingZWDetails[WEIGHT_TYPES.OZ] = [];
					// Empty array for the 0th index as we are not considering it for zone access simplicity
					existingZWDetails[WEIGHT_TYPES.LBS][0] = [];
					existingZWDetails[WEIGHT_TYPES.OZ][0] = [];
					existingData.forEach((item, index) => {
						// Push the object for the respective zone
						if (
							existingZWDetails[item.weightUnit][+item.zone] &&
							existingZWDetails[item.weightUnit][+item.zone].length
						) {
							existingZWDetails[item.weightUnit][+item.zone].push(item);
						} else {
							existingZWDetails[item.weightUnit][+item.zone] = [item];
						}
					});
					setExistingRatesZoneWiseDetails(
						sortAllDataByWeight(existingZWDetails),
					);
				}
			}
			setIsRateFetching(false);
		};

		fetchRates();
	}, [selectedOption, serviceType, viewingAs, token]);

	const isAnyDataAvailable = () => {
		return (
			(zoneWiseDetails[WEIGHT_TYPES.LBS] &&
				zoneWiseDetails[WEIGHT_TYPES.LBS].length) ||
			(zoneWiseDetails[WEIGHT_TYPES.OZ] &&
				zoneWiseDetails[WEIGHT_TYPES.OZ].length)
		);
	};

	const areBothZonesDataAvailable = () => {
		return (
			zoneWiseDetails[WEIGHT_TYPES.LBS] &&
			zoneWiseDetails[WEIGHT_TYPES.LBS].length &&
			zoneWiseDetails[WEIGHT_TYPES.OZ] &&
			zoneWiseDetails[WEIGHT_TYPES.OZ].length
		);
	};

	const isOZDataAvailable = () =>
		zoneWiseDetails[WEIGHT_TYPES.OZ] && zoneWiseDetails[WEIGHT_TYPES.OZ].length;

	// const isLBSDataAvailable = () =>
	// 	zoneWiseDetails[WEIGHT_TYPES.LBS] &&
	// 	zoneWiseDetails[WEIGHT_TYPES.LBS].length;

	const generateRateCard = async () => {
		setIsRateDownloading(true);

		const resData = await getRateCardDetails(
			viewingAs.participantCode,
			selectedOption,
			token,
		);

		const isShipper = viewingAs.isShipper;
		const isCarrier = viewingAs.isCarrier;

		let response;
		if (isShipper) {
			response = await fetch(
				`/data-template/EMPTY-SHIPPER-RATE-CARD.xlsx?t=${new Date().getTime()}`,
			);
		} else if (isCarrier) {
			response = await fetch(
				`/data-template/EMPTY-CARRIER-RATE-CARD.xlsx?t=${new Date().getTime()}`,
			);
		} else {
			throw new Error("User is neither a Shipper nor a Carrier.");
		}

		if (!response.ok) throw new Error("Network response was not ok");

		const arrayBuffer = await response.arrayBuffer();
		const workbook = new ExcelJS.Workbook();
		await workbook.xlsx.load(arrayBuffer);

		let rateCardSheetName;
		if (isCarrier) {
			rateCardSheetName = "Base Rates_G";
		} else if (isShipper) {
			rateCardSheetName = "Base Rates_DGRD";
		}

		const sheet = workbook.getWorksheet(rateCardSheetName);
		if (!sheet)
			throw new Error(`Worksheet ${rateCardSheetName} not found for carrier.`);

		// Styles
		const tableCellFill = {
			type: "pattern",
			pattern: "solid",
			fgColor: { argb: "FFFFFF" },
		};

		const tableCellAlignment = {
			horizontal: "right",
		};

		const tableCellBorder = {
			top: { style: "thin" },
			left: { style: "thin" },
			bottom: { style: "thin" },
			right: { style: "thin" },
		};

		// const tableHeaderFont = {
		// 	font: { bold: true },
		// };

		const formattedRateArrayLB = [];
		const formattedRateArrayOZ = [];
		const groupedByWeightLB = {};
		const groupedByWeightOZ = {};

		// Group data by weight and unit (LB and OZ)
		if (resData && resData.length > 0 && resData[0]?.rateCardRecords?.length) {
			resData[0].rateCardRecords.forEach((record) => {
				const weightKey = `${record.weight}`;

				// Handling for LB (lbs table)
				if (record.weightUnit === "LB") {
					if (!groupedByWeightLB[weightKey]) {
						groupedByWeightLB[weightKey] = new Array(9).fill(null);
						groupedByWeightLB[weightKey][0] = weightKey; // Set weight value
					}

					let zoneIndex;
					// Shippers in LB start from zone 2
					if (isShipper) {
						zoneIndex = parseInt(record.zone) - 1; // Zones start from 2 for shippers in LB
					} else if (isCarrier) {
						zoneIndex = parseInt(record.zone); // Zones start from 1 for carriers
					}

					if (zoneIndex >= 1 && zoneIndex <= 9) {
						groupedByWeightLB[weightKey][zoneIndex] = `$${record.rate}`; // Place rate in correct column
					}
				}

				// Handling for OZ (oz table)
				if (record.weightUnit === "OZ") {
					if (!groupedByWeightOZ[weightKey]) {
						groupedByWeightOZ[weightKey] = new Array(9).fill(null);
						groupedByWeightOZ[weightKey][0] = weightKey;
					}

					let zoneIndex;
					// Shippers in OZ start from zone 1
					if (isShipper) {
						zoneIndex = parseInt(record.zone); // Zones start from 1 for shippers in OZ
					} else if (isCarrier) {
						zoneIndex = parseInt(record.zone); // Zones start from 1 for carriers
					}

					if (zoneIndex >= 1 && zoneIndex <= 9) {
						groupedByWeightOZ[weightKey][zoneIndex] = `$${record.rate}`;
					}
				}
			});
		} else {
			console.error("rateCardRecords is undefined or empty for carrier.");
			setIsRateDownloading(false);
			return; // Exit early if no data
		}

		// Finalize arrays for LB and OZ
		for (const weight in groupedByWeightLB) {
			formattedRateArrayLB.push(groupedByWeightLB[weight]);
		}

		for (const weight in groupedByWeightOZ) {
			formattedRateArrayOZ.push(groupedByWeightOZ[weight]);
		}

		// Ensure that there are rates to process
		if (
			formattedRateArrayLB.length === 0 &&
			formattedRateArrayOZ.length === 0
		) {
			console.error("No formatted rates to populate.");
			setIsRateDownloading(false);
			return; // No data to process
		}

		// Set header row indices based on shipper or carrier
		let headerRowIndexLB, headerRowIndexOZ, startColumnTable2LB;

		if (isShipper) {
			headerRowIndexLB = 34;
			headerRowIndexOZ = 11;
			startColumnTable2LB = 10;
		} else if (isCarrier) {
			headerRowIndexLB = 11;
			headerRowIndexOZ = 26;
			startColumnTable2LB = null;
		}

		// Clear previous data in the worksheet, excluding header rows
		sheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
			// Adjust this condition to avoid clearing the header rows (Rows 23, 24, 25 should not be cleared)
			if (
				(rowNumber > headerRowIndexLB && rowNumber <= headerRowIndexLB + 35) || // Clear LB data rows but not headers
				(rowNumber >= headerRowIndexOZ + 1 &&
					rowNumber <= headerRowIndexOZ + 16) // Clear OZ data rows but not headers
			) {
				row.eachCell({ includeEmpty: true }, (cell) => {
					if (cell.value && rowNumber > 25) {
						// Exclude rows 23, 24, 25 (headers)
						cell.value = null; // Clear the cell data, but keep the headers
					}
				});
			}
		});

		const zoneColumnMapping = {
			1: 2,
			2: 3,
			3: 4,
			4: 5,
			5: 6,
			6: 7,
			7: 8,
			8: 9,
			9: 10,
		};

		function copyCellStyle(sourceCell, targetCell) {
			if (sourceCell.font) targetCell.font = { ...sourceCell.font };
			if (sourceCell.alignment)
				targetCell.alignment = { ...sourceCell.alignment };
			if (sourceCell.fill) targetCell.fill = { ...sourceCell.fill };
			if (sourceCell.border) targetCell.border = { ...sourceCell.border };
			if (sourceCell.numFmt) targetCell.numFmt = sourceCell.numFmt;
		}

		let cellOneStyleSource;
		let cellOtherStyleSource;

		// Populate the first table for LB
		if (formattedRateArrayLB.length > 0) {
			formattedRateArrayLB.slice(0, 35).forEach((dataRow, rowIndex) => {
				const row = sheet.getRow(headerRowIndexLB + rowIndex);
				if (rowIndex === 0) {
					cellOneStyleSource = row.getCell(1);
					cellOtherStyleSource = row.getCell(2);
				}
				const stableSourceCell = cellOneStyleSource;
				row.getCell(1).value = dataRow[0]; // Set the LB weight in the first column
				if (row.getCell(1).isMerged) {
					sheet.unMergeCells(row.getCell(1).address);
					for (let i = rowIndex; i <= rowIndex + 3; i++) {
						const currentRow = sheet.getRow(headerRowIndexLB + i);
						currentRow.getCell(1).border.top.style = "thin";
						currentRow.height = 14.25;
						copyCellStyle(stableSourceCell, currentRow.getCell(1));
					}
				}

				if ((rowIndex >= 1 && rowIndex <= 14) || rowIndex >= 25) {
					row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
						if (colNumber >= 2 && colNumber <= 5) {
							copyCellStyle(cellOtherStyleSource, cell);
						}
					});
				}

				dataRow.slice(1).forEach((rate, index) => {
					const zone = index + 1; // Adjust index to start from zone 1
					const columnIndex = zoneColumnMapping[zone];
					const zoneCell = row.getCell(columnIndex);
					zoneCell.value = rate || null;
				});
			});
		} else {
			console.error("formattedRateArrayLB is empty for carrier.");
		}

		// Only populate the second table for shippers
		if (isShipper && formattedRateArrayLB.length > 35) {
			formattedRateArrayLB.slice(35).forEach((dataRow, rowIndex) => {
				const row = sheet.getRow(headerRowIndexLB + rowIndex);
				row.getCell(startColumnTable2LB).value = dataRow[0]; // Set LB weight for the second table in column 10
				row.getCell(startColumnTable2LB).border = tableCellBorder;
				row.getCell(startColumnTable2LB).fill = tableCellFill;

				dataRow.slice(1).forEach((rate, index) => {
					const zone = index + 1;
					if (zoneColumnMapping[zone]) {
						const cell = zoneColumnMapping[zone] + startColumnTable2LB - 1;
						row.getCell(cell).value = rate || null;
						row.getCell(cell).alignment = tableCellAlignment;
						row.getCell(cell).border = tableCellBorder;
						row.getCell(cell).fill = tableCellFill;
					}
				});
			});
		}

		// Populate the table for OZ
		if (formattedRateArrayOZ.length > 0) {
			formattedRateArrayOZ.slice(0, 16).forEach((dataRow, rowIndex) => {
				const row = sheet.getRow(headerRowIndexOZ + rowIndex); // Starting from row 11 for OZ data
				row.getCell(1).value = dataRow[0]; // Set the OZ weight in the first column
				row.getCell(1).border = tableCellBorder;
				row.getCell(1).fill = tableCellFill;

				dataRow.slice(1).forEach((rate, index) => {
					const zone = index + 1;
					if (zoneColumnMapping[zone]) {
						row.getCell(zoneColumnMapping[zone]).value = rate || null;
						row.getCell(zoneColumnMapping[zone]).alignment = tableCellAlignment;
						row.getCell(zoneColumnMapping[zone]).border = tableCellBorder;
						row.getCell(zoneColumnMapping[zone]).fill = tableCellFill;
					}
				});
			});
		} else {
			console.error("formattedRateArrayOZ is empty for carrier.");
		}

		// Optional: Adjust the row height for both tables if necessary
		sheet.getRow(
			headerRowIndexLB +
				Math.max(formattedRateArrayLB.length, formattedRateArrayOZ.length) -
				1,
		).height = 20;

		setIsRateDownloading(false);

		// Export the updated Excel sheet with error handling
		try {
			const newWorkbookData = await workbook.xlsx.writeBuffer();
			const blob = new Blob([newWorkbookData], {
				type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			});
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			if (isCarrier) {
				link.download = `${permission}_Carrier_Rate_Card.xlsx`;
			} else if (isShipper) {
				link.download = `${permission}_Shipper_Rate_Card.xlsx`;
			} else {
				link.download = "Rate_Card.xlsx";
			}
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		} catch (error) {
			console.error("Error while writing Excel buffer:", error);
		}
	};

	const onFileSelected = (file) => {
		setAlertDetails(null);
		const reader = new FileReader();
		reader.onload = (e) => {
			const data = new Uint8Array(e.target?.result);
			const workbook = XLSX.read(data, { type: "array" });

			// First worksheet data fetch logic
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			const sType = sheetName.split("_")[1];
			if (!sType) {
				toast({
					title: `Service type is missing in ${sheetName}!`,
					status: "error",
				});
				return;
			}

			let startDate = null;
			let endDate = null;
			let currency = null;

			// Variables to track weight units' row indices for both CARRIER and SHIPPER
			let weightOzRowIndex = -1;
			let weightLbsRowIndex = -1;

			// Define different labels for carrier and shipper
			const weightLabels =
				selectedOption === "CARRIER"
					? { oz: "Weight (oz)", lbs: "Weight (lbs)" }
					: { oz: "Weights (oz)", lbs: "Weights (lb)" };

			// Loop through sheet data to find "EFFECTIVE START DATE", "EFFECTIVE END DATE", "CURRENCY", and weight units
			for (let i = 0; i < sheetData.length; i++) {
				const row = sheetData[i];

				// Ensure row[0] is a string before calling includes
				if (
					typeof row[0] === "string" &&
					row[0].includes("EFFECTIVE START DATE")
				) {
					startDate = row[0].split(":")[1].trim();
					if (!isValid(new Date(startDate))) {
						toast({
							title: "Invalid start date in the sheet!",
							status: "error",
						});
						return;
					}
				}

				// Ensure row[0] is a string before calling includes
				else if (
					typeof row[0] === "string" &&
					row[0].includes("EFFECTIVE END DATE")
				) {
					endDate = row[0].split(":")[1].trim();
					if (!isValid(new Date(endDate))) {
						toast({ title: "Invalid end date in the sheet!", status: "error" });
						return;
					}
				}

				// Ensure row[0] is a string before calling includes
				else if (typeof row[0] === "string" && row[0].includes("CURRENCY")) {
					currency = row[0].split(":")[1].trim();
				}

				// Check for weight labels based on selectedOption
				// Ensure each cell is a string before calling includes
				else if (
					row.some(
						(cell) =>
							typeof cell === "string" && cell.includes(weightLabels.oz),
					)
				) {
					weightOzRowIndex = i;
				}

				// Ensure each cell is a string before calling includes
				else if (
					row.some(
						(cell) =>
							typeof cell === "string" && cell.includes(weightLabels.lbs),
					)
				) {
					weightLbsRowIndex = i;
				}

				// If both Weight (oz) and Weight (lbs) are found, break the loop early
				if (weightOzRowIndex !== -1 && weightLbsRowIndex !== -1) {
					break;
				}
			}

			// If the start date, end date, or currency is missing, show an error
			if (!startDate || !endDate || !currency) {
				toast({
					title: "Missing start date, end date, or currency in the sheet!",
					status: "error",
				});
				return;
			}

			// If the weight units are not found, show an error
			if (weightOzRowIndex === -1 || weightLbsRowIndex === -1) {
				toast({
					title: "Weight (oz) or Weight (lbs) not found in the sheet!",
					status: "error",
				});
				return;
			}
			setServiceType(sType);
			generateZoneWiseDetails(
				sheetData,
				sType,
				startDate,
				endDate,
				currency,
				weightOzRowIndex,
				weightLbsRowIndex,
			);
		};
		reader.readAsArrayBuffer(file);
	};

	const createItemObject = (
		networkCode,
		participantCode,
		serviceType,
		zone,
		weight,
		weightUnit,
		rate,
		currency,
		validFrom,
		validTo,
		isValidRate,
	) => {
		const cleanedRate = parseFloat(rate?.toString().replace(/[^0-9.]/g, ""));
		return {
			networkCode: networkCode ?? "OCN",
			participantCode,
			serviceType,
			zone: zone?.toString(),
			weight: weight?.toString(),
			weightUnit,
			rate: +cleanedRate?.toFixed(2),
			currency,
			validFrom,
			validTo,
			isValidRate,
		};
	};

	const sortAllDataByWeight = (data) => {
		const sortedData = [];
		Object.keys(data).forEach((weightType) => {
			sortedData[weightType] = data[weightType].map((zoneData) => {
				if (zoneData && zoneData.length) {
					return zoneData.sort((a, b) => +a.weight - +b.weight);
				}
				return [];
			});
		});
		return sortedData;
	};

	const createZoneWiseDetailsForCarrier = (
		fileData,
		sType,
		sDate,
		eDate,
		currency,
		weightOzRowIndex,
		weightLbsRowIndex,
	) => {
		const zsForWeightTypes = {
			[WEIGHT_TYPES.OZ]: [],
			[WEIGHT_TYPES.LBS]: [],
		};
		// Object with weight unit as a keys, those keys contains 2D array data
		// 1st index for zone, 2nd index for zone items
		const zwDetails = {};
		zwDetails[WEIGHT_TYPES.LBS] = [];
		zwDetails[WEIGHT_TYPES.OZ] = [];
		zwDetails[WEIGHT_TYPES.LBS][0] = [];
		zwDetails[WEIGHT_TYPES.OZ][0] = [];

		let startDate = format(sDate, "yyyy-MM-dd");
		let endDate = format(eDate, "yyyy-MM-dd");
		const curr = currency;
		let rowDetails = fileData[weightOzRowIndex];

		// Add zone in zones array as per first table weight unit
		for (const val of rowDetails.slice(1)) {
			if (!val) break;
			// To get zone number from string(ex. Zone 1)
			const num = val.toString().replace(/[^0-9]/g, "");
			zsForWeightTypes[WEIGHT_TYPES.OZ].push(+num);
		}

		// Start reading the first table data until we have values
		let fileDataReadIndex = weightOzRowIndex + 1;
		while (fileData[fileDataReadIndex] && fileData[fileDataReadIndex][1]) {
			rowDetails = fileData[fileDataReadIndex];
			const weightVal = rowDetails[0];
			rowDetails.slice(1).forEach((rateVal, index) => {
				const itemObj = createItemObject(
					null,
					viewingAs.participantCode,
					sType,
					zsForWeightTypes[WEIGHT_TYPES.OZ][index],
					weightVal,
					WEIGHT_TYPES.OZ,
					rateVal,
					curr,
					startDate,
					endDate,
					true,
				);
				if (
					zwDetails[WEIGHT_TYPES.OZ][
						zsForWeightTypes[WEIGHT_TYPES.OZ][index]
					] &&
					zwDetails[WEIGHT_TYPES.OZ][zsForWeightTypes[WEIGHT_TYPES.OZ][index]]
						.length
				) {
					zwDetails[WEIGHT_TYPES.OZ][
						zsForWeightTypes[WEIGHT_TYPES.OZ][index]
					].push(itemObj);
				} else {
					zwDetails[WEIGHT_TYPES.OZ][zsForWeightTypes[WEIGHT_TYPES.OZ][index]] =
						[itemObj];
				}
			});
			fileDataReadIndex++;
		}

		rowDetails = fileData[weightLbsRowIndex];

		// Add zone in zones array as per second table weight unit
		for (const val of rowDetails.slice(1)) {
			if (!val) break;
			// To get zone number from string(ex. Zone 1)
			const num = val.toString().replace(/[^0-9]/g, "");
			zsForWeightTypes[WEIGHT_TYPES.LBS].push(+num);
		}

		fileDataReadIndex = weightLbsRowIndex + 1;
		while (fileData[fileDataReadIndex] && fileData[fileDataReadIndex][1]) {
			rowDetails = fileData[fileDataReadIndex];
			if (rowDetails && rowDetails[0] !== undefined) {
				const weightVal = rowDetails[0];
				rowDetails.slice(1).forEach((rateVal, index) => {
					const itemObj = createItemObject(
						null,
						viewingAs.participantCode,
						sType,
						zsForWeightTypes[WEIGHT_TYPES.LBS][index],
						weightVal,
						WEIGHT_TYPES.LBS,
						rateVal,
						curr,
						startDate,
						endDate,
						true,
					);
					if (
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						] &&
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						].length
					) {
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						].push(itemObj);
					} else {
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						] = [itemObj];
					}
				});
			}
			fileDataReadIndex++;
		}

		return { zsForWeightTypes, zwDetails };
	};

	const createZoneWiseDetailsForShipper = (
		fileData,
		sType,
		sDate,
		eDate,
		currency,
		weightOzRowIndex,
		weightLbsRowIndex,
	) => {
		const zsForWeightTypes = {
			[WEIGHT_TYPES.OZ]: [],
			[WEIGHT_TYPES.LBS]: [],
		};
		// Object with weight unit as a keys, those keys contains 2D array data
		// 1st index for zone, 2nd index for zone items
		const zwDetails = {};
		zwDetails[WEIGHT_TYPES.LBS] = [];
		zwDetails[WEIGHT_TYPES.OZ] = [];
		zwDetails[WEIGHT_TYPES.LBS][0] = [];
		zwDetails[WEIGHT_TYPES.OZ][0] = [];

		let startDate = format(sDate, "yyyy-MM-dd");
		let endDate = format(eDate, "yyyy-MM-dd");
		const curr = currency;

		// Process the first table (Weight (oz))
		let rowDetails = fileData[weightOzRowIndex];

		// Add zone in zones array as per first table weight unit
		for (const val of rowDetails.slice(1)) {
			if (!val) break;
			// To get zone number from string(ex. Zone 1)
			const num = val.toString().replace(/[^0-9]/g, "");
			zsForWeightTypes[WEIGHT_TYPES.OZ].push(+num);
		}

		// Start reading the first table data until we have values
		let fileDataReadIndex = weightOzRowIndex + 1;
		while (fileData[fileDataReadIndex] && fileData[fileDataReadIndex][1]) {
			rowDetails = fileData[fileDataReadIndex];
			if (rowDetails && rowDetails[0] !== undefined) {
				const weightVal = rowDetails[0];
				rowDetails
					.slice(1, zsForWeightTypes[WEIGHT_TYPES.OZ].length + 1)
					.forEach((rateVal, index) => {
						const itemObj = createItemObject(
							null,
							viewingAs.participantCode,
							sType,
							zsForWeightTypes[WEIGHT_TYPES.OZ][index],
							weightVal,
							WEIGHT_TYPES.OZ,
							rateVal,
							curr,
							startDate,
							endDate,
							true,
						);
						if (
							zwDetails[WEIGHT_TYPES.OZ][
								zsForWeightTypes[WEIGHT_TYPES.OZ][index]
							] &&
							zwDetails[WEIGHT_TYPES.OZ][
								zsForWeightTypes[WEIGHT_TYPES.OZ][index]
							].length
						) {
							zwDetails[WEIGHT_TYPES.OZ][
								zsForWeightTypes[WEIGHT_TYPES.OZ][index]
							].push(itemObj);
						} else {
							zwDetails[WEIGHT_TYPES.OZ][
								zsForWeightTypes[WEIGHT_TYPES.OZ][index]
							] = [itemObj];
						}
					});
			}
			fileDataReadIndex++;
		}

		rowDetails = fileData[weightLbsRowIndex];

		for (const val of rowDetails.slice(1)) {
			if (!val) break;
			// To get zone number from string(ex. Zone 1)
			const num = val.toString().replace(/[^0-9]/g, "");
			zsForWeightTypes[WEIGHT_TYPES.LBS].push(+num);
		}
		fileDataReadIndex = weightLbsRowIndex + 1;

		while (fileData[fileDataReadIndex] && fileData[fileDataReadIndex][1]) {
			rowDetails = fileData[fileDataReadIndex];
			if (rowDetails && rowDetails[0] !== undefined) {
				const weightVal = rowDetails[0];

				rowDetails.slice(1).forEach((rateVal, index) => {
					const itemObj = createItemObject(
						null,
						viewingAs.participantCode,
						sType,
						zsForWeightTypes[WEIGHT_TYPES.LBS][index],
						weightVal,
						WEIGHT_TYPES.LBS,
						rateVal,
						curr,
						startDate,
						endDate,
						true,
					);
					if (
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						] &&
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						].length
					) {
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						].push(itemObj);
					} else {
						zwDetails[WEIGHT_TYPES.LBS][
							zsForWeightTypes[WEIGHT_TYPES.LBS][index]
						] = [itemObj];
					}
				});
			}
			fileDataReadIndex++;
		}

		return { zsForWeightTypes, zwDetails };
	};

	const generateZoneWiseDetails = (
		fileData,
		sType,
		startDate,
		endDate,
		currency,
		weightOzRowIndex,
		weightLbsRowIndex,
	) => {
		let zwDetails, detailsObj;

		try {
			if (selectedOption === "CARRIER") {
				detailsObj = createZoneWiseDetailsForCarrier(
					fileData,
					sType,
					startDate,
					endDate,
					currency,
					weightOzRowIndex,
					weightLbsRowIndex,
				);
			} else {
				detailsObj = createZoneWiseDetailsForShipper(
					fileData,
					sType,
					startDate,
					endDate,
					currency,
					weightOzRowIndex,
					weightLbsRowIndex,
				);
			}

			if (detailsObj) {
				setZonesForWeightTypes(detailsObj?.zsForWeightTypes);
				// Sort details by weight in each zones to render data in table correctly
				zwDetails = sortAllDataByWeight(detailsObj?.zwDetails);
				setZoneWiseDetails(zwDetails);
			}
		} catch (err) {
			console.log(err);
			toast({
				title: "Failed, Please check and correct the template file!",
				status: "error",
			});
		}
	};

	const checkAnomaly = () => {
		let areAllValuesValid = true;
		const meanArr = [];
		const thresholdArr = [];
		Object.keys(zoneWiseDetails).forEach((weightType) => {
			meanArr[weightType] = [];
			thresholdArr[weightType] = [];
		});

		if (!isAnyDataAvailable()) return;

		// Loop through first zone data to get idea about the number of entries and for each of the row need to find mean
		Object.keys(zoneWiseDetails).forEach((weightType) => {
			if (
				!zoneWiseDetails[weightType][zonesForWeightTypes[weightType][0]] ||
				!zoneWiseDetails[weightType][zonesForWeightTypes[weightType][0]].length
			) {
				return;
			}
			zoneWiseDetails[weightType][zonesForWeightTypes[weightType][0]].map(
				(_, index) => {
					const rateValuesForSpecificWeight = [];
					zonesForWeightTypes[weightType].map(
						(zone) =>
							zoneWiseDetails[weightType][zone] &&
							rateValuesForSpecificWeight.push(
								zoneWiseDetails[weightType][zone][index].rate,
							),
					);
					const mean =
						rateValuesForSpecificWeight.reduce((acc, val) => acc + val, 0) /
						rateValuesForSpecificWeight.length;
					const stdDev = Math.sqrt(
						rateValuesForSpecificWeight
							.map((x) => Math.pow(x - mean, 2))
							.reduce((a, b) => a + b) / rateValuesForSpecificWeight.length,
					);

					let threshold;

					if (viewingAs.isCarrier) {
						threshold = 1.2 * stdDev;
					} else {
						threshold = 2 * stdDev; // 2 standard deviations from the mean
					}
					meanArr[weightType].push(mean);
					thresholdArr[weightType].push(threshold);
				},
			);
		});

		// Again loop through the data to update isValid field with the help of mean, threshold values
		Object.keys(zoneWiseDetails).forEach((weightType) => {
			if (
				!zoneWiseDetails[weightType][zonesForWeightTypes[weightType][0]] ||
				!zoneWiseDetails[weightType][zonesForWeightTypes[weightType][0]].length
			) {
				return;
			}
			zoneWiseDetails[weightType][zonesForWeightTypes[weightType][0]].forEach(
				(_, index) => {
					zonesForWeightTypes[weightType].forEach((zone) => {
						const rate = zoneWiseDetails[weightType][zone]
							? +zoneWiseDetails[weightType][zone][index].rate
							: "";
						if (
							Math.abs(rate - meanArr[weightType][index]) >
							thresholdArr[weightType][index]
						) {
							areAllValuesValid = false;
							setZoneWiseDetails((prevDetails) => {
								const details = { ...prevDetails };
								if (details[weightType][zone]) {
									details[weightType][zone][index] = {
										...details[weightType][zone][index],
										isValidRate: false,
									};
								}
								return { ...details };
							});
						} else {
							setZoneWiseDetails((prevDetails) => {
								const details = { ...prevDetails };
								if (details[weightType][zone]) {
									details[weightType][zone][index] = {
										...details[weightType][zone][index],
										isValidRate: true,
									};
								}
								return { ...details };
							});
						}
					});
				},
			);
		});

		if (areAllValuesValid) {
			setAlertDetails({
				status: "success",
				title: "File uploaded successfully",
				description:
					"File uploaded successfully! Please review and save your file.",
				bgColor: "#22E5881A",
				iconColor: "#22E588",
			});
		} else {
			setAlertDetails({
				status: "error",
				title: "This is an error message",
				description: "Invalid rate(s) for some cells. Please check and update",
				bgColor: "#FF263F1A",
				iconColor: "#FF263F",
			});
		}

		setIsAnomalyCheckPassed(areAllValuesValid);
		return areAllValuesValid;
	};

	const updateHandler = (value, index, zone, weightUnit) => {
		if (value === "" || /^\d*\.?\d*$/.test(value)) {
			setZoneWiseDetails((prevDetails) => {
				const details = { ...prevDetails };
				if (details[weightUnit][zone]) {
					details[weightUnit][zone][index] = {
						...details[weightUnit][zone][index],
						rate: +value,
					};
				}

				return details;
			});
		} else {
			console.error(`Invalid rate at index: ${index}, zone: ${zone}`);
		}
	};

	const saveHandler = () => {
		setIsEditMode(false);
		const passed = checkAnomaly();
		if (passed) {
			setShowConfirmationDialog(true);
		}
	};

	const saveConfirmHandler = async () => {
		setShowConfirmationDialog(false);
		setIsRateSaving(true);
		const requestObj = {
			serviceType: serviceType,
			rateCardRecords: [
				...zoneWiseDetails[WEIGHT_TYPES.LBS],
				...zoneWiseDetails[WEIGHT_TYPES.OZ],
			]
				.flat()
				.filter((item) => !!item)
				.map(({ isValidRate, ...restProps }) => ({
					...restProps,
					rate: restProps.rate.toString(),
				})),
		};

		const res = await updateRateCardDetails(
			viewingAs.participantCode,
			selectedOption,
			[requestObj],
			token,
		);
		setIsRateSaving(false);
		if (res) {
			resetDetails();
			toast({ title: "Rates saved successfully!", status: "success" });
			return;
		}
		toast({ title: "Failed, Unable to Save Rates!", status: "error" });
	};

	const resetDetails = () => {
		setShowRateCardModal(false);
		setIsFirstTime(true);
		setZoneWiseDetails([]);
		setExistingRatesZoneWiseDetails([]);
		setServiceType("");
		setIsEditMode(false);
		setAlertDetails(null);
		setSelectedShowTableOption(WEIGHT_TYPES.OZ);
	};

	// Render cell based on the existing details check
	const renderCell = (index, zone, weightUnit) => {
		if (
			(existingRatesZoneWiseDetails[WEIGHT_TYPES.LBS] &&
				existingRatesZoneWiseDetails[WEIGHT_TYPES.LBS].length) ||
			(existingRatesZoneWiseDetails[WEIGHT_TYPES.OZ] &&
				existingRatesZoneWiseDetails[WEIGHT_TYPES.OZ].length)
		) {
			if (zoneWiseDetails[weightUnit][zone]?.[index]?.isValidRate) {
				return (
					<Td
						className="!text-center text-[#EBEBEB99] rounded-2xl"
						key={`${weightUnit}_${zone}_${index}`}
					>
						<div className="flex justify-center h-12 w-[132px]">
							<span className="">
								$
								{existingRatesZoneWiseDetails[weightUnit][zone]?.[index]
									?.rate ?? 0}
							</span>
							<span className="w-[1px] bg-[#EBEBEB1A] rotate-[60deg]"></span>
							<span className="text-[#FFB323] font-medium self-end">
								${zoneWiseDetails[weightUnit][zone][index].rate}
							</span>
						</div>
					</Td>
				);
			} else {
				return (
					<Td className="!text-center" key={`${weightUnit}_${zone}_${index}`}>
						<div className="flex justify-center h-12 bg-[#FF263F55] rounded-2xl p-1 w-[132px]">
							<span className="">
								$
								{existingRatesZoneWiseDetails[weightUnit][zone]?.[index]
									?.rate ?? 0}
							</span>
							<span className="w-[1px] bg-[#EBEBEB1A] rotate-[60deg]"></span>
							<span className="text-[#FFB323] self-end">
								{!isEditMode && (
									<span>
										${zoneWiseDetails[weightUnit][zone]?.[index].rate}
									</span>
								)}
								{isEditMode && (
									<span className="flex ml-5 w-[50px]">
										<NumberInput
											size="sm"
											defaultValue={
												zoneWiseDetails[weightUnit][zone]?.[index].rate
											}
											maxW={14}
											onChange={(valueAsNumber) =>
												updateHandler(valueAsNumber, index, zone, weightUnit)
											}
										>
											<NumberInputField className="!px-2" />
										</NumberInput>
									</span>
								)}
							</span>
						</div>
					</Td>
				);
			}
		} else {
			if (zoneWiseDetails[weightUnit][zone][index].isValidRate) {
				return (
					<Td
						className="!text-center text-[#EBEBEB99] rounded-2xl w-[132px]"
						key={`${weightUnit}_${zone}_${index}`}
					>
						${zoneWiseDetails[weightUnit][zone][index].rate}
					</Td>
				);
			} else {
				return (
					<Td className="!text-center" key={`${weightUnit}_${zone}_${index}`}>
						<div className="text-[#EBEBEB] rounded-2xl font-bold h-9 flex justify-center items-center w-[112px]">
							{!isEditMode && (
								<span className="bg-[#FF263F99] p-3 rounded-2xl min-w-16 text-center">
									${zoneWiseDetails[weightUnit][zone][index].rate}
								</span>
							)}
							{isEditMode && (
								<NumberInput
									defaultValue={zoneWiseDetails[weightUnit][zone]?.[index].rate}
									maxW={14}
									onChange={(valueAsNumber) =>
										updateHandler(valueAsNumber, index, zone, weightUnit)
									}
								>
									<NumberInputField className="!bg-[#FF263F99] !px-2" />
								</NumberInput>
							)}
						</div>
					</Td>
				);
			}
		}
	};

	return (
		<>
			<AccountTabs />
			<Page>
				{(viewingAs.isShipper || viewingAs.isCarrier) && (
					<Card
						mt="20px"
						border="1px"
						borderRadius="20px"
						borderWidth="1px"
						borderColor="rgba(133, 134, 152, 0.2)"
						p={8}
					>
						<h3 className="text-xl font-bold mb-2">
							View or provide Rates: {viewingAs?.name}
						</h3>
						<p className="mb-6">
							Upload xls file to update Rates information or Download xls file
							to view Rates information
						</p>
						<FormControl>
							<RadioGroup
								className="mb-5"
								onValueChange={(val) => setSelectedOption(val)}
								value={selectedOption}
							>
								{viewingAs?.isCarrier && (
									<div className="flex items-center text-lg">
										<Radio value="CARRIER" id="CARRIER" className=""></Radio>
										<label
											htmlFor="CARRIER"
											className="cursor-pointer text-white"
										>
											Carrier Rates
										</label>
									</div>
								)}
								{viewingAs?.isShipper && (
									<div className="flex items-center text-lg">
										<Radio value="SHIPPER" id="SHIPPER"></Radio>
										<label
											htmlFor="SHIPPER"
											className="cursor-pointer text-white"
										>
											Shipper Rates
										</label>
									</div>
								)}
							</RadioGroup>
						</FormControl>
						<div className="flex items-center gap-6">
							<Button
								disabled={!selectedOption}
								isLoading={isRateDownloading}
								onClick={generateRateCard}
								prefixIcon={<ArrowDownTrayIcon />}
								context={!selectedOption ? "outlined" : "primary"}
								className={`${!selectedOption ? "!bg-[#292A35]" : ""}`}
							>
								Download
							</Button>
							{(user.isPlatformSuperAdmin || user.isFinance) &&
								isNonProdEnvironment && (
									<ImportFile
										isDataExists={false}
										isImportFileDisable={!selectedOption}
										selectedOption={selectedOption}
										endpoint="import-rate-card"
										showDownloadTemplateBtn={false}
										onFileSelected={onFileSelected}
									/>
								)}
						</div>
					</Card>
				)}
				{!!alertDetails && (
					<Alert
						status={alertDetails.status}
						mt={6}
						backgroundColor={alertDetails.bgColor}
					>
						<AlertIcon color={alertDetails.iconColor} />
						<AlertDescription className="flex-1">
							<div className="flex justify-between">
								<div>
									<p className="text-md font-semibold">{alertDetails.title}</p>
									<p className="text-xs text-[#C8C5C5]">
										{alertDetails.description}
									</p>
								</div>
								<div className="flex">
									<Button
										context="text"
										onClick={() => setShowRateCardModal(true)}
									>
										View
									</Button>
									<Button context="text" onClick={resetDetails}>
										Dismiss
									</Button>
								</div>
							</div>
						</AlertDescription>
					</Alert>
				)}
			</Page>

			<Modal isOpen={showRateCardModal} onClose={resetDetails}>
				<ModalOverlay />
				<ModalContent className="!w-auto !max-w-[90vw]">
					<ModalHeader className="text-center">Rate Card</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<TableContainer className="max-h-[400px] !overflow-y-scroll">
							<Table variant="simple">
								<Thead>
									<Tr>
										<Th className="py-2">Weight</Th>
										{zonesForWeightTypes[selectedShowTableOption].map(
											(zone) => (
												<Th className="!text-center" key={zone}>
													Zone {zone}
												</Th>
											),
										)}
									</Tr>
								</Thead>
								<Tbody>
									{isRateFetching && (
										<Tr>
											<Td colSpan={100} className="!text-center !p-[100px]">
												<Spinner />
											</Td>
										</Tr>
									)}
									{!isRateFetching &&
										selectedShowTableOption === WEIGHT_TYPES.OZ &&
										zoneWiseDetails[WEIGHT_TYPES.OZ] &&
										zoneWiseDetails[WEIGHT_TYPES.OZ].length > 1 &&
										zoneWiseDetails[WEIGHT_TYPES.OZ][
											zonesForWeightTypes[WEIGHT_TYPES.OZ][0]
										]?.map((zwItem, index) => (
											<Tr
												className="border-b border-gray-700 h-14 text-sm"
												key={`${WEIGHT_TYPES.OZ}_${index}`}
											>
												<Td className="text-[#EBEBEBCC]">
													{zwItem.weight} {zwItem.weightUnit}
												</Td>
												{zonesForWeightTypes[WEIGHT_TYPES.OZ].map((zone) => {
													return renderCell(index, zone, WEIGHT_TYPES.OZ);
												})}
											</Tr>
										))}
									{!isRateFetching &&
										selectedShowTableOption === WEIGHT_TYPES.LBS &&
										zoneWiseDetails[WEIGHT_TYPES.LBS] &&
										zoneWiseDetails[WEIGHT_TYPES.LBS].length > 1 &&
										zoneWiseDetails[WEIGHT_TYPES.LBS][
											zonesForWeightTypes[WEIGHT_TYPES.LBS][0]
										]?.map((zwItem, index) => (
											<Tr
												className="border-b border-gray-700 h-14 text-sm"
												key={`${WEIGHT_TYPES.LBS}_${index}`}
											>
												<Td className="text-[#EBEBEBCC]">
													{zwItem.weight} {zwItem.weightUnit}
												</Td>
												{zonesForWeightTypes[WEIGHT_TYPES.LBS].map((zone) => {
													return renderCell(index, zone, WEIGHT_TYPES.LBS);
												})}
											</Tr>
										))}
								</Tbody>
							</Table>
						</TableContainer>
						<div className="mt-4">
							{areBothZonesDataAvailable() && (
								<Button
									context="outlined"
									onClick={() =>
										setSelectedShowTableOption(
											selectedShowTableOption === WEIGHT_TYPES.LBS
												? WEIGHT_TYPES.OZ
												: WEIGHT_TYPES.LBS,
										)
									}
								>
									<div className="flex items-center gap-1 text-sm">
										<span>{`Show ${
											selectedShowTableOption === WEIGHT_TYPES.LBS
												? WEIGHT_TYPES.OZ
												: WEIGHT_TYPES.LBS
										} card`}</span>
										<ExpandIcon
											fill="#74BCFF"
											style={{ transform: "rotate(135deg)" }}
										/>
									</div>
								</Button>
							)}
							<div className="flex justify-end gap-2 mt-4 mb-4 flex-2">
								{!isAnomalyCheckPassed && !isEditMode && (
									<Button
										className="text-sm !h-8 w-20"
										onClick={() => setIsEditMode(true)}
									>
										Edit
									</Button>
								)}
								<Button
									className="text-sm !h-8 w-20"
									onClick={saveHandler}
									isLoading={isRateSaving}
								>
									Save
								</Button>
							</div>
						</div>
					</ModalBody>
				</ModalContent>
			</Modal>

			<AlertDialog isOpen={showConfirmationDialog}>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize="lg" fontWeight="bold">
							Rates Confirmation
						</AlertDialogHeader>

						<AlertDialogBody>
							{isAnomalyCheckPassed
								? "Do you really want to save the rates?"
								: "The rate card to be uploaded still has some values that appear anomalous and may need to be corrected. Do you still want to proceed with the upload?"}
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button onClick={saveConfirmHandler}>Yes</Button>
							<Button
								context="outlined"
								ref={cancelRef}
								className="ml-3"
								onClick={() => setShowConfirmationDialog(false)}
							>
								No
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
}

export default Rates;
