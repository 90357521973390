import React, { useCallback, useContext, useEffect, useState } from "react";
import { components } from "react-select";
import { Box, Text, Divider, Spinner } from "@chakra-ui/react";

import CurrentDate from "../interfaces/Dashboard/CurrentDate";
import { MultiSelect, CarrierWhatIfScenario, Checkbox, UserContext } from "../interfaces";
import {
  getCarrierAddCoverageMap,
  getCarrierAddImpact,
  getCarrierAddInsights,
  getCarrierAddRateCompetitiveness,
  getCarrierAddition,
} from "../services";
import { Button } from "../componentLibrary";

const checkedBoxStyle = {
  "& .chakra-checkbox__control": {
    background: "#2294FF",
    border: "0 !important",
  },
  "& .chakra-checkbox__control svg": {
    stroke: "#EBEBEB !important",
  },
};

const Menu = ({ children, onSubmit, ...props }) => {
  return (
    <components.Menu {...props}>
      {children}
      <div className="p-2 flex flex-col items-center gap-[10px]">
        <Divider className="my-2 !border-b-2" />
        <Button
          context={props.getValue().length === 0 ? "outlined" : "primary"}
          className="!w-max !px-4 !h-10 !text-sm !font-medium"
          onClick={onSubmit}
          disabled={props.getValue().length === 0}
        >
          Apply
        </Button>
      </div>
    </components.Menu>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  const isAllSelected = props.selectProps.value.find(
    (option) => option.value === "all"
  );

  return (
    <components.ValueContainer {...props}>
      {isAllSelected
        ? isAllSelected.label
        : props.selectProps.value?.length > 5
          ? `${props.selectProps.value?.length} Carrier Selected`
          : values}
      {input}
    </components.ValueContainer>
  );
};

const CarrierAddition = () => {
  const [isOpenMenu, setIsOpenMenu] = useState({
    baseConfig: false,
    newCarriers: false,
  });

  const [carrierConfig, setCarrierConfig] = useState({
    baseConfig: [],
    newCarriers: [],
  });
  const [selectedCarrier, setSelectedCarrier] = useState({
    baseConfig: [],
    newCarriers: [],
  });

  const [carriers, setCarriers] = useState({
    baseConfig: [],
    newCarriers: [],
  });
  const [isLoadingCarriers, setIsLoadingCarriers] = useState(true);

  const [isLoadingResult, setIsLoadingResult] = useState(false);
  const [isResultDisabled, setIsResultDisabled] = useState(true);
  const [analysisResult, setAnalysisResult] = useState(null);

  const { token } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      try {
        const carriers = await getCarrierAddition(token);
        const baseConfigOption = ["All Carriers", ...carriers[0].value].map(
          (carrier) => ({
            value: carrier === "All Carriers" ? "all" : carrier,
            label: carrier,
          })
        );

        setCarriers({
          baseConfig: baseConfigOption,
          newCarriers: carriers[1].value.map((carrier) => ({
            value: carrier,
            label: carrier,
          })),
        });

        setCarrierConfig((config) => ({
          ...config,
          baseConfig: baseConfigOption,
        }));
        setSelectedCarrier((carriers) => ({
          ...carriers,
          baseConfig: baseConfigOption,
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingCarriers(false);
      }
    })();
  }, []);

  const handleOpenMenu = useCallback((key) => {
    setIsOpenMenu((menus) => ({ ...menus, [key]: true }));
  }, []);

  const handleCloseMenu = useCallback(
    (key, isApplyButtonClicked) => {
      if (!isApplyButtonClicked) {
        setCarrierConfig((config) => ({
          ...config,
          [key]: selectedCarrier[key],
        }));
      }
      setIsOpenMenu((menus) => ({ ...menus, [key]: false }));
    },
    [selectedCarrier]
  );

  const handleCarrierChange = useCallback((data, event, key, opt) => {
    const isAllOptSelected = event.option.value === "all";
    if (event.action === "select-option") {
      if (isAllOptSelected) {
        setCarrierConfig((config) => ({ ...config, [key]: opt }));
      } else {
        if (opt && opt.length - 1 === data.length) {
          setCarrierConfig((config) => ({
            ...config,
            [key]: [{ value: "all", label: "All Carriers" }, ...data],
          }));
        } else {
          setCarrierConfig((config) => ({ ...config, [key]: data }));
        }
      }
    } else {
      if (isAllOptSelected) {
        setCarrierConfig((config) => ({ ...config, [key]: [] }));
      } else {
        const clonedData = JSON.parse(JSON.stringify(data));
        if (clonedData.find((option) => option.value === "all")) {
          clonedData.splice(0, 1);
        }
        setCarrierConfig((config) => ({ ...config, [key]: clonedData }));
      }
    }
  }, []);

  const handleSubmitCarriers = useCallback(
    (key) => {
      setSelectedCarrier((carriers) => {
        if (carrierConfig.newCarriers.length > 0) {
          setIsResultDisabled(false);
        } else {
          setIsResultDisabled(true);
        }
        return {
          ...carriers,
          [key]: carrierConfig[key],
        };
      });
      handleCloseMenu(key, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [carrierConfig]
  );

  const handleViewResults = useCallback(async () => {
    setIsLoadingResult(true);
    try {
      const coverageMapPayload = [
        {
          label: "Base configurations",
          value: selectedCarrier.baseConfig
            .map((carrier) => carrier.value)
            .filter((carrier) => carrier !== "all"),
        },
        {
          label: "New carriers",
          value: selectedCarrier.newCarriers.map((carrier) => carrier.value),
        },
      ];
      const coverageMap = await getCarrierAddCoverageMap(coverageMapPayload, token);
      const impact = await getCarrierAddImpact(coverageMapPayload, token);
      const insights = await getCarrierAddInsights(coverageMapPayload, token);
      const rateCompetitiveness =
        await getCarrierAddRateCompetitiveness(coverageMapPayload, token);

      setAnalysisResult({
        coverageMap,
        impact,
        insights,
        rateCompetitiveness,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsResultDisabled(true);
      setIsLoadingResult(false);
    }
  }, [selectedCarrier]);

  if (isLoadingCarriers) {
    return (
      <Box display="flex" justifyContent="center" my={"10em"}>
        <Spinner />
      </Box>
    );
  }

  return (
    <div className="flex-grow p-5 ml-2 mt-2">
      <CurrentDate className={"font-medium"} dateFormat={"d MMM yyyy HH:mm"} />

      <Text className="mt-11 text-lg font-semibold text-[#F9F9FF]">
        What-if Analysis When a New Carrier is Added to the Existing Orchestro
        Network
      </Text>

      <Box
        marginTop={3}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"flex-end"}
        className="gap-x-6 xl:gap-x-10"
      >
        <div className="mb-3">
          <Text className="pb-[10px] text-sm font-medium tracking-[0.28px] text-[#EBEBEB]">
            Existing Carriers - Base Configuration
          </Text>

          <div className="w-[250px] md:w-[300px] lg:w-[340px] relative">
            <MultiSelect
              placeholder={"Select onboarded carriers"}
              options={carriers.baseConfig}
              components={{
                Menu: (props) => (
                  <Menu
                    {...props}
                    onSubmit={() => handleSubmitCarriers("baseConfig")}
                  />
                ),
                ValueContainer,
              }}
              value={carrierConfig.baseConfig}
              onChange={(data, action) => {
                handleCarrierChange(
                  data,
                  action,
                  "baseConfig",
                  carriers.baseConfig
                );
              }}
              onMenuClose={() => handleCloseMenu("baseConfig")}
              onMenuOpen={() => handleOpenMenu("baseConfig")}
              menuIsOpen={isOpenMenu.baseConfig}
            />
          </div>
        </div>

        <div className="mb-3">
          <Text className="pb-[10px] text-sm font-medium tracking-[0.28px] text-[#EBEBEB]">
            New Carrier
          </Text>

          <div className="w-[250px] md:w-[300px] lg:w-[340px] relative">
            <MultiSelect
              placeholder={"Select new carriers"}
              options={carriers.newCarriers}
              components={{
                Menu: (props) => (
                  <Menu
                    {...props}
                    onSubmit={() => handleSubmitCarriers("newCarriers")}
                  />
                ),
                ValueContainer,
              }}
              value={carrierConfig.newCarriers}
              onChange={(data, action) => {
                handleCarrierChange(data, action, "newCarriers");
              }}
              onMenuClose={() => handleCloseMenu("newCarriers")}
              onMenuOpen={() => handleOpenMenu("newCarriers")}
              menuIsOpen={isOpenMenu.newCarriers}
            />
          </div>
        </div>

        <div className="mb-3">
          <Button
            context={!isResultDisabled ? "primary" : "outlined"}
            className="!px-5 !h-[44px] text-sm font-medium"
            disabled={isResultDisabled}
            onClick={handleViewResults}
          >
            View Results
          </Button>
        </div>
      </Box>

      {isLoadingResult ? (
        <Box display={"flex"} justifyContent={"center"} mt={52}>
          <Spinner />
        </Box>
      ) : (
        analysisResult && (
          <CarrierWhatIfScenario
            analysisResult={analysisResult}
            isCarrierAdditionPage={true}
          />
        )
      )}
    </div>
  );
};

export default CarrierAddition;
