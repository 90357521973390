export const mockTrackingData = {
    "results": [
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "DELIVERED ",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 06, 2024 01:28 PM",
            "timezone": null,
            "city": null,
            "state": null,
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "Delivered",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Out For Delivery Today",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 06, 2024 10:03 AM",
            "timezone": null,
            "city": null,
            "state": null,
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Processing at UPS Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 06, 2024 09:15 AM",
            "timezone": null,
            "city": null,
            "state": null,
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "UPS will be in your area and will deliver your package a day early.",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 06, 2024 09:08 AM",
            "timezone": null,
            "city": null,
            "state": null,
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "Out for Delivery",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Package transferred to post office ",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 05, 2024 01:32 PM",
            "timezone": null,
            "city": null,
            "state": null,
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Processing at UPS Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 02, 2024 03:51 PM",
            "timezone": null,
            "city": "Middleburg Heights",
            "state": "OH",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Package in transit and scheduled for UPS delivery attempt to recipient on next UPS business day.",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 02, 2024 03:50 PM",
            "timezone": null,
            "city": "Middleburg Heights",
            "state": "OH",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "Out for Delivery",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Package in transit and scheduled for UPS delivery attempt to recipient on next UPS business day.",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 02, 2024 06:36 AM",
            "timezone": null,
            "city": "Middleburg Heights",
            "state": "OH",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "Out for Delivery",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Arrived at Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 11:51 PM",
            "timezone": null,
            "city": "Middleburg Heights",
            "state": "OH",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": "DADL",
            "status": "Departed from Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 09:33 PM",
            "timezone": null,
            "city": "Columbus",
            "state": "OH",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": "grey circle"
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Arrived at Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 03:49 PM",
            "timezone": null,
            "city": "Columbus",
            "state": "OH",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": "DADL",
            "status": "Departed from Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 10:37 AM",
            "timezone": null,
            "city": "New Stanton",
            "state": "PA",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": "grey circle"
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Arrived at Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 08:42 AM",
            "timezone": null,
            "city": "New Stanton",
            "state": "PA",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": "DADL",
            "status": "Departed from Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 04:09 AM",
            "timezone": null,
            "city": "Easton",
            "state": "PA",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": "grey circle"
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Arrived at Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "August 01, 2024 12:19 AM",
            "timezone": null,
            "city": "Easton",
            "state": "PA",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": "DADL",
            "status": "Departed from Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "July 31, 2024 10:00 PM",
            "timezone": null,
            "city": "Lyndhurst",
            "state": "NJ",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": "grey circle"
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": null,
            "status": "Arrived at Facility",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "July 31, 2024 07:56 PM",
            "timezone": null,
            "city": "Lyndhurst",
            "state": "NJ",
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "In Transit",
            "sliderPoint": null
        },
        {
            "trackingNumber": "P85LJ7J9",
            "carrierTrackingNumber": "1ZH4A877YW04910574",
            "uspsTrackingNumber": "92612903033072543475000650",
            "carrierName": "UPSMI",
            "networkTrackCode": "DLCT",
            "status": "Shipper created a label, UPS has not received the package yet. ",
            "comments": null,
            "alternateStatusCode": null,
            "deliverySignature": null,
            "timestamp": "July 30, 2024 05:31 PM",
            "timezone": null,
            "city": null,
            "state": null,
            "zip": null,
            "latitude": 0,
            "longitude": 0,
            "containerTrackingId": null,
            "pod": null,
            "uiStatus": "Label Created",
            "sliderPoint": "green tick"
        }
    ]
}