import { NavLink } from "react-router-dom";
import { Text } from "@chakra-ui/react";

import classes from "./IntegrationTabs.module.css";

export function IntegrationTabs() {
  const accountIntegrationPageRoot = "/app/account/integration";
  const linkClass = ({ isActive }) =>
    isActive ? `${classes.links} ${classes.active}` : classes.links;

  return (
    <nav role="navigation" className={classes.nav}>
      <ul className={classes.navList}>
        <li>
          <NavLink
            className={linkClass}
            to={accountIntegrationPageRoot + "/last-mile-carrier"}
          >
            <Text>Last Mile Carrier</Text>
          </NavLink>
        </li>
        <li>
          <NavLink
            className={linkClass}
            to={accountIntegrationPageRoot + "/shipper"}
          >
            <Text>Shipper</Text>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
