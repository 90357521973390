import React, { useState, useCallback } from "react";
import { get, capitalize } from "lodash";
import {
  Card,
  Flex,
  Heading,
  FormControl,
  InputRightElement,
  FormErrorMessage,
  InputGroup,
  Button,
  Icon,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { Divider, Label } from "../../../componentLibrary";
import { InputField } from "../../InputFields";
import { DropdownIndicator } from "../preferences/DropdownIndicator";
import { Select } from "../../ReactSelect";
import { AddIcon } from "../../CustomIcons";

const optionRoleType = [
  { value: "Admin", label: "Admin" },
  { value: "Technical", label: "Technical" },
  { value: "Billing / AR", label: "Billing / AR" },
  { value: "Customer Service", label: "Customer Service" },
  { value: "Operational", label: "Operational" },
];

const selectStyles = (isError) => ({
  container: (styles, { isDisabled }) => ({
    ...styles,
    width: "100% !important",
    marginTop: "10px",
    // cursor: `${isDisabled ? "not-allowed" : "text"}`,
  }),
  control: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      height: "52px !important",
      borderRadius: "4px !important",
      backgroundColor: `${isDisabled || isFocused ? "transparent" : "#85869833"} !important`,
      border: `1px solid ${isDisabled ? "#85869833" : isFocused ? "#63b3ed" : isError ? "#FC8181" : "transparent"} !important`,
      opacity: isDisabled ? "0.75" : "1",
    };
  },
  input: (styles) => ({
    ...styles,
    marginLeft: "0 !important",
  }),
  singleValue: (styles) => ({
    ...styles,
    marginLeft: "0 !important",
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    marginLeft: "0 !important",
    color: `${isDisabled ? "#EBEBEB99" : ""} !important`,
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    color: `${isDisabled ? "#EBEBEB99" : ""} !important`,
  }),
});

export const ContactForm = ({
  formik,
  carrierInfo,
  isEditMode,
  labelClassName,
  inputClassName,
}) => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    setFieldTouched,
  } = formik;

  const [focusedField, setFocusedField] = useState("");

  const handleSelectChanges = useCallback((option, key) => {
    setFieldValue(key, option.label);
    // eslint-disable-next-line
  }, []);

  const handleSelectBlur = useCallback((key) => {
    setFieldTouched(key);
    // eslint-disable-next-line
  }, []);

  const handleFieldFocus = useCallback((e) => {
    e.preventDefault();
    setTimeout(() => {
      setFocusedField(() => e.target.name);
    }, 140);
  }, []);

  const handleFieldBlur = useCallback((e) => {
    e.preventDefault();
    setTimeout(() => {
      handleBlur(e);
      setFocusedField(() => "");
    }, 130);
    // eslint-disable-next-line
  }, []);

  const handleEnterPressed = useCallback((e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
    // eslint-disable-next-line
  }, []);

  const handleDismissFieldChanges = useCallback(
    (key) => {
      setFieldValue(key, get(carrierInfo, key));
    },
    // eslint-disable-next-line
    [carrierInfo]
  );

  const handleAddNewContact = () => {
    setFieldValue("contacts", [
      ...values.contacts,
      {
        contactType: "OFFICIAL",
        admin: false,
        primary: false,
        firstName: "",
        lastName: "",
      },
    ]);
  };

  return (
    <Card
      mb={7}
      borderRadius="20px"
      borderWidth="1px"
      borderColor="rgba(133, 134, 152, 0.2)"
      py={9}
      px={"44px"}
    >
      <Heading as={"h2"} size={"md"} mb={"30px"}>
        Contact
      </Heading>

      <Flex flexDirection={"column"} className="gap-11">
        {values.contacts.map(
          (contact, index) =>
            contact.primary && (
              <ContactFields
                key={index}
                index={index}
                contact={contact}
                isEditMode={isEditMode}
                formik={formik}
                handleFieldFocus={handleFieldFocus}
                focusedField={focusedField}
                handleFieldBlur={handleFieldBlur}
                handleDismissFieldChanges={handleDismissFieldChanges}
                handleEnterPressed={handleEnterPressed}
                labelClassName={labelClassName}
                inputClassName={inputClassName}
                handleSelectChanges={handleSelectChanges}
                handleSelectBlur={handleSelectBlur}
              />
            )
        )}
        {values.contacts.map(
          (contact, index) =>
            !contact.primary && (
              <ContactFields
                key={index}
                index={index}
                contact={contact}
                isEditMode={isEditMode}
                formik={formik}
                handleFieldFocus={handleFieldFocus}
                focusedField={focusedField}
                handleFieldBlur={handleFieldBlur}
                handleDismissFieldChanges={handleDismissFieldChanges}
                handleEnterPressed={handleEnterPressed}
                labelClassName={labelClassName}
                inputClassName={inputClassName}
                handleSelectChanges={handleSelectChanges}
                handleSelectBlur={handleSelectBlur}
              />
            )
        )}
        <div className="text-left">
          <Button
            leftIcon={
              <Icon
                as={AddIcon}
                boxSize={5}
                className="border rounded-full text-[#74BCFF]"
              />
            }
            color="#74BCFF"
            variant="link"
            isDisabled={!isEditMode}
            onClick={handleAddNewContact}
          >
            Add new contact
          </Button>
        </div>
        <Divider
          orientation="horizontal"
          className="!border-[#85869833] my-2"
        />
        <Flex gap={6}>
          <FormControl
            isInvalid={
              !!errors?.address?.address1 && touched?.address?.address1
            }
          >
            <Label
              htmlFor="address.address1"
              className={labelClassName(
                !!errors?.address?.address1 && touched?.address?.address1
              )}
            >
              Address 1 *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={values.address.address1 ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`address.address1`}
                id={`address.address1`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === "address.address1" && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`address.address1`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Address 1 is required
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!errors?.address?.address2 && touched?.address?.address2
            }
          >
            <Label
              htmlFor="address.address2"
              className={labelClassName(
                !!errors?.address?.address2 && touched?.address?.address2
              )}
            >
              Address 2 *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={values.address.address2 ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`address.address2`}
                id={`address.address2`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === "address.address2" && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`address.address2`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Address 2 is required
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex gap={6}>
          <FormControl
            isInvalid={!!errors?.address?.city && touched?.address?.city}
          >
            <Label
              htmlFor="address.city"
              className={labelClassName(
                !!errors?.address?.city && touched?.address?.city
              )}
            >
              City *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={values.address.city ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`address.city`}
                id={`address.city`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === "address.city" && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`address.city`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              City is required
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors?.address?.state && touched?.address?.state}
          >
            <Label
              htmlFor="address.state"
              className={labelClassName(
                !!errors?.address?.state && touched?.address?.state
              )}
            >
              State *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={values.address.state ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`address.state`}
                id={`address.state`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === "address.state" && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`address.state`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              State is required
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors?.address?.zip && touched?.address?.zip}
          >
            <Label
              htmlFor="address.zip"
              className={labelClassName(
                !!errors?.address?.zip && touched?.address?.zip
              )}
            >
              Zip *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={values.address.zip ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`address.zip`}
                id={`address.zip`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === "address.zip" && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`address.zip`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Zip is required
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors?.address?.country && touched?.address?.country}
          >
            <Label
              htmlFor="address.country"
              className={labelClassName(
                !!errors?.address?.country && touched?.address?.country
              )}
            >
              Country *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={values.address.country ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`address.country`}
                id={`address.country`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === "address.country" && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`address.country`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Country is required
            </FormErrorMessage>
          </FormControl>
        </Flex>
      </Flex>
    </Card>
  );
};

const InputCloseIcon = ({ handleDismissFieldChanges, name }) => {
  return (
    <InputRightElement
      top={4}
      right={1}
      onClick={() => {
        handleDismissFieldChanges(name);
      }}
    >
      <CloseIcon w={3} h={3} />
    </InputRightElement>
  );
};

const ContactFields = ({
  index,
  contact,
  isEditMode,
  formik,
  handleFieldFocus,
  focusedField,
  handleFieldBlur,
  handleDismissFieldChanges,
  handleEnterPressed,
  labelClassName,
  inputClassName,
  handleSelectChanges,
  handleSelectBlur,
}) => {
  const { handleChange, errors, touched } = formik;

  return (
    <div>
      <div className="opacity-60 mt-2 mb-7 text-lg font-medium">
        {capitalize(`${contact?.contactType} Contact`)}
      </div>
      <Flex flexDirection={"column"} gap={6}>
        <Flex gap={6}>
          <FormControl
            isInvalid={
              !!errors?.contacts?.[index]?.firstName &&
              touched?.contacts?.[index]?.firstName
            }
          >
            <Label
              htmlFor={`contacts[${index}].firstName`}
              className={labelClassName(
                !!errors?.contacts?.[index]?.firstName &&
                  touched?.contacts?.[index]?.firstName
              )}
            >
              First Name *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={contact.firstName ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`contacts[${index}].firstName`}
                id={`contacts[${index}].firstName`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === `contacts[${index}].firstName` && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`contacts[${index}].firstName`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              First Name is required
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!errors?.contacts?.[index]?.lastName &&
              touched?.contacts?.[index]?.lastName
            }
          >
            <Label
              htmlFor={`contacts[${index}].lastName`}
              className={labelClassName(
                !!errors?.contacts?.[index]?.lastName &&
                  touched?.contacts?.[index]?.lastName
              )}
            >
              Last Name *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={contact.lastName ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`contacts[${index}].lastName`}
                id={`contacts[${index}].lastName`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === `contacts[${index}].lastName` && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`contacts[${index}].lastName`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Last Name is required
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!errors?.contacts?.[index]?.designation &&
              touched?.contacts?.[index]?.designation
            }
          >
            <Label
              htmlFor={`contacts[${index}].designation`}
              className={labelClassName(
                !!errors?.contacts?.[index]?.designation &&
                  touched?.contacts?.[index]?.designation
              )}
            >
              Designation *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={contact.designation ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`contacts[${index}].designation`}
                id={`contacts[${index}].designation`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === `contacts[${index}].designation` && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`contacts[${index}].designation`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Designation is required
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!errors?.contacts?.[index]?.roleType &&
              touched?.contacts?.[index]?.roleType
            }
          >
            <Label
              htmlFor={`contacts[${index}].roleType`}
              className={labelClassName(
                isEditMode &&
                  !!errors?.contacts?.[index]?.roleType &&
                  touched?.contacts?.[index]?.roleType
              )}
            >
              Role Type
            </Label>
            <Select
              value={{ value: contact.roleType, label: contact.roleType }}
              className={inputClassName}
              onChange={(options) =>
                handleSelectChanges(options, `contacts[${index}].roleType`)
              }
              onBlur={() => handleSelectBlur("roleType")}
              name={`contacts[${index}].roleType`}
              id={`contacts[${index}].roleType`}
              onFocus={handleFieldFocus}
              onKeyUp={handleEnterPressed}
              options={optionRoleType}
              isDisabled={!isEditMode}
              customStyles={selectStyles(
                !!errors?.contacts?.[index]?.roleType &&
                  touched?.contacts?.[index]?.roleType
              )}
              components={
                {
                  // DropdownIndicator: (props) => (
                  //   <DropdownIndicator
                  //     {...props}
                  //     handleDismissFieldChanges={handleDismissFieldChanges}
                  //   />
                  // ),
                }
              }
            />
            {isEditMode && (
              <FormErrorMessage className="!mt-3">
                Role Type is required
              </FormErrorMessage>
            )}
          </FormControl>
        </Flex>
        <Flex gap={6} width={"calc(50% - 1rem)"}>
          <FormControl
            isInvalid={
              !!errors?.contacts?.[index]?.phone &&
              touched?.contacts?.[index]?.phone
            }
          >
            <Label
              htmlFor={`contacts[${index}].phone`}
              className={labelClassName(
                !!errors?.contacts?.[index]?.phone &&
                  touched?.contacts?.[index]?.phone
              )}
            >
              Phone number *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={contact.phone ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`contacts[${index}].phone`}
                id={`contacts[${index}].phone`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === `contacts[${index}].phone` && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`contacts[${index}].phone`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Phone number is required
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={
              !!errors?.contacts?.[index]?.email &&
              touched?.contacts?.[index]?.email
            }
          >
            <Label
              htmlFor={`contacts[${index}].email`}
              className={labelClassName(
                !!errors?.contacts?.[index]?.email &&
                  touched?.contacts?.[index]?.email
              )}
            >
              Email *
            </Label>
            <InputGroup>
              <InputField
                className={inputClassName}
                isDisabled={!isEditMode}
                value={contact.email ?? ""}
                onChange={handleChange}
                onBlur={handleFieldBlur}
                name={`contacts[${index}].email`}
                id={`contacts[${index}].email`}
                onFocus={handleFieldFocus}
                onKeyUp={handleEnterPressed}
              />
              {focusedField === `contacts[${index}].email` && (
                <InputCloseIcon
                  handleDismissFieldChanges={handleDismissFieldChanges}
                  name={`contacts[${index}].email`}
                />
              )}
            </InputGroup>
            <FormErrorMessage className="!mt-3">
              Email is required
            </FormErrorMessage>
          </FormControl>
        </Flex>
      </Flex>
    </div>
  );
};
