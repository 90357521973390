import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";

export const calculateTotalDistance = (coordinates) => {
	let totalDistance = 0;
	for (let i = 0; i < coordinates.length - 1; i++) {
		const start = turf.point(coordinates[i]);
		const end = turf.point(coordinates[i + 1]);
		const distance = turf.distance(start, end);
		totalDistance += distance;
	}
	return totalDistance;
};

const interpolateCoords = (start, end, fraction) => {
	return [
		start[0] + (end[0] - start[0]) * fraction,
		start[1] + (end[1] - start[1]) * fraction,
	];
};


export const addPopup = (mapRef, parcelDelivery, finalCoord) => {
	const popup = new mapboxgl.Popup({
		closeOnClick: true,
		offset: { bottom: [0, -50] },
	})
		.setLngLat(finalCoord)
		.setHTML(
			`${parcelDelivery.city?.trim() || ""}${parcelDelivery.city ? "," : ""} ${parcelDelivery.state || ""}${parcelDelivery.zip ? "," : ""} ${parcelDelivery.zip || ""}`.trim(),
		)
		.addTo(mapRef.current);
};

const zoomIntoRoute = (mapRef, coordinates, onComplete) => { 
	const targetZoom = 5.5;
	const targetCoord = coordinates[0];

	mapRef.current.flyTo({
		center: targetCoord,
		zoom: 2.5,
		duration: 0,
	});

	mapRef.current.easeTo({
		center: targetCoord,
		zoom: targetZoom,
		duration: 2000,
		easing: (t) => t, 
	});

	setTimeout(onComplete, 2000);
};

export const animateMarker = (
	mapRef,
	markersGeoJSON,
	movingMarkerFeature,
	coordinates,
	coordIndex = 0,
	locationData,
	trackingData,
) => {
	let index = coordIndex;

	if (!coordinates || coordinates.length < 2) {
		console.error("Not enough coordinates for animation");
		return;
	}

	const totalDistance = calculateTotalDistance(coordinates);
	const maxSpeed = 0.26;
	const minSpeed = 0.14;
	const baseSpeed =
		totalDistance >= 600
			? maxSpeed
			: Math.max(minSpeed, Math.min(maxSpeed, totalDistance / 10));

	trackingData.forEach((item) => {
		if (item.status === "Created") {
			if (item.city === null) {
				item.city = locationData.shipFrom.city;
				item.state = locationData.shipFrom.state;
				item.zip = locationData.shipFrom.zip;
			}
		}
	});

	let parcelDelivery = "";

	for (let i = 0; i < trackingData.length; i++) {
		const item = trackingData[i];
		if (item.latitude && item.longitude && item.uiStatus !== "Label Created") {
			parcelDelivery = item;
			break;
		}
	}

	const finalCoord = coordinates[coordinates.length - 1];

	if (index >= coordinates.length - 1) {
		movingMarkerFeature.geometry.coordinates = finalCoord;
    	mapRef.current.getSource("markers").setData(markersGeoJSON);
		addPopup(mapRef, parcelDelivery, finalCoord);
		console.error("Starting index is too high, no animation possible.");
		return;
	}


	let start = coordinates[index];
	let end = coordinates[index + 1];
	let totDistance = turf.distance(turf.point(start), turf.point(end));
	let progress = 0;

	function updateCamera(mapRef, currentCoord) {
		// const bearing = turf.bearing(turf.point(coordinates[0]), turf.point(currentCoord));

  		// mapRef.current.setCenter(currentCoord);
  		// mapRef.current.setBearing(bearing);
  		// mapRef.current.setPitch(60); 
		const camera = mapRef.current.getFreeCameraOptions();
		const targetCoord = coordinates[0];

		const altitude = 1500000;
		const bearing = turf.bearing(turf.point(targetCoord), turf.point(currentCoord));
		const pitch = 60;

		camera.position = mapboxgl.MercatorCoordinate.fromLngLat(currentCoord, altitude);
		camera.bearing = bearing;
		//camera.pitch = pitch;

		camera.lookAtPoint({
			lng: currentCoord[0],
			lat: currentCoord[1],
		});

		mapRef.current.setFreeCameraOptions(camera);
	}

	function moveMarker(timestamp) {

		progress += (baseSpeed / totDistance) * 20;


		if (!start || !end || !Array.isArray(start) || !Array.isArray(end)) {
			console.error("Invalid coordinates for marker animation:", {
				start,
				end,
			});
			return;
		}

    

		if (progress >= 1) {
			index++;
			if (index >= coordinates.length - 1) {
				addPopup(mapRef, parcelDelivery, finalCoord);
				movingMarkerFeature.geometry.coordinates = finalCoord;
    mapRef.current.getSource("markers").setData(markersGeoJSON);
				return;
			}

			start = coordinates[index];
			end = coordinates[index + 1];
			totDistance = turf.distance(turf.point(start), turf.point(end));
			progress = 0;
		}

		const currentCoord = interpolateCoords(start, end, progress);

		movingMarkerFeature.geometry.coordinates = currentCoord;
		mapRef.current.getSource('markers').setData(markersGeoJSON);

		updateCamera(mapRef, currentCoord);


		requestAnimationFrame(moveMarker);
	}

	zoomIntoRoute(mapRef, coordinates, () => {
		requestAnimationFrame(moveMarker);
	});
};
