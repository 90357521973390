import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import { useLocalStorage } from "../../../hooks";
import {
	SOMETHING_WENT_WRONG,
	ERROR_FETCH_ALERTS,
} from "../../../shared/constants/ErrorMessages";

const coreServiceUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const coreServicePath = process.env.REACT_APP_CORE_SERVICES_PATH;
const environment = process.env.REACT_APP_ENVIRONMENT;
const apiUrl = coreServiceUrl + coreServicePath;

export const UserContext = React.createContext({
	user: null,
	isDemo: false,
	isDemoEnvironment: false,
	isLoggingOut: false,
	isNonProdEnvironment: false,
	alert: {},
	viewingAs: {},
	viewingAsByCode: "",
	filters: [],
	accountOptions: [],
	setAccountOptions: () => {},
	setViewingAs: () => {},
	setViewingAsByCode: () => {},
	createAlert: () => {},
	dismissAlert: () => {},
	logoutUser: () => {},
	setFilters: () => {},
	setDemoMode: () => {},
});

export function UserProvider({ children }) {
	const [token, setToken] = useLocalStorage("accessToken", null);
	const [isDemoEnvironment] = React.useState(
		environment === "localhost" ||
			environment === "dev" ||
			environment === "dev-e1" ||
			environment === "test-e1",
	);
	const [isDemo, setIsDemo] = useLocalStorage("isDemo", false);
	const [isNonProdEnvironment] = React.useState(
		!(environment === "prod" || environment === "stage-e1"),
	);
	const [viewingAsByCode, setViewingAsByCode] = React.useState("");
	const [viewingAs, setViewingAs] = React.useState({});

	const [alert, setAlert] = useLocalStorage("alert", {});
	const [filters, setFilters] = React.useState([]);
	const [accountOptions, setAccountOptions] = React.useState([]);
	const [shipmentFilters, setShipmentFilters] = React.useState(false);
	const [isLoggingOut, setIsLoggingOut] = React.useState(false);

	const {
		user: userProfile,
		isAuthenticated,
		logout,
		getAccessTokenSilently,
	} = useAuth0();

	useEffect(() => {
		const updateUser = async () => {
			if (!isAuthenticated) return;
			const accessToken = await getAccessTokenSilently();
			setToken(`Bearer ${accessToken}`);
		};
		updateUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	const logoutUser = () => {
		setIsLoggingOut(true);

		// Clear local storage for filters
		localStorage.removeItem("shipmentFilters");

		// Revoke the users session in the OCN service
		fetch(apiUrl + "/revoke-token", {
			method: "DELETE",
			headers: {
				Authorization: token,
			},
		})
			.then((response) => {
				if (response.status !== 204) {
					console.error("Failed to revoke token on logout");
				}
			})
			.finally(() => {
				// Perform Auth0 sign out
				logout({
					logoutParams: {
						returnTo: window.location.origin,
					},
				});
			});
	};

	const createAlert = () => {
		const getAlerts = async () => {
			if (!token) return;

			try {
				const response = await fetch(apiUrl + "/fetch-alerts", {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: token,
					},
				});
				const resp = await response.json();
				if (resp.error) {
					console.error(SOMETHING_WENT_WRONG);
				} else {
					setAlert(resp);
				}
			} catch (error) {
				console.error(ERROR_FETCH_ALERTS, error);
			}
		};
		getAlerts();
	};

	const dismissAlert = () => {
		setAlert({});
		// Also need to PUT to /ack-alert
	};

	const setDemoMode = () => {
		if (isDemoEnvironment) {
			setIsDemo(!isDemo);
		}
	};

	let user = React.useMemo(() => {
		// TODO: Parse user permissions from token. At some point will be remove Groups and then services will use the accessToken instead
		const parsedToken = token ? JSON.parse(atob(token.split(".")[1])) : null;
		let roles =
			userProfile && userProfile["user/roles"] ? userProfile["user/roles"] : [];

		// No SUPER_ADMIN in production envs
		if (environment === "prod" || environment === "stage-e1") {
			roles = roles.filter((g) => g !== "PLATFORM_SUPER_ADMIN");
		}

		return {
			...userProfile,
			Groups: parsedToken?.permissions || [],
			isPlatformSuperAdmin: roles.length
				? roles.includes("PLATFORM_SUPER_ADMIN")
				: false,
			isPlatformAdmin: roles.length ? roles.includes("PLATFORM_ADMIN") : false,
			isNetworkAdmin: roles.length ? roles.includes("NETWORK_ADMIN") : false,
			isMemberAdmin: roles.length ? roles.includes("MEMBER_ADMIN") : false,
			isInternal: roles.length ? roles.includes("INTERNAL") : false,
			isCarrier: roles.length ? roles.includes("CARRIER") : false,
			isShipper: roles.length ? roles.includes("SHIPPER") : false,
			isSupport: roles.length ? roles.includes("SUPPORT") : false,
			isFinance: roles.length
				? roles.includes("FINANCE" || roles.includes("INTERNAL"))
				: false,
			participantCode: parsedToken?.permissions[0] || [],
		};
	}, [token, userProfile]);

	useEffect(() => {
		if (user.isPlatformAdmin) {
			setViewingAsByCode("ORCHESTRO");
		}
		setViewingAs(user);
	}, [user]);

	// useEffect(() => {
	//   console.log(`user:`, user)
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [user]);

	const contextValue = {
		alert,
		filters,
		isDemo,
		isDemoEnvironment,
		isLoggingOut,
		isNonProdEnvironment,
		shipmentFilters,
		token,
		viewingAs,
		viewingAsByCode,
		user,
		accountOptions,
		createAlert,
		dismissAlert,
		logoutUser,
		setDemoMode,
		setFilters,
		setShipmentFilters,
		setViewingAs,
		setViewingAsByCode,
		setAccountOptions,
	};

	return (
		<UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
	);
}
