import React, { useState, useEffect } from "react";
import History from "./History";

const TrackHistoryPanel = ({ data }) => {
	const [historyData, setHistoryData] = useState([]);

	useEffect(() => {
		if (data.length > 0) {
			setHistoryData(data);
		}
	}, [data]);

	const filteredHistory = historyData;

	return (
		<div className="absolute top-0 right-0 bg-[#1E2028] w-[520px] h-[85vh] text-white px-4 pb-4 pt-2 m-4 shadow-lg rounded-sm">
			<h2 className="text-lg font-semibold mb-2">Tracking ID <span className="font-normal">{filteredHistory[0]?.trackingNumber || ""}</span> </h2>

			<div className="text-xs mb-4">Times shown are in the local time of the parcel's location</div>
			<div className="absolute w-[1px] bg-[#D2D3E0] left-[9rem] top-24 bottom-8"></div>
			<div className="mt-10 overflow-y-auto max-h-[70vh]">
				{filteredHistory.map((item, index) => {
					return (
					<div
						key={index}
						className="relative flex flex-row items-center justify-start mb-6"
					>
						<div
							className={`${index === 0 ? "text-[#FFB323]" : "text-[#EBEBEB]"} text-xs w-24 text-right mx-2`}
						>	{item.timestamp}
						</div>

						<div className="flex ml-[16px]" id="connecter">
							<img src="/images/map/connector.svg" alt="connector" />
						</div>
						<History
							itemNumber={index}
							title={item.status || ""}
							status={item.uiStatus || ""}
							location={` ${item.city || ""}${item.city && item.state ? ', ' : ''}${item.state || ""}`}
							last={index === 0? true : false}
						/>
					</div>
					);
})}
			</div>
		</div>
	);
};

export default TrackHistoryPanel;
