import React from "react";
import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { Button } from "../../componentLibrary";
import { BaseDrawer } from "../BaseDrawer";
import DashboardFilterPanel from "./DashboardFilterPanel";
import ShipmentListFilterPanel from "./ShipmentListFilterPanel";
import styles from "./FilterPanel.module.css";

const FilterPanel = ({
	isOpen = false,
	onClose,
	formik,
	handleApplySelectedFilters = () => {},
	selectedFilter,
	...props
}) => {
	const { pathname } = useLocation();

	const handleCheckboxChange = (event) => {
		// console.log("Checkbox state changed:", event.target.checked);
	};

	return (
		<BaseDrawer
			open={isOpen}
			onClose={onClose}
			drawerHeader={selectedFilter?.name ? selectedFilter.name : "Filter"}
			closeIcon={<img alt="close filters" src="/images/close-filter.svg" />}
			variant={"deepNavyBlue"}
			size={"sm"}
			drawerBodyClasses={styles.drawerBody}
			drawerHeaderClasses={styles.drawerHeader}
		>
			{pathname.includes("dashboard") ? (
				<DashboardFilterPanel handleCheckboxChange={handleCheckboxChange} />
			) : (
				<ShipmentListFilterPanel
					formik={formik}
					onClose={onClose}
					selectedFilter={selectedFilter}
					{...props}
				/>
			)}

			{!selectedFilter && (
				<div className="flex p-6 justify-center gap-4">
					<Button
						className="text-sm font-semibold w-[142px]"
						context="textSecondary"
						onClick={onClose}
					>
						Cancel
					</Button>
					<Button
						className="text-sm font-semibold w-[142px]"
						onClick={() => {
							handleApplySelectedFilters();
							formik?.handleSubmit();
						}}
						type="submit"
					>{`Apply Filter(s)`}</Button>
				</div>
			)}
		</BaseDrawer>
	);
};

export default FilterPanel;

FilterPanel.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};
