import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { components } from "react-select";
import classes from "./Header.module.css";
import {
	Avatar,
	Badge,
	Box,
	Flex,
	FormControl,
	FormLabel,
	Menu,
	MenuButton,
	MenuDivider,
	MenuGroup,
	MenuItem,
	MenuList,
	Switch,
	Text,
	Divider,
	useDisclosure,
} from "@chakra-ui/react";

import { CreateAccount, Select, UserContext } from "../";
import { Button } from "../../componentLibrary";
import { SearchIcon } from "../CustomIcons";
import { getParticipant } from "../../services";
import SearchField from "../../utilities/searchfield";

function BlankMenuItem({ children }) {
	return (
		<Box mx={4} mb={1}>
			{children}
		</Box>
	);
}

const Control = ({ children, ...props }) => (
	<components.Control {...props}>
		<SearchIcon fill="#EBEBEB" className="!w-5 !h-5 ml-2" /> {children}
	</components.Control>
);

const MenuForSelect = ({ children, onOpenAccountModal, ...props }) => {
	const { user } = useContext(UserContext);
	return (
		<components.Menu {...props}>
			{children}
			{/* Hide "Add New Account" for 1.9 release */}
			{false && user.isPlatformAdmin && (
				<div className="p-2 flex flex-col items-center gap-[10px]">
					<Divider className="my-2 !border-b-2" />
					<Button
						context="primary"
						className="!w-max !px-4 !h-10 !text-sm !font-medium"
						onClick={onOpenAccountModal}
					>
						Add New Account
					</Button>
				</div>
			)}
		</components.Menu>
	);
};

export function Header({ handleFilter }) {
	const location = useLocation();
	const {
		accountOptions,
		isDemo,
		isDemoEnvironment,
		token,
		user,
		viewingAs,
		viewingAsByCode,
		logoutUser,
		setDemoMode,
		setViewingAs,
		setViewingAsByCode,
	} = useContext(UserContext);
	const logout = async () => await logoutUser();
	const {
		isOpen: isOpenAccountModal,
		onOpen: onOpenAccountModal,
		onClose: onCloseAccountModal,
	} = useDisclosure();

	// const [searchQuery, setSearchQuery] = useState("");
	const [accounts, setAccounts] = useState([]);
	const [isAccountLoading, setIsAccountLoading] = useState(true);

	const isAccountPage = useMemo(
		() => location.pathname.includes("account/"),
		[location],
	);

	useEffect(() => {
		if (isAccountPage) {
			(async () => {
				try {
					const response = await getParticipant(token);
					if (response) {
						response.sort((a, b) => a.name.localeCompare(b.name));
						setAccounts(response);
					}
				} catch (error) {
					console.error("Error fetching data:", error);
				} finally {
					setIsAccountLoading(false);
				}
			})();
		}
		// eslint-disable-next-line
	}, [isAccountPage]);

	useEffect(() => {
		setAccounts(accountOptions);
	}, [accountOptions]);

	useEffect(() => {
		const account = accounts?.find(
			(account) => account.code === viewingAsByCode,
		);
		const nextViewingAs = {
			Groups: [account?.code] || [],
			isPlatformSuperAdmin: false,
			isPlatformAdmin: false,
			isNetworkAdmin: false,
			isMemberAdmin: false,
			isInternal: false,
			isCarrier: account?.participantType === "CARRIER",
			isShipper: account?.participantType === "BRAND",
			isSupport: false,
			isFinance: false,
			name: account?.name,
			participantCode: account?.code,
		};
		setViewingAs(nextViewingAs);
		// eslint-disable-next-line
	}, [accounts, viewingAsByCode]);

	useEffect(() => {
		const permission = user?.Groups[0];
		if (isAccountPage && permission && !viewingAsByCode) {
			setViewingAsByCode(permission);
		}
		// eslint-disable-next-line
	}, [isAccountPage, accounts]);

	const handleSearch = (query) => {
		// setSearchQuery(query);
	};

	function MenuAvatar() {
		return <Avatar bg="#c8cacc" width={"40px"} height={"40px"} />;
	}

	const permission = user.Groups[0];

	const showAccountSelector = useMemo(() => {
		return (
			(user.isFinance || user.isPlatformAdmin || user.isPlatformSuperAdmin) &&
			isAccountPage &&
			!isAccountLoading &&
			accounts?.length > 0
		);
	}, [accounts, isAccountPage, isAccountLoading, user]);

	return (
		<Box className={classes.header}>
			<Flex className={classes.headerContainer} justifyContent="space-between">
				<Box>
					{showAccountSelector && (
						<>
							<div className="text-sm font-medium text-[#EBEBEB] mb-1 mt-[-7px]">
								Account
							</div>
							<Select
								name="account"
								className={"min-w-[300px] !mt-0"}
								options={accounts.map(({ code, name }) => ({
									value: code,
									label: name,
								}))}
								onChange={(option) => {
									setViewingAsByCode(option.value);
								}}
								value={{
									value: viewingAs.participantCode,
									label: viewingAs.name,
								}}
								components={{
									Control,
									Menu: ({ children, ...props }) => (
										<MenuForSelect
											onOpenAccountModal={onOpenAccountModal}
											{...props}
										>
											{children}
										</MenuForSelect>
									),
								}}
								customStyles={{
									singleValue: (base) => ({
										...base,
										marginLeft: "2rem !important",
									}),
								}}
							/>
						</>
					)}
					{!showAccountSelector && isDemo && (
						<SearchField onSearch={handleSearch} filterHandler={handleFilter} />
					)}
				</Box>
				<Menu>
					<MenuButton height={"44px"}>
						<MenuAvatar />
					</MenuButton>
					<MenuList>
						<MenuGroup title={user?.name}></MenuGroup>
						<BlankMenuItem>
							<Text fontSize={"xs"}>{user?.email}</Text>
							<div className="flex gap-2 my-1">
								{user.isPlatformSuperAdmin && (
									<Badge fontSize={"10px"} colorScheme="green">
										Platform Super Admin
									</Badge>
								)}
								{user.isPlatformAdmin && (
									<Badge fontSize={"10px"} colorScheme="green">
										Platform Admin
									</Badge>
								)}
								{user.isMemberAdmin && (
									<Badge fontSize={"10px"} colorScheme="green">
										Member Admin
									</Badge>
								)}
								{user.isNetworkAdmin && (
									<Badge fontSize={"10px"} colorScheme="green">
										Network Admin
									</Badge>
								)}
								{user.isInternal && (
									<Badge fontSize={"10px"} colorScheme="orange">
										Internal
									</Badge>
								)}
								{user.isFinance && (
									<Badge fontSize={"10px"} colorScheme="orange">
										Finance
									</Badge>
								)}
								{user.isSupport && (
									<Badge fontSize={"10px"} colorScheme="orange">
										Support
									</Badge>
								)}
							</div>
							<div className="flex gap-2 my-1">
								{user.isCarrier && <Badge fontSize={"10px"}>Carrier</Badge>}
								{user.isShipper && <Badge fontSize={"10px"}>Shipper</Badge>}
								{permission && <Badge fontSize={"10px"}>{permission}</Badge>}
							</div>
						</BlankMenuItem>
						{user.isPlatformAdmin &&
							viewingAs.participantCode &&
							viewingAs.participantCode !== "ORCHESTRO" && (
								<>
									<MenuDivider />
									<BlankMenuItem>
										<Text fontSize={"xs"}>Viewing as:</Text>
										<div className="flex gap-2 my-1">
											<Badge fontSize={"10px"} colorScheme="red">
												{viewingAs.name} ({viewingAs.isCarrier && "Carrier"}
												{viewingAs.isShipper && "Shipper"})
											</Badge>
										</div>
									</BlankMenuItem>
								</>
							)}
						<MenuDivider />
						{isDemoEnvironment && (
							<BlankMenuItem>
								<FormControl
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									ml="-5px"
									onChange={setDemoMode}
								>
									<FormLabel m="0">
										<Text fontSize={"xs"}>
											Demo mode: {isDemo ? "Active" : "Inactive"}
										</Text>
									</FormLabel>
									<Switch size="sm" name="demoFlag" defaultChecked={isDemo} />
								</FormControl>
							</BlankMenuItem>
						)}
						<MenuDivider />
						<MenuItem onClick={logout}>Log out</MenuItem>
					</MenuList>
				</Menu>
			</Flex>
			<CreateAccount open={isOpenAccountModal} onClose={onCloseAccountModal} />
		</Box>
	);
}
